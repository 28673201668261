import { Icon } from 'antd'
import styled from 'styled-components'

const iconSize = '18px'

export const StyledIcon = styled(Icon)`
  color: rgb(140, 140, 140);
  margin-right: 15px;
  font-size: ${iconSize};
  cursor: pointer;
`

export const CapsText = styled.span`
  text-transform: uppercase;
  font-size: 14.5px;
  font-weight: bold;
  letter-spacing: 0.6px;
`

export const NormalText = styled.span`
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.3px;
`

export const MutedText = styled.span`
  font-size: 13px;
  color: rgb(180, 180, 180);
`

export const StyledMenu = styled.div`
  padding: 7px 15px 15px 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06) !important;
  box-sizing: border-box;
  border: 1px solid #d5dce0;
  width: 350px;
  max-width: 350px;

  .ant-select {
    width: 100%;
    font-size: 13.222px;
    font-weight: 400;
    color: #151b26;
  }

  .ant-select-dropdown-menu-item {
    padding: 8px 12px;
    text-align: left;

    &:hover {
      background: rgb(245, 247, 249);
    }
  }

  .ant-select-dropdown-menu-item-active {
    background: rgb(245, 247, 249);
  }

  .ant-btn {
    font-size: 13.5px;
    font-weight: 500;
    width: 100%;
    margin-top: 30px;
    height: 34px;
  }
`

export const ArrowPanel = styled.div`
  text-align: center;
  padding: 10px 0;

  .anticon {
    font-size: 14px;
  }
`

export const RolloverTitle = styled.div`
  padding: 8px 0 12px;
  font-size: 13.222px;
  color: #151b26;
  text-align: left;
`

export const NotItemsFound = styled.div`
  padding-top: 20px;
  text-align: center;
  font-size: 13.222px;
  color: #151b26;
`
