import { DocumentStatus } from '@/types/graphql'

import { DeleteOutlined, DollarOutlined, MailOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Tooltip, Typography } from 'antd-v5'
import startCase from 'lodash/startCase'

import { DocUsage } from '@/components/Workflow/Document/Container'
import StatusIcons from '@/components/Workflow/Document/StatusIcons'
import { usePersistedBookingData } from '@/store/bookingData'
import { documentType } from '@/utils/labelMap'

const Document = props => {
  const { uploadedDoc, documentApproval, vouchers = [], requests = [], onClick } = props

  const { bookingData } = usePersistedBookingData()

  const isDeleted = uploadedDoc?.status === DocumentStatus.Deleted
  const isVerified = uploadedDoc?.status === DocumentStatus.Verified

  const docPOD = bookingData?.jobs?.flatMap(job =>
    job?.trips
      ?.filter(trip => trip.uuid === uploadedDoc.tripUuid)
      .map(trip => {
        const jobNoPart = job.jobNo.slice(10, job.jobNo.length)
        return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
      })
  )

  return (
    <div
      className="cursor-pointer p-2 shadow-sm inline-block rounded"
      style={{
        width: '192px',
        borderLeft: isVerified ? '2px solid #167fe0' : !isDeleted ? '2px solid #f4c542' : 'none'
      }}
      onClick={onClick}
    >
      <Typography.Text className="text-xs font-medium mt-1">
        <Tooltip title={startCase(uploadedDoc.type)}>
          <Typography.Text ellipsis className="overflow-hidden text-sm" style={{ width: '128px' }}>
            {startCase(documentType[uploadedDoc.type] || uploadedDoc.type)}
          </Typography.Text>
        </Tooltip>
        <DocUsage>
          {requests.length > 0 && (
            <Tooltip title="Attached to requests.">
              <MailOutlined />
            </Tooltip>
          )}
          {vouchers.length > 0 && (
            <Tooltip title="Attached to vouchers.">
              <DollarOutlined />
            </Tooltip>
          )}
        </DocUsage>
      </Typography.Text>
      <Typography.Text
        ellipsis
        className="text-xs font-medium text-black overflow-hidden"
        style={{ width: '170px' }}
      >
        <Typography.Text
          ellipsis
          className="inline-block text-black font-medium font-medium overflow-hidden"
          style={{ width: '128px' }}
        >
          {isDeleted ? <DeleteOutlined /> : <PaperClipOutlined />}
          {uploadedDoc.document.name}
          {docPOD}
        </Typography.Text>
        <StatusIcons uploadedDoc={uploadedDoc} documentApproval={documentApproval || {}} />
      </Typography.Text>
    </div>
  )
}

export default Document
