import type { BillingUnitsForSelectorQuery } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'
import { BillingUnitStatus } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { logger } from 'App/utils/logger'
import { hasPermissionError } from 'App/utils/u'

const BILLING_UNITS_QUERY = gql(`
  query billingUnitsForSelector(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BillingUnitStatus]
  ) {
    billingUnits(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        uuid
        code
      }
    }
  }
`)

type BillingUnitSelectProps = SelectProps & {
  getUuid?: boolean
  getAll?: boolean
}

const BillingUnitSelect = (props: BillingUnitSelectProps) => {
  const { getUuid, getAll, ...selectProps } = props
  const { value, mode, style, onChange } = selectProps

  const [billingUnits, setBillingUnits] = useState<
    NonNullable<NonNullable<BillingUnitsForSelectorQuery>['billingUnits']>['rows']
  >([])
  const [searchInput, setSearchInput] = useState('')

  const params = useMemo(
    () => ({
      q: mode === 'multiple' ? searchInput || '' : value || searchInput || '',
      statuses: getAll
        ? [BillingUnitStatus.Activated, BillingUnitStatus.Deleted]
        : [BillingUnitStatus.Activated],
      limit: 20
    }),
    [value, searchInput]
  )

  const { loading, error, data, refetch } = useQuery(BILLING_UNITS_QUERY, {
    variables: params,
    fetchPolicy: 'cache-first'
  })

  const onSearch = useCallback(
    debounce(value => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        console.error(error)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (setBillingUnits) setBillingUnits(data?.billingUnits?.rows)
  }, [data])

  if (error) {
    logger.error('BillingUnitSelect BillingUnitQuery error', error)
  }

  return (
    <Select
      allowClear
      showSearch
      mode={mode}
      style={style}
      value={value}
      loading={loading}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={mode === 'multiple'}
      placeholder="Select a billing unit..."
      notFoundContent={
        loading
          ? 'Searching...'
          : error && hasPermissionError(error)
            ? 'No permission to view this.'
            : 'Not found.'
      }
      options={billingUnits?.map(u => ({ label: u?.code, value: getUuid ? u?.uuid : u?.code }))}
      {...selectProps}
    />
  )
}

export default memo(BillingUnitSelect)
