import { DeleteOutlined } from '@ant-design/icons'
import { graphql } from '@apollo/client/react/hoc'
import { Popconfirm } from 'antd-v5'
import PropTypes from 'prop-types'
import { compose, withHandlers } from 'recompose'

import { StyledButton } from '@/components/ButtonWrapper'
import { DELETE_LEG } from '@/graphql/booking'

const withGraphqlDeleteLeg = graphql(DELETE_LEG, {
  props: ({ mutate }) => ({
    deleteLeg: uuid =>
      mutate({
        variables: {
          uuid
        }
      })
  })
})

const handlers = {
  onConfirm: props => async () => {
    try {
      await props.deleteLeg(props.leg.uuid)
      props.onLegDeleted()
    } catch (error) {
      console.log(error)
    }
  }
}

const enhance = compose(withGraphqlDeleteLeg, withHandlers(handlers))

const LegDeleteAction = ({ type, onConfirm }) => {
  return (
    <>
      <Popconfirm
        arrow={false}
        title="Are you sure?"
        okText="Delete"
        okType="danger"
        onConfirm={onConfirm}
      >
        {type === 'icon' && (
          <StyledButton>
            <DeleteOutlined />
          </StyledButton>
        )}
        {!type && <StyledButton>Delete</StyledButton>}
      </Popconfirm>
    </>
  )
}

LegDeleteAction.propTypes = {
  onConfirm: PropTypes.func
}

export default enhance(LegDeleteAction)
