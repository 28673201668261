import type { FormItemLayout } from '@/components/Manage/FormItemMapper'
import type { TreeRateGroup } from '@/store/rate'
import type { Connector, NodeSelectorQuery, Root } from '@/types/graphql'
import type { SetURLSearchParams } from 'react-router-dom'

import { get, groupBy } from 'lodash'

import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'

export const rateCompactLayout: FormItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 12 }
  }
}
export const onValuesChange = (props, changedValues, allValues) => {
  if (props.onChange) {
    props.onChange(allValues)
  }
}

export const nodeReducer = (node: any, path: string) => {
  const k = get(node, path)
  const keys: string[] = []

  if (k) {
    keys.push(k)
  }

  if (node?.children?.length) {
    const childrenKeys = node.children.flatMap(n => nodeReducer(n, path))
    keys.push(...childrenKeys)
  }

  return keys
}

export const getTreeTitle = (firstEntryCondition: NodeSelectorQuery, selectorKey: string) => {
  if (!firstEntryCondition) return 'new node'
  // TODO: should include all and truncate
  const { comparator, value } = firstEntryCondition //@ts-ignore
  const formattedComparator = comparator.replace('$', '').toUpperCase()

  let keyValue: string | undefined = undefined
  let formattedValue = value

  if (selectorKey) {
    const formattedSelectorKey = selectorKey.split('.')
    const lastWord = formattedSelectorKey.pop()
    const beforeLastWord = formattedSelectorKey.pop()

    keyValue = lastWord
    if (lastWord === 'length') {
      keyValue = `${beforeLastWord} count`
    }
  }

  if (typeof value === 'string' && value.includes('|')) {
    formattedValue = value.split('|')[0]
  }

  const identifier = `${formattedComparator} ${formattedValue}`
  return `${identifier}${keyValue ? ` | ${keyValue}` : ''}`
}

export const stripUnwantedObjKey = (value: Record<string, any>, unwantedKeys: string[] = []) => {
  if (!unwantedKeys.length) return value

  const unwantedObjKeyStripper = value => {
    if (typeof value === 'object' && value !== null) {
      Object.entries(value).forEach(([key, val]) => {
        if (unwantedKeys.includes(key)) {
          delete value[key]
        } else {
          unwantedObjKeyStripper(val)
        }
      })
    } else if (Array.isArray(value)) {
      value.forEach(v => unwantedObjKeyStripper(v))
    }
  }

  unwantedObjKeyStripper(value)
}

export const nodeFinder = (
  node: Record<string, any> | Record<string, any>[],
  matchValue: string,
  matchKey: string = '_id',
  nestedKey: string = 'children'
): Record<string, any> | null => {
  if (!node) return null

  if (Array.isArray(node)) {
    for (const n of node) {
      const found = nodeFinder(n, matchValue, matchKey, nestedKey)

      if (found) return found
    }

    return null
  } else if (typeof node === 'object') {
    const value = get(node, matchKey)

    if (value === matchValue) return node

    return nodeFinder(node[nestedKey], matchValue, matchKey, nestedKey)
  }

  return null
}

export const getRootTitle = (node: Root | null) =>
  node ? `${node.code}-${node.name}` : 'Create new root'

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

export const nodeFlatter = (node: any) => {
  const { children, ...rest } = node
  let nodes: Record<string, any>[] = []

  if (rest) {
    nodes = nodes.concat(rest)
  }

  if (children?.length) {
    const childrenNodes = children.flatMap(nodeFlatter)
    nodes = nodes.concat(...childrenNodes)
  }

  return nodes
}

export const getTreeKey = (parentId: string, nodeId: string = 'new node') =>
  `${parentId}:${nodeId || 'new node'}`

export const parseNodeKey = (key: string) => {
  const [parentId, nodeId] = key.split(':')

  return { parentId, nodeId }
}

export const getMode = (key: string) =>
  parseNodeKey(key).nodeId === 'new node' ? FormMode.Create : FormMode.Edit

export const getValue = (key: string, allNodes: any[]) => {
  const { parentId, nodeId } = parseNodeKey(key)
  const mode = getMode(key)

  const obj: any = {}

  if (mode === FormMode.Edit) {
    obj.nodeId = nodeId

    const node = allNodes.find(n => n.childId === nodeId && n.parentId === parentId)

    if (node) {
      obj.query = node.childSelector.query
    }
  }

  obj.parentId = parentId

  return obj
}

export const getPaths = (nodeId: string, allNodes: any[], paths: string[] = []) => {
  if (!nodeId) return paths

  const node = allNodes.find(n => n.nodeId === nodeId)

  if (!node) return paths

  const parentNode = allNodes.find(n => n.nodeId === node.parentId)

  if (!parentNode) return paths

  const k = getTreeKey(parentNode.nodeId, node.nodeId)

  const amendedPaths = [k, ...paths]

  return getPaths(parentNode.parentId, allNodes, amendedPaths)
}

export const removeNestedKeys = (nodeId: string, keys: string[]) => {
  const o = parseNodeKey(nodeId)

  const r = keys.find(n => parseNodeKey(n).parentId === o.nodeId)

  if (!r) return keys

  return removeNestedKeys(
    r,
    keys.filter(k => k !== r)
  )
}

export const recursiveRemoveNestedKeys = (nodeId: string[], keys: string[]) => {
  const k = nodeId.reduce((acc, n) => removeNestedKeys(n, acc), keys)

  return k
}

export const searchParamSoftUpdater =
  (setSearchParamsFn: SetURLSearchParams) => (obj: Record<string, any>) => {
    setSearchParamsFn(prev => {
      const updated = new URLSearchParams(prev.toString())

      Object.entries(obj).forEach(([key, value]) => {
        if (value) {
          updated.set(key, value)
        } else {
          updated.delete(key)
        }
      })

      return updated
    })
  }

export const convertToRateTreeGroups = (sourceId: string, arr: Connector[]) => {
  const byTarget = groupBy(arr, 'target')
  const bySource = groupBy(arr, 'source')

  const treeify = (nodeId: string): TreeRateGroup => {
    const children = bySource[nodeId]?.map(a => treeify(a.target)) || []

    return { nodeId, children, parentId: byTarget[nodeId]?.[0]?.source }
  }

  return [treeify(sourceId)]
}
