import type { Maybe, ProcessFlowStepType } from '@/types/graphql'

import { Steps, Tooltip } from 'antd-v5'
import findIndex from 'lodash/findIndex'

type ProgressProps = {
  steps?: Maybe<ProcessFlowStepType>[] | null
}

const Progress = ({ steps = [] }: ProgressProps) => {
  const foundCurrentStepIndex = findIndex(steps, step => !!step?.isCurrentStep)
  let currentIndex: number

  if (steps && steps[steps.length - 1]?.isCurrentStep) {
    currentIndex = steps.length - 1 // Set to last one
  } else if (steps && steps[steps.length - 1]?.isComplete) {
    currentIndex = steps.length // Set past it.
  } else if (foundCurrentStepIndex >= 0) {
    currentIndex = foundCurrentStepIndex
  } else {
    currentIndex = -1
  }

  const stepItems = steps?.map(s => {
    return {
      title: (
        <Tooltip placement="bottom" title={s?.helpText}>
          {s?.helpText}
        </Tooltip>
      )
    }
  })

  return <Steps size="small" progressDot current={currentIndex} items={stepItems} />
}

export default Progress
