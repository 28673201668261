import type { Booking } from '@/types/graphql'

import { Typography } from 'antd-v5'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'

import Container, { ItemsWrapper } from '@/components/Workflow/Request/Container'
import RequestItem from '@/components/Workflow/Request/RequestItem'
import Requisition from '@/components/Workflow/Requisition'
import { NoDocument } from '@/components/Workflow/Styled'

type RequestProps = {
  booking?: Booking | null
  requisitions?: { text; type; get; send }[]
}

const Request = (props: RequestProps) => {
  const { booking, requisitions } = props

  const filteredRequests = filter(
    booking?.requests,
    request => findIndex(requisitions, requisition => requisition.type === request?.type) !== -1
  )

  const sortedRequests = sortBy(filteredRequests, request => {
    return findIndex(requisitions, r => request?.type === r.type)
  })

  const requests = sortedRequests.map((req, index) => ({
    key: index,
    uuid: req?.uuid,
    type: req?.type,
    status: req?.status,
    createdAt: req?.createdAt
  }))

  return (
    <Container>
      <Typography.Text strong>
        Request / Notify
        <Requisition booking={booking} requisitions={requisitions} />
      </Typography.Text>
      <ItemsWrapper>
        {requests.length === 0 ? <NoDocument>No Request</NoDocument> : null}
        {requests.map(request => (
          <RequestItem key={request.uuid} request={request} />
        ))}
      </ItemsWrapper>
    </Container>
  )
}

export default Request
