import type { TransportZone } from 'App/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { TRANSPORT_ZONES_QUERY } from 'App/components/Manage/TransportZones/schema'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

type TransportZonesProps = {
  value?: any
  onChange?: any
  disabled?: boolean
  mode?: string
  placeholder?: string
  style?: object
  isRouteZones?: boolean
  zoneConvert?: any
}

export const useZonesCodeArray = () =>
  useQuery(TRANSPORT_ZONES_QUERY, {
    variables: {},
    fetchPolicy: 'cache-and-network'
  })

export const routeZones = (zoneConvert: any, transportZones: any) => {
  const zonesArray = transportZones.map((zone: TransportZone) => zone.code)
  const selectedZones = zonesArray.filter(
    (zone: string) => !Object.keys(zoneConvert).includes(zone)
  )
  const zonesArr: any = []
  for (let i = 0; i < selectedZones.length; i++) {
    const from = selectedZones[i]
    for (let j = 0; j < selectedZones.length; j++) {
      const to = selectedZones[j]
      if (zonesArr.includes(`${to}-${from}`)) continue
      zonesArr.push(`${from}-${to}`)
    }
  }
  return zonesArr
}

const TransportZonesSelect = forwardRef((props: TransportZonesProps, ref: any) => {
  const {
    value,
    onChange,
    zoneConvert,

    mode = 'default',
    disabled = false,
    isRouteZones = false,
    style = { width: '100%' },
    placeholder = 'Select a transport zone...'
  } = props

  const [searchInput, setSearchInput] = useState(value)
  const [whichZone, setWhichZone] = useState([])

  const parsedSearchInput = useMemo(() => {
    if (mode == 'default') return searchInput

    return ''
  }, [mode, searchInput])

  const { data, error, loading, refetch } = useQuery(TRANSPORT_ZONES_QUERY, {
    variables: { q: parsedSearchInput },
    fetchPolicy: 'cache-and-network'
  })

  if (error) {
    logger.error('TransportZone Error: ', error)
    respHandler(error, 'error')
  }

  useEffect(() => {
    if (data?.transportZones?.rows?.length) {
      const transportZones = data.transportZones.rows

      setWhichZone(isRouteZones ? routeZones(zoneConvert, transportZones) : transportZones)
    }
  }, [data, isRouteZones, zoneConvert])

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInput(value)
      refetch()
    }, 500),
    []
  )

  return (
    <Select
      ref={ref}
      allowClear
      // @ts-ignore
      mode={mode}
      style={style}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onSearch={handleSearch}
      showSearch
      placeholder={placeholder}
      loading={loading}
    >
      {whichZone?.map((zone: any) => (
        <Select.Option key={zone.uuid || zone.code} value={zone.code}>
          {zone.code}
        </Select.Option>
      ))}
    </Select>
  )
})

export default memo(TransportZonesSelect)
