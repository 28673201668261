import type { DynamicField, Job, JobType } from '@/types/graphql'
import { DocumentCreatorTemplateStatus, JobStatus } from '@/types/graphql'

import { memo, useEffect, useMemo, useState } from 'react'
import { Col, Row, Typography } from 'antd-v5'

import { RenderFields } from '@/components/Booking/General/FieldDisplayHelper'
import JobEditAction from '@/components/Booking/General/JobEditAction'
import {
  containerStyle,
  contentStyle,
  typeStyle,
  verticalStyle
} from '@/components/Booking/JobsAndTrips/styles'
import TripsDetailsDisplay from '@/components/Booking/JobsAndTrips/TripsDetailsDisplay'
import DocumentCreatorPrinter from '@/components/Shared/DocumentCreatorPrinter'
import useJobTypes from '@/hooks/useJobTypes'

interface JobFormProps {
  job: Job
  index: number
  hideDeleted?: boolean
  hideFunctions?: boolean
}

const JobDetailsDisplay = (props: JobFormProps) => {
  const { job, index, hideDeleted = true, hideFunctions = false } = props

  const [jobType, setJobType] = useState<JobType | null>(null)
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([])
  const [tripDynamicFields, setTripDynamicFields] = useState<DynamicField[]>([])
  const { jobTypes, requestJobTypes } = useJobTypes()

  const foundJobType = useMemo(
    () => jobTypes?.find((jt: JobType) => jt.name === job.type),
    [jobTypes, job.type]
  )

  useEffect(() => {
    if (!jobTypes?.length) return requestJobTypes()
    if (foundJobType) setJobType(foundJobType)
  }, [jobTypes, job.type, foundJobType])

  useEffect(() => {
    if (!jobType) {
      return
    }

    setDynamicFields(jobType.dynamicFields as DynamicField[])
    setTripDynamicFields(jobType.tripDynamicFields as DynamicField[])
  }, [jobType])

  const isCancelledJob = [JobStatus.Cancelled].includes(job.status as JobStatus)

  const style = useMemo(
    () => (isCancelledJob ? { ...containerStyle, backgroundColor: '#F2B1B1' } : containerStyle),
    [isCancelledJob]
  )

  return (
    <div style={style}>
      <div style={verticalStyle}>
        Job {index + 1} <span style={typeStyle}>({job.type})</span>
      </div>

      <div style={contentStyle}>
        {!hideFunctions && (
          <div className="flex justify-end items-center gap-8">
            <Typography.Text strong style={{ fontSize: 12 }}>
              {job.status}
            </Typography.Text>

            <DocumentCreatorPrinter
              job={job}
              variables={{
                qs: ['JOB_MANIFEST', 'MANIFEST', 'CONSIGNMENT_NOTE'],
                statuses: [DocumentCreatorTemplateStatus.Activated]
              }}
            />

            <JobEditAction job={job} />
          </div>
        )}

        <Row gutter={[16, { xs: 20, lg: 48 }]} style={{ marginTop: 2, marginBottom: 10 }}>
          {[0, 1, 2].map(colIndex => (
            <Col key={colIndex} xs={24} sm={12} lg={8}>
              {dynamicFields
                .slice(
                  Math.floor((colIndex * dynamicFields.length) / 3),
                  Math.floor(((colIndex + 1) * dynamicFields.length) / 3)
                )
                .map((field: DynamicField) => RenderFields(job, field))}
            </Col>
          ))}
        </Row>

        <Row>
          <TripsDetailsDisplay
            trips={job.trips}
            tripDynamicFields={tripDynamicFields}
            hideDeleted={hideDeleted}
            hideFunctions={hideFunctions}
          />
        </Row>
      </div>
    </div>
  )
}

// Memoize the component with proper comparison
export default memo(JobDetailsDisplay, (prevProps, nextProps) => {
  return (
    prevProps.job.uuid === nextProps.job.uuid &&
    prevProps.hideDeleted === nextProps.hideDeleted &&
    prevProps.hideFunctions === nextProps.hideFunctions
  )
})
