import type { Booking, VoucherItem } from '@/types/graphql'

import EditableFieldsWrapper from '@/components/Voucher/VoucherItems/EditableFieldsWrapper'

type RenderEditablesProps = {
  form: any
  booking: Booking
  showVoucherItem: boolean
  selectedVoucherItem: VoucherItem
  handleCancel: () => void
  handleUpdateVoucherItem: (voucherItem: VoucherItem) => void
}

const RenderEditables = (props: RenderEditablesProps) => {
  const {
    showVoucherItem,
    selectedVoucherItem,
    form,
    booking,
    handleCancel,
    handleUpdateVoucherItem
  } = props
  if (!showVoucherItem) return <span>Closing...</span>

  return (
    <EditableFieldsWrapper
      key={selectedVoucherItem?.uuid}
      form={form}
      booking={booking}
      onCancel={handleCancel}
      selectedVoucherItem={selectedVoucherItem}
      handleUpdateVoucherItem={handleUpdateVoucherItem}
    />
  )
}

export default RenderEditables
