import { Select } from 'antd'
import { Col, Flex } from 'antd-v5'

import { ActionWrapper, FilterName } from '@/components/Manage/Permissions/TableView/Actions/Styled'
import EmployeeRoleSelect from '@/components/Select/EmployeeRoleSelect'
import ImportExport from '@/components/Shared/ImportExport'

const TableViewActions = props => {
  const { selectedRole, selectedSystem, updateSelectedRole, updateSelectedSystem } = props
  const query = {
    limit: 20,
    statuses: ['ACTIVE']
  }

  return (
    <ActionWrapper>
      <Flex align="center" style={{ marginBottom: '8px' }}>
        <Col span={3}>
          <FilterName>System : </FilterName>
        </Col>
        <Col span={6}>
          <Select value={selectedSystem} onChange={value => updateSelectedSystem(value)}>
            <Select.Option value="">ShipX</Select.Option>
            <Select.Option value="entity">Entity</Select.Option>
            <Select.Option value="transport">Transport</Select.Option>
          </Select>
        </Col>
        <Col span={1} />
        <Col span={6}>
          <ImportExport selectedEntity="rolesPermissions" />
        </Col>
      </Flex>
      <Flex align="center">
        <Col span={3}>
          <FilterName>Roles : </FilterName>
        </Col>
        <Col span={6}>
          <EmployeeRoleSelect query={query} value={selectedRole} onChange={updateSelectedRole} />
        </Col>
        <Col span={1} />
      </Flex>
    </ActionWrapper>
  )
}

export default TableViewActions
