import { gql } from '@/types'

export const PLACES_QUERY = gql(`
  query placesPortSelector($q: String) {
    places(q: $q) {
      placeId
      description
    }
  }
`)
