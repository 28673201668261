import type { Booking, ProcessFlow, ProcessFlowSection } from '@/types/graphql'

import { LinkOutlined, QrcodeOutlined, RightOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Col, Flex, message, Modal, QRCode, Row, Tooltip, Typography } from 'antd-v5'
import { filter, findIndex, groupBy, startCase, uniqBy } from 'lodash'

import Icon from '@/components/Icons/FA'
import Chronology from '@/components/Workflow/Chronology'
import MermaidLink from '@/components/Workflow/MermaidLink'
import Progress from '@/components/Workflow/Progress'
import Request from '@/components/Workflow/Request'
import labels from '@/components/Workflow/Section/labels'
import { ReferenceLabel } from '@/components/Workflow/Section/Styled'
import Uploaded from '@/components/Workflow/Uploaded'
import Voucher from '@/components/Workflow/Voucher'
import config from '@/config'
import { IS_SERVER } from '@/utils/website'

const fallbackCopyTextToClipboard = text => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')

    if (successful) {
      message.success('Link copied to clipboard.', 3)
    } else {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  } catch (err) {
    console.error(err)
    message.error('Unable to copy link. Please use a modern browser.', 5)
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      message.success('Link copied to clipboard.', 3)
    },
    function () {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  )
}

const handleCopyClick = text => e => {
  e.preventDefault()
  copyTextToClipboard(text)
}

const handleQRCodeClick = link => e => {
  e.preventDefault()

  Modal.info({
    title: 'QR Code',
    okText: 'Close',
    maskClosable: true,
    content: (
      <Flex gap={20} vertical justify="center" align="center">
        <Typography.Text>Scan the QR code below to go to this booking:</Typography.Text>
        <QRCode value={link} size={192} />
      </Flex>
    )
  })
}

type SectionProps = {
  refetchBooking: () => void
  section: Partial<ProcessFlowSection> | null | undefined
  booking: Partial<Booking> | null | undefined
  process: Partial<ProcessFlow> | null | undefined
  extras?: any
  requisitionActions: any
}

const Section = (props: SectionProps) => {
  const { refetchBooking, section, booking, process, extras: Extras, requisitionActions } = props

  const uploadedDocs = filter(
    booking?.bookingDocuments,
    doc => findIndex(section?.bookingDocuments, sectionDoc => sectionDoc?.type === doc?.type) !== -1
  )

  const vouchers = filter(
    booking?.vouchers,
    voucher =>
      findIndex(section?.vouchers, sectionVoucher => sectionVoucher?.type === voucher?.type) !== -1
  )

  const requisitions = section?.requests?.map(request => {
    return {
      text: request?.type,
      type: request?.type,
      get: () => requisitionActions.getTemplate(request?.type),
      send: requisition => requisitionActions.handleSendRequisition(requisition)
    }
  })

  const chronologies = filter(
    booking?.chronologies,
    chrono =>
      findIndex(section?.chronologies, sectionChrono => sectionChrono?.type === chrono?.type) !== -1
  )

  const renderUploadedDocs = () => {
    if (!section?.bookingDocuments) return null

    const voucherDocuments: any = []

    booking?.vouchers?.forEach(bv => {
      if (bv?.bookingDocuments) bv.bookingDocuments.forEach(bd => voucherDocuments.push(bd))
    })

    const highlightedDocuments = filter(
      uploadedDocs,
      ud => findIndex(voucherDocuments, (vd: { type: string }) => vd.type === ud?.type) !== -1
    )

    return (
      <Uploaded
        refetchBooking={refetchBooking}
        section={section}
        booking={booking}
        requiredDocs={section?.bookingDocuments || []}
        uploadedDocs={uploadedDocs || []}
        highlightedDocuments={uniqBy(highlightedDocuments, 'type')}
      />
    )
  }

  const renderVouchers = () => {
    if (section?.vouchers === null) return null

    return (
      <Voucher
        data={booking}
        section={section}
        type={section?.name}
        steps={section?.steps}
        viewableVouchers={vouchers || []}
      />
    )
  }

  const renderRequests = () => {
    if (section?.requests === null) return null

    return <Request booking={booking} requisitions={requisitions} />
  }

  const renderChronologies = () => {
    if (!section?.chronologies) {
      return null
    }

    const bookingChronoGroups = chronologies && groupBy(chronologies, c => c?.reference)

    return (
      <Flex vertical>
        <Typography.Text strong>{'Chronologies'.toUpperCase()}</Typography.Text>

        {!Object.keys(bookingChronoGroups).length && (
          <Row>
            {section.chronologies.map(sectionChrono => {
              const chrono = {
                ...sectionChrono,
                title: labels[sectionChrono?.type ?? ''] || sectionChrono?.type
              }
              return (
                <Col key={sectionChrono?.type} span={6}>
                  <Chronology booking={booking} chrono={chrono} />
                </Col>
              )
            })}
          </Row>
        )}

        {Object.keys(bookingChronoGroups).map(key => {
          return (
            <Row key={key} style={{ padding: 5, marginBottom: 5 }}>
              <Flex style={{ marginBottom: 5 }}>
                <ReferenceLabel>
                  <RightOutlined />
                  {key || <i>(empty reference)</i>}
                </ReferenceLabel>
              </Flex>

              {section.chronologies?.map((sectionChrono, index) => {
                const existingChronoData = bookingChronoGroups[key].find(
                  c => c?.type === sectionChrono?.type
                ) || { reference: key }

                const chrono = {
                  ...sectionChrono,
                  title: labels[sectionChrono?.type ?? ''] || sectionChrono?.type,
                  data: existingChronoData
                }

                return (
                  <Col key={sectionChrono?.type} span={(index + 1) % 5 !== 0 ? 5 : 4}>
                    <Chronology booking={booking} chrono={chrono} />
                  </Col>
                )
              })}
            </Row>
          )
        })}
      </Flex>
    )
  }

  const mappedSection = labels[section?.name ?? '']

  const iconType = mappedSection?.icon || 'bars'

  const title = mappedSection?.text || section?.name

  const domain = IS_SERVER ? '' : window?.location?.origin

  const bookingLink = `${domain}/bookings/${booking?.uuid}#${section?.name}`

  const whatsappLink = `${config.whatsapp.apiLinkFront}${encodeURIComponent(bookingLink)}`

  const iconStyle = { color: 'gray', marginLeft: 10 }

  return (
    <Flex vertical gap={20} className="px-4">
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Icon type={iconType} /> {startCase(title)}
        </Flex>

        <Flex align="center" gap={10}>
          <MermaidLink type={booking?.type} process={process?.type} />

          <Tooltip title="Copy to clipboard">
            <Typography.Link
              href={bookingLink}
              rel="noopener noreferrer"
              onClick={handleCopyClick(bookingLink)}
            >
              <LinkOutlined style={iconStyle} />
            </Typography.Link>
          </Tooltip>

          <Tooltip title="Generate QR code">
            <Typography.Link
              href={bookingLink}
              rel="noopener noreferrer"
              onClick={handleQRCodeClick(bookingLink)}
            >
              <QrcodeOutlined style={iconStyle} />
            </Typography.Link>
          </Tooltip>

          <Tooltip title="Send WhatsApp message">
            <Typography.Link href={whatsappLink} target="_blank" rel="noopener noreferrer">
              <WhatsAppOutlined style={iconStyle} />
            </Typography.Link>
          </Tooltip>
        </Flex>
      </Flex>

      <div id={section?.name || ''}>
        <Flex vertical gap={20}>
          <Progress steps={section?.steps} />
          {renderChronologies()}
          {renderUploadedDocs()}
          {renderRequests()}
          {renderVouchers()}
        </Flex>
        {Extras && <Extras {...props} />}
      </div>
    </Flex>
  )
}

export default Section
