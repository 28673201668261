import { VoucherStatus } from '@/types/graphql'

import { Button, Modal } from 'antd-v5'
import { compose, withHandlers, withState } from 'recompose'

import changeVoucherStatusMutation from 'App/containers/voucher/mutation/changeVoucherStatus'
import BulkSubmitList from './BulkSubmitList'

const STATUS = {
  pending: 'pending',
  inProgress: 'inProgress',
  done: 'done'
}

const statusState = withState('status', 'setStatus', STATUS.pending)
const voucherResultsState = withState('voucherResults', 'setVoucherResults', props => {
  const { vouchers = [] } = props
  return vouchers.map(v => {
    return {
      ...v,
      result: {
        status: 'pending'
      }
    }
  })
})

const handleChangeVoucherStatus = async (voucher, changeVoucherStatus) => {
  try {
    const { data } = await changeVoucherStatus({
      uuid: voucher.uuid,
      status: 'SUBMITTED'
    })

    if (data?.changeVoucherStatus?.status === VoucherStatus.Submitted) {
      return {
        status: 'success'
      }
    }
  } catch (error) {
    return {
      status: 'fail',
      reason: 'Error in submitting voucher.',
      error
    }
  }
}

const handlers = withHandlers({
  handleCancel: props => () => {
    const { onClose, status } = props
    if (onClose) {
      onClose(status)
    }
  },
  handleOk: props => async () => {
    const { vouchers, changeVoucherStatus, status, setStatus, onClose, setVoucherResults } = props
    let { voucherResults } = props

    if (status === STATUS.done) {
      setStatus(STATUS.pending)
      if (onClose) {
        onClose(status)
      }
      return
    }

    setStatus(STATUS.inProgress)

    for (let i = 0; i < vouchers.length; i++) {
      const voucher = vouchers[i]

      const result = await handleChangeVoucherStatus(voucher, changeVoucherStatus)

      const newVoucherResults = voucherResults.map(r => {
        if (voucher.uuid === r.uuid) {
          return {
            ...r,
            result
          }
        }

        return r
      })

      voucherResults = newVoucherResults
      setVoucherResults(newVoucherResults)
    }

    setStatus(STATUS.done)
  }
})

const enhance = compose(changeVoucherStatusMutation, statusState, voucherResultsState, handlers)

const getOkText = status => {
  if (status === STATUS.pending) {
    return 'Submit'
  } else if (status === STATUS.inProgress) {
    return 'Submitting'
  } else if (status === STATUS.done) {
    return 'Done'
  }
}

const getFooter = props => {
  const { status, handleOk } = props

  return (
    <Button type="primary" loading={status === STATUS.inProgress} onClick={handleOk}>
      {getOkText(status)}
    </Button>
  )
}

const BulkSubmitModal = props => {
  const { visible, handleCancel, handleOk, voucherResults } = props

  if (!visible) {
    return null
  }

  return (
    <Modal
      destroyOnClose
      onOk={handleOk}
      open={visible}
      onCancel={handleCancel}
      footer={getFooter(props)}
      title="Bulk submit vouchers"
    >
      <p>Click OK to submit the following vouchers.</p>
      <BulkSubmitList voucherResults={voucherResults} />
    </Modal>
  )
}

export default enhance(BulkSubmitModal)
