import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { findGqlError, getGqlResponse, mapFromCsv } from '../helpers'
import { createEntityGql, getDataGql, updateEntityGql } from './schema'

type MutationResultsType = {
  updateHoliday?: any
  createHoliday?: any
}

const sampleData = [
  {
    no: 1,
    name: 'New Year Day',
    date: '="2024-01-01"',
    description: 'New Year Holiday',
    status: 'ACTIVE',
    uuid: '',
    zzz: ''
  }
]

const remarks = '*Note: The required fields are: name and date (YYYY-MM-DD format). '

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Date*',
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    ellipsis: true,
    key: 'uuid'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

// cache to hold queried data, to check against data for import
const cache: any = {
  uuid: {}
}

const findBadInputs = (holiday: any) => {
  if (!holiday.name) {
    return { nullError: 'Name is required.' }
  }
  if (!holiday.date) {
    return { nullError: 'Date is required in YYYY-MM-DD format.' }
  }
  if (!moment(holiday.date, 'YYYY-MM-DD', true).isValid()) {
    return { nullError: 'Invalid date format. Use YYYY-MM-DD.' }
  }
}

const populateFields = (obj: any, uuid: string) => {
  return {
    uuid,
    name: obj?.name?.toString(),
    date: obj?.date,
    description: obj?.description?.toString(),
    status: obj?.status
  }
}

const holidaysHelper = {
  queryName: 'holidays',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const response = await getGqlResponse(selectedGlobalCompany, getDataGql, {
        limit: 10000
      })
      const readyForImport = response?.data?.holidays?.rows?.map((d: any, index: number) => ({
        no: index + 1,
        ...d,
        // Add ="YYYY-MM-DD" format to force Excel to treat it as text
        date: `="${moment(d.date).format('YYYY-MM-DD')}"`,
        zzz: ''
      }))
      return readyForImport
    } catch (error) {
      logger.error('holidaysHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (inputData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...holiday } = inputData

    const nullInputError = findBadInputs(holiday)
    if (nullInputError) {
      return nullInputError
    }

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const res = await getGqlResponse(selectedGlobalCompany, getDataGql, {
          limit: 10000
        })
        const queriedData = res?.data?.holidays?.rows

        console.log('--------queriedData', queriedData)
        for (let i = 0; i < queriedData?.length; i++) {
          cache.uuid[queriedData[i]?.uuid] = queriedData[i]
        }
      } catch (error) {
        logger.error('holidaysHelper cache getDataGql error', error)
        return error
      }
    }

    const mutationResults: MutationResultsType = {}

    console.log('--------cache.uuid', cache.uuid)
    // If exists, then update
    if (cache.uuid[holiday.uuid]) {
      try {
        const toUpdate = populateFields(holiday, holiday.uuid)
        mutationResults.updateHoliday = await getGqlResponse(
          selectedGlobalCompany,
          updateEntityGql,
          {
            input: toUpdate
          }
        )
      } catch (error) {
        logger.error('holidaysHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new
      try {
        const newUuid = uuidv4()
        const toCreate = populateFields(holiday, newUuid)
        mutationResults.createHoliday = await getGqlResponse(
          selectedGlobalCompany,
          createEntityGql,
          {
            input: toCreate
          }
        )
      } catch (error) {
        logger.error('holidaysHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    const errorObj = findGqlError(mutationResults)
    if (errorObj) {
      return errorObj
    }

    return mutationResults
  },

  mapFromCsv,
  sampleData,
  remarks,
  tableColumns
}

export default holidaysHelper
