import { useEffect, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd-v5'

import { WorkflowWrapper } from '@/components/Booking/Container'
import Workflow from '@/components/Workflow'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from '@/containers/booking/schema/bookingForWorkflowsSlim'
import { useRequisition } from '@/hocs/requisition'
import withUpload from '@/hocs/upload'
import { usePersistedBookingData } from '@/store/bookingData'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { hasPermissionError } from '@/utils/u'

type WorkflowsProps = {
  bookingUuid: string
  refreshCount: number
  bookingQuery: any
  uploadActions: any
  requisitionActions: any
}

const Workflows = ({ bookingUuid, refreshCount, bookingQuery, uploadActions }: WorkflowsProps) => {
  const { setBookingData } = usePersistedBookingData()

  const { getTemplate, handleSendRequisition } = useRequisition(bookingQuery)

  const { data, loading, error, refetch } = useQuery(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
    variables: { uuid: bookingUuid },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  })

  const workflows = useMemo(() => data?.booking?.processFlow || [], [data])

  useEffect(() => {
    refetch()
  }, [refreshCount])

  useEffect(() => {
    if (data?.booking) setBookingData(data.booking)
  }, [data?.booking])

  if (!data || loading) {
    return <Skeleton active />
  }

  if (error) {
    logger.error('Workflows BOOKING_FOR_WORKFLOWS_SLIM_QUERY Error.', error, { bookingUuid })

    if (!hasPermissionError(error)) {
      responseHandler(error, 'error')
    }
  }

  const requisitionActions = {
    getTemplate,
    handleSendRequisition
  }

  return (
    <WorkflowWrapper>
      {workflows.map(process => (
        <Workflow
          key={process?.type}
          process={process}
          data={data?.booking}
          booking={data?.booking}
          refetchBooking={refetch}
          uploadActions={uploadActions}
          requisitionActions={requisitionActions}
        />
      ))}
    </WorkflowWrapper>
  )
}

export default withUpload(Workflows)
