import { memo, useMemo, useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { Row, Skeleton, Table } from 'antd-v5'
import { filter, find, includes } from 'lodash'

import PageHeader from '@/components/Manage/PageHeader'
import Actions from '@/components/Manage/Permissions/TableView/Actions'
import MiscPermissions from '@/components/Manage/Permissions/TableView/MiscPermissions'
import Permission from '@/components/Manage/Permissions/TableView/Permission'
import {
  PERMISSION_QUERY,
  PERMISSION_TYPES_QUERY
} from '@/components/Manage/Permissions/TableView/schema'
import { PermissionItem, PermissionName } from '@/components/Manage/Permissions/TableView/Styled'
import SpinSkeleton from '@/components/Manage/SpinSkeleton'
import { PageView } from '@/components/Manage/Styled'
import NoAccess from '@/components/Shared/NoAccess'
import { TableWrapper } from '@/components/TableView/Styled'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'

const mainPermissions = ['index', 'view', 'create', 'update', 'destroy']

const PermissionsTableView = memo(() => {
  const [selectedRole, updateSelectedRole] = useState()
  const [selectedSystem, updateSelectedSystem] = useState('')

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'permission',
    permission: 'index'
  })

  const tableColumns = useMemo(
    () => [
      {
        title: 'Permission',
        key: 'permission',
        dataIndex: 'resources',
        render: resources => <PermissionName>{resources}</PermissionName>
      },
      {
        title: 'List View',
        key: 'listView',
        render: (text, record) => (
          <Permission
            selectedRole={selectedRole}
            resourceType="index"
            resourceName={record.resources}
            permissions={record.permissions}
            allowedPermissions={record.allowedPermissions}
          />
        )
      },
      {
        title: 'Item View',
        key: 'itemView',
        render: (text, record) => (
          <Permission
            selectedRole={selectedRole}
            resourceType="view"
            resourceName={record.resources}
            permissions={record.permissions}
            allowedPermissions={record.allowedPermissions}
          />
        )
      },
      {
        title: 'Create',
        key: 'create',
        render: (text, record) => (
          <Permission
            selectedRole={selectedRole}
            resourceType="create"
            resourceName={record.resources}
            permissions={record.permissions}
            allowedPermissions={record.allowedPermissions}
          />
        )
      },
      {
        title: 'Edit',
        key: 'edit',
        render: (text, record) => (
          <Permission
            selectedRole={selectedRole}
            resourceType="update"
            resourceName={record.resources}
            permissions={record.permissions}
            allowedPermissions={record.allowedPermissions}
          />
        )
      },
      {
        title: 'Delete',
        key: 'delete',
        render: (text, record) => (
          <Permission
            selectedRole={selectedRole}
            resourceType="destroy"
            resourceName={record.resources}
            permissions={record.permissions}
            allowedPermissions={record.allowedPermissions}
          />
        )
      },
      {
        title: 'Misc',
        key: 'misc',
        render: (text, record) => {
          const miscPermissions = filter(record.permissions, p => !includes(mainPermissions, p))

          if (!miscPermissions || !miscPermissions.length) {
            return <PermissionItem />
          }

          return (
            <PermissionItem>
              <MiscPermissions
                selectedRole={selectedRole}
                permissions={record.permissions}
                miscResource={record.resources}
                miscPermissions={miscPermissions}
                allowedPermissions={record.allowedPermissions}
              />
            </PermissionItem>
          )
        }
      }
    ],
    [selectedRole]
  )

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) return <NoAccess />

  return (
    <PageView>
      <PageHeader title="Permissions" />

      <Actions
        selectedRole={selectedRole}
        selectedSystem={selectedSystem}
        updateSelectedRole={updateSelectedRole}
        updateSelectedSystem={updateSelectedSystem}
      />

      <TableWrapper>
        <Query query={PERMISSION_QUERY} variables={{ role: selectedRole }}>
          {({ loading, data }) => {
            const rolePermissions = data?.permission

            return (
              <SpinSkeleton loading={loading} data={data?.permission}>
                <Query query={PERMISSION_TYPES_QUERY}>
                  {({ loading, data }) => {
                    const dataSource = data?.permissionTypes
                      ?.map((pt, index) => {
                        const mappedPermission = find(
                          rolePermissions,
                          rp => rp.resources === pt.resources
                        )
                        return {
                          key: index,
                          ...pt,
                          allowedPermissions: mappedPermission && mappedPermission.permissions
                        }
                      })
                      .filter(p => {
                        const resourceParts = p.resources.split(':')

                        if (
                          (resourceParts.length === 1 && selectedSystem === '') ||
                          (resourceParts.length === 2 && resourceParts[0] === selectedSystem)
                        ) {
                          return true
                        }

                        return false
                      })

                    return (
                      <SpinSkeleton loading={loading} data={data?.permissionTypes}>
                        <Table columns={tableColumns} dataSource={dataSource} pagination={false} />
                      </SpinSkeleton>
                    )
                  }}
                </Query>
              </SpinSkeleton>
            )
          }}
        </Query>
      </TableWrapper>
    </PageView>
  )
})

export default PermissionsTableView
