import { memo } from 'react'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Flex, Input } from 'antd-v5'
import debounce from 'lodash/debounce'

import ImportExport from '@/components/Shared/ImportExport'

type TableViewActionsProps = {
  refetch: () => void
  setSearchKeyword: (value: string) => void
  exportSelectedEntity: string
  createUrl: string
}

const TableViewActions = memo((props: TableViewActionsProps) => {
  const { refetch, setSearchKeyword, exportSelectedEntity, createUrl } = props

  const handleInput = debounce((value: string) => {
    setSearchKeyword(value)
    refetch()
  }, 500)

  return (
    <Flex justify="space-between" align="center" gap="large">
      <Input
        allowClear
        prefix={<SearchOutlined />}
        placeholder="Search something here..."
        onChange={e => handleInput(e.target.value)}
      />
      <Flex gap={'large'}>
        <ImportExport selectedEntity={exportSelectedEntity} />
        <Button href={createUrl}>
          <PlusOutlined /> Create
        </Button>
      </Flex>
    </Flex>
  )
})

export default TableViewActions
