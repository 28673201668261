import type { LegActivity } from '@/types/graphql'
import type { TableProps } from 'antd-v5'

import { memo, useCallback, useMemo, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Alert, Button, Flex, Table, Typography } from 'antd-v5'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isNull from 'lodash/isNull'

import {
  LEG_ACTIVITIES_QUERY,
  PROCESS_VEHICLE_PLAN_QUERY
} from '@/components/Transport/Schemas/schema'
import { logger } from '@/utils/logger'

dayjs.extend(localizedFormat)

const LegActivities = memo((props: any) => {
  const { leg, setLegActivitiesVisible } = props
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })

  const { loading, error, data } = useQuery(LEG_ACTIVITIES_QUERY, {
    variables: {
      input: {
        legUuids: [leg?.legUuid],
        ...pagination
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useQuery(PROCESS_VEHICLE_PLAN_QUERY, {
    variables: { vehicleUuid: leg?.vehicleUuid, baseCompanyUuid: leg?.ownerUuid, describe: true },
    skip: !leg?.vehicleUuid || !leg?.ownerUuid,
    fetchPolicy: 'cache-and-network'
  })

  const onPaginationChange = useCallback(
    (current: number, pageSize?: number | undefined) => {
      setPagination({ limit: pageSize || 20, offset: (current || 1) - 1 })
    },
    [setPagination]
  )

  const paginationObj = useMemo(
    () => ({
      pageSize: pagination?.limit,
      showSizeChanger: true,
      showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
      total: data?.legActivities?.pageInfo?.count || 0,
      current: (data?.legActivities?.pageInfo?.offset || 0) + 1,
      onChange: onPaginationChange,
      onShowSizeChange: onPaginationChange,
      pageSizeOptions: ['10', '20', '50', '100']
    }),
    [data, onPaginationChange, pagination]
  )

  const renderDate = (record: string) =>
    isNull(record) ? 'null' : record ? dayjs(record).format('L HH:mm') : ''

  const columns: TableProps<LegActivity>['columns'] = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'expandRowButton',
        key: 'expandRowButton',
        width: 1,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Updated By',
        dataIndex: ['createdBy', 'email'],
        key: 'email',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Updated At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'Leg Status',
        dataIndex: 'legStatus',
        key: 'legStatus',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Transporter',
        dataIndex: 'transportName',
        key: 'transportName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Driver',
        dataIndex: 'driverName',
        key: 'driverName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Vehicle',
        dataIndex: 'vehicleName',
        key: 'vehicleName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Trailer',
        dataIndex: 'trailerName',
        key: 'trailerName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'planStart',
        dataIndex: 'planStart',
        key: 'planStart',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'start',
        dataIndex: 'start',
        key: 'start',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'startOut',
        dataIndex: 'startOut',
        key: 'startOut',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'planEnd',
        dataIndex: 'planEnd',
        key: 'planEnd',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'end',
        dataIndex: 'end',
        key: 'end',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'endOut',
        dataIndex: 'endOut',
        key: 'endOut',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: renderDate
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      }
    ],
    []
  )

  if (error) {
    logger.error('Update Leg Modal LegActivities Error', error)
    return <Alert message="Failed to load leg activities." type="error" />
  }

  return (
    <>
      <Flex justify="space-between" align="center">
        <Typography.Paragraph
          strong
        >{`View Activity Logs For Leg ${leg?.sequence} - ${leg?.legStatus}`}</Typography.Paragraph>
        <Button
          type="link"
          icon={<CloseOutlined />}
          onClick={() => setLegActivitiesVisible(false)}
          style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
        />
      </Flex>

      <Table
        bordered
        size="small"
        columns={columns}
        loading={loading}
        pagination={paginationObj}
        rowKey={(record: LegActivity) => record._id}
        dataSource={data?.legActivitiesJson?.rows || []}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: record => {
            const recordWithoutNull = Object.entries(record).reduce(
              (result: any, [key, value]: Array<string>) => {
                if (value) result[key] = value
                return result
              },
              {}
            )
            return (
              <Typography.Text>
                <pre>{JSON.stringify(recordWithoutNull, null, 2)}</pre>
              </Typography.Text>
            )
          }
        }}
      />
    </>
  )
})

export default LegActivities
