import { Flex } from 'antd-v5'

import ActivitiesView from '@/components/DocumentViewer/Content/Activities'
import ResourceViewer from '@/components/ResourceViewer'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

function ViewerContent(props) {
  const { bookingDocument = {}, bookingQuery, refreshBooking } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const fileType = bookingDocument?.document?.type
  const url = bookingDocument?.url

  return (
    <Flex className="h-full" gap="middle">
      <div className="flex flex-1 items-center justify-center">
        <div
          className="flex h-full w-full items-center justify-center relative"
          style={{ overflow: fileType === 'application/pdf' ? 'hidden' : 'auto' }}
        >
          <ResourceViewer
            url={url}
            mimeType={fileType}
            fetchOptions={{
              method: 'GET',
              headers: {
                authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
                'base-company-uuid': selectedGlobalCompany.uuid
              }
            }}
          />
        </div>
      </div>
      <div className="bg-white border-solid-white text-sm" style={{ width: '300px' }}>
        <ActivitiesView
          bookingQuery={bookingQuery}
          bookingDocument={bookingDocument}
          refreshBooking={refreshBooking}
        />
      </div>
    </Flex>
  )
}

export default ViewerContent
