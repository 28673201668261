import { YardStatus } from 'App/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import QuickCreateYard from 'App/components/Manage/Yards/QuickCreateYard'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'

const YARDS_SELECTOR_QUERY = gql`
  query yardsSelector1($input: YardQueryInput!) {
    yards(input: $input) {
      rows {
        uuid
        code
        name
      }
    }
  }
`

const Option = Select.Option

type YardSelectorProps = {
  value?: any
  onChange?: any
  mode?: string
  quickCreate?: boolean
  style?: any
}

const YardSelector = forwardRef((props: YardSelectorProps, ref: any) => {
  const { value, onChange, mode, quickCreate = false, style } = props

  const [yards, setYards] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState('')

  const { data, error, loading, refetch } = useQuery(YARDS_SELECTOR_QUERY, {
    variables: {
      input: {
        q: (!mode && value) || searchInput || '',
        statuses: [YardStatus.Active],
        limit: 20
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (setYards) {
      setYards(data?.yards?.rows)
    }
  }, [data])

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('YardSelector error', error)
    responseHandler(error, 'error')
  }

  return (
    <>
      <Select
        allowClear
        filterOption={false}
        loading={loading}
        // @ts-ignore
        mode={mode}
        notFoundContent={loading ? 'Searching...' : 'Not found.'}
        onChange={onChange}
        // @ts-ignore
        onSearch={handleSearch}
        placeholder="Select a yard..."
        ref={ref}
        showSearch
        style={style}
        value={value}
      >
        {yards?.map((yard: any) => (
          <Option key={yard.uuid} value={yard.uuid}>
            {yard?.code} - {yard?.name}
          </Option>
        ))}
      </Select>
      {quickCreate && <QuickCreateYard />}
    </>
  )
})

export default memo(YardSelector)
