import type { GraphQLException } from '@/utils/error'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'

import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { logger } from '@/utils/logger'

export const BASE_COMPANY_SETTING = gql(`
  query departmentSelect($key: String) {
    setting(key: $key) {
      key
      setting {
        value
        type
      }
    }
  }
`)

type DepartmentSelectProps = SelectProps & {
  setIsDeptRequired?: (value: boolean) => void
  queryOnMount?: boolean
}

const DepartmentSelect = (props: DepartmentSelectProps) => {
  const { setIsDeptRequired, queryOnMount = false, ...selectProps } = props
  const { value, disabled = false, mode = 'multiple' } = selectProps

  const { t } = useTranslation()

  const [departments, setDepartments] = useState([])

  const [getDepartments, { data, error, loading }] = useLazyQuery(BASE_COMPANY_SETTING, {
    variables: { key: 'bookingDepartments' },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (queryOnMount || value?.length !== 0) getDepartments()
  }, [])

  useEffect(() => {
    if (data?.setting) {
      const bookingDepartments = data?.setting?.setting?.[0]?.value
      if (bookingDepartments) {
        const parsedSettings = JSON.parse(bookingDepartments)
        if (setIsDeptRequired) setIsDeptRequired(parsedSettings?.isRequired)
        return setDepartments(parsedSettings?.departments)
      }
      return setDepartments([])
    }
  }, [data, setIsDeptRequired])

  if (error) {
    const exception = error.graphQLErrors?.[0]?.extensions?.exception as GraphQLException
    if (exception.statusCode !== 404) {
      logger.error('DepartmentSelect BASE_COMPANY_SETTING error', error)
    }
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDepartments()
  }

  return (
    <Select
      {...selectProps}
      allowClear
      showSearch
      mode={mode}
      loading={loading}
      disabled={disabled}
      filterOption={false}
      onFocus={fetchOnFocus}
      placeholder={t('common.selectDepartment')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
    >
      {departments?.map((department: string) => (
        <Select.Option key={department} value={department}>
          {department}
        </Select.Option>
      ))}
    </Select>
  )
}

export default memo(DepartmentSelect)
