import { DocumentStatus } from '@/types/graphql'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { EllipsisOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Modal, Popover } from 'antd-v5'

import { getBkQueryVariables } from '@/components/Booking/bookingHelper'
import { DELETE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import { StyledMenuItem } from '@/components/DocumentViewer/Header/Styled'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import { useBookingStore } from '@/store/booking'

const HeaderOptions = ({ bookingDocument, refreshBooking }) => {
  const { params } = useParams()
  const setSelectedBookingDocument = useBookingStore.use.setSelectedBookingDocument()
  const setOpenDocumentViewer = useBookingStore.use.setOpenDocumentViewer()

  const [visible, setVisible] = useState(false)

  const [deleteDocument] = useMutation(DELETE_BOOKING_DOCUMENT, {
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params)
      },
      {
        query: BookingDocumentQuery,
        variables: { uuid: bookingDocument.uuid }
      }
    ],
    onCompleted: result => handleDeleted(result)
  })

  const handleDelete = () => {
    setVisible(false)
    Modal.confirm({
      title: 'Confirm to delete this document?',
      content: 'All changes or links to this document will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDocument({ variables: { uuid: bookingDocument.uuid } })
      }
    })
  }

  const handleDeleted = ({ deleteBookingDocument }) => {
    setSelectedBookingDocument({
      ...deleteBookingDocument,
      allowedApprovals: bookingDocument.allowedApprovals
    })
    setOpenDocumentViewer(false)

    refreshBooking()
  }

  const isPdfType = bookingDocument.document?.type === 'application/pdf'

  const Content = () => (
    <>
      <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}`}>
        View in new tab
      </StyledMenuItem>

      {isPdfType && (
        <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}?tag=1`}>
          View Tagged in new tab
        </StyledMenuItem>
      )}

      {bookingDocument.status !== DocumentStatus.Deleted && (
        <StyledMenuItem onClick={handleDelete}>Delete document</StyledMenuItem>
      )}
    </>
  )

  return (
    <Popover
      arrow={false}
      content={<Content />}
      placement="bottomLeft"
      trigger="click"
      open={visible}
    >
      <Button icon={<EllipsisOutlined />} type="dashed" onClick={() => setVisible(!visible)} />
    </Popover>
  )
}

export default HeaderOptions
