import type { Booking } from '@/types/graphql'
import type { TableProps } from 'antd-v5'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { QMethods } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Form } from 'antd'
import { Button, Popover, Table } from 'antd-v5'

import BookingSelectForm from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalContent/BookingSelectForm'
import { TableWrapper } from '@/components/Voucher/Styled'
import { BOOKINGS_SEARCH_QUERY } from '@/graphql/booking'
import { useBookingStore } from '@/store/booking'

interface AssociatedBookingsTableProps {
  form: WrappedFormUtils
  generatedDocument: any
}

const AssociatedBookingsTable = (props: AssociatedBookingsTableProps) => {
  const { form, generatedDocument } = props

  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()

  const { data, loading } = useQuery(BOOKINGS_SEARCH_QUERY, {
    variables: {
      input: {
        q: generatedDocument.groups.join(' '),
        qMethod: QMethods.Or
      }
    }
  })

  const columns: TableProps<Booking>['columns'] = [
    {
      key: 'no',
      title: 'Booking No',
      dataIndex: 'no'
    }
  ]

  const setForm = useBookingStore.use.setDocumentGeneratorAssociatedBookingsForm()
  const resetForm = useBookingStore.use.resetDocumentGeneratorAssociatedBookingsForm()

  useEffect(() => {
    setForm(form)

    return () => {
      resetForm()
    }
  }, [])

  return (
    <>
      <Popover
        arrow={false}
        trigger="click"
        open={visible}
        onOpenChange={setVisible}
        content={<BookingSelectForm form={form} generatedDocument={generatedDocument} />}
      >
        {t('common.bookings')} <Button size="small" icon={<PlusOutlined />} shape="circle" />
      </Popover>

      <TableWrapper>
        <Table
          rowKey={'uuid'}
          columns={columns}
          loading={loading}
          pagination={false}
          style={{ width: '50%' }}
          dataSource={data?.bookingsSearchJson?.rows || []}
        />
      </TableWrapper>
    </>
  )
}

export default Form.create<AssociatedBookingsTableProps>()(AssociatedBookingsTable)
