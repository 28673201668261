import { useState } from 'react'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'

import { StyledButton } from '@/components/ButtonWrapper'
import useDownloadEffect from '@/hooks//useDownloadEffect'
import useCheckingEffect from '@/hooks/useCheckingEffect'
import useQueueEffect from '@/hooks/useQueueEffect'
import useGlobalCompanyStore from '@/store/globalCompany'
import respHandler from '@/utils/responseHandler'

const useAsyncPdfDownload = (consolVoucher, type, selectedGlobalCompany) => {
  const [state, setState] = useState({
    status: 'none', // [none, generating, checking, downloading, downloaded]
    pdfUuid: null
  })

  useQueueEffect(state, setState, consolVoucher, type, selectedGlobalCompany)
  useCheckingEffect(state, setState)
  useDownloadEffect(state, setState, consolVoucher)

  const trigger = () => {
    respHandler('Downloading PDF, hang on...', 'load')
    setState({ status: 'queueing', pdfUuid: null })
  }

  return [state, trigger]
}

const ConsolVoucherPdfAsyncDownloadAction = ({ consolVoucher, type }) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const [state, trigger] = useAsyncPdfDownload(consolVoucher, type, selectedGlobalCompany)

  return (
    <span>
      <StyledButton onClick={trigger}>
        {state.status === 'generating' || state.status === 'checking' ? (
          <LoadingOutlined />
        ) : (
          <DownloadOutlined />
        )}
      </StyledButton>
    </span>
  )
}

export default ConsolVoucherPdfAsyncDownloadAction
