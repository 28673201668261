import type { PropsWithChildren } from 'react'

import { Col, Form, Row } from 'antd'
import styled from 'styled-components'

export const PageView = styled.div`
  min-height: 50px;
  width: 100%;

  .ant-skeleton-paragraph {
    padding: 0;
  }
`

export const ViewContent = styled.div<PropsWithChildren<unknown>>`
  padding: 10px 0px;
  width: 100%;
`

export const TableViewWrapper = styled.div`
  padding: 10px 0;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 15px 10px;
  }

  .ant-pagination-simple-pager {
    font-size: 13.5px;
    font-weight: 500;
  }

  .anticon-edit {
    cursor: pointer;
    font-size: 16px;
    color: rgb(120, 120, 120);

    &:hover {
      color: black;
    }
  }
`

export const SearchBox = styled(Col)`
  text-align: left;

  .ant-input {
    font-size: 13px;
    font-weight: 400;
    color: black;
    height: 34px;
    letter-spacing: 0.3px;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 35px;
  }
`

export const StatusText = styled.div`
  font-size: 10.5px;
  font-weight: bold;
  color: rgb(150, 150, 150);
  text-transform: uppercase;
  letter-spacing: 0.6px;
`

export const NumeralText = styled.div`
  font-size: 12.5px;
  font-weight: 600;
  color: black;
  letter-spacing: 0px;
`

export const StyledAnchor = styled.div`
  font-size: 13.222px;
  font-weight: 500;
  color: #111;
  display: block;
  max-width: 400px;

  &:hover {
    color: #1890ff;
  }
`

export const BackLink = styled.span`
  display: inline-block;
  line-height: 32px;
  padding: 0 10px;
  margin-right: 10px;
  color: rgb(140, 140, 140);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;

  .anticon {
    font-size: 18px;
    padding-right: 10px;
    vertical-align: -0.225em;
  }

  &:hover {
    color: black;
  }
`
type ActionProps = {
  noBottomPad?: boolean
}
export const Actions = styled(Row)<ActionProps>`
  margin: 20px 0 ${props => props.noBottomPad && '0'};
`

type ActionItemProps = {
  align?: string
}

export const ActionItem = styled(Col)<ActionItemProps>`
  text-align: ${props => (props.align ? props.align : 'left')};

  .ant-btn {
    margin-left: 10px;
    font-weight: 500;
  }
`

export const Inputs = styled(Form)`
  padding: 20px 0 0;

  .ant-btn,
  .ant-select,
  .ant-select-dropdown-menu-item,
  .ant-select-search--inline .ant-select-search__field {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .ant-btn {
    height: 30px;
  }

  .ant-select-selection-selected-value,
  .ant-select-search--inline .ant-select-search__field {
    color: black;
  }
`

export const NoItemsFound = styled.div`
  padding: 20px 0;
  font-size: 13.5px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.3px;
  color: rgb(150, 150, 150);
`

export const NormalText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const boldUpperStyle = `
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: rgb(175, 175, 175);
`

export const Numeric = styled.span`
  font-size: 13.222px;
  font-weight: 500;
  letter-spacing: 0;
`

export const BoldUpperText = styled.span`
  ${boldUpperStyle}
  font-size: 12px;
`
type NumericLabelProps = {
  highlight?: string
}
export const NumericLabel = styled.span<NumericLabelProps>`
  font-size: 10.5px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => (props.highlight === 'blue' ? '#0f5fc6' : '#2ecc71')};
  padding-right: 3px;
`

export const Muted = styled.span`
  ${boldUpperStyle}
`

export const MutedDark = styled.span`
  ${boldUpperStyle}
  font-size: 11.5px;
  font-weight: 600;
  color: #111;
`

export const ItemText = styled.span`
  font-size: 13.222px;
  font-weight: 500;
  letter-spacing: 0.1px;
`

type ItemTextFixedProps = {
  fixAt?: string
}
export const ItemTextFixed = styled(ItemText)<ItemTextFixedProps>`
  display: block;
  ${props => props.fixAt && `max-width: ${props.fixAt}px`};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ModalHeader = styled(Row)`
  .anticon-close {
    cursor: pointer;
    font-size: 16px;
    color: rgb(120, 120, 120);

    &:hover {
      color: black;
    }
  }
`

type HeaderItemProps = {
  align?: string
}

export const HeaderItem = styled(Col)<HeaderItemProps>`
  text-align: ${props => props.align || 'left'};
`

export const SectionAction = styled.div`
  padding: 0 0 10px;
  text-align: left;
`

export const SkeletonSpinner = styled.div`
  text-align: center;
  padding-top: 60px;

  .anticon-spin {
    height: 2em;
    width: 2em;
    color: rgb(180, 180, 180);
    animation: loadingCircle 0.5s infinite linear;
  }
`

export const ItemNotfound = styled.div`
  padding-top: 10%;
  font-size: 14.5px;
  color: rgb(140, 140, 140);
  text-align: center;
  letter-spacing: 0.2px;
`

export const StyledLinkTag = styled.div`
  font-weight: bold;
`

export const HelpWrapper = styled.div`
  padding-top: 20px;
`
