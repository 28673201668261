import type { VoucherItem } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import type { SelectValue } from 'antd-v5/es/select'

import { forwardRef, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { VOUCHER_ITEMS_SELECT } from '@/components/Select/schema'
import notify from '@/utils/notify'

interface VoucherItemsSelectProps extends SelectProps<SelectValue> {
  voucherUuid: string
  autoSelect?: boolean
}

const VoucherItemsSelect = forwardRef((props: VoucherItemsSelectProps, ref: any) => {
  const { t } = useTranslation()
  const { voucherUuid, onChange, autoSelect = true, placeholder = t('common.search') } = props

  const [voucherItems, setVoucherItems] = useState<VoucherItem[]>([])

  const { data, error, loading } = useQuery(VOUCHER_ITEMS_SELECT, {
    variables: { uuid: voucherUuid }
  })

  useEffect(() => {
    const voucherItems = (data?.voucher?.voucherItems ?? []).filter(
      (item): item is VoucherItem => item !== null
    )

    setVoucherItems(voucherItems)
    if (autoSelect && voucherItems.length === 1) {
      onChange?.(voucherItems[0].uuid, voucherItems[0].uuid)
    }
  }, [data, autoSelect])

  if (error) notify(error.message, 'error')

  const viOptions = voucherItems?.map((vi: VoucherItem) => {
    return {
      label: `${vi.booking?.no} - ${vi.costItem?.chargeItem?.name}`,
      value: vi.uuid
    }
  })

  return (
    <Select
      {...props}
      ref={ref}
      allowClear
      loading={loading}
      filterOption={false}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
      placeholder={placeholder}
      options={viOptions}
    />
  )
})

export default memo(VoucherItemsSelect)
