import { graphql } from '@apollo/client/react/hoc'
import { isObject } from 'lodash'

import companyGql from './companyGql'

interface CompanyData {
  uuid: string
  name: string
}

interface CompanyGraphqlResponse {
  loading: boolean
  error?: any
  company?: CompanyData
}

interface CompanyProps {
  companyUuid?: string
  companyGraphql?: CompanyGraphqlResponse
}

const companyGraphql = graphql(companyGql, {
  name: 'companyGraphql',
  skip: (props: CompanyProps) => !props.companyUuid,
  options: (props: CompanyProps) => ({
    variables: {
      // @ts-ignore
      uuid: isObject(props.companyUuid) ? props.companyUuid?.uuid : props.companyUuid
    }
  })
})

const Company = (props: CompanyProps) => {
  const { companyUuid = '', companyGraphql = {} } = props
  const { loading, error, company }: any = companyGraphql

  if (!companyUuid) return <span>Company is not selected.</span>
  if (loading) return <span>Loading...</span>
  if (error) return <span>Error while loading company.</span>
  if (!company) return <span>Error: Company not found.</span>

  return (
    <span>
      [{company.code}] {company.name}
    </span>
  )
}

export default companyGraphql(Company)
