import { gql } from '@/types'

export const EVENTS_ETC = gql(`
  query getEventsEtc($input: QueryEventEtc) {
    eventsEtc(input: $input) {
      rows {
        createdAt
        action
        user {
          name
          status
          email
          uuid
        }
        payload
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
export const EVENTS_VOUCHER = gql(`
  query getEventsVoucher($input: QueryEventVoucher) {
    eventsVoucher(input: $input) {
      rows {
        createdAt
        action
        user {
          name
          status
          email
          uuid
        }
        payload
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
