import { BookingStatus, DocumentCreatorTemplateStatus } from '@/types/graphql'

import { CopyOutlined, EditOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'
import includes from 'lodash/includes'

import AssigneeSelector from '@/components/Booking/Assignee'
import BackButton from '@/components/Booking/BackButton'
import { getButtons } from '@/components/Booking/BookingActionButton'
import General from '@/components/Booking/General'
import BookingStatusIcon from '@/components/Booking/ListView/BookingStatusIcon'
import Title from '@/components/Booking/Title'
import Link from '@/components/Link'
import DocumentCreatorPrinter from '@/components/Shared/DocumentCreatorPrinter'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import { useBookingStore } from '@/store/booking'
import styles from './OverviewSection.module.css'

const OverviewSection = ({ booking, collapsedDrawer }) => {
  const { isPortalUser } = useProcessPortalUser()

  const isBookingCollapse = useBookingStore.use.isBookingCollapse()
  const setIsBookingCollapse = useBookingStore.use.setIsBookingCollapse()

  const toggleDrawer = () => setIsBookingCollapse(!isBookingCollapse)

  const isLiveJob = !includes(
    [BookingStatus.Cancelled, BookingStatus.Draft, BookingStatus.Submitted],
    booking?.status
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.left}>
          <BackButton />
          <div className={styles.bookingText}>
            <BookingStatusIcon booking={booking} />
            <Title booking={booking} />
          </div>
        </div>

        <div className={styles.right}>
          {/* @ts-ignore */}
          <AssigneeSelector booking={booking} />

          {!collapsedDrawer ? (
            <MenuUnfoldOutlined
              style={{ fontSize: '15px', cursor: 'pointer' }}
              onClick={toggleDrawer}
            />
          ) : (
            <MenuFoldOutlined
              style={{ fontSize: '15px', cursor: 'pointer' }}
              onClick={toggleDrawer}
            />
          )}
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.buttonsContainer}>
          {!isPortalUser && isLiveJob && (
            <DocumentCreatorPrinter
              job={booking}
              variables={{
                qs: ['BOOKING_RECORD', 'CONSIGNMENT_NOTE'],
                statuses: [DocumentCreatorTemplateStatus.Activated]
              }}
            />
          )}

          {!isPortalUser && getButtons(booking)}

          {!isPortalUser && isLiveJob && (
            <Link to={`/bookings/${booking?.uuid}/edit`}>
              <Button
                id="edit-booking-button"
                type="dashed"
                icon={<EditOutlined className="mr-2" />}
              >
                Edit
              </Button>
            </Link>
          )}

          <Link to={`/bookings/${booking.uuid}/duplicate`}>
            <Button type="dashed" icon={<CopyOutlined className="mr-2" />}>
              Duplicate
            </Button>
          </Link>

          {!isPortalUser && isLiveJob && (
            <Link to={`/bookings/${booking.uuid}/cost-sheet`}>
              <Button type="dashed">Cost sheet</Button>
            </Link>
          )}

          {!isPortalUser && booking.status === 'CANCELLED' && <span>Status: CANCELLED</span>}
        </div>

        <General booking={booking} />
      </div>
    </div>
  )
}

export default OverviewSection
