import { memo, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd-v5'

import EventList from '@/components/Shared/Events/EventList'
import { EventToggle } from '@/components/Shared/Events/Styled'

export type EventsProps = {
  gql?: any
  variables: Record<string, any>
}

const Events = (props: EventsProps) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const openDrawer = () => setShowDrawer(true)
  const closeDrawer = () => setShowDrawer(false)

  return (
    <>
      {/* <EventToggle> */}
      <Button onClick={openDrawer}>
        <ArrowLeftOutlined /> View events
      </Button>
      {/* </EventToggle> */}

      <Drawer width={350} placement="right" open={showDrawer} onClose={closeDrawer} title="Events">
        <EventList {...props} />
      </Drawer>
    </>
  )
}

export default memo(Events)
