import type { SelectProps } from 'antd/lib/select'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { JOBS_SELECT_OPTIONS } from '@/components/Select/job/schema'

const { Option } = Select

type JobSelectProps = SelectProps & {
  bookingUuid: string
  autoSelect?: boolean
}

const JobSelect = (props: JobSelectProps) => {
  const { bookingUuid, style, onChange, autoSelect = true, placeholder = 'Select a job' } = props

  const { data, loading } = useQuery(JOBS_SELECT_OPTIONS, {
    variables: { bookingUuid: bookingUuid ? bookingUuid : '' }
  })

  useEffect(() => {
    const jobs = data?.jobs?.rows ?? []

    if (autoSelect && jobs.length === 1) {
      onChange?.(jobs[0]?.uuid, jobs[0]?.uuid)
    }
  }, [data, autoSelect])

  return (
    <Select
      {...props}
      style={{ width: '100%', ...style }}
      loading={loading}
      placeholder={placeholder}
    >
      {data?.jobs?.rows?.map(job => (
        <Option key={job?.jobNo} value={job?.uuid ?? ''}>
          {job?.jobNo}
        </Option>
      ))}
    </Select>
  )
}

export default JobSelect
