import styled from 'styled-components'

export const StatusSummary = styled.div`
  text-align: center;
  font-size: 13.222px;
  color: rgb(50, 60, 70);
  letter-spacing: 0.1px;
  line-height: 40px;

  .anticon {
    font-size: 21px;
    vertical-align: -0.275em;
    margin-right: 5px;
  }
`
