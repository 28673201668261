import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { JobStatus } from 'App/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { Input, InputNumber } from 'antd'

import BillingUnitSelect from 'App/components/Select/BillingUnitSelect'
import BookingSelect from 'App/components/Select/BookingSelect'
import TaxSelect from 'App/components/Select/TaxSelect'
import VoucherItemSelect from 'App/components/Select/VoucherItemSelect'
import VouchersSelect from 'App/components/Select/VouchersSelect'
import TransportJobSelector from 'App/components/Transport/Components/TransportJobSelector'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import { isUuid } from 'App/utils/u'
import EditableForm from './EditableForm'
import { EDIT_VOUCHER_ITEM_JOB_QUERY } from './schema'

interface EditableFieldsProps {
  form: WrappedFormUtils

  onCancel: any
  selectedVoucherItem: any
  dbExchangeRate: any
  handleUpdateVoucherItem: any
}

const EditableFields = (props: EditableFieldsProps) => {
  const { form, selectedVoucherItem, dbExchangeRate, onCancel, handleUpdateVoucherItem } = props

  const { getFieldDecorator } = form

  const { t } = useTranslation()

  const selectedVoucher = useSelector((state: any) => state.voucher.selectedVoucher)

  const [selectedBookingUuid, setSelectedBookingUuid] = useState('')
  const [selectedTax, setSelectedTax] = useState(selectedVoucherItem?.tax)
  const [selectedInvoiceLinkedUuid, setSelectedInvoiceLinkedUuid] = useState(
    selectedVoucherItem?.voucherItemCn?.voucher?.uuid
  )

  const [getJobs, { data: jobsData, error: jobsErr }] = useLazyQuery(EDIT_VOUCHER_ITEM_JOB_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  if (jobsErr) {
    responseHandler(jobsErr, 'error')
    logger.error('EDIT_VOUCHER_ITEM_JOB_QUERY error', jobsErr)
  }

  useEffect(() => {
    setSelectedTax(selectedVoucherItem?.tax)

    return () => {
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (isUuid(selectedVoucherItem.bookingUuid)) {
      getJobs({ variables: { bookingUuid: selectedVoucherItem.bookingUuid } })
    }
  }, [selectedVoucherItem.bookingUuid])

  const handleSubmit = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        const validJobUuidsFromBooking = jobsData?.jobs?.rows
          ?.filter(j => j !== null && j.status === JobStatus.Active)
          ?.map(j => j?.uuid)
        if (!validJobUuidsFromBooking?.includes(values.jobUuid)) {
          return responseHandler(
            'The selected job does not match valid jobs from the selected booking.',
            'error'
          )
        }
        // eslint-disable-next-line
        const { voucherCnUuid, ...omitValues } = values

        const updateRequest = {
          ...omitValues,
          uuid: selectedVoucherItem.uuid,
          currencyUuid: selectedVoucherItem.currency.uuid
        }

        if (selectedVoucher.status === 'NEW') {
          updateRequest.tax = selectedTax
        }

        await handleUpdateVoucherItem(updateRequest)

        onCancel()
      } catch (error) {
        responseHandler(error, 'error')
        logger.error('Edit voucher item handleSubmit error', error)
      }
    })
  }, [jobsData, selectedTax])

  const handleSelectTax = useCallback((value: string, tax: any) => setSelectedTax(tax), [])
  const handleSelectVoucher = useCallback(val => setSelectedInvoiceLinkedUuid(val), [])

  useEffect(() => {
    const bookingUuid = form.getFieldValue('bookingUuid')
    if (bookingUuid && selectedBookingUuid !== bookingUuid) {
      setSelectedBookingUuid(bookingUuid)

      const jobUuid = form.getFieldValue('jobUuid')
      if (jobUuid) {
        form.setFieldsValue({
          jobUuid: ''
        })
      }

      // if (selectedVoucher.isCreditNote || selectedVoucher.isJournalVoucher) {
      //   const voucherItemCnUuid = form.getFieldValue('voucherItemCnUuid')
      //   if (voucherItemCnUuid) {
      //     form.setFieldsValue({
      //       voucherItemCnUuid: ''
      //     })
      //   }
      // }
    }
  }, [form])

  const voucherItemCols: any = [
    {
      label: 'Description',
      value: 'description',
      attributes: {
        initialValue: selectedVoucherItem?.description
      },
      input: <Input.TextArea autoComplete="off" />
    },
    {
      label: 'Size',
      value: 'size',
      attributes: {
        initialValue: selectedVoucherItem?.size
      },
      input: <Input autoComplete="off" type="number" />
    },
    {
      label: 'Billing Unit',
      value: 'unit',
      attributes: {
        initialValue: selectedVoucherItem?.unit
      },
      input: <BillingUnitSelect />
    },
    {
      label: 'Quantity',
      value: 'quantity',
      attributes: {
        initialValue: selectedVoucherItem?.quantity
      },
      input: <InputNumber id="quantity-input" autoComplete="off" type="number" />
    },
    {
      label: 'Base Rate',
      value: 'baseRate',
      attributes: {
        initialValue: selectedVoucherItem?.baseRate
      },
      input: <InputNumber id="baserate-input" autoComplete="off" type="number" />
    },
    {
      label: 'Ex. Rate',
      value: 'exchangeRate',
      attributes: {
        initialValue: selectedVoucherItem?.exchangeRate
      },
      input: <InputNumber autoComplete="off" type="number" />
    },
    {
      label: 'Local Ex. Rate',
      value: 'localExchangeRate',
      attributes: {
        initialValue: selectedVoucherItem?.localExchangeRate
      },
      input: <InputNumber autoComplete="off" type="number" />
    }
  ]

  if (dbExchangeRate) {
    voucherItemCols.push({
      label: 'DB Ex. Rate',
      nonSubmitField: true,
      input: <span>{dbExchangeRate}</span>
    })
  }

  voucherItemCols.push({
    label: 'Tax Code',
    value: 'taxUuid',
    attributes: {
      initialValue: selectedVoucherItem.tax?.uuid || selectedVoucherItem?.taxUuid
    },
    input: <TaxSelect onChange={handleSelectTax} />
  })

  const linkInvoiceItemCols = [
    {
      label: 'Invoice',
      value: 'voucherCnUuid',
      attributes: {
        initialValue: selectedVoucherItem?.voucherItemCn?.voucher?.uuid,
        rules: [
          {
            required: true,
            message: 'Invoice is required.'
          }
        ]
      },
      input: (
        <VouchersSelect
          queryOnMount
          onChange={handleSelectVoucher}
          excludedVouchers={[selectedVoucher.uuid]}
          customerUuid={selectedVoucher.customer?.uuid}
          vendorUuid={selectedVoucher.vendor?.uuid}
        />
      )
    },
    {
      label: 'Item',
      value: 'voucherItemCnUuid',
      attributes: {
        initialValue: selectedVoucherItem?.voucherItemCn?.uuid,
        rules: [
          {
            required: true,
            message: 'Item is required.'
          }
        ]
      },
      input: (
        <VoucherItemSelect
          viUuid={selectedVoucherItem?.voucherItemCn?.uuid}
          queryOnMount
          voucherUuid={selectedInvoiceLinkedUuid}
        />
      )
    }
  ]

  const linkJobCols = [
    {
      label: t('common.booking'),
      value: 'bookingUuid',
      attributes: {
        initialValue: selectedVoucherItem?.bookingUuid || selectedVoucherItem?.job?.bookingUuid,
        rules: [
          {
            required: true,
            message: 'Booking is required.'
          }
        ]
      },
      input: <BookingSelect queryOnMount />
    }
  ]

  if (selectedBookingUuid) {
    linkJobCols.push({
      label: t('common.job'),
      value: 'jobUuid',
      attributes: {
        initialValue: selectedVoucherItem?.job?.uuid,
        rules: [
          {
            required: true,
            message: 'Job is required.'
          }
        ]
      },
      input: <TransportJobSelector bookingUuids={[selectedBookingUuid]} />
    })
  }

  const fields = [
    {
      cols: voucherItemCols
    },
    {
      title: 'Link Job',
      useDivider: true,
      cols: linkJobCols
    }
  ]

  if (selectedVoucher.isCreditNote || selectedVoucher.isJournalVoucher) {
    fields.splice(1, 0, {
      title: 'Link Invoice Item',
      useDivider: true,
      cols: linkInvoiceItemCols
    })
  }
  return (
    <EditableForm
      fields={fields}
      onCancel={onCancel}
      handleSubmit={handleSubmit}
      getFieldDecorator={getFieldDecorator}
    />
  )
}

export default memo(EditableFields)
