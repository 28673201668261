import { Component } from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import join from 'lodash/join'

import Link from '@/components/Link'
import { NormalText, StatusText } from '@/components/TableView/Styled'
import { StyledLinkTag } from '../../Styled'
import StatusesFilter from './Filters/Statuses'
import TypesFilter from './Filters/Types'

class TableViewData extends Component {
  render() {
    const { items, filters, updateFilters, pagination } = this.props
    const { onSizeChange } = pagination

    const dataSource = items?.map(item => ({ ...item, key: item.uuid }))

    const columns = []

    columns.push(
      {
        title: 'Name',
        key: 'name',
        render: (text, record) => {
          const baseCompany = record.ownerUuid === record.accountUuid
          return (
            <Link to={`/manage/companies/view?uuid=${record.uuid}`}>
              <StyledLinkTag>
                {baseCompany ? (
                  <>
                    <LinkOutlined />
                    {` ${record.name}`}
                  </>
                ) : (
                  <>{record.name}</>
                )}
              </StyledLinkTag>
            </Link>
          )
        }
      },
      {
        title: 'Code',
        key: 'code',
        render: (text, record) => <NormalText>{record.code || '-'}</NormalText>
      },
      {
        title: 'Debtor',
        key: 'debtorCode',
        render: (text, record) => <NormalText>{record.debtorCode || '-'}</NormalText>
      },
      {
        title: 'Creditor',
        key: 'creditorCode',
        render: (text, record) => <NormalText>{record.creditorCode || '-'}</NormalText>
      },
      {
        title: 'Types',
        key: 'types',
        filterDropdown: <TypesFilter filters={filters} updateFilters={updateFilters} />,
        render: (text, record) => (
          <StatusText>{(record.types && join(record.types, ', ')) || '-'}</StatusText>
        )
      },
      {
        title: 'Country',
        key: 'country',
        render: (text, record) => {
          const country = record.country

          if (country) {
            return <div>{record.country?.name}</div>
          }

          return <div>-</div>
        }
      },
      {
        title: 'Status',
        key: 'status',
        filterDropdown: <StatusesFilter filters={filters} updateFilters={updateFilters} />,
        render: (text, record) => <StatusText>{record.status}</StatusText>
      }
    )

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        locale={{ emptyText: 'No items found.' }}
        pagination={{
          total: pagination?.total,
          simple: true,
          current: pagination?.offset / pagination.limit + 1,
          pageSize: pagination?.limit,
          onChange: page => onSizeChange(page - 1)
        }}
      />
    )
  }
}

export default TableViewData
