import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { DatePicker, Form } from 'antd'
import { Button, Col, Divider, Input, Popover, Row } from 'antd-v5'
import moment from 'moment'

import JobSelect from '@/components/Select/NoQuery/JobSelect'
import TripSelector from '@/components/Select/NoQuery/TripSelector'
import schema from '@/containers/booking/schema'
import { INSERT_CHRONOLOGY } from '@/graphql/booking'
import { chronologyTypes } from '@/utils/labelMap'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'

const FormItem = Form.Item

const dateFormat = 'DD/MM/YYYY HH:mm'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

export const getJobsAndTrips = (booking, getFieldsValue) => {
  const only1Job = booking?.jobs?.length === 1 && booking?.jobs?.[0]?.uuid
  const selectedValues = getFieldsValue()
  const tripsToSelect = only1Job
    ? booking?.jobs?.[0]?.trips
    : booking?.jobs?.find(job => job?.uuid === selectedValues?.jobUuid)?.trips
  const only1Trip = tripsToSelect?.length === 1 && tripsToSelect?.[0]?.uuid

  return [only1Job, only1Trip, tripsToSelect]
}

type ChronologyAddButtonProps = {
  chrono
  form: WrappedFormUtils
  booking
  bookingUuid: string
}

const ChronologyAddButton = (props: ChronologyAddButtonProps) => {
  const { chrono, form, booking, bookingUuid } = props
  const { getFieldDecorator, getFieldsValue } = form

  const [insertChronology] = useMutation(INSERT_CHRONOLOGY)

  const [mode, setMode] = useState('date')
  const [isVisible, setIsVisible] = useState(false)

  const title = chronologyTypes[chrono.title] || ''

  useEffect(() => {
    form.resetFields()
  }, [])

  const handleInsert = e => {
    e.preventDefault()

    form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      try {
        handleResponse(`Creating ${chrono.title}, hang on...`, 'load')

        const insertObj = {
          bookingUuid,
          jobUuid: values.jobUuid,
          tripUuid: values.tripUuid,
          type: chrono.type,
          date: values.chronoDatetime,
          remarks: values.remarks,
          reference: values.reference
        }

        await insertChronology({
          variables: { input: insertObj },
          refetchQueries: [
            {
              query: schema.BOOKING_QUERY_WORKFLOW,
              variables: {
                uuid: bookingUuid
              }
            }
          ]
        })

        setIsVisible(false)
        handleResponse('Created successfully.', 'success')
      } catch (error) {
        logger.error('ChronologyAddButton insertChronology error', error)
        handleResponse(error, 'error')
      }
    })
  }

  const handleDatepickerChange = () => {
    if (mode === 'date') {
      setMode('time')
    }
  }

  const handleOpenChange = () => setMode('date')

  const handlePanelChange = (_value, mode) => setMode(mode)

  const [only1Job, only1Trip, tripsToSelect] = getJobsAndTrips(booking, getFieldsValue)

  const content = (
    <Form onSubmit={handleInsert} style={{ width: '600px' }}>
      <FormItem label="Date & Time" {...formItemLayout}>
        {getFieldDecorator('chronoDatetime', {
          rules: [{ required: true, message: 'Date time required.' }],
          initialValue: moment()
        })(
          <DatePicker
            showTime
            mode={mode}
            format={dateFormat}
            style={{ width: 200 }}
            onOpenChange={handleOpenChange}
            onChange={handleDatepickerChange}
            onPanelChange={handlePanelChange}
            placeholder="Select a date and time"
            getCalendarContainer={trigger => trigger.parentNode}
          />
        )}
      </FormItem>

      <FormItem label="Job" {...formItemLayout}>
        {getFieldDecorator('jobUuid', {
          initialValue: only1Job || undefined,
          rules: [{ required: !!chrono?.isJobRequired, message: 'Job is required.' }]
        })(<JobSelect jobs={booking?.jobs} />)}
      </FormItem>

      <FormItem label="Trip" {...formItemLayout}>
        {getFieldDecorator('tripUuid', {
          initialValue: only1Trip || undefined,
          rules: [{ required: !!chrono?.isTripRequired, message: 'Trip is required.' }]
        })(<TripSelector form={form} trips={tripsToSelect} />)}
      </FormItem>

      <FormItem label="Reference" {...formItemLayout}>
        {getFieldDecorator('reference', {
          initialValue: chrono?.data?.date ? '' : chrono?.data?.reference
        })(<Input autoComplete="off" />)}
      </FormItem>

      <FormItem label="Remarks" {...formItemLayout}>
        {getFieldDecorator('remarks', {
          initialValue: ''
        })(<Input autoComplete="off" />)}
      </FormItem>

      <Divider style={{ margin: '12px 0' }} />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button onClick={() => setIsVisible(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
            Insert
          </Button>
        </Col>
      </Row>
    </Form>
  )

  return (
    <Popover
      arrow={false}
      title={title}
      trigger="click"
      open={isVisible}
      placement="right"
      content={isVisible ? content : 'Closing...'}
      onOpenChange={visible => setIsVisible(visible)}
    >
      <PlusOutlined onClick={() => setIsVisible(true)} />
    </Popover>
  )
}

export default Form.create<ChronologyAddButtonProps>()(ChronologyAddButton)
