import { gql } from '@/types'

export const BOOKINGS_MAIN_QUERY = gql(`
  query bookingsMainEs($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const MAKE_BOOKING_MUTATION = gql(`
  mutation makeBooking1($input: MakeBookingInput!) {
    makeBooking(input: $input) {
      uuid
      no
    }
  }
`)

export const BOOKING_EVENTS = gql(`
  query getBookingEvents($uuid: UUID!) {
      booking(uuid: $uuid) {
        events {
          ... on BookingEvent {
            context
            event
            time
            payload
            user {
              uuid
              nickname
              email
              picture
            }
          }
          ... on MessageEvent {
            event
            time
            payload
            user {
              uuid
              nickname
              email
              picture
            }
          }
        }
      }
  }
`)
