import type {
  Address,
  Incentive,
  TransportActivity,
  TransportJob,
  TransportReportJob
} from '@/types/graphql'
import Enums from '@/types/enums'
import { LegStatus } from '@/types/graphql'

import { isNil } from 'lodash'
import find from 'lodash/find'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import set from 'lodash/set'
import moment from 'moment'

import { getActTime, getLocalTimeString } from '@/components/RelativeDateTime'
import { getGroupingLookupKey } from '@/components/Shared/TableDataPanel/helper'
import { GroupingMethod } from '@/components/Shared/TableDataPanel/maps'
import { transTripTypes } from '@/utils/labelMap'
import { printStringOrArray } from '@/utils/u'
import { formatCurrencyAmount } from '@/utils/voucher'

export const getJobNo = job => {
  return job?.no || job?.bookingNo || ''
}

export type TransportJobStatusType =
  | 'PENDING'
  | 'PLANNED'
  | 'STARTED'
  | 'AT_PICKUP'
  | 'ENROUTE'
  | 'STAGED'
  | 'AT_DROPOFF'
  | 'COMPLETED'
  | 'CANCELLED'
  | 'DELETED'
  | 'MT'
  | 'LATE'

export type TransportJobEmptyTrip = TransportJob & { isEmptyTrip?: boolean }

export const getStatusFromJob = (job: TransportJobEmptyTrip): TransportJobStatusType => {
  if (!job) return 'COMPLETED'

  // @ts-expect-error - statuses is not defined in TransportJob
  const statuses = job.statuses ? job.statuses.filter(s => s?.compile !== 'TRANS_BOOKED') : []

  const lastStatus = statuses && statuses[statuses.length - 1]

  if (job.isEmptyTrip) return 'MT'

  if (!lastStatus) return 'PLANNED'

  if (lastStatus.compile === 'COMPLETED') {
    return 'COMPLETED'
  }

  if (lastStatus.compile === 'STAGED') {
    return 'STAGED'
  }

  const plan: TransportActivity = find(
    statuses,
    (status: TransportActivity) => status.compile === 'PLANNED'
  )
  if (!plan || moment().subtract(10, 'minutes').isAfter(new Date(plan.ts))) {
    return 'LATE'
  } else {
    return 'STARTED'
  }
}

export const isValidContainerNumber = (jobNo: string) => {
  let t
  let sum = 0

  if (!jobNo) return true // empty is valid container for imports
  jobNo = jobNo.replace(' ', '')
  if (jobNo.length !== 11) return false

  for (let i = 0; i < 10; i++) {
    if (/[A-Z]/.test(jobNo[i])) {
      t = Enums.Container.CharLookup[jobNo[i]]
    } else if (/\d/.test(jobNo[i])) {
      t = jobNo[i]
    }
    sum += t * Math.pow(2, i)
  }

  return (sum % 11) % 10 === Number.parseInt(jobNo[10])
}

export const sanitizeToArray = (
  input: Array<string | null | undefined> | string | null | undefined
) => {
  if (!input) return undefined

  if (isArray(input)) return input

  if (input && !isArray(input)) {
    if (input.indexOf(',') >= 0) {
      return input?.split(',')
    }

    return [input]
  }
}

export const formatIncentivesVoucher = (
  job: TransportReportJob,
  country: string,
  currency: string,
  dateFormat?: boolean,
  isExport?: boolean
) => {
  const incentiveVoucherDate = job.incentiveVoucherDates
    ? job.incentiveVoucherDates
        ?.map(date => (date ? getActTime(new Date(date), dateFormat) : ''))
        ?.join(', ') || '-'
    : '-'
  const incentiveVoucherAmount = formatCurrencyAmount(job.incentiveVoucherTotal, country, currency)

  return {
    incentiveVoucherNo: job.incentiveVoucherNos?.join(', ') || '-',
    incentiveVoucherDate,
    incentiveVoucherAmount: isExport ? job.incentiveVoucherTotal : incentiveVoucherAmount,
    incentiveVoucherStatus: job.incentiveVoucherStatuses?.join(', ') || '-'
  }
}

export const getIncentiveTypes = (job: any): Record<string, any> => {
  const latestIncentives =
    job.incentives?.sort(
      (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
    ) || []

  return latestIncentives.reduce((obj: any, incentive: Incentive, i) => {
    const lookup = incentive.type?.code
      ? `${incentive.type.code}[${incentive.type.name}]`
      : incentive.typeUuid

    if (i === 0) {
      // insert latest incentive value
      obj[getGroupingLookupKey(GroupingMethod.LATEST_VALUE, lookup)] = incentive.amount || 0
    }

    if (isNil(obj[lookup])) {
      obj[lookup] = incentive.amount || 0
      return obj
    }

    obj[lookup] += incentive?.amount || 0

    return obj
  }, {})
}

export const formatCostItems = (job: TransportReportJob, isExport?: boolean) => {
  const voucherCurrency = job.voucherCurrencies?.[0] || '-'
  const costItemSellTotal = job.costItemSellTotal
    ? `${voucherCurrency} ${job.costItemSellTotal}`
    : '-'
  const costItemARTotal = job.costItemARTotal ? `${voucherCurrency} ${job.costItemARTotal}` : '-'
  const costItemARDraftTotal = job.costItemARDraftTotal
    ? `${voucherCurrency} ${job.costItemARDraftTotal}`
    : '-'
  const costItemAPTotal = job.costItemAPTotal ? `${voucherCurrency} ${job.costItemAPTotal}` : '-'
  const costItemAPDraftTotal = job.costItemAPDraftTotal
    ? `${voucherCurrency} ${job.costItemAPDraftTotal}`
    : '-'

  return {
    costItemSellTotal: isExport ? job.costItemSellTotal : costItemSellTotal,
    costItemARTotal: isExport ? job.costItemARTotal : costItemARTotal,
    costItemARDraftTotal: isExport ? job.costItemARDraftTotal : costItemARDraftTotal,
    costItemAPTotal: isExport ? job.costItemAPTotal : costItemAPTotal,
    costItemAPDraftTotal: isExport ? job.costItemAPDraftTotal : costItemAPDraftTotal
  }
}

export const formatVouchers = (job: any, dateFormat?: boolean, isExport?: boolean) => {
  const voucherAccDate =
    job.voucherAccountDates
      ?.map((date: string) => getActTime(new Date(date), dateFormat))
      ?.join(', ') || '-'
  const voucherIssueDate =
    job.voucherIssueDates
      ?.map((date: string) => getActTime(new Date(date), dateFormat))
      ?.join(', ') || '-'
  // job.voucherCurrencies !== selectedGlobalCompany?.company?.currency?.code
  const voucherCurrency = job.voucherCurrencies?.[0] || '-'
  const voucherArTotal = job.voucherArTotal ? `${voucherCurrency} ${job.voucherArTotal}` : '-'
  const voucherApTotal = job.voucherApTotal ? `${voucherCurrency} ${job.voucherApTotal}` : '-'
  return {
    voucherNo: job.voucherNos?.join(', ') || '-',
    arVoucherNo: job.voucherNosAr?.join(', ') || '-',
    apVoucherNo: job.voucherNosAp?.join(', ') || '-',
    voucherAccDate,
    voucherIssueDate,
    voucherCurrency,
    voucherArTotal: isExport ? job.voucherArTotal : voucherArTotal,
    voucherApTotal: isExport ? job.voucherApTotal : voucherApTotal,
    voucherStatus: job.voucherStatuses?.join(', ') || '-'
  }
}

export const formatLegTimings = (leg: TransportJob) => {
  return leg
    ? {
        planStart: leg.planStart ? getActTime(leg.planStart) : '-',
        start: leg.start ? getActTime(leg.start) : '-',
        startOut: leg.startOut ? getActTime(leg.startOut) : '-',
        planEnd: leg.planEnd ? getActTime(leg.planEnd) : '-',
        end: leg.end ? getActTime(leg.end) : '-',
        endOut: leg.endOut ? getActTime(leg.endOut) : '-',
        transportSource: leg.transportSource || '-',
        remarks: leg.remarks || '-'
      }
    : null
}

const convertArrayToString = (array: any) => {
  return Array.isArray(array) ? array.join(', ') : array || '-'
}

export const getBookingInfo = (leg: TransportJob, dateFormat?: boolean) => {
  // @ts-ignore
  const transportTypeSize = [leg.transportType, leg.size]?.filter((item: string | number) => item)

  const departments = leg.bookingDepts?.join(', ') || '-'
  const tags = leg.bookingTags?.join(', ') || '-' //@ts-ignore
  const from = leg.from || leg.shipperAddress || leg?.shipperAddressCache //@ts-ignore
  const to = leg.to || leg.consigneeAddress || leg?.consigneeAddressCache

  return {
    bookingNo: leg.bookingNo,
    bookingDateTimeCreated: getActTime(leg.dateCreated, dateFormat),
    no: leg.no,
    jobNo: leg.jobNo || '-',
    shipperRequiredDateTime: getActTime(leg.shipperRequiredDate, dateFormat),
    shipperRequiredDate: leg.shipperRequiredDate
      ? moment(leg.shipperRequiredDate).format('YYYY/MM/DD')
      : '-',
    shipperRequiredTime: getLocalTimeString(leg.shipperRequiredDate),
    consigneeRequiredDate: getActTime(leg.consigneeRequiredDate, dateFormat),
    shipperRef: leg.bookingDetails?.shipperRef || '-',
    references: convertArrayToString(leg.bookingDetails?.references),
    departments,
    tags,
    billTo: leg.billToName || '-',
    billToSource: leg.billToSource || '-',
    shipper: leg.shipperName,
    consignee: leg.consigneeName,
    origin: from?.areaCode || '-',
    destination: to?.areaCode || '-',
    shipperZone: from?.zone || '-',
    consigneeZone: to?.zone || '-',
    // @ts-ignore
    containerType: leg.containerType || '-',
    size: convertArrayToString(transportTypeSize)
  }
}

export const getBkDetailsForPlanning = (leg: any, query?: any) => {
  // @ts-ignore
  const hasZones = leg.from?.zone && leg.to?.zone
  const fromToZone = hasZones ? leg.from?.zone + ' - ' + leg.to?.zone : '-'
  const hasAreaCodes = leg.from?.areaCode && leg.to?.areaCode
  const fromToAreaCodes = hasAreaCodes ? leg.from?.areaCode + ' - ' + leg.to?.areaCode : '-'
  const department = printStringOrArray(leg.bookingDetails?.departments || leg.bookingDetails?.dept)
  const tag = printStringOrArray(leg.bookingDetails?.tags)
  const fromAddress = mergeAddressFull(leg?.from)
  const toAddress = mergeAddressFull(leg?.to)

  return {
    bookingDetails: {
      ...leg.bookingDetails,
      departments: department || ['UNKNOWN'],
      tags: tag || ['UNKNOWN']
    },
    shipperDept: leg.shipDept || leg.dept || '-',
    consigneeDept: leg.conDept || leg.dept || '-',
    MT: leg.isEmptyTrip ? 'MT' : 'LADEN',
    transportType: leg.transportType,
    containerType: leg.containerType,
    tripStatus: (leg.isEmptyTrip ? leg.status : leg.tripStatus) || '-',
    legStatus: [leg.sequence, leg.legStatus]?.filter(Boolean)?.join('-') || '-',
    fromToZone,
    fromToAreaCodes,
    department,
    tag,
    fromAddress,
    toAddress,
    fromName: leg.from?.name || '-',
    toName: leg.to?.name || '-',
    // fromName: (leg.shipperName || '') + '\n' + (leg.from?.name || '-'),
    // toName: (leg.consigneeName || '') + '\n' + (leg.to?.name || '-'),
    VDJMStartDate: query?.shipperRequiredDateStart || leg.start || leg.shipperRequiredDate,
    VDJMEndDate:
      query?.shipperRequiredDateEnd ||
      leg.endOut ||
      moment(leg.shipperRequiredDate).add(1, 'day').toDate()
  }
}

export const formatJobDistanceTime = (job: TransportReportJob, isExport?: boolean) => {
  const estimatedDistanceKm = job.estimatedDistanceKm ? `${job.estimatedDistanceKm} km` : '-'
  const actualDistanceKm = job.actualDistanceKm ? `${job.actualDistanceKm} km` : '-'
  const estimatedTravelTimeMins = job.estimatedTravelTimeMins
    ? `${job.estimatedTravelTimeMins} mins`
    : '-'
  const actualTravelTimeMins = job.actualTravelTimeMins ? `${job.actualTravelTimeMins} mins` : '-'

  return {
    estimatedDistanceKm: isExport ? job.estimatedDistanceKm : estimatedDistanceKm,
    actualDistanceKm: isExport ? job.actualDistanceKm : actualDistanceKm,
    estimatedTravelTimeMins: isExport ? job.estimatedTravelTimeMins : estimatedTravelTimeMins,
    actualTravelTimeMins: isExport ? job.actualTravelTimeMins : actualTravelTimeMins
  }
}

export const formatReportJobTripTimings = (job: TransportReportJob, dateFormat?: boolean) => {
  const tripType =
    job?.tripsTimings?.map(timing => transTripTypes[timing?.tripType as string])?.join(', ') || '-'
  const tripStatus = job?.tripsTimings?.map(timing => timing?.tripStatus)?.join(', ') || '-'
  const tripTimings = JSON.stringify(
    job?.tripsTimings?.map(timing => {
      return {
        tripSequence: timing?.tripSequence,
        firstLegStart: timing?.firstLegStart && getActTime(timing.firstLegStart, dateFormat),
        firstLegStartOut: timing?.firstLegStartOut && getActTime(timing.firstLegStart, dateFormat),
        timeAtShipperMins: timing?.timeAtShipperMins,
        lastLegEnd: timing?.lastLegEnd && getActTime(timing.firstLegStart, dateFormat),
        lastLegEndOut: timing?.lastLegEndOut && getActTime(timing.firstLegStart, dateFormat),
        timeAtConsigneeMins: timing?.timeAtConsigneeMins
      }
    })
  )

  return {
    tripTimings,
    tripType,
    tripStatus
  }
}

export const formatReportJobLegs = (job: TransportReportJob) => {
  return {
    // vehicleCode & driverCode are optional and so may be undefined for subcon
    PMs: job.vehicleNames?.join(', ') || '-',
    DRs: job.driverNames?.join(', ') || '-',
    trailers: job.trailerNames?.join(', ') || '-',
    transporters: job.transportNames?.join(', ') || '-',
    transportSource: job.transportSources?.join(', ') || '-'
  }
}

export const mergeAddressFull = (address: Address) => {
  if (!address) {
    return '-'
  }

  const splitAddress = [
    address.address1,
    address.address2,
    address.address3,
    address.address4,
    address.city,
    address.postCode,
    address.district,
    address.countryAlpha3
  ]
  return splitAddress.filter((item: string) => item)?.join(', ')
}

export const mergeAddressShort = (address: Address) => {
  if (!address) {
    return '-'
  }

  const splitAddress = [address.name, address.address1, address.areaCode, address.zone]
  return splitAddress.filter(Boolean)?.join(', ')
}

export const filterByBookingDetail = (
  filteredData: any,
  selectedDepts: Array<string>,
  option: string
) => {
  return filteredData
    ?.filter((d: any) => {
      const { bookingDetails } = d
      if (
        !bookingDetails ||
        !Array.isArray(bookingDetails[option]) ||
        !bookingDetails[option].length
      ) {
        return null
      }
      const items = d.bookingDetails?.[option]
        ?.map((item: string) => {
          if (selectedDepts.includes(item)) {
            return item
          }
          return null
        })
        ?.filter(Boolean)

      if (items?.length) {
        return d
      } else {
        return null
      }
    })
    ?.filter(Boolean)
}

export const isActiveLeg = (leg?: TransportJob): boolean => {
  if (!leg?.legStatus) return false
  const validStatuses: LegStatus[] = Object.values(LegStatus).filter(
    (s: LegStatus) => s !== LegStatus.Cancelled && s !== LegStatus.Deleted
  )
  return validStatuses.includes(leg.legStatus)
}

const convertTypeMapper: any = {
  tripSummary: {
    toStr: 'to',
    fromStr: 'from'
  },
  transportJob: {
    toStr: 'to.zone',
    fromStr: 'from.zone'
  }
}

export const convertZones = (data: any, zonesMapper: any, dataType: string) => {
  if (!data) return
  if (!zonesMapper) return data

  const toStr = convertTypeMapper[dataType].toStr
  const fromStr = convertTypeMapper[dataType].fromStr

  const newData = { ...data }

  if (zonesMapper[get(newData, fromStr)]) {
    set(newData, fromStr, zonesMapper[get(newData, fromStr)])
  }
  if (zonesMapper[get(newData, toStr)]) {
    set(newData, toStr, zonesMapper[get(newData, toStr)])
  }

  return newData
}

export const getTrailerDetails = (obj: any) => {
  if (!obj?.trailerName && !obj?.trailerEntity?.registration) {
    return '-'
  }

  const code = obj?.trailerCode || obj?.trailerEntity?.code
  const registration = obj?.trailerName || obj?.trailerEntity?.registration
  return `${code ? `${code} - ` : ''}${registration}`
}

const formatJob = (
  job: TransportReportJob,
  countryCode: string,
  currencyCode: string,
  isDateFormat: boolean = false,
  isExport: boolean = false
) => {
  const incentivesVoucher = formatIncentivesVoucher(
    job,
    countryCode,
    currencyCode,
    isDateFormat,
    isExport
  )
  const costItems = formatCostItems(job, isExport)
  const vouchers = formatVouchers(job, isDateFormat, isExport)
  const bookingInfo = getBookingInfo(job as TransportJob, isDateFormat)
  const distanceTime = formatJobDistanceTime(job, isExport)
  const tripTimings = formatReportJobTripTimings(job, isDateFormat)
  const legsInfo = formatReportJobLegs(job)

  return {
    ...job,
    ...incentivesVoucher,
    ...distanceTime,
    ...bookingInfo,
    ...tripTimings,
    ...costItems,
    ...vouchers,
    ...legsInfo,
    key: job.jobUuid
  }
}

export const formatJobForTable = (selectedGlobalCompany: any, job: TransportReportJob) => {
  return formatJob(
    job,
    selectedGlobalCompany?.country?.alpha2,
    selectedGlobalCompany?.company?.currency?.code,
    true,
    false
  )
}

export const formatJobForExport = (selectedGlobalCompany: any, job: TransportReportJob) => {
  return formatJob(
    job,
    selectedGlobalCompany?.country?.alpha2,
    selectedGlobalCompany?.company?.currency?.code,
    true,
    true
  )
}
