import type { DocumentNode, QueryResult } from '@apollo/client'
import type { JobType } from 'App/types/graphql'
import { JobTypeStatus } from 'App/types/graphql'
import { ActiveStatus } from '@/types/graphql'

import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Flex, Pagination, Result, Skeleton, Table } from 'antd-v5'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'

import PageHeader from 'App/components/Manage/PageHeader'
import TableActions from 'App/components/Manage/Shared/CrudType/TableView/Actions'
import StatusFilter from 'App/components/Manage/Shared/CrudType/TableView/StatusFilter'
import HelpIcon from 'App/components/Shared/HelpIcon'
import NoAccess from 'App/components/Shared/NoAccess'
import { StyledAnchor } from 'App/components/TableView/Styled'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

type CrudTypesTableViewProps = {
  gqlQuery: DocumentNode
  crudType: string
  gqlResponse?: QueryResult
  defaultQuery?: Record<string, any>
  isInputNested?: boolean
  articleUrl?: string
  tableColumns?: any[]
}

const CrudTypesTableView = (props: CrudTypesTableViewProps) => {
  const {
    gqlQuery,
    crudType,
    gqlResponse,
    defaultQuery = {},
    isInputNested,
    articleUrl,
    tableColumns
  } = props

  const dataKey = `${crudType}s`
  const crudName = startCase(camelCase(dataKey))
  const createUrl = `/manage/${kebabCase(crudType)}s/create`

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: `${crudType}`,
    permission: 'index'
  })

  const defaultTableColumns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code: string, record: JobType) => {
        return (
          <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
            <StyledAnchor>{code}</StyledAnchor>
          </Link>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{name}</StyledAnchor>
        </Link>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description: string, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{description}</StyledAnchor>
        </Link>
      )
    },
    {
      title: 'Sorting',
      dataIndex: 'sorting',
      key: 'sorting',
      render: (sorting: any, record: JobType) => (
        <Link to={`/manage/${kebabCase(dataKey)}/${record.uuid}`}>
          <StyledAnchor>{sorting}</StyledAnchor>
        </Link>
      )
    },
    {
      ...StatusFilter({
        status: ActiveStatus
      })
    }
  ]

  const [tableData, setTableData] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })

  const queryObj = {
    q: searchKeyword,
    statuses: [JobTypeStatus.Active, JobTypeStatus.Deleted],
    ...defaultQuery,
    ...pagination
  }

  const internalGqlResponse = useQuery(gqlQuery, {
    variables: isInputNested ? { input: queryObj } : queryObj,
    skip: !!gqlResponse,
    fetchPolicy: 'cache-and-network'
  })

  const { data, loading, error, refetch } = gqlResponse || internalGqlResponse

  const pageInfo = data?.[dataKey]?.pageInfo

  const onPaginationChange = useCallback((current: number, pageSize: number) => {
    setPagination({ limit: pageSize, offset: (current || 1) - 1 })
  }, [])

  useEffect(() => {
    setTableData(data?.[dataKey]?.rows)
  }, [data, dataKey])

  if (error) {
    logger.error(`CrudTypeTableView ${crudName} error`, error)
    respHandler(error, 'error')
    return <Result status="warning" title={`Failed to load ${crudName}`} />
  }

  if (!hasPermission && !permLoading) {
    return <NoAccess />
  }

  return (
    <Flex vertical>
      <Flex align="center">
        <PageHeader title={crudName} />
        {articleUrl && (
          <HelpIcon style={{ marginTop: 20, marginLeft: 10 }} articleUrl={articleUrl} />
        )}
      </Flex>

      <Flex vertical gap={'large'}>
        <TableActions
          refetch={refetch}
          createUrl={createUrl}
          exportSelectedEntity={dataKey}
          setSearchKeyword={setSearchKeyword}
        />
        <Flex vertical gap={'large'}>
          <Skeleton loading={permLoading}>
            <Table
              loading={loading}
              pagination={false}
              dataSource={tableData}
              rowKey={(record: JobType) => record.uuid}
              columns={tableColumns || defaultTableColumns}
            />
            <Flex justify="flex-end">
              <Pagination
                showSizeChanger
                total={pageInfo?.count || 0}
                onChange={onPaginationChange}
                pageSize={pageInfo?.limit || 20}
                current={(pageInfo?.offset || 0) + 1}
                onShowSizeChange={onPaginationChange}
                pageSizeOptions={['10', '20', '50', '100']}
                showTotal={(total: number, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
              />
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CrudTypesTableView
