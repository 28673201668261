import type { DynamicField } from '@/types/graphql'
import type { SelectProps } from 'antd/es/select'
import type { ModeOption } from 'antd/lib/select'
import { DynamicFieldQuery, DynamicFieldTag } from '@/types/graphql'

import { useState } from 'react'
import { Select } from 'antd'

import { getSelectQueryPropsForDynamicField } from '@/components/Booking/DynamicField/helper'
import SelectWithQuery from '@/components/Shared/SelectWithQuery'

interface DynamicFieldSelectorProps extends SelectProps {
  dynamicField: DynamicField | null
  autoSelectFirst?: boolean
}

const parseValue = ({ value, multiple }: { value: any; multiple: boolean }) => {
  let v = value
  if (!v && multiple) v = []

  if (typeof v === 'object') v = v?.uuid ? v.uuid : v

  return v
}

const parseOptions = (df: DynamicField | null) =>
  df?.query === DynamicFieldQuery.CustomValues ? df.customValues?.split('\n') || [] : undefined

const parseMode = (df: DynamicField | null): ModeOption =>
  df?.query === DynamicFieldQuery.CustomValues && !df.customValues
    ? 'tags'
    : df?.tags?.includes(DynamicFieldTag.Multiple)
      ? 'multiple'
      : 'default'

const DynamicFieldSelector = ({ dynamicField, ...props }: DynamicFieldSelectorProps) => {
  const [query] = useState(
    getSelectQueryPropsForDynamicField(dynamicField?.query, dynamicField?.companyTypes)
  )
  const [options] = useState<string[] | undefined>(parseOptions(dynamicField))

  const sharedProps = {
    ...props,
    mode: parseMode(dynamicField),
    value: parseValue({
      value: props.value,
      multiple: Boolean(dynamicField?.tags?.includes(DynamicFieldTag.Multiple))
    })
  }

  if (!dynamicField) return null

  return (
    <>
      {options?.length ? (
        <Select {...sharedProps}>
          {options.map(option => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      ) : query ? (
        <SelectWithQuery {...query} {...sharedProps} />
      ) : null}
    </>
  )
}

export default DynamicFieldSelector
