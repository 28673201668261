import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { Button, Card, Flex, Modal, notification, Popconfirm, Skeleton, Typography } from 'antd-v5'
import includes from 'lodash/includes'

import VoucherApprovals from '@/components/Voucher/Approvals'
import ConfirmationControl from '@/components/Voucher/Confirmation'
import ModalHeader from '@/components/Voucher/ModalHeader'
import { LoadingPanel } from '@/components/Voucher/Styled'
import VoucherView from '@/components/Voucher/View'

const ModalViewVoucher = props => {
  const {
    loading,
    toggleVoucherView,
    handleSubmit,
    approveVoucher,
    submitting,
    selectedVoucher,
    updateVoucherStatus,
    visible,
    bookingQuery,
    fullBookingQuery,
    loadRequiredData,
    handleCreateCreditNote
  } = props

  const { t } = useTranslation()

  const renderCancelControl = () => <Button onClick={toggleVoucherView}>{t('common.close')}</Button>

  const renderCreateControl = () => (
    <Button onClick={() => handleSubmit(false)} loading={submitting}>
      Create
    </Button>
  )

  const onSubmit = action => {
    const zeroPriceItems = Array.from(
      new Set(
        selectedVoucher.voucherItems
          .filter(c => c.baseRate === 0 && !c.isDeleted)
          .map(item => item.costItem.chargeItem.code)
      )
    )

    if (zeroPriceItems.length > 0) {
      return notification.error({
        message: 'Error',
        description: `There are items with zero price : ${zeroPriceItems.join(', ')}, please update the price before submitting the voucher`,
        duration: 2
      })
    } else {
      action()
    }
  }

  const renderCreateAndSubmitControl = () => (
    <Button
      id="submit-invoice-button"
      type="primary"
      loading={submitting}
      onClick={() => onSubmit(() => handleSubmit(true))}
    >
      {t('voucher.createAndSubmit')}
    </Button>
  )

  const renderSubmitControl = () => (
    <Button
      type="primary"
      loading={submitting}
      onClick={() => onSubmit(() => updateVoucherStatus('SUBMITTED'))}
    >
      {t('voucher.submitForApproval')}
    </Button>
  )

  const renderRevertToDraftControl = () => {
    return (
      <Popconfirm
        arrow={false}
        title="All approvals and linked items will be lost, confirm revert?"
        onConfirm={() => updateVoucherStatus('DRAFT')}
        okText="Revert"
        placement="topLeft"
      >
        <Button danger>{t('voucher.revertToDraft')}</Button>
      </Popconfirm>
    )
  }

  const renderDeleteControl = () => {
    return (
      <ConfirmationControl
        buttonType="danger"
        buttonText="Delete"
        okText="Delete"
        cancelText="Cancel"
        title="Permanently delete this voucher?"
        onConfirm={() => updateVoucherStatus('DELETED', { load: 'Deleting', success: 'Deleted' })}
      />
    )
  }

  const renderVoidControl = () => {
    return (
      <ConfirmationControl
        buttonType="danger"
        buttonText="Void"
        title="Permanently void this voucher?"
        okText="Void"
        cancelText="Cancel"
        onConfirm={() => updateVoucherStatus('VOIDED', { load: 'Voiding', success: 'Voided' })}
      />
    )
  }

  const renderControls = () => {
    const statuses = {
      NEW: ['CLOSE', 'CREATE', 'CREATE_SUBMIT'],
      DRAFT: ['CLOSE', 'DELETE', 'SUBMIT'],
      SUBMITTED: ['CLOSE', 'REVERT_TO_DRAFT'],
      APPROVED: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      PARTIALLY_PAID: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      PAID: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      DELETED: ['CLOSE'],
      VOIDED: ['CLOSE']
    }

    const currentControls = statuses[selectedVoucher.status]

    return currentControls?.map(ctrl => <Fragment key={ctrl}>{mapControl(ctrl)}</Fragment>)
  }

  const mapControl = ctrl => {
    switch (ctrl) {
      case 'CREATE':
        return renderCreateControl()
      case 'CREATE_SUBMIT':
        return renderCreateAndSubmitControl()
      case 'DELETE':
        return renderDeleteControl()
      case 'REVERT_TO_DRAFT':
        return renderRevertToDraftControl()
      case 'VOID':
        return renderVoidControl()
      case 'SUBMIT':
        return renderSubmitControl()
      default:
        return renderCancelControl()
    }
  }

  const renderFooter = () => {
    if (!selectedVoucher) return <Skeleton />

    const status = selectedVoucher.status
    const approvedByUser = selectedVoucher.approvedBy || {}
    const userName =
      approvedByUser.name ||
      approvedByUser.givenName ||
      approvedByUser.nickname ||
      approvedByUser.email

    return (
      <Flex gap={10} wrap="wrap" align="center" justify="flex-end">
        <Flex flex={1}>
          {!includes(['NEW', 'DRAFT', 'DELETED', 'VOIDED'], status) && (
            <VoucherApprovals submitting={submitting} onApproveVoucher={approveVoucher} />
          )}
        </Flex>

        {userName && (
          <Flex flex={1}>
            <Typography.Text>
              Approved By: <Typography.Text strong>{userName}</Typography.Text>
            </Typography.Text>
          </Flex>
        )}
        <Flex flex={1} justify="flex-end">
          {renderControls()}
        </Flex>
      </Flex>
    )
  }

  const renderView = () => {
    if (loading) {
      return (
        <LoadingPanel>
          <Card loading variant="borderless" />
        </LoadingPanel>
      )
    } else {
      return <VoucherView {...props} />
    }
  }

  return (
    <Modal
      width="97%"
      destroyOnClose
      title={null}
      open={visible}
      closable={false}
      style={{ top: 20 }}
      onCancel={toggleVoucherView}
      footer={loading ? null : renderFooter()}
    >
      <Form>
        <ModalHeader
          onCancel={toggleVoucherView}
          bookingQuery={bookingQuery}
          fullBookingQuery={fullBookingQuery}
          loadRequiredData={loadRequiredData}
          handleCreateCreditNote={handleCreateCreditNote}
        />
        {renderView()}
      </Form>
    </Modal>
  )
}

export default ModalViewVoucher
