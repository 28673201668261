import { RoleStatus } from 'App/types/graphql'

import { cloneDeep } from 'lodash'

import { logger } from 'App/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'
import {
  createPermissionGql,
  deletePermissionGql,
  getAllRolesGql,
  getPermissionsPerRoleGql,
  getPermissionTypesGql
} from './schema'

type PermissionObjType = {
  role?: string | null | undefined
  resource?: string | null | undefined
  permission?: string | null | undefined
  oneOrZero: number
}

const sampleData = [
  {
    roleCode: 'OPERATIONS',
    roleName: 'OPERATIONS',
    roleStatus: 'ACTIVE',
    roleUuid: 'ab274867-99d8-45c9-a268-0c0bde03b7cc',
    resource: 'booking',
    permission: 'create',
    oneOrZero: 1,
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: The required inputs are: roleCode (e.g. OPERATIONS), resource (e.g. booking), permission (e.g. create), and oneOrZero (e.g. 1).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'roleCode*',
    dataIndex: 'roleCode',
    key: 'roleCode'
  },
  {
    title: 'roleName',
    dataIndex: 'roleName',
    key: 'roleName'
  },
  {
    title: 'roleStatus',
    dataIndex: 'roleStatus',
    key: 'roleStatus'
  },
  {
    title: 'roleUuid',
    dataIndex: 'roleUuid',
    key: 'roleUuid'
  },
  {
    title: 'resource*',
    dataIndex: 'resource',
    key: 'resource'
  },
  {
    title: 'permission*',
    dataIndex: 'permission',
    key: 'permission'
  },
  {
    title: 'oneOrZero*',
    dataIndex: 'oneOrZero',
    key: 'oneOrZero'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  roles: {},
  permissionTypes: {}
}

const findNullInputs = async (selectedGlobalCompany: any, obj: any) => {
  if (!obj.roleCode) {
    return { nullError: 'Code is required, e.g. OPERATIONS.' }
  }
  if (!obj.resource) {
    return { nullError: 'Resource is required, e.g. booking.' }
  }
  if (!obj.permission) {
    return { nullError: 'Permission is required, e.g. create.' }
  }
  if (Number(obj.oneOrZero) !== 0 && Number(obj.oneOrZero) !== 1) {
    return { nullError: 'oneOrZero is required, e.g. 1 or 0.' }
  }

  if (obj.roleCode) {
    if (Object.keys(cache.roles)?.length < 1) {
      const rolesQuery = {
        limit: 10000,
        offset: 0,
        statuses: RoleStatus.Active
      }
      const rolesResults = await getGqlResponse(selectedGlobalCompany, getAllRolesGql, {
        input: rolesQuery
      })
      if (rolesResults?.data?.roles?.rows?.length < 1) {
        return { nullError: 'Error in Querying roles.' }
      }

      for (let i = 0; i < rolesResults?.data?.roles?.rows?.length; i++) {
        cache.roles[rolesResults?.data?.roles?.rows[i]?.code] =
          rolesResults?.data?.roles?.rows[i]?.uuid
      }
    }

    if (!cache.roles[obj.roleCode]) {
      return {
        nullError: `The provided roleCode="${obj.roleCode?.toString()}" is not found. Please create this role.`
      }
    }
  }

  if (obj.resource && obj.permission) {
    if (Object.keys(cache.permissionTypes)?.length < 1) {
      const permissionTypes = await getGqlResponse(selectedGlobalCompany, getPermissionTypesGql, {})
      if (permissionTypes?.data?.permissionTypes?.length < 1) {
        return { nullError: 'Error in Querying Permissions.' }
      }

      for (let i = 0; i < permissionTypes?.data?.permissionTypes?.length; i++) {
        cache.permissionTypes[permissionTypes?.data?.permissionTypes[i]?.resources] =
          permissionTypes?.data?.permissionTypes[i]?.permissions
      }
    }

    if (!cache.permissionTypes[obj.resource]) {
      return {
        nullError: `The provided resource=${obj.resource} is not found. Please check that it exists in the Permissions page.`
      }
    }

    if (!cache.permissionTypes[obj.resource]?.includes(obj.permission)) {
      return {
        nullError: `The provided permission=${obj.permission} is not found for resource=${obj.resource}. Please check that it exists in the Permissions page.`
      }
    }
  }
}

const populatePermissionObj = (obj: any) => {
  const permissionObj: PermissionObjType = {
    role: obj?.roleCode?.toString()?.trim(),
    resource: obj?.resource,
    permission: obj?.permission,
    oneOrZero: Number(obj.oneOrZero)
  }
  return permissionObj
}

const rolesPermissionsHelper = {
  queryName: 'rolesPermissions',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const rolesQuery = {
        limit: 10000,
        offset: 0,
        statuses: RoleStatus.Active
      }
      const rolesResults = await getGqlResponse(selectedGlobalCompany, getAllRolesGql, {
        input: rolesQuery
      })
      const roles = rolesResults?.data?.roles?.rows
      const exportData: any = []

      if (roles?.length) {
        for (let i = 0; i < roles?.length; i++) {
          const permissionTypes = await getGqlResponse(
            selectedGlobalCompany,
            getPermissionTypesGql,
            {}
          )
          const allPermissionTypes =
            permissionTypes?.data?.permissionTypes &&
            Array.isArray(permissionTypes?.data?.permissionTypes) &&
            cloneDeep(permissionTypes?.data?.permissionTypes)?.sort((a: any, b: any) => {
              return a?.resources && a?.resources?.localeCompare(b?.resources && b?.resources)
            })

          allPermissionTypes?.forEach((permissionType: any) => {
            permissionType.permissions?.forEach((typeAction: string) => {
              exportData.push({
                roleCode: roles[i]?.code?.toString(),
                roleName: roles[i]?.name,
                roleStatus: roles[i]?.status,
                roleUuid: roles[i]?.uuid,
                resource: permissionType?.resources,
                permission: typeAction,
                oneOrZero: 0,
                zzz: ''
              })
            })
          })

          const permissionsRes = await getGqlResponse(
            selectedGlobalCompany,
            getPermissionsPerRoleGql,
            { role: roles[i]?.code?.toString() }
          )
          const permissionsForRole =
            permissionsRes?.data?.permission &&
            Array.isArray(permissionsRes?.data?.permission) &&
            cloneDeep(permissionsRes?.data?.permission)
          if (!(exportData && Array.isArray(exportData)) || !permissionsForRole) {
            return
          }

          const permissionForRoleObj: any = {}
          permissionsForRole?.forEach((perm: any) => {
            if (!permissionForRoleObj[perm?.resources]) {
              permissionForRoleObj[perm?.resources] = perm?.permissions
            }
          })

          if (exportData && Array.isArray(exportData)) {
            exportData.forEach((data: any) => {
              if (
                data?.roleCode === roles[i]?.code &&
                permissionForRoleObj?.[data?.resource]?.includes(data?.permission)
              ) {
                data.oneOrZero = 1
              }
            })
          }
        }
      }

      return exportData
    } catch (error) {
      logger.error('rolesPermissionsHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (permissionData: any, selectedGlobalCompany: any) => {
    const { importStatus, key, ...permission } = permissionData

    const nullInputError = await findNullInputs(selectedGlobalCompany, permission)
    if (nullInputError) return nullInputError
    const permissionObj = populatePermissionObj(permission)

    try {
      // For some reason typeof permission.oneOrZero is still 'string'
      const mutationResults = await getGqlResponse(
        selectedGlobalCompany,
        Number(permission.oneOrZero) === 1 ? createPermissionGql : deletePermissionGql,
        permissionObj
      )
      return mutationResults
    } catch (error) {
      logger.error('rolesPermissionsHelper handleImportData createPermissionGql error', error)
      return error
    }
  },

  mapFromCsv,

  sampleData,

  remarks,

  tableColumns
}

export default rolesPermissionsHelper
