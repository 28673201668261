import type { PermissionProps } from './Permission'

import { LoadingOutlined } from '@ant-design/icons'
import { Mutation } from '@apollo/client/react/components'
import { Checkbox, notification } from 'antd-v5'

import {
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_QUERY
} from '@/components/Manage/Permissions/TableView/schema'
import handleResponse from '@/utils/responseHandler'

type PermissionCheckBoxProps = PermissionProps & {
  displayName?: string
  isAllowed?: boolean
}

const PermissionCheckBox = (props: Partial<PermissionCheckBoxProps>) => {
  const { displayName, isAllowed, resourceName, resourceType, selectedRole } = props
  const [api, contextHolder] = notification.useNotification({ stack: { threshold: 3 } })

  const handleCheck = (checked, onCheck) => {
    onCheck({
      variables: {
        role: selectedRole,
        resource: resourceName,
        permission: resourceType
      }
    })
  }

  const handleCompleted = isAllowed => {
    api.success({
      message: `Permission ${isAllowed ? 'Revoked' : 'Created'}`,
      description: `Permission ${resourceType} in ${resourceName} has been ${
        isAllowed ? 'revoked' : 'created'
      }.`
    })
  }

  const handleError = error => handleResponse(error, 'error')

  return (
    <>
      {contextHolder}
      <Mutation
        onError={handleError}
        onCompleted={() => handleCompleted(isAllowed)}
        mutation={isAllowed ? DELETE_PERMISSION : CREATE_PERMISSION}
        refetchQueries={[{ query: PERMISSION_QUERY, variables: { role: selectedRole } }]}
      >
        {(onCheck, { loading }) => {
          if (loading) return <LoadingOutlined />

          return (
            <Checkbox checked={isAllowed} onChange={e => handleCheck(e.target.checked, onCheck)}>
              {displayName}
            </Checkbox>
          )
        }}
      </Mutation>
    </>
  )
}

export default PermissionCheckBox
