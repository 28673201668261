import { gql } from '@/types'

export const COMPANY_QUERY = gql(`
  query companyForLegSelector($uuid: UUID!) {
    company(uuid: $uuid) {
      accountUuid
      tags
      uuid
    }
  }
`)

export const COMPANY_CREATE = gql(`
  mutation createCompany3($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      uuid
      name
      types
      countryAlpha3
    }
  }
`)

export const COMPANY_DELETE = gql(`
  mutation deleteCompany2($uuid: UUID!) {
    deleteCompany(uuid: $uuid) {
      success
    }
  }
`)

export const COMPANY_UPDATE = gql(`
  mutation updateCompany1($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      uuid
      name
      types
      countryAlpha3
    }
  }
`)

export const COMPANIES_QUERY_SLIM = gql(`
  query companiesSlim1(
    $types: [CompanyType!]
    $query: String
    $portalCompanyQueryType: PortalCompanyQueryType
    $limit: Int
    $offset: Int
    $countryAlpha3: LimitedString
  ) {
    companies(
      types: $types
      query: $query
      portalCompanyQueryType: $portalCompanyQueryType
      limit: $limit
      offset: $offset
      countryAlpha3: $countryAlpha3
    ) {
      rows {
        uuid
        name
        countryAlpha3
        types
        status
        code
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const BILL_TO_COMPANY_QUERY = gql(`
  query company4($uuid: UUID!) {
    company(uuid: $uuid) {
      billTo {
        uuid
        name
      }
    }
  }
`)

export const COMPANIES_QUERY_NAME_FROM_UUIDS = gql(`
  query companiesName($uuids: [UUID], $limit: Int, $offset: Int) {
    companies(uuids: $uuids, limit: $limit, offset: $offset) {
      rows {
        uuid
        code
        name
        addresses {
          uuid
          name
          address1
          address2
          address3
          address4
          city
          district
          postCode
          areaCode
          zone
        }
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
