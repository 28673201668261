import { LoadingOutlined } from '@ant-design/icons'
import { Typography } from 'antd-v5'

import { useDynamicComponent } from '@/hooks/useDynamicComponent'

type DynamicComponentLoaderProps = {
  componentName: string
  loading?: boolean
  [key: string]: any
}

const DynamicComponentLoader = ({
  componentName,
  loading,
  ...props
}: DynamicComponentLoaderProps) => {
  const { Component, error } = useDynamicComponent({ componentName })

  if (!componentName) return null

  if (loading) return <LoadingOutlined />

  if (!Component) return null

  if (error) return <Typography.Text type="danger">{error}</Typography.Text>

  return <Component {...props} />
}

export default DynamicComponentLoader
