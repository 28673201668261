import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'

import AddCostItemForm from '@/components/Voucher/CostItems/AddCostItemForm'
import { CostItemModal } from '@/components/Voucher/CostItems/Styled'

const AddCostItemAction = props => {
  const { bookingUuid, onCostItemAdded = () => {} } = props
  const [visible, setVisible] = useState(false)

  const handleClick = () => setVisible(true)
  const handleOnVisibleChange = (visible: boolean) => setVisible(visible)

  const handleCostItemAdded = (costItem, bookingUuid) => {
    onCostItemAdded(costItem, bookingUuid)
    setVisible(false)
  }

  return (
    <>
      <Button
        size="small"
        id="invoice-add-new-cost-item-button"
        icon={<PlusOutlined />}
        shape="circle"
        onClick={handleClick}
      />
      <CostItemModal
        width={600}
        footer={null}
        destroyOnClose
        open={visible}
        title="Add new cost item"
        onCancel={() => handleOnVisibleChange(false)}
      >
        <AddCostItemForm bookingUuid={bookingUuid} onCostItemAdded={handleCostItemAdded} />
      </CostItemModal>
    </>
  )
}

export default AddCostItemAction
