import { gql } from '@/types'

export const VOUCHER_FOR_JV = gql(`
  query voucherForJv($uuid: UUID!) {
    voucher(uuid: $uuid) {
      uuid
      voucherNumber
      voucherItems {
        voucherItemCn {
          uuid
        }
      }
      bookings {
        uuid
      }
    }
  }
`)

export const VOUCHERS_SELECT_QUERY = gql(`
  query vouchersSelectEs($input: VouchersSearchInput) {
    vouchersSearchJson(input: $input) {
      rows
    }
  }
`)

export const VOUCHER_ITEMS_SELECT = gql(`
  query voucherItemsSelectByVoucher($uuid: UUID) {
    voucher(uuid: $uuid) {
      voucherItems {
        uuid
        total
        booking {
          no
        }
        costItem {
          chargeItem {
            name
          }
        }
      }
    }
  }
`)

export const VOUCHER_NUMBER_CN = gql(`
  query voucherNumberCn($input: voucherItemQueryInput) {
    voucherItems(input: $input) {
      uuid
      voucherItemCn {
        voucher {
          voucherNumber
        }
        costItem {
          chargeItem {
            name
          }
        }
      }
    }
  }
`)

export const EXCHANGE_RATES_QUERY = gql(`
  query exchangeRatesSelector($limit: Int, $offset: Int, $fromUuid: UUID, $toUuid: UUID, $date: DateTime) {
    exchangeRates(
      limit: $limit
      offset: $offset
      fromUuid: $fromUuid
      toUuid: $toUuid
      date: $date
    ) {
      rows {
        uuid
        from {
          uuid
          code
          name
        }
        to {
          uuid
          code
          name
        }
        company {
          uuid
          name
        }
        start
        end
        rate
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const FAFS_QUERY = gql(`
  query fafsSelector(
    $date: DateTime
    $limit: Int
    $offset: Int
    $vendorUuids: [UUID]
    $customerUuids: [UUID]
  ) {
    fafs(
      date: $date
      limit: $limit
      offset: $offset
      vendorUuids: $vendorUuids
      customerUuids: $customerUuids
    ) {
      rows {
        uuid
        start
        end
        rate
        vendorUuid
        customerUuid
        customer {
          name
        }
        vendor {
          name
        }
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const INCENTIVE_VOUCHERS_SMALL_QUERY = gql(`
  query incentiveVoucherSelector($input: IncentiveVoucherQueryInput!) {
    incentiveVouchers(input: $input) {
      rows {
        _id
        voucherNo
      }
    }
  }
`)

export const INTEGRATION_MAPPINGS_QUERY = gql(`
  query integrationMappingsSelector($customerUuid: UUID, $limit: Int, $offset: Int) {
    integrationMappings(customerUuid: $customerUuid, limit: $limit, offset: $offset) {
      rows {
        uuid
        type
        externalCode
        internalCode
        integrationDetailUuid
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

export const PORTAL_COMPANIES_QUERY = gql(`
  query portalCompaniesSelector($query: String, $limit: Int, $offset: Int) {
    portalCompanies(query: $query, limit: $limit, offset: $offset) {
      rows {
        uuid
        name
        status
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)
