import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd-v5'
import findIndex from 'lodash/findIndex'

import { NoItemsFound, SectionTitle, TableWrapper } from '@/components/Voucher/Styled'
import SummaryView from '@/components/Voucher/VoucherItems/SummaryView'
import { Brief } from '@/components/Workflow/Styled'
import { updateVoucherItemsDeletedView } from '@/states/reducers/voucher'

const TableView = ({ columns, dataSource, selectedVoucher }) => {
  const dispatch = useDispatch()
  const showDeletedVoucherItems = useSelector(state => state.voucher.showDeletedVoucherItems)

  const toggleVoucherItemsDeletedView = () => {
    dispatch(updateVoucherItemsDeletedView(!showDeletedVoucherItems))
  }

  const voucherItems = selectedVoucher?.voucherItems || []
  const hasDeletedVoucherItem = findIndex(voucherItems, (vi: any) => vi.isDeleted) >= 0

  if (!voucherItems.length) {
    return <NoItemsFound>No voucher items selected.</NoItemsFound>
  }

  return (
    <>
      <SectionTitle borderless>
        Selected Items
        {hasDeletedVoucherItem && (
          <Brief onClick={toggleVoucherItemsDeletedView}>
            {showDeletedVoucherItems ? 'Hide deleted' : 'Show deleted'}
          </Brief>
        )}
      </SectionTitle>

      <TableWrapper>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          locale={{ emptyText: 'No items selected.' }}
        />

        <SummaryView selectedVoucher={selectedVoucher} />
      </TableWrapper>
    </>
  )
}

export default TableView
