import { createContext } from 'react'

const initial = {}
const BookingContext = createContext(initial)

export function withBooking(Component) {
  return function BookingComponent(props) {
    return (
      <BookingContext.Consumer>
        {data => <Component {...props} data={data} booking={data.booking} />}
      </BookingContext.Consumer>
    )
  }
}

export default BookingContext
