import { useEffect, useState } from 'react'
import { Result, Spin } from 'antd-v5'

import responseHandler from '@/utils/responseHandler'

interface ResourceViewerProps {
  url?: string | null
  fetchOptions?: RequestInit
  mimeType?: string | null
}

const ResourceViewer = ({ url, fetchOptions, mimeType }: ResourceViewerProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [objectUrl, setObjectUrl] = useState<string>('')

  const fetchResource = async () => {
    setIsLoading(true)
    try {
      if (!url) return
      const res = await fetch(url, fetchOptions)

      if (!res.ok) {
        responseHandler({ message: `Error status: ${res.status}` }, 'error')
      }

      const blob = await res.blob()
      const object = window.URL.createObjectURL(blob)

      setObjectUrl(object)
    } catch (error) {
      responseHandler(error, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchResource()

    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl)
    }
  }, [fetchOptions, url])

  if (isLoading) {
    return (
      <div style={{ height: '100%', width: '100%', display: 'grid', alignItems: 'center' }}>
        <Spin />
      </div>
    )
  }

  const isImageType = mimeType?.startsWith('image/')

  if (!objectUrl)
    return <Result style={{ marginTop: 150 }} status="error" title="Failed to load resource" />

  if (isImageType) {
    return (
      <img
        alt="resource_image"
        src={objectUrl}
        style={{ maxWidth: '100%', height: 'auto' }}
        onError={e => responseHandler(e, 'error')}
      />
    )
  }

  if (!mimeType) return <embed src={objectUrl} width="100%" height="100%" />
  return <embed src={objectUrl} type={mimeType} width="100%" height="100%" />
}

export default ResourceViewer
