import type { Booking, Job } from '@/types/graphql'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd-v5'
import { cloneDeep } from 'lodash'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { DELETE_JOB } from '@/graphql/booking'
import notify from '@/utils/notify'

type JobDeleteActionProps = {
  job: Job
}

const JobDeleteAction = ({ job }: JobDeleteActionProps) => {
  const { t } = useTranslation()

  const [deleteJobMutation] = useMutation(DELETE_JOB, {
    update: cache => {
      const data = cache.readQuery<{ booking: { booking: Booking } }>({
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: { uuid: job.bookingUuid }
      })

      if (!data || !data.booking) return
      const { booking } = data.booking
      const clonedBooking = cloneDeep(booking)

      const jobIndex = clonedBooking.jobs?.findIndex(j => j?.uuid === job.uuid) || 0
      clonedBooking.jobs?.splice(jobIndex, 1)

      cache.writeQuery({
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: { uuid: job.bookingUuid },
        data: { booking: clonedBooking }
      })
    },
    refetchQueries: [
      {
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: {
          uuid: job.bookingUuid
        }
      }
    ]
  })

  const handleConfirm = async () => {
    notify('Deleting job, hang on...', 'load')

    try {
      await deleteJobMutation({ variables: { uuid: job.uuid } })
      notify('Job deleted successfully.', 'success')
    } catch (error) {
      if (error instanceof Error) notify(error.message, 'error')
    }
  }

  return (
    <Popconfirm
      arrow={false}
      title="Confirm to delete this job?"
      onConfirm={handleConfirm}
      okText={t('common.yes')}
      okType="danger"
      cancelText={t('common.no')}
    >
      <Button danger type="primary">
        {t('common.delete')}
      </Button>
    </Popconfirm>
  )
}

export default JobDeleteAction
