export const getDataGql = `
query bookingTypes_ImportExportData(
  $limit: Int
  $offset: Int
  $q: String
  $codes: [String]
  $statuses: [BookingTypeStatus]
) {
  bookingTypes(limit: $limit, offset: $offset, q: $q, codes: $codes, statuses: $statuses) {
    rows {
      uuid
      code
      name
      description
      flowType
      sorting
      status
      billing {
        desc
        chargeItemUuid
        billManner
        sellRate
        sellRateId
        costRate
        costRateId
      }
      dynamicFields{
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
    }
  }
}
`

export const createEntityGql = `
  mutation createBookingType_ImportExportData ($input: UpdateBookingTypeInput) {
    createBookingType (input: $input) {
      uuid
      code
      name
      description
      flowType
      sorting
      status
      billing {
        desc
        chargeItemUuid
        billManner
        sellRate
        sellRateId
        costRate
        costRateId
      }
      dynamicFields{
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
    }
  }
`

export const updateEntityGql = `
    mutation updateBookingType_ImportExportData ($input: UpdateBookingTypeInput) {
      updateBookingType (input: $input) {
        uuid
        code
        name
        description
        flowType
        sorting
        status
        billing {
          desc
          chargeItemUuid
          billManner
          sellRate
          sellRateId
          costRate
          costRateId
        }
        dynamicFields{
          key
          type
          query
          companyTypes
          control
          enumName
          customValues
          tags
        }
      }
    }
`
