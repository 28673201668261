import type { ItemType, MenuItemType } from 'antd-v5/es/menu/interface'

import { LogoutOutlined } from '@ant-design/icons'
import { Menu } from 'antd-v5'
import { cloneDeep, pick } from 'lodash'

import Filter from '@/components/Layouts/LeftFilter/Filter'
import { MenuTitle, Sidebar } from '@/components/Layouts/LeftFilter/Styled'
import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import labelMap from '@/utils/labelMap'

const Title = ({ text }: { text: string }) => (
  <MenuTitle>
    <LogoutOutlined />
    <span>{text}</span>
  </MenuTitle>
)

const LeftFilter = ({ customOverflow, facets }) => {
  const { data = [] } = useBaseCompanyType()

  if (!facets) return null

  const menuItems: ItemType<MenuItemType>[] = [
    {
      key: 'general',
      label: <Title text={'General'} />,
      children: labelMap.miscCategories
        .map(category => {
          const [key, subKey, sortFnc] = category
          const validKeys = [key, subKey].filter(k => typeof k === 'string')

          let facet = pick(facets, validKeys)[key as any]

          if (!facet) return null

          if (sortFnc && typeof sortFnc === 'function') {
            facet = cloneDeep(facet).sort(sortFnc)
          }

          return {
            key,
            className: 'p-0',
            label: <Filter type="general" titleAttribute={key} facet={facet} />
          }
        })
        .filter(Boolean)
    },
    ...data.map(type => ({
      key: type?.key,
      label: <Title text={type.label} />,
      children: type.meta?.facets?.map((facetKey: string) => {
        const facet = pick(facets, facetKey)[facetKey]
        return {
          key: facetKey,
          className: 'p-0',
          label: <Filter key={facetKey} type={type.label} titleAttribute={facetKey} facet={facet} />
        }
      })
    }))
  ]

  return (
    <Sidebar customOverflow={customOverflow}>
      <Menu mode="inline" multiple={false} items={menuItems} defaultOpenKeys={['general']} />
    </Sidebar>
  )
}

export default LeftFilter
