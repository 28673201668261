import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'antd-v5'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'

import DocumentSelector from '../../DocumentSelector'
import { NoItemsFound, SectionTitle, TableWrapper } from '../Styled'
import Selectables from './Selectables'

type VoucherAttachmentsProps = {
  onRemoveDocument: (val) => void
  onSelectSingleDocument: (val) => void
}

const VoucherAttachments = (props: VoucherAttachmentsProps) => {
  const { onRemoveDocument, onSelectSingleDocument } = props

  const { selectedVoucher, voucherBooking = { bookingDocuments: [] } } = useSelector(state => ({
    selectedVoucher: state.voucher.selectedVoucher,
    voucherBooking: state.voucher.voucherBooking
  }))

  const renderDocuments = selectableDocuments => {
    const removable = includes(['DRAFT', 'NEW'], selectedVoucher.status)

    return (
      <Row>
        {selectableDocuments.map(doc => (
          <Col key={doc.uuid} span={12}>
            <DocumentSelector
              key={doc.uuid}
              uploadedDoc={doc}
              removable={removable}
              hrefUrl={`/booking/document/${doc.uuid}`}
              onRemove={() => onRemoveDocument(doc)}
            />
          </Col>
        ))}
      </Row>
    )
  }

  const renderSelectableControl = (selectable, selectableDocuments, selectedDocuments) => {
    if (!voucherBooking) return null

    return (
      <Selectables
        selectable={selectable}
        selectedDocuments={selectedDocuments}
        selectableDocuments={selectableDocuments}
        onSelectSingleDocument={onSelectSingleDocument}
      />
    )
  }

  const selectableDocuments = useMemo(
    () =>
      filter(
        voucherBooking.bookingDocuments,
        bDoc => bDoc.status !== 'UPLOADED' && bDoc.status !== 'DELETED'
      ),
    [voucherBooking.bookingDocuments]
  )

  const selectedDocuments = useMemo(
    () =>
      filter(
        selectableDocuments,
        sDoc =>
          findIndex(
            selectedVoucher.bookingDocuments,
            (vDoc: { uuid: string }) => vDoc.uuid === sDoc.uuid
          ) !== -1
      ),
    [selectableDocuments, selectedVoucher.bookingDocuments]
  )

  const creatable = includes(['DRAFT', 'NEW'], selectedVoucher.status)

  return (
    <>
      <SectionTitle borderless>
        Attachments
        {creatable && renderSelectableControl(creatable, selectableDocuments, selectedDocuments)}
      </SectionTitle>
      <TableWrapper>
        {!selectedDocuments.length ? (
          <NoItemsFound align="left">No documents selected.</NoItemsFound>
        ) : (
          renderDocuments(selectedDocuments)
        )}
      </TableWrapper>
    </>
  )
}

export default VoucherAttachments
