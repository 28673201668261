import { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'

import ChangeTypeModal from './ChangeTypeModal'

const ChangeTypeAction = props => {
  const { voucher, onChanged = () => {} } = props
  const [visible, setVisible] = useState(false)

  return (
    <>
      <div onClick={() => setVisible(true)}>
        <EditOutlined style={{ marginRight: 8 }} />
        Change voucher type
      </div>

      <ChangeTypeModal
        voucher={voucher}
        visible={visible}
        onClose={value => {
          setVisible(false)
          onChanged(value)
        }}
      />
    </>
  )
}

export default ChangeTypeAction
