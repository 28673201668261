export default {
  Container: {
    CharLookup: {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38
    }
  },
  Incentive: {
    Category: {
      INCENTIVE: 'INCENTIVE',
      EXPENSES: 'EXPENSES'
    }
  },
  Job: {
    Type: {
      LADEN: 'LADEN',
      EMPTY: 'EMPTY'
    }
  },
  UserPreference: {
    Type: {
      JOBS_STATUS_TABLE: 'JOBS_STATUS_TABLE',
      NEWBOOKING: 'NEWBOOKING',
      PLANNING_JOBS_TABLE: 'PLANNING_JOBS_TABLE',
      SETTINGS: 'SETTINGS'
    }
  },
  Vehicle: {
    Display: {
      both: 'both',
      code: 'code',
      key: 'VEHICLE_CODE_REGISTRATION',
      registration: 'registration'
    }
  }
}

export enum BookingStateStat {
  Submitted = 100,
  Booked = 120,
  LcPlanned = 200,
  DelPlanned = 300,
  DelStarted = 500,
  DelStaged = 800,
  Delivered = 1000,
  Rfc = 1200,
  ColPlanned = 1300,
  ColStarted = 1500,
  ColStaged = 1800,
  Completed = 2000
}