import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import startCase from 'lodash/startCase'

import Header from '@/components/Layouts/header'
import FeatureGuardUrl from '@/components/Shared/FeatureGuard/Url'
import ErrorFallback from '@/ErrorFallback'
import base from '@/hocs/base'
import { isUuid } from '@/utils/u'
import styles from './WithHeader.module.css'

function WithHeaderLayout() {
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split('/').filter(e => !isUuid(e))
    const length = pathname.length

    let title = ''

    if (length <= 2) title = pathname?.[1]?.replace('-', ' ')
    else if (pathname.length === 3 && pathname?.[1] === 'bookings')
      title = pathname?.[1]?.replace('-', ' ')
    else if (length === 3) title = pathname?.[2]?.replace('-', ' ')
    else title = pathname?.[3]?.replace('-', ' ')

    document.title = `ShipX ${!title ? '' : '|'} ${startCase(title)}`
  }, [location])

  return (
    <>
      <Header />

      <ErrorBoundary fallback={<ErrorFallback />}>
        <div className={styles.content}>
          <FeatureGuardUrl>
            <Outlet />
          </FeatureGuardUrl>
        </div>
      </ErrorBoundary>
    </>
  )
}

export default base(WithHeaderLayout)
