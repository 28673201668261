import type { Booking, Job } from '@/types/graphql'

import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd-v5'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { UNDELETE_JOB } from '@/graphql/booking'
import respHandler from '@/utils/responseHandler'

type JobUndeleteActionProps = {
  job: Job
}

const JobUndeleteAction = ({ job }: JobUndeleteActionProps) => {
  const { t } = useTranslation()
  const [undeleteJobMutation] = useMutation(UNDELETE_JOB, {
    update: cache => {
      const data = cache.readQuery<{ booking: { booking: Booking } }>({
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: { uuid: job.bookingUuid }
      })

      if (!data || !data.booking) return
      const { booking } = data.booking

      cache.writeQuery({
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: { uuid: job.bookingUuid },
        data: { booking }
      })
    },
    refetchQueries: [
      {
        query: JOBS_FROM_BOOKING_SLIM_QUERY,
        variables: {
          uuid: job.bookingUuid
        }
      }
    ]
  })

  const handleConfirm = async () => {
    respHandler('undeleting job, hang on...', 'load')
    try {
      await undeleteJobMutation({ variables: { uuid: job.uuid } })
      respHandler('Job undeleted successfully.', 'success')
    } catch (error) {
      respHandler(error, 'error')
    }
  }

  return (
    <Popconfirm
      arrow={false}
      title="Confirm to undelete this job?"
      onConfirm={handleConfirm}
      okText={t('common.yes')}
      okType="danger"
      cancelText={t('common.no')}
    >
      <Button danger type="primary">
        {t('common.undelete')}
      </Button>
    </Popconfirm>
  )
}

export default JobUndeleteAction
