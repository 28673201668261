import { memo } from 'react'
import { LockOutlined } from '@ant-design/icons'
import { mapValues } from 'lodash'

import ReactTable from 'App/components/Transport/Components/Shared/ReactTable'
import { confidentialMetrics } from 'App/components/Transport/Dashboard/helpers/general'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { getTableRows } from '../helpers/getTableRows'
import { getMtdColsData } from '../helpers/mtd'

const styleTableCells = (options: any) => {
  const { cell } = options
  return { ...cell.column.style }
}

const styleTableHeaders = () => {
  return { textAlign: 'right', paddingLeft: '10px', minWidth: '100px' }
}

export const FilterConfidential = (rows: any[], hasConfidentialPermission: boolean = false) => {
  return rows.map(row => {
    const k = row.key
    const nonValueKeys = ['key', 'rowName']

    if (!hasConfidentialPermission && confidentialMetrics.includes(k)) {
      return mapValues(row, (v, k) =>
        nonValueKeys.includes(k) ? (
          v
        ) : (
          <LockOutlined style={{ fontSize: '16px', color: '#9ca3af' }} />
        )
      )
    }

    return row
  })
}

const MTDTable = ({ data, lastYrData, query, settings, hasConfidentialPermission }: any) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { tableColumns, tableData } = getMtdColsData({
    reportJobSummaries: data?.reportJobSummaries?.rows,
    lastYrData: lastYrData?.reportJobSummaries?.rows,
    queryInput: query,
    selectedGlobalCompany
  })

  let resultData: any = tableData
  resultData = getTableRows(settings, tableData)
  resultData = FilterConfidential(resultData, hasConfidentialPermission)

  return (
    <ReactTable
      tableColumns={tableColumns}
      tableData={resultData}
      styleTableCells={styleTableCells}
      styleTableHeaders={styleTableHeaders}
    />
  )
}

export default memo(MTDTable)
