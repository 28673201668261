import { Button } from 'antd'
import { Modal } from 'antd-v5'

import RequestItemResultModalContent from './RequestItemResultModalContent'

const RequestItemResultModal = props => {
  const { visible, onClose, request } = props
  return (
    <Modal
      title="Request Status"
      destroyOnClose
      open={visible}
      cancelText={null}
      okText="Close"
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <RequestItemResultModalContent request={request} />
    </Modal>
  )
}

export default RequestItemResultModal
