import { useState } from 'react'
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Flex, Input } from 'antd-v5'

import ImportExport from '@/components/Shared/ImportExport'

const TableViewActions = props => {
  const { updateSearchKeyword, updatePageSize } = props
  const [keyword, setKeyword] = useState('')

  const handleSearch = value => {
    updateSearchKeyword(value)
    updatePageSize(0)
  }

  const handleInput = value => {
    setKeyword(value)
    handleSearch(value)
  }

  const suffix = keyword ? <CloseCircleOutlined onClick={() => handleInput('')} /> : null

  return (
    <Flex justify="space-between" align="center" gap="middle">
      <Input
        value={keyword}
        prefix={<SearchOutlined />}
        suffix={suffix}
        placeholder="Search a company here..."
        onChange={e => handleInput(e.target.value)}
      />
      <ImportExport selectedEntity="companies" />
      <Button href="/manage/companies/create">
        <PlusOutlined />
        Create
      </Button>
    </Flex>
  )
}

export default TableViewActions
