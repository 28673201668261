import type { DynamicField, Maybe } from '@/types/graphql'
import type { Moment } from 'moment'
import { DynamicFieldQuery, DynamicFieldTag, DynamicFieldType } from '@/types/graphql'

import { DatePicker, Input, Typography } from 'antd-v5'
import moment from 'moment'

import DynamicInputText from '@/components/Booking/DynamicField/DynamicInputText'
import FieldSelector from '@/components/Booking/DynamicField/FieldSelector'
import { hasDynamicFieldTag } from '@/components/Booking/DynamicField/helper'
import UploadDocument from '@/components/Booking/DynamicField/UploadDocument'
import EnumSelector from '@/components/Shared/EnumSelector'
import TagsInput from '@/components/Shared/TagsInput/TagsInput'

interface DynamicFieldProps {
  field: Maybe<DynamicField>
  id?: string
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

const DynamicFieldComponent = (props: DynamicFieldProps) => {
  const { value, field, onChange, id, disabled = false } = props

  const renderField = (dynamicField: any) => {
    const { key, query, type, customValues } = dynamicField

    const renameId = id?.split('.').join('-') || id

    let sharedProps: any = {
      value,
      id: renameId,
      disabled
    }

    if (!field) {
      return <Typography.Text>Dynamic Field is undefined</Typography.Text>
    }

    switch (type) {
      case DynamicFieldType.Selector:
        sharedProps = {
          ...sharedProps,
          onChange
        }
        switch (query) {
          case DynamicFieldQuery.Enums:
            return (
              <EnumSelector
                placeholder="Select a value..."
                enumName={field.enumName}
                multiple={hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiple)}
                {...sharedProps}
              />
            )
          default: {
            if (query === DynamicFieldQuery.CustomValues && !customValues) {
              return <TagsInput placeholder={`Enter ${key}`} {...sharedProps} />
            }

            return (
              <FieldSelector
                key={key}
                dynamicField={field}
                style={{ width: '100%' }}
                {...sharedProps}
              />
            )
          }
        }
      case DynamicFieldType.Date:
        sharedProps = {
          ...sharedProps,
          onChange: onChange ? (date: Moment) => onChange(date) : onChange,
          value: value ? moment(value) : undefined
        }

        return (
          <DatePicker
            // showTime
            format={DATE_FORMAT}
            placeholder="Select date and time"
            {...sharedProps}
          />
        )

      case DynamicFieldType.Document:
        sharedProps = {
          ...sharedProps
        }
        return (
          <UploadDocument
            data={value}
            fieldKey={field.key}
            handleDocumentUpload={(acceptedFiles: File) => onChange?.(acceptedFiles)}
            {...sharedProps}
          />
        )
      default:
        sharedProps = {
          ...sharedProps,
          onChange
        }

        return hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiline) ? (
          <Input.TextArea
            rows={4}
            autoComplete="off"
            placeholder={`Enter ${key}`}
            {...sharedProps}
          />
        ) : (
          <DynamicInputText
            isCapitalize={hasDynamicFieldTag(dynamicField, DynamicFieldTag.Capitalize)}
            text={key || ''}
            {...sharedProps}
          />
        )
    }
  }

  return renderField(field)
}

export default DynamicFieldComponent
