import type { TextProps } from 'antd/lib/typography/Text'

import { Link } from 'react-router-dom'
import { Typography } from 'antd-v5'

interface WayBillDisplayProps extends TextProps {
  id: string
}

const WayBillDisplay = ({ id, ...rest }: WayBillDisplayProps) => {
  return (
    <Typography.Text {...rest}>
      <Link
        target="_blank"
        style={{ color: 'rgb(140, 140, 140)', paddingRight: 5 }}
        to={`https://trac.shipx.cc/u/${id}`}
      >
        {id}
      </Link>
    </Typography.Text>
  )
}
export default WayBillDisplay
