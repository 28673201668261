import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'
import { DocumentGeneratorTemplateFieldQuery } from '@/types/graphql'

import { forwardRef, memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { camelCaseToSpace } from '@/utils/u'

interface Props extends SelectProps {
  camelCaseToNormal?: boolean
  enumName: string
  style?: any
  value?: any
  onChange?: any
  allowClear?: boolean
  multiple?: boolean
  id?: string
  include?: string[]
  exclude?: string[]
}

export const enumQueryString = `
  query enum1 ($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`

export const ENUM_QUERY = gql(`
  query enumForSelector($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`)

const EnumSelector = forwardRef((props: Props, ref: any) => {
  const {
    id,
    value,
    enumName,
    onChange,
    multiple,
    camelCaseToNormal,
    allowClear = false,
    include,
    exclude
  } = props

  const { loading, error, data } = useQuery(ENUM_QUERY, {
    variables: { name: enumName },
    fetchPolicy: 'cache-first'
  })

  const customValues = useMemo(() => DocumentGeneratorTemplateFieldQuery.CustomValues, [])

  const enums = useMemo(() => {
    let enumValues = data?.__type?.enumValues || []

    if (include) {
      enumValues = enumValues.filter((t: any) => include.includes(t.name))
    }

    if (exclude) {
      enumValues = enumValues.filter((t: any) => !exclude.includes(t.name))
    }

    return enumValues
      .map((t: any) => {
        if (camelCaseToNormal) {
          return {
            value: t.name,
            label: camelCaseToSpace(t.name)
          }
        }
        return {
          value: t.name,
          label: t.name
        }
      })
      .sort((a: any, b: any) => (a.value === customValues ? -1 : b.value === customValues ? 1 : 0))
  }, [camelCaseToNormal, data?.__type?.enumValues, customValues, include, exclude])

  if (error) {
    logger.error(`EnumSelector ${enumName} error`, error)
    responseHandler(error, 'error')
  }

  const options = enums?.map((t: any) => {
    return { label: t.label, value: t.value }
  })

  return (
    <Select
      {...props}
      id={id ?? `${enumName}-selector`}
      ref={ref}
      value={value}
      options={options}
      loading={loading}
      onChange={onChange}
      filterOption={false}
      allowClear={allowClear}
      mode={multiple ? 'multiple' : props.mode || undefined}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    />
  )
})

export default memo(EnumSelector)
