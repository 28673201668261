import type { SelectProps } from 'antd-v5'

import { useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { BASE_COMPANY_CURRENCY, GET_CURRENCIES } from '@/components/Manage/Currencies/schema'
import useGlobalCompanyStore from '@/store/globalCompany'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

interface CurrencySelectProps extends SelectProps {
  showAll?: boolean
  isBasecompanyCurrency?: boolean
}

const CurrencySelect = ({ isBasecompanyCurrency, showAll, ...props }: CurrencySelectProps) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [allCurrencies, setAllCurrencies] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState<string>(props.value)

  const { error } = useQuery(GET_CURRENCIES, {
    variables: {
      query: searchInput || '',
      showAll
    },
    onCompleted: data => {
      setAllCurrencies(data?.currencies?.rows || [])
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce(value => {
      try {
        setSearchInput(value)
      } catch (err) {
        logger.error('CurrencySelect handleSearch error', err)
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('CurrencySelect error', error)
    responseHandler(error, 'error')
  }

  const { data: baseCompanyCurrencies, loading: bccLoading } = useQuery(BASE_COMPANY_CURRENCY, {
    variables: { uuid: selectedGlobalCompany.uuid },
    skip: !isBasecompanyCurrency
  })

  const sort = currencies => {
    return [...currencies].sort((a, b) => (a.code < b.code ? -1 : 1)) || []
  }

  const sorted = isBasecompanyCurrency
    ? sort(baseCompanyCurrencies?.baseCompany?.currencies || [])
    : sort(allCurrencies)

  const currencyOptions: SelectProps['options'] = sorted?.map(currency => {
    return {
      label: `${currency.code} - ${currency.name}`,
      value: currency.uuid
    }
  })

  return (
    <Select
      showSearch
      filterOption={false}
      placeholder="Select a currency..."
      {...props}
      loading={bccLoading}
      onSearch={handleSearch}
      options={currencyOptions}
      notFoundContent={<LoadingOutlined />}
    />
  )
}

export default CurrencySelect
