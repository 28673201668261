import { Anchor, Col, Flex } from 'antd-v5'

import { AnchorIcon, AnchorState, AnchorTitle } from '@/components/Booking/Status/Container'
import { filterBookingState, getDisplayState } from '@/utils/booking'

const iconSpan = 4
const titleSpan = 20

function Status({ bookingState, booking }) {
  const findSection = (stateName, processFlowType, processFlow) => {
    const pf = processFlow.find(p => p.type === processFlowType)
    let section
    if (pf) {
      section = pf.sections.find(section => section.steps.find(step => step.key === stateName))
    }
    return section
  }

  const stages = bookingState?.reduce((arr, state) => {
    const found = arr.find(s => s.sectionKey === state.key)

    if (found) return arr

    const section = findSection(state.value, state.key, booking.processFlow)
    const filteredStateInfo = getDisplayState(state.key)

    const obj = {
      fa: filteredStateInfo.fa,
      size: filteredStateInfo.size,
      stageName: filteredStateInfo.defaultDisplayName,
      stageIcon: filteredStateInfo.iconName,
      stageHref: section ? section.name : state.key,
      sectionKey: state.key,
      state: filterBookingState(state.value)
    }

    arr.push(obj)

    return arr
  }, [])

  const anchorItems = stages?.map(stage => {
    return {
      key: stage.sectionKey,
      href: `#${stage.stageHref}`,
      title: (
        <Flex align="middle" justify="space-between">
          <Col span={iconSpan}>
            {/* <AnchorIcon fa={stage.fa} size={stage.size} type={stage.stageIcon} /> */}
          </Col>
          <Col span={titleSpan}>
            <AnchorTitle>
              {stage.stageName}
              <AnchorState>{stage.state.stateDisplayName}</AnchorState>
            </AnchorTitle>
          </Col>
        </Flex>
      )
    }
  })

  return (
    <Anchor
      offsetTop={56}
      items={[
        {
          key: 'bookingOverview',
          href: '#bookingOverview',
          title: (
            <Flex gap={5}>
              <Col span={iconSpan}>
                <AnchorIcon type="align-left" />
              </Col>
              <Col span={titleSpan}>
                <AnchorTitle>Overview</AnchorTitle>
              </Col>
            </Flex>
          )
        },
        ...(anchorItems || [])
      ]}
    />
  )
}

export default Status
