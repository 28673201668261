import type { FormProps } from 'antd-v5'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { Fragment } from 'react'
import { Col, Form } from 'antd'

import { ColWrapper, Divider } from 'App/components/Manage/FormItemMapper/Styled'

const FormItem = Form.Item

export type FormItemLayout = {
  labelCol?: FormProps['labelCol']
  wrapperCol?: FormProps['wrapperCol']
}

type FormItemAttributes = {
  initialValue?: any
  rules?: any[] // Array<{ required: boolean, message: string}>
  valuePropName?: string
}

export type FormItemConfig = FormItemLayout & {
  key?: string
  isCustom?: boolean
  removeDecorator?: boolean
  label: string
  value: string
  input: JSX.Element
  attributes: FormItemAttributes
}

export type FormField = {
  cols: FormItemConfig[] | null[]
  label?: string
  key?: string
  combine?: boolean
  full?: boolean
  width?: string
}

export const defaultFormItemLayout: FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

type FormItemMapperProps = {
  fields: FormField[]
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  headerTitle?: string
}

const FormItemMapper = (props: FormItemMapperProps) => {
  const { fields, getFieldDecorator, headerTitle } = props

  return (
    <>
      <div style={{ paddingLeft: 15, margin: '20px 0' }}>{headerTitle && <b>{headerTitle}</b>}</div>
      {fields?.map((field, fieldIndex) => {
        if (field.combine) {
          return (
            <FormItem key={field.key ?? fieldIndex} label={field.label}>
              {field.cols?.map((col, colIndex) => (
                <FormItem
                  key={col.key ?? colIndex}
                  labelCol={col.labelCol || defaultFormItemLayout.labelCol}
                  wrapperCol={col.wrapperCol || defaultFormItemLayout.wrapperCol}
                >
                  {getFieldDecorator(col.value as never, {
                    ...col.attributes,
                    initialValue: col.attributes.initialValue
                  })(col.input)}
                </FormItem>
              ))}
            </FormItem>
          )
        }

        return (
          <Col key={field.key ?? fieldIndex}>
            {field.label && <Divider>{field.label}</Divider>}
            <ColWrapper full={field.full} width={field.width}>
              {field.cols?.map((col, colIndex) => {
                if (col.isCustom) {
                  return (
                    <Fragment key={col.key ?? colIndex}>
                      {col.removeDecorator
                        ? col.input
                        : getFieldDecorator(col.value as never, {
                            ...col.attributes,
                            initialValue: col.attributes?.initialValue
                          })(col.input)}
                    </Fragment>
                  )
                } else {
                  return (
                    <FormItem
                      key={col.key ?? colIndex}
                      label={col.label}
                      labelCol={col.labelCol || defaultFormItemLayout.labelCol}
                      wrapperCol={col.wrapperCol || defaultFormItemLayout.wrapperCol}
                    >
                      {col.removeDecorator
                        ? col.input
                        : getFieldDecorator(col.value as never, {
                            ...col.attributes,
                            initialValue: col.attributes?.initialValue
                          })(col.input)}
                    </FormItem>
                  )
                }
              })}
            </ColWrapper>
          </Col>
        )
      })}
    </>
  )
}

export default FormItemMapper
