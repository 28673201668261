import type { PropsWithChildren } from 'react'

import styled from 'styled-components'

export const DocumentView = styled.div`
  flex: 1;
`
export const PreviewWrapper = styled.div<PropsWithChildren<{ type: string }>>`
  height: 100%;
  width: 100%;
  text-align: center;
  overflow: ${props => (props.type === 'application/pdf' ? 'hidden' : 'auto')};
  position: relative;

  img {
    max-width: 95%;
    max-height: 95%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const NotAvailable = styled.div`
  font-size: 13.222px;
  font-weight: 500;
  color: rgb(150, 150, 150);
  letter-spacing: 0.1px;
`

export const Loader = styled.div`
  text-align: center;
  font-size: 13.5px;
  color: rgb(130, 130, 130);

  .anticon-spin {
    animation: loadingCircle 0.6s infinite linear;
    margin-right: 10px;
  }
`
