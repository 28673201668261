import { useQuery } from '@apollo/client'
import { Flex, Timeline } from 'antd-v5'
import find from 'lodash/find'

import Chronology from '@/components/Workflow/Chronology'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from '@/containers/booking/schema/bookingForWorkflowsSlim'

const renderSchedules = booking => {
  const { chronologies, processFlow } = booking
  const bookingSchedules = processFlow.reduce((acc, item) => {
    const sectionTypes = item.sections.reduce((sectionAcc, section) => {
      if (section.chronologies) {
        const chronologiesTypes = section.chronologies.map(chrono => chrono.type)
        return [...sectionAcc, ...chronologiesTypes]
      }
      return sectionAcc
    }, [])
    return [...acc, ...sectionTypes]
  }, [])

  return bookingSchedules?.map(schedule => {
    const findChrono = find(chronologies, c => c.type === schedule)
    const chrono = findChrono
      ? { data: findChrono, type: findChrono.type, title: schedule }
      : { data: {}, type: schedule, title: schedule }

    return { children: <Chronology chrono={chrono} booking={booking} /> }
  })
}

const Schedules = ({ booking }) => {
  const { data: bookingData } = useQuery(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { uuid: booking.uuid }
  })

  if (!bookingData?.booking) {
    return null
  }

  return (
    <Flex className="pt-[20px]">
      <Timeline items={renderSchedules(bookingData?.booking)} />
    </Flex>
  )
}

export default Schedules
