import { useState } from 'react'
import { DatePicker } from 'antd'

const styles = {
  width: '100%',
  maxWidth: '350px'
}

type DateTimePickerProps = {
  value?: any
  onChange?: (date: any, dateString: string) => void
  className?: string
}

const DateTimePicker = ({ value, onChange = () => {}, className }: DateTimePickerProps) => {
  const [mode, setMode] = useState('date')

  const handleDatepickerChange = (date, dateString) => {
    if (mode === 'date') {
      setMode('time')
    }
    onChange(date, dateString)
  }

  const handleOpenChange = () => {
    setMode('date')
  }

  const handlePanelChange = (value, mode) => {
    setMode(mode)
  }

  return (
    <DatePicker
      className={className}
      showTime
      value={value}
      style={styles}
      format="DD/MM/YYYY HH:mm"
      placeholder="Select a date and time"
      getCalendarContainer={trigger => trigger.parentNode}
      mode={mode}
      onChange={handleDatepickerChange}
      onOpenChange={handleOpenChange}
      onPanelChange={handlePanelChange}
    />
  )
}

export default DateTimePicker
