import { Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Popconfirm, Table, Tooltip } from 'antd-v5'

import './BulkUpdateLegsTable.css'

type BulkUpdateLegsProps = {
  data: any[]
  columns: any[]
  selectedRow: any
  paginationObj: any
  mutationButtons: MutationButton[]
  setSelectedRow: (row: any) => void
  activityObj: Record<string, any>
}

export type MutationButton = {
  key: string
  title: string
  onClick: (row: any) => Promise<void>
}

const timings = ['planStart', 'start', 'startOut', 'planEnd', 'end', 'endOut']

const BulkUpdateLegsTable = (props: BulkUpdateLegsProps) => {
  const { t } = useTranslation()
  const {
    data,
    columns,
    selectedRow,
    paginationObj,
    setSelectedRow,
    mutationButtons,
    activityObj
  } = props

  const mutateRow = useCallback(
    async (mutation: (row: any) => Promise<void>) => {
      for (const d of data) {
        await mutation(d)
      }
    },
    [data]
  )

  const isAnyOfTheLegsVehicleDriverAlreadySelected = !!data
    .map(j => j.driverUuid || j.vehicleUuid)
    .filter(Boolean).length

  const toUpdateTimingFields = timings.filter(tm => activityObj?.[tm])
  const isTimingSetAlready = toUpdateTimingFields.some(tm => data.some(j => j[tm]))

  const isData = data.length > 0

  const rowClassName = record => (selectedRow?.legUuid === record.legUuid ? 'selected-row ' : '')

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={paginationObj}
        rowKey={record => record.legUuid}
        onRow={record => ({ onClick: () => setSelectedRow(record) })}
        rowClassName={rowClassName}
      />

      {isData && (
        <Flex justify="flex-end" gap={5}>
          {mutationButtons.map(({ key, title, onClick }) => {
            if (key === 'updateLeg' && isAnyOfTheLegsVehicleDriverAlreadySelected) {
              return (
                <Popconfirm
                  arrow={false}
                  key={key}
                  okText="Ok"
                  cancelText="No"
                  title={t(
                    'transport.bulkUpdateLegsModal.someTripsAlreadyHaveADriverOrVehicleSelected'
                  )}
                  onConfirm={async () => await mutateRow(onClick)}
                >
                  <Button type="primary" id="bulk-update-all-button">
                    {title}
                  </Button>
                </Popconfirm>
              )
            }

            const isDisabled = key === 'updateTiming' && isTimingSetAlready

            return (
              <Fragment key={key}>
                <Tooltip
                  title={
                    isDisabled
                      ? t('transport.bulkUpdateLegsModal.timingAlreadySetInOneOfTheTrips')
                      : ''
                  }
                >
                  <Button disabled={isDisabled} onClick={async () => await mutateRow(onClick)}>
                    {title}
                  </Button>
                </Tooltip>
              </Fragment>
            )
          })}
        </Flex>
      )}
    </>
  )
}

export default BulkUpdateLegsTable
