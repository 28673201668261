import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddCostItemActionUI from '@/components/Voucher/CostItems/AddCostItemActionUI'
import { updateCostsheetBookings } from '@/states/reducers/voucher'

const AddCostItemAction = props => {
  const { bookingUuid, onCostItemAdded = () => {} } = props
  const dispatch = useDispatch()
  const costsheetBookings = useSelector(state => state.voucher.costsheetBookings)

  const handleOnCostItemAdded = useCallback(
    (newCostItemValues, bookingUuid) => {
      if (!bookingUuid) return

      const updatedBookings = costsheetBookings?.map(booking => {
        const updatedBooking = { ...booking }

        if (booking.uuid === bookingUuid) {
          const updatedBookingCostItems = [...booking.costItems]

          updatedBookingCostItems.push(newCostItemValues)
          updatedBooking.costItems = updatedBookingCostItems.map(bci => bci)
        }

        return updatedBooking
      })

      dispatch(updateCostsheetBookings(updatedBookings))
      onCostItemAdded()
    },
    [costsheetBookings, dispatch, onCostItemAdded]
  )

  return <AddCostItemActionUI bookingUuid={bookingUuid} onCostItemAdded={handleOnCostItemAdded} />
}

export default AddCostItemAction
