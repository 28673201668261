import type { ProcessFlowSection } from '@/types/graphql'
import { DocumentStatus } from '@/types/graphql'

import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd-v5'

import UploadedItems from '@/components/Workflow/Uploaded/UploadedItems'
import UploadZone from '@/components/Workflow/Uploaded/UploadZone'

type UploadedProps = {
  section?: Partial<ProcessFlowSection> | null
  refetchBooking?: () => void
  uploadedDocs: any[]
  requiredDocs: any[]
  booking: any
  highlightedDocuments: any[]
}

const Uploaded = (props: UploadedProps) => {
  const {
    section,
    refetchBooking,
    uploadedDocs = [],
    requiredDocs = [],
    booking,
    highlightedDocuments
  } = props

  const [showDeleted, setShowDeleted] = useState(false)
  const [showUploadZone, setShowUploadZone] = useState(false)

  const hasDeleted = uploadedDocs.find(doc => doc.status === DocumentStatus.Deleted)

  return (
    <Flex vertical className="mb-8">
      <Flex gap="small" align="center" className="pb-4">
        <Typography.Text strong>{'Documents'.toUpperCase()}</Typography.Text>
        {hasDeleted && (
          <Typography.Link
            type="secondary"
            className="text-xxs"
            onClick={() => setShowDeleted(!showDeleted)}
          >
            {showDeleted ? 'Hide' : 'Show'} Deleted
          </Typography.Link>
        )}
        <Button
          size="small"
          shape="circle"
          className="ml-2"
          icon={<PlusOutlined />}
          onClick={() => setShowUploadZone(true)}
        />
      </Flex>

      <UploadedItems
        refetchBooking={refetchBooking}
        uploadedDocs={uploadedDocs}
        section={section}
        booking={booking}
        showDeleted={showDeleted}
        highlightedDocuments={highlightedDocuments}
      />
      <UploadZone
        refetchBooking={refetchBooking}
        visible={showUploadZone}
        requiredDocs={requiredDocs}
        toggleUploadZone={setShowUploadZone}
      />
    </Flex>
  )
}

export default Uploaded
