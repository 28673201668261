import type { CostItem, GetVoucherBookingCostSheetQuery, Voucher } from '@/types/graphql'

import { useQuery } from '@apollo/client'
import { Card, Result } from 'antd-v5'
import find from 'lodash/find'

import NoAccess from '@/components/Shared/NoAccess'
import { VOUCHER_BOOKING_SHEET } from '@/components/Voucher/Bookings/TabView/schema'
import ViewContent from '@/components/Voucher/Bookings/TabView/ViewContent'
import responseHandler from '@/utils/responseHandler'
import { hasPermissionError } from '@/utils/u'

type TabViewProps = {
  bookingUuid: string
  onRemoveDocument: (val) => void
  onSelectSingleCostItem: (voucher: Voucher, costItem: CostItem) => void
  onSelectSingleDocument: (val) => void
  updateCostsheetBookings: (val) => void
  costsheetBookings: any[]
}

const TabView = (props: TabViewProps) => {
  const { onRemoveDocument, onSelectSingleCostItem, onSelectSingleDocument, bookingUuid } = props

  const handleCompleted = (result: GetVoucherBookingCostSheetQuery | undefined) => {
    if (result?.booking) {
      const { costsheetBookings = [], updateCostsheetBookings } = props

      const existBooking = find(costsheetBookings, b => b.uuid === result.booking?.uuid)
      const newBookings = costsheetBookings.map((b: any) => b)

      if (!existBooking) {
        newBookings.push(result.booking)
        updateCostsheetBookings(newBookings)
      }
    }
  }

  const { data, error, loading, refetch } = useQuery(VOUCHER_BOOKING_SHEET, {
    variables: { uuid: bookingUuid },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  if (loading) return <Card variant="borderless" loading={loading} />

  if (error) {
    if (hasPermissionError(error)) {
      return <NoAccess />
    } else {
      responseHandler(error.message, 'error')
      return <Result status="warning" title="Something wrong.." />
    }
  }

  return (
    <ViewContent
      data={data}
      refetch={refetch}
      onCostItemAdded={refetch}
      onCostItemEdited={refetch}
      onRemoveDocument={onRemoveDocument}
      onSelectSingleCostItem={onSelectSingleCostItem}
      onSelectSingleDocument={onSelectSingleDocument}
    />
  )
}

export default TabView
