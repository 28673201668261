import { VoucherTypeType } from '@/types/graphql'

import { useEffect } from 'react'
import { connect } from 'react-redux'
import { gql, useApolloClient, useLazyQuery } from '@apollo/client'
import { calculateVoucherItem } from '@shipx/formula2'
import { Alert, Skeleton } from 'antd-v5'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import { bindActionCreators } from 'redux'

import {
  calculateExchangeRate,
  getExchangeRates,
  getLocalExchangeRate
} from '@/components/Voucher/Utils'
import * as voucherActions from '@/states/reducers/voucher'
import useGlobalCompanyStore from '@/store/globalCompany'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'
import { calculateGrossProfit, calculateUnbilled, recalibrateVoucherItem } from '@/utils/voucher'

const jobsByBookingUuid = {}

const getError = (errors: { field }[] = [], field) => {
  return find(errors, e => e.field === field)
}

const JOBS_FOR_SELECTOR_QUERY1 = gql`
  query bulkActualizeCostItemJobs($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        bookingUuid
        details
        jobNo
        no
        status
        uuid
      }
    }
  }
`

export default WrappedComponent => {
  const WithCostItems = props => {
    const { voucherBookings, costsheetBookings, selectedVoucher, updateSelectedVoucher } = props
    const client = useApolloClient()

    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const [getJobs, { loading: jobsLoading, error: jobsError, data: jobs }] = useLazyQuery(
      JOBS_FOR_SELECTOR_QUERY1,
      { fetchPolicy: 'cache-and-network' }
    )

    if (voucherBookings?.length)
      voucherBookings.forEach(booking => {
        if (booking?.uuid && !jobsByBookingUuid[booking?.uuid]) {
          jobsByBookingUuid[booking?.uuid] = booking?.jobs
        }
      })

    useEffect(() => {
      if (costsheetBookings?.length)
        costsheetBookings.forEach(booking => {
          if (!voucherBookings?.find(bk => bk.uuid === booking?.uuid)) {
            getJobs({ variables: { bookingUuid: booking?.uuid } })
          }
        })
    }, [])

    useEffect(() => {
      if (costsheetBookings?.length)
        costsheetBookings.forEach(booking => {
          jobsByBookingUuid[booking?.uuid] = jobs?.jobs?.rows
        })
    }, [jobs])

    if (jobsLoading || !selectedGlobalCompany) {
      return <Skeleton active />
    }

    if (jobsError) {
      logger.error('JOBS_FOR_SELECTOR_QUERY1 for bulkActualizeCostItemsButton error', jobsError)
      handleResponse(jobsError, 'error')
      return <Alert message="Error loading jobs." type="error" />
    }

    const handleSelectAll = async changeRows => {
      // Populate jobs in cache to later be included in actualized cost items
      changeRows.forEach(row => {
        const voucherBooking = find(costsheetBookings, b => b.uuid === row.details.bookingUuid)
        if (voucherBooking?.uuid && !jobsByBookingUuid[voucherBooking?.uuid]) {
          getJobs({ variables: { bookingUuid: voucherBooking?.uuid } })
          jobsByBookingUuid[voucherBooking?.uuid] = jobs?.jobs?.rows
        }
      })

      // Wrap in setTimeout to allow time to load jobs
      setTimeout(async () => {
        try {
          const transactionType = selectedVoucher.transactionType
          const isAp = transactionType === VoucherTypeType.Accpay
          const existingVoucherItems = selectedVoucher.voucherItems || []
          let updatedVoucherItems: any = []

          const groupedVoucherItems = groupBy(changeRows, ci => {
            if (isAp) return ci.details?.costCurrency?.uuid
            else return ci.details?.sellCurrency?.uuid
          })

          const exchangeRates = await getExchangeRates({
            client,
            voucher: selectedVoucher,
            groupedVoucherItems
          })

          updatedVoucherItems = await Promise.all(
            changeRows.map(async row => {
              const selectedCompany = selectedVoucher.vendor || selectedVoucher.customer
              const voucherBooking = find(
                costsheetBookings,
                b => b?.uuid === row.details.bookingUuid
              )
              const voucherCostItem = find(
                voucherBooking.costItems,
                ci => ci.uuid === row.details.uuid
              )

              const localExchangeRate = await getLocalExchangeRate(
                client,
                props.selectedVoucher,
                voucherCostItem,
                selectedGlobalCompany?.currency
              )

              const currentBooking = jobsByBookingUuid[voucherBooking?.uuid]
              const singleJob = (currentBooking?.length === 1 && currentBooking[0]) || null

              const recalibratedItem: any = await recalibrateVoucherItem(
                client,
                row.details,
                isAp,
                voucherBooking?.uuid,
                singleJob,
                selectedCompany,
                null,
                props.selectedVoucher
              )
              recalibratedItem.localExchangeRate = localExchangeRate

              const overrideExchangeRate = calculateExchangeRate({
                isAp,
                voucher: selectedVoucher,
                booking: voucherBooking,
                costItem: voucherCostItem,
                voucherItem: recalibratedItem,
                exchangeRates,
                selectedGlobalCompany
              })

              return calculateVoucherItem({
                ...recalibratedItem,
                exchangeRate: (overrideExchangeRate && overrideExchangeRate.rate) || 1
              })
            })
          )

          updateSelectedVoucher({
            ...selectedVoucher,
            voucherItems: [...existingVoucherItems, ...updatedVoucherItems]
          })
        } catch (error) {
          console.error('Error selecting all cost items', error)
        }
      }, 500)
    }

    return (
      <WrappedComponent
        {...props}
        costItems={props.costItems}
        getError={getError}
        handleSelectAll={handleSelectAll}
        calculateUnbilled={calculateUnbilled}
        calculateGrossProfit={calculateGrossProfit}
      />
    )
  }

  return connect(
    state => ({
      voucherBookings: state.voucher.voucherBookings,
      costItemsView: state.voucher.costItemsView,
      selectedVoucher: state.voucher.selectedVoucher,
      costsheetBookings: state.voucher.costsheetBookings,
      showDeletedCostItems: state.voucher.showDeletedCostItems
    }),
    // @ts-expect-error
    dispatch => ({ dispatch, ...bindActionCreators({ ...voucherActions }, dispatch) })
  )(WithCostItems)
}
