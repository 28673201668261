import { CompanyStatus, YardStatus } from 'App/types/graphql'

import { memo, useCallback, useMemo, useState } from 'react'
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Input, InputNumber, Radio } from 'antd'
import { Button, Modal } from 'antd-v5'
import styled from 'styled-components'
import uuidv4 from 'uuid/v4'

import Link from 'App/components/Link'
import FormItemMapper from 'App/components/Manage/FormItemMapper'
import AddressTagsSelect from 'App/components/Select/AddressTagsSelect'
import AddressTypesSelect from 'App/components/Select/AddressTypesSelect'
import SearchLocationInput from 'App/components/Select/MapSearch'
import TransportZonesSelect from 'App/components/Select/TransportZonesSelect'
import AreaCodeSelect from 'App/components/Select/TypeToFetch/AreaCodeSelect'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { ActionItem, Actions, BackLink } from '../Styled'
import FormActions from './Actions'

const StyledComponentDiv = styled.div`
  margin-bottom: 24px;
`

const CreateYardForm = memo(props => {
  const { item, form, viewType, mode, loading, submitText, onCustomSubmit } = props
  const { getFieldDecorator } = form

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const defaultCountryAlpha3 = useMemo(
    () => selectedGlobalCompany?.country?.alpha3,
    [selectedGlobalCompany]
  )
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false)

  const setModalVisibility = useCallback(() => {
    setIsAddressFormOpen(state => !state)
  }, [])

  const renderBackLink = useCallback(() => {
    if (viewType === 'modal') {
      return null
    }

    return (
      <Link to="/manage/yards">
        <BackLink>
          <ArrowLeftOutlined />
          Back
        </BackLink>
      </Link>
    )
  }, [viewType])

  const renderActions = useCallback(() => {
    const customProps = {}

    if (onCustomSubmit) {
      customProps.onClick = onCustomSubmit
    } else {
      customProps.htmlType = 'submit'
    }

    if (mode === 'edit') {
      return (
        <ActionItem span={18} align="right">
          <FormActions
            item={item}
            submitText={submitText}
            onDeleted={props.onDeleted}
            refetchItem={props.refetchItem}
            parentLoading={loading}
            customProps={customProps}
          />
        </ActionItem>
      )
    }

    return (
      <ActionItem span={18} align="right">
        <Button type="primary" {...customProps} loading={loading}>
          {loading ? 'Submitting...' : submitText}
        </Button>
      </ActionItem>
    )
  }, [item, loading, mode, onCustomSubmit, props.onDeleted, props.refetchItem, submitText])

  const fields = useMemo(
    () => [
      {
        cols: [
          {
            label: '',
            value: 'uuid',
            input: <input type="hidden" />,
            attributes: {
              initialValue: item?.uuid
            }
          },
          {
            label: 'Code',
            value: 'code',
            input: <Input autoComplete="off" placeholder="Enter a code..." />,
            attributes: {
              initialValue: item?.code,
              rules: [
                {
                  required: true,
                  message: 'Code is required.'
                }
              ]
            }
          },
          {
            label: 'Name',
            value: 'name',
            input: <Input autoComplete="off" placeholder="Enter a name..." />,
            attributes: {
              initialValue: item?.name,
              rules: [
                {
                  required: true,
                  message: 'Name is required.'
                }
              ]
            }
          },
          {
            label: 'Remarks',
            value: 'remarks',
            input: <Input autoComplete="off" placeholder="Enter remarks..." />,
            attributes: {
              initialValue: item?.remarks
            }
          },
          {
            label: 'Status',
            value: 'status',
            input: (
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={YardStatus.Active}>{YardStatus.Active}</Radio.Button>
                <Radio.Button value={YardStatus.Deleted}>{YardStatus.Deleted}</Radio.Button>
              </Radio.Group>
            ),
            attributes: {
              initialValue: item?.status || YardStatus.Active
            }
          },
          {
            label: 'Address',
            value: 'address',
            input: <Input.TextArea autoComplete="off" autoSize disabled />,
            attributes: {
              initialValue: item?.address ? JSON.stringify(item.address) : '',
              rules: [
                {
                  required: true,
                  message: 'Address is required.'
                }
              ]
            }
          }
        ]
      }
    ],
    [item]
  )

  const getGoogleMapsData = useCallback(
    async searchRes => {
      await form.setFieldsValue({
        address_name: searchRes?.name || '',
        address_address1: searchRes?.address1 || '',
        address_address2: searchRes?.address2 || '',
        address_address3: searchRes?.address3 || '',
        address_city: searchRes?.city || '',
        address_district: searchRes?.district || '',
        address_postCode: searchRes?.postCode || '',
        address_latitude: searchRes?.latitude || '',
        address_longitude: searchRes?.longitude || '',
        address_placeId: searchRes?.placeId || '',
        address_plusCode: searchRes?.plusCode || ''
      })
    },
    [form]
  )

  const getYardAddress = useCallback(() => {
    form.validateFields(async (err, values) => {
      if (err?.address_name?.errors?.length || err?.address_address1?.errors?.length) {
        return
      }

      await form.setFieldsValue({
        address: JSON.stringify({
          uuid: values?.address_uuid || uuidv4(),
          name: values?.address_name,
          status: values?.address_status || CompanyStatus.Activated,
          type: values?.address_type || null,
          address1: values?.address_address1,
          address2: values?.address_address2 || null,
          address3: values?.address_address3 || null,
          address4: values?.address_address4 || null,
          city: values?.address_city || null,
          district: values?.address_district || null,
          postCode: values?.address_postCode || null,
          placeId: values?.address_placeId || null,
          plusCode: values?.address_plusCode || null,
          areaCode: values?.address_areaCode || null,
          zone: values?.address_zone || null,
          phone: values?.address_phone || null,
          fax: values?.address_fax || null,
          tags: values?.address_tags || null,
          countryAlpha3: values?.address_countryAlpha3 || null,
          location:
            values?.address_latitude && values?.address_latitude
              ? {
                  type: 'Point',
                  coordinates: [values?.address_latitude, values?.address_longitude]
                }
              : null
        })
      })

      await setModalVisibility()
    })
  }, [form, setModalVisibility])

  const addressFields = useMemo(
    () => [
      {
        cols: [
          {
            label: '',
            value: 'address_uuid',
            input: <input type="hidden" />,
            attributes: {
              initialValue: item?.address?.uuid || ''
            }
          },
          {
            label: 'Name',
            value: 'address_name',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.name || '',
              rules: [
                {
                  required: true,
                  message: 'Address name is required.'
                }
              ]
            }
          },
          {
            label: 'Types',
            value: 'address_type',
            input: <AddressTypesSelect />,
            attributes: {
              initialValue: item?.address?.type?.length ? item?.address?.type : undefined
            }
          },
          {
            label: 'Country',
            value: 'address_countryAlpha3',
            input: <CountrySelect useAlpha3 />,
            attributes: {
              initialValue: item?.address?.countryAlpha3 || defaultCountryAlpha3,
              rules: [
                {
                  required: true,
                  message: 'Address country is required.'
                }
              ]
            }
          },
          {
            label: 'Address 1',
            value: 'address_address1',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.address1 || '',
              rules: [
                {
                  required: true,
                  message: 'Address 1 is required.'
                }
              ]
            }
          },
          {
            label: 'Address 2',
            value: 'address_address2',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.address2 || ''
            }
          },
          {
            label: 'Address 3',
            value: 'address_address3',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.address3 || ''
            }
          },
          {
            label: 'Address 4',
            value: 'address_address4',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.address4 || ''
            }
          },
          {
            label: 'City',
            value: 'address_city',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.city || ''
            }
          },
          {
            label: 'District',
            value: 'address_district',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.district || ''
            }
          },
          {
            label: 'Post code',
            value: 'address_postCode',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.postCode || ''
            }
          },
          {
            label: 'Area code',
            value: 'address_areaCode',
            input: <AreaCodeSelect />,
            attributes: {
              initialValue: item?.address?.areaCode,
              rules: [
                {
                  required: true,
                  message: 'Address area code is required.'
                }
              ]
            }
          },
          {
            label: 'Zone',
            value: 'address_zone',
            input: <TransportZonesSelect />,
            attributes: {
              initialValue: item?.address?.zone,
              rules: [
                {
                  required: true,
                  message: 'Address zone is required.'
                }
              ]
            }
          },
          {
            label: 'Latitude',
            value: 'address_latitude',
            input: <InputNumber min={-90} max={90} step={0.1} />,
            attributes: {
              initialValue: item?.address?.location?.coordinates?.[0] || ''
            }
          },
          {
            label: 'Longitude',
            value: 'address_longitude',
            input: <InputNumber min={-180} max={180} step={0.1} />,
            attributes: {
              initialValue: item?.address?.location?.coordinates?.[1] || ''
            }
          },
          {
            label: 'Google Place Id',
            value: 'address_placeId',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.placeId || ''
            }
          },
          {
            label: 'Plus Code',
            value: 'address_plusCode',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.plusCode || ''
            }
          },
          {
            label: 'Phone',
            value: 'address_phone',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.phone || ''
            }
          },
          {
            label: 'Fax',
            value: 'address_fax',
            input: <Input autoComplete="off" />,
            attributes: {
              initialValue: item?.address?.fax || ''
            }
          },
          {
            label: 'Tags',
            value: 'address_tags',
            input: <AddressTagsSelect />,
            attributes: {
              initialValue: item?.address?.tags?.length ? item?.address?.tags : undefined
            }
          },
          {
            label: 'Status',
            value: 'address_status',
            input: (
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={CompanyStatus.Activated}>
                  {CompanyStatus.Activated}
                </Radio.Button>
                <Radio.Button value={CompanyStatus.Deleted}>{CompanyStatus.Deleted}</Radio.Button>
              </Radio.Group>
            ),
            attributes: {
              initialValue: item?.address?.status || CompanyStatus.Activated
            }
          }
        ]
      }
    ],
    [defaultCountryAlpha3, item]
  )

  return (
    <>
      <Actions>
        <ActionItem span={6}>{renderBackLink()}</ActionItem>
        {renderActions()}
      </Actions>
      <StyledComponentDiv>
        <FormItemMapper fields={fields} getFieldDecorator={getFieldDecorator} />
      </StyledComponentDiv>

      <Button icon={<EditOutlined />} onClick={setModalVisibility} style={{ marginLeft: '100px' }}>
        Edit Address
      </Button>

      <Modal
        width={'80%'}
        style={{ top: 20 }}
        title="Address Details"
        visible={isAddressFormOpen}
        onCancel={setModalVisibility}
        onOk={getYardAddress}
      >
        <SearchLocationInput onClickSearchResult={getGoogleMapsData} />
        <StyledComponentDiv>
          <FormItemMapper fields={addressFields} getFieldDecorator={getFieldDecorator} />
        </StyledComponentDiv>
      </Modal>

      <Actions>
        <ActionItem span={6}>{renderBackLink()}</ActionItem>
        {renderActions()}
      </Actions>
    </>
  )
})

export default CreateYardForm
