import type { CostItem, Voucher } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import { Checkbox } from 'antd'
import { Flex, Modal } from 'antd-v5'

import { VOUCHER_FOR_JV } from '@/components/Select/schema'
import VouchersSelect from '@/components/Select/VouchersSelect'
import { DEFAULT_COST_ITEM, GET_VOUCHER_ITEMS } from '@/components/Voucher/schema'
import { useVoucherStore } from '@/store/voucher'
import notify from '@/utils/notify'
import VoucherItemSelect from '../Select/VoucherItemSelect'

interface VoucherItemJvModalProps {
  onSelectSingleCostItem: (
    voucher: Voucher | undefined,
    costItem: CostItem | undefined,
    saveJv?: boolean
  ) => void
  selectedVoucher: Voucher
}

const VoucherItemJvModal = (props: VoucherItemJvModalProps) => {
  const { selectedVoucher, onSelectSingleCostItem } = props

  const { t } = useTranslation()
  const client = useApolloClient()

  const [reuseJvForNewItems, setReuseJvForNewItems] = useState(false)

  const isJvVoucher = useVoucherStore.use.isJvVoucher()
  const voucherUuid = useVoucherStore.use.voucherUuid()
  const voucherItemUuid = useVoucherStore.use.voucherItemUuid()
  const openJvModal = useVoucherStore.use.openJvModal()
  const isJvNewItems = useVoucherStore.use.isJvNewItems()
  const defaultCostItem = useVoucherStore.use.defaultCostItem()
  const setVoucherUuid = useVoucherStore.use.setVoucherUuid()
  const setVoucherItemUuid = useVoucherStore.use.setVoucherItemUuid()
  const setVoucher = useVoucherStore.use.setVoucher()
  const setCostItem = useVoucherStore.use.setCostItem()
  const setCostItemUuid = useVoucherStore.use.setCostItemUuid()
  const setOpenJvModal = useVoucherStore.use.setOpenJvModal()
  const setIsJvNewItems = useVoucherStore.use.setIsJvNewItems()

  useEffect(() => {
    if (!client || !selectedVoucher?.isJournalVoucher) return

    const fetchFirstVoucherItemCn = async () => {
      const voucherItemsQuery = await client.query({
        query: GET_VOUCHER_ITEMS,
        variables: { input: { bookingUuids: selectedVoucher.bookings?.map(b => b?.uuid) } }
      })

      const voucherItem = voucherItemsQuery?.data.voucherItems?.filter(
        vi => vi?.voucherItemCn === null
      )?.[0]

      if (!voucherItem) {
        console.error('Query first voucher item failed')
        return
      }

      const costItemsQuery = await client.query({
        query: DEFAULT_COST_ITEM,
        variables: { input: { filter: { voucherUuid: voucherItem?.voucher?.uuid } } }
      })

      const costItem = costItemsQuery?.data.vouchersSearch?.rows?.[0]?.voucherItems?.[0]?.costItem

      if (!costItem) {
        console.error('Query default cost item failed')
        return
      }

      setVoucherUuid(voucherItem.voucher?.uuid)
      setCostItemUuid(costItem.uuid)
    }

    fetchFirstVoucherItemCn()
  }, [selectedVoucher, setVoucherUuid, setCostItemUuid])

  const handleOk = async () => {
    if (!isJvVoucher) return notify('Not a Journal Voucher', 'error')

    const voucherQuery = await client?.query({
      query: VOUCHER_FOR_JV,
      variables: { uuid: voucherUuid }
    })

    const voucher = voucherQuery?.data.voucher ?? undefined

    setVoucher(voucher)
    setIsJvNewItems(reuseJvForNewItems)

    if (!voucher) return notify('No voucher', 'error')
    if (!defaultCostItem) return notify('No cost item', 'error')

    setCostItem(defaultCostItem)

    onSelectSingleCostItem(voucher, defaultCostItem, !reuseJvForNewItems)
    setOpenJvModal(false)
  }

  // if (!bookingUuid && openJvModal) return null

  return (
    <Modal
      destroyOnClose
      title={t('voucher.voucherItemToJv')}
      open={!isJvNewItems && openJvModal}
      closable={false}
      onOk={handleOk}
      onCancel={() => setOpenJvModal(false)}
    >
      <Flex vertical gap="middle">
        <Checkbox
          checked={reuseJvForNewItems}
          onChange={e => setReuseJvForNewItems(e.target.checked)}
        >
          Use this JV&apos;ed voucher item for new items
        </Checkbox>
        <VouchersSelect
          queryOnMount
          value={voucherUuid}
          onChange={v => setVoucherUuid(v as string)}
        />

        {voucherUuid && (
          <VoucherItemSelect
            voucherUuid={voucherUuid}
            value={voucherItemUuid}
            onChange={v => setVoucherItemUuid(v as string)}
          />
        )}
      </Flex>
    </Modal>
  )
}

export default VoucherItemJvModal
