import type { SelectProps } from 'antd-v5'

import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { PLACES_QUERY } from 'App/components/Booking/DynamicField/PlaceSelector/schema'
import useDebounce from 'App/hooks/useDebounce'

const PlaceSelect = (selectProps: SelectProps) => {
  const [searchInput, setSearchInput] = useState(selectProps?.value || '')

  const debouncedSearch = useDebounce<string>(searchInput, 300)

  const [getPlaces, { data, loading }] = useLazyQuery(PLACES_QUERY, {
    variables: { q: debouncedSearch },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    getPlaces()
  }, [debouncedSearch, getPlaces])

  const placeOptions = data?.places?.map(p => ({ label: p?.description, value: p?.placeId }))

  return (
    <Select
      {...selectProps}
      id="place-selector"
      allowClear
      showSearch
      loading={loading}
      filterOption={false}
      style={{ width: '100%' }}
      placeholder="Select a city..."
      onSearch={(value: string) => setSearchInput(value)}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      options={placeOptions}
    />
  )
}

export default PlaceSelect
