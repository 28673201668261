import type { BookingDocument, ProcessFlowDocumentType, Vouchers } from '@/types/graphql'

import { useQuery } from '@apollo/client'

import Document from '@/components/Workflow/Document'
import { BOOKING_DOCUMENT } from '@/graphql/document-booking'
import { useBookingStore } from '@/store/booking'
import respHandler from '@/utils/responseHandler'

interface UploadedItemProps {
  uploadedDoc: BookingDocument
  isHighlighted?: boolean
  vouchers?: Vouchers
  requests?: any
  documentApproval?: ProcessFlowDocumentType
  refetchBooking?: () => void
}

const UploadedItem = (props: UploadedItemProps) => {
  const {
    uploadedDoc,
    isHighlighted = false,
    vouchers = [],
    requests = [],
    documentApproval,
    refetchBooking
  } = props

  const { loading, error, data } = useQuery(BOOKING_DOCUMENT, {
    variables: { uuid: uploadedDoc.uuid },
    skip: !uploadedDoc.uuid
  })
  const setOpenDocumentViewer = useBookingStore.use.setOpenDocumentViewer()
  const setSelectedBookingDocument = useBookingStore.use.setSelectedBookingDocument()

  const handleClick = () => {
    if (loading) return respHandler('Loading document...', 'load')

    if (error || !data?.bookingDocument)
      respHandler('Unable to load voucher. Please try again later.', 'error')

    setSelectedBookingDocument({ ...data?.bookingDocument, allowedApprovals: documentApproval })
    setOpenDocumentViewer(true)
    refetchBooking?.()
  }

  return (
    <Document
      uploadedDoc={uploadedDoc}
      vouchers={vouchers}
      requests={requests}
      documentApproval={documentApproval}
      isHighlighted={isHighlighted}
      onClick={handleClick}
    />
  )
}

export default UploadedItem
