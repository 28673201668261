import { RoleStatus } from '@/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { gqlRoles } from '@/components/Select/EmployeeRoleSelect/gqlRoles'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const EmployeeRoleSelect = forwardRef((props, ref) => {
  const { value, onChange, mode, query, noTags, tags, onBlur, style = { width: '100%' } } = props

  const [roles, setRoles] = useState([])
  const [q, setQ] = useState()

  const queryInput = useMemo(() => query || { limit: 20 }, [query])
  const input = useMemo(
    () => ({
      ...queryInput,
      statuses: [RoleStatus.Active],
      q,
      noTags,
      tags
    }),
    [queryInput, q, noTags, tags]
  )

  const { data, error, loading, refetch } = useQuery(gqlRoles, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleChange = useCallback(value => {
    onChange(value)
  }, [])

  const onSearch = useCallback(
    debounce(value => {
      setQ(value)
      refetch()
    }, 500),
    []
  )

  useEffect(() => {
    if (setRoles) {
      setRoles(data?.roles?.rows)
    }
  }, [data])

  if (error) {
    logger.error('EmployeeRoleSelect gqlRoles error', error)
    responseHandler(error, 'error')
  }

  const roleOptions = roles?.map(role => {
    return {
      value: role.code,
      label: role.code
    }
  })
  return (
    <Select
      ref={ref}
      allowClear
      showSearch
      mode={mode}
      style={style}
      value={value}
      onBlur={onBlur}
      loading={loading}
      onSearch={onSearch}
      filterOption={false}
      options={roleOptions}
      onChange={handleChange}
      placeholder="Search a role(s)..."
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    />
  )
})

export default memo(EmployeeRoleSelect)
