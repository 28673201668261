import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Popconfirm } from 'antd-v5'

import { PreviewWrapper } from '@/components/DocumentViewer/Content/Styled'
import { ModalViewer } from '@/components/DocumentViewer/Styled'
import ResourceViewer from '@/components/ResourceViewer'
import config from '@/config'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

type TaxDocumentViewerProps = {
  uuid: string
  setDocumentUuid?: any
  toggleModal: boolean
  setToggleModal: any
}

function TaxDocumentViewer({
  uuid,
  setDocumentUuid,
  toggleModal,
  setToggleModal
}: TaxDocumentViewerProps) {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const taxExemptionDocUrl = `${config.api.baseUrl}/booking/document/${uuid}`

  const closeModal = () => setToggleModal(false)

  const deleteDocument = () => {
    setDocumentUuid('')
    setToggleModal(false)
  }

  return (
    <ModalViewer
      title={null}
      footer={null}
      closable={false}
      open={toggleModal}
      onCancel={closeModal}
    >
      <Flex vertical style={{ height: '95vh' }}>
        <CloseOutlined
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}
          onClick={closeModal}
        />

        <PreviewWrapper type={'application/pdf'}>
          <ResourceViewer
            url={taxExemptionDocUrl}
            mimeType={'application/pdf'}
            fetchOptions={{
              method: 'GET',
              headers: {
                authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
                'base-company-uuid': selectedGlobalCompany.uuid
              }
            }}
          />
        </PreviewWrapper>

        {setDocumentUuid && (
          <Flex justify="flex-end" gap={10} style={{ padding: 10 }}>
            <Button danger key={'delete'}>
              <Popconfirm
                okText="Yes"
                arrow={false}
                cancelText="No"
                title="Delete this document?"
                onConfirm={deleteDocument}
              >
                <a href="#">Delete</a>
              </Popconfirm>
            </Button>

            <Button type="primary" key={'ok'} onClick={closeModal}>
              Ok
            </Button>
          </Flex>
        )}
      </Flex>
    </ModalViewer>
  )
}

export default TaxDocumentViewer
