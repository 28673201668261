import type { VoucherItem } from '@/types/graphql'

import { Col, Flex } from 'antd-v5'
import { isEmpty } from 'lodash'

type RenderPopoverProps = {
  selectedVoucherItem: VoucherItem
}

const RenderPopover = ({ selectedVoucherItem }: RenderPopoverProps) => {
  if (isEmpty(selectedVoucherItem)) return

  const { code, description } = selectedVoucherItem?.costItem?.chargeItem || {}

  return (
    <Flex justify="space-between">
      <Col>
        <p>Edit voucher item</p>
      </Col>
      <Col>
        <p>{code}</p>
        <p>{description || ''}</p>
      </Col>
    </Flex>
  )
}

export default RenderPopover
