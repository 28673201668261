import type { Booking } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { QMethods } from '@/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import { intersection } from 'lodash'
import debounce from 'lodash/debounce'

import { BOOKINGS_SEARCH_QUERY } from '@/graphql/booking'
import { getBl, getRef, getShipperOrConsignee } from '@/utils/booking'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

type BookingSelectProps = SelectProps & {
  filterOption?: any
  filter?: any
  useIcon?: boolean
  queryOnMount?: boolean
  isStoreEntireBooking?: boolean
  isIncludingSearchInput?: boolean
  urlParams?: string
  enablePasteBulk?: boolean
  simpleOptionText?: boolean
  handleAutoSearch?: (value: boolean) => void
  useStrict?: boolean // only set exact matched booking no
}

const BookingSelect = forwardRef((props: BookingSelectProps, ref: any) => {
  const {
    mode,
    value,
    onChange,
    urlParams,
    filter = {},
    queryOnMount,
    filterOption,
    useIcon = true,
    handleAutoSearch,
    enablePasteBulk = true,
    simpleOptionText = false,
    isStoreEntireBooking = false,
    isIncludingSearchInput = false,
    placeholder = 'Search a booking here...',
    useStrict = false,
    ...rest
  } = props

  const client = useApolloClient()

  const [bookings, setBookings] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(
    () => ({
      q: (!mode && value) || searchInput || '',
      limit: 20,
      filter
    }),
    [mode, value, searchInput, filter]
  )

  const [getBookingSearch, { loading, error, data }] = useLazyQuery(BOOKINGS_SEARCH_QUERY, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = debounce(async (userInput: string) => {
    if (userInput.includes(' ') && enablePasteBulk) {
      const { data } = await client.query({
        query: BOOKINGS_SEARCH_QUERY,
        variables: {
          input: {
            q: userInput,
            qMethod: QMethods.Or,
            limit: 1000
          }
        }
      })

      const inputBookingNoes = userInput.split(' ')
      const bookingNos = data.bookingsSearchJson.rows.map((booking: Booking) => booking.no)
      const toSet = intersection(inputBookingNoes, bookingNos)

      setSearchInput('')
      onChange?.([...(Array.isArray(value) ? value : []), ...(useStrict ? toSet : bookingNos)])
      handleAutoSearch?.(true)
    }
    setSearchInput(userInput)
    getBookingSearch()
  }, 500)

  useEffect(() => {
    if (queryOnMount) getBookingSearch()
  }, [])

  useEffect(() => {
    if (urlParams) handleSearch(urlParams)

    // dont uncomment this line, else it will trigger inifinite request
  }, [urlParams])

  useEffect(() => {
    if (!loading && data?.bookingsSearchJson?.rows?.length) {
      setBookings(data.bookingsSearchJson.rows)
    } else {
      setBookings([])
    }
  }, [loading, data])

  if (error) {
    logger.error('BookingSelect getBookingSearch error', error)
    responseHandler(error, 'error')
  }

  const placeholderComponent = (
    <>
      {useIcon && <SearchOutlined />} {placeholder}
    </>
  )

  const optionItems: SelectProps['options'] = bookings?.map((booking: Booking) => {
    const blOrRef = getBl(booking)
    const bookingnoOrrefno = getRef(undefined, booking)
    const shipperOrConsignee = getShipperOrConsignee(booking)

    const simpleTextOption = `${bookingnoOrrefno || 'N/A'}`
    const textOption = `${bookingnoOrrefno || 'N/A'} - ${blOrRef || 'N/A'} - ${shipperOrConsignee || 'N/A'}`

    return {
      value: isStoreEntireBooking
        ? isIncludingSearchInput
          ? JSON.stringify({ ...booking, searchInput })
          : JSON.stringify(booking)
        : booking.uuid,
      label: simpleOptionText ? simpleTextOption : textOption
    }
  })

  return (
    <Select
      {...rest}
      ref={ref}
      showSearch
      allowClear
      mode={mode}
      value={value}
      loading={loading}
      onChange={onChange}
      options={optionItems}
      onSearch={handleSearch}
      notFoundContent={'Not found.'}
      placeholder={placeholderComponent}
      filterOption={filterOption !== undefined ? filterOption : false}
    />
  )
})

export default memo(BookingSelect)
