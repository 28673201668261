import { gql } from '@/types'

export const DRIVERS_QUERY = gql(`
  query driversForSelect(
    $q: String
    $statuses: [DriverStatus]
    $uuids: [UUID]
    $companyUuids: [String]
    $customerUuids: [String]
    $subContractorUuids: [String]
    $limit: Int
    $offset: Int
  ) {
    drivers(
      q: $q
      statuses: $statuses
      uuids: $uuids
      companyUuids: $companyUuids
      customerUuids: $customerUuids
      subContractorUuids: $subContractorUuids
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        name
        code
        department
        contact {
          type
          number
        }
        vehiclePreference
        miscFieldTypes {
          tags
          key
          status
        }
        miscFields
      }
    }
  }
`)
