import type { Company, CompanyForLegSelectorQuery, TransportJob } from '@/types/graphql'
import type { FormInstance } from 'antd-v5'
import { ContactSimpleType, LegStatus, TransportSource } from '@/types/graphql'

import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { DatePicker, Form, Input, Typography } from 'antd-v5'
import dayjs from 'dayjs'

import { defaultFormItemLayout } from '@/components/Manage/FormItemMapper'
import { DriverSelector } from '@/components/Select/driver-select'
import CompanySelect from '@/components/Select/TypeToFetch/CompanySelect'
import TrailerSelector from '@/components/Transport/Components/TrailerSelector'
import VehicleSelector from '@/components/Transport/Components/VehicleSelector'
import { COMPANY_QUERY } from '@/graphql/company'
import { usePreviousProps } from '@/hooks/usePreviousProps'
import useGlobalCompanyStore from '@/store/globalCompany'
import { useUnplannedStore } from '@/store/unplanned'
import { useVDJMStore } from '@/store/vdjm'
import { isUuid } from '@/utils/u'

export type LegFormDetailsProps = {
  leg?: TransportJob
  setFormError: (msg: string) => void
  setFormSuccessMsg: (msg: string) => void
  isVDJM?: boolean
  form: FormInstance
}

const LegFormDetails = (props: LegFormDetailsProps) => {
  const { leg, form, setFormError, setFormSuccessMsg, isVDJM } = props

  const companyUuid = Form.useWatch('transportUuid', form)
  const { t } = useTranslation()
  const { selectedDriver, selectedVehicle } = useUnplannedStore()
  const { selectedDriver: selectedVdjmDriver, selectedVehicle: selectedVdjmVehicle } =
    useVDJMStore()

  const selectSelectedDriver = isVDJM ? selectedVdjmDriver : selectedDriver
  const selectSelectedVehicle = isVDJM ? selectedVdjmVehicle : selectedVehicle

  const userBaseCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [transportSource, setTransportSource] = useState(
    leg?.transportSource || TransportSource.Own
  )
  const [selectedComp, setSelectedComp] =
    useState<NonNullable<CompanyForLegSelectorQuery>['company']>(null)
  const [driverPreference, setDriverPreference] = useState(null)
  const [vehiclePreference, setVehiclePreference] = useState<(string | null)[]>([])
  const [trailerPreference, setTrailerPreference] = useState(null)

  const prevComp = usePreviousProps(selectedComp) as unknown as Company

  const [getCompany] = useLazyQuery(COMPANY_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    setDriverPreference(null)
    setVehiclePreference([])
    setTrailerPreference(null)
    setFormSuccessMsg('')
    setFormError('')
  }, [leg, setFormError, setFormSuccessMsg])

  const fetchCompany = async (companyUuid: string) => {
    const { data } = await getCompany({ variables: { uuid: companyUuid } })
    setSelectedComp(data?.company)
  }

  useEffect(() => {
    if (!companyUuid || companyUuid === prevComp?.uuid || !isUuid(companyUuid)) return

    fetchCompany(companyUuid)
  }, [companyUuid])

  useEffect(() => {
    if (selectedComp?.accountUuid === userBaseCompany?.uuid) {
      setTransportSource(TransportSource.Own)
    } else if (selectedComp?.tags?.includes('isInterComp')) {
      setTransportSource(TransportSource.Intercompany)
    } else if (selectedComp?.tags?.includes('isInterBranch')) {
      setTransportSource(TransportSource.Interbranch)
    } else if (leg?.transportSource && !selectedComp) {
      setTransportSource(leg?.transportSource)
    } else {
      setTransportSource(TransportSource.Outsource)
    }
  }, [leg, selectedComp, userBaseCompany])

  const legTypeDisplay = useMemo(
    () =>
      [leg?.sequence, leg?.legStatus]
        ?.filter((elem: number | LegStatus | null | undefined) => elem)
        ?.join(' - '),
    [leg]
  )

  const isFirstTripAndFirstLeg = leg?.sequence === 1 && leg?.tripSequence === 1

  const initialValues = {
    transportUuid:
      leg?.transportUuid ||
      ((leg?.transportSource === TransportSource.Own || !leg?.transportSource) &&
        userBaseCompany?.company?.uuid) ||
      undefined,
    transportSource: transportSource,
    driverUuid:
      leg?.driverUuid ||
      leg?.driverEntity?.uuid ||
      driverPreference?.[0] ||
      selectSelectedDriver?.uuid,
    assistantUuids: leg?.assistantUuids || [],
    driverCode: leg?.driverCode || leg?.driverEntity?.code,
    driverName: leg?.driverName || leg?.driverEntity?.name,
    driverIc: leg?.driverIc || leg?.driverEntity?.registration,
    driverPhoneNo:
      leg?.driverPhoneNo ||
      leg?.driverEntity?.contact?.find(c => c?.type === ContactSimpleType.Primary || c?.number)
        ?.number,
    vehicleUuid: leg?.vehicleUuid || vehiclePreference?.[0] || selectSelectedVehicle?.uuid,
    vehicleCode: leg?.vehicleCode || leg?.vehicleEntity?.code,
    vehicleName: leg?.vehicleName || leg?.vehicleEntity?.registration,
    trailerUuid: leg?.trailerUuid || trailerPreference?.[0],
    trailerCode: leg?.trailerCode || leg?.trailerEntity?.code,
    trailerName: leg?.trailerName || leg?.trailerEntity?.registration,
    remarks: leg?.remarks,
    planStart:
      isFirstTripAndFirstLeg && leg.shipperRequiredDate ? dayjs(leg.shipperRequiredDate) : dayjs()
  }

  return (
    <Form form={form} layout="horizontal" initialValues={initialValues}>
      <Form.Item label={t('common.leg')} {...defaultFormItemLayout}>
        <Typography.Text type="secondary" strong>
          {legTypeDisplay}
        </Typography.Text>
      </Form.Item>

      <Form.Item
        label={t('common.transporter')}
        {...defaultFormItemLayout}
        name="transportUuid"
        rules={[
          {
            required: !!(!leg?.transportSource || leg?.transportSource === TransportSource.Own),
            message: t('validation.companyTransporterIsRequired')
          }
        ]}
      >
        <CompanySelect types={['transporter', 'haulier']} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item label={t('common.source')} {...defaultFormItemLayout} name="transportSource">
        <Typography.Text type="secondary" strong>
          {transportSource}
        </Typography.Text>
      </Form.Item>

      {transportSource !== TransportSource.Outsource && (
        <>
          <Form.Item
            label={t('common.driver')}
            {...defaultFormItemLayout}
            name="driverUuid"
            rules={[
              {
                required: transportSource === TransportSource.Own,
                message: t('validation.driverIsRequired')
              }
            ]}
          >
            <DriverSelector
              queryOnMount={true}
              style={{ width: '100%' }}
              setVehiclePreference={pref => {
                setVehiclePreference(pref)
                if (pref?.[0]) form.setFieldValue('vehicleUuid', pref[0])
              }}
            />
          </Form.Item>
          <Form.Item
            label={t('common.assistants')}
            {...defaultFormItemLayout}
            name="assistantUuids"
          >
            <DriverSelector mode="multiple" queryOnMount={true} style={{ width: '100%' }} />
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.driverCode')} {...defaultFormItemLayout} name="driverCode">
            <Input placeholder="Enter driver code..." />
          </Form.Item>
          <Form.Item
            label={t('common.driverName')}
            {...defaultFormItemLayout}
            name="driverName"
            rules={[
              {
                required: false,
                message: t('validation.driverNameIsRequired')
              }
            ]}
          >
            <Input placeholder={t('common.enterDriverName')} />
          </Form.Item>
          <Form.Item label={t('common.driverIc')} {...defaultFormItemLayout} name="driverIc">
            <Input placeholder={t('common.enterDriverIc')} maxLength={12} />
          </Form.Item>
          <Form.Item
            label={t('common.driverPhoneNo')}
            {...defaultFormItemLayout}
            name="driverPhoneNo"
          >
            <Input placeholder={t('common.enterDriverPhoneNo')} maxLength={15} />
          </Form.Item>
          <Form.Item
            label={t('common.assistants')}
            {...defaultFormItemLayout}
            name="assistantUuids"
          >
            <DriverSelector queryOnMount={true} style={{ width: '100%' }} mode="tags" />
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Outsource && (
        <Form.Item
          label={t('common.vehicle')}
          {...defaultFormItemLayout}
          name="vehicleUuid"
          rules={[
            {
              required: transportSource === TransportSource.Own,
              message: t('validation.vehicleIsRequired')
            }
          ]}
        >
          <VehicleSelector
            queryOnMount={true}
            style={{ width: '100%' }}
            setDriverPreference={setDriverPreference}
            setTrailerPreference={setTrailerPreference}
          />
        </Form.Item>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.vehicleCode')} {...defaultFormItemLayout} name="vehicleCode">
            <Input placeholder={t('common.enterVehicleCode')} />
          </Form.Item>

          <Form.Item
            label={t('common.vehicleReg')}
            {...defaultFormItemLayout}
            name="vehicleName"
            rules={[
              {
                required: false,
                message: t('validation.vehicleRegistrationIsRequired')
              }
            ]}
          >
            <Input placeholder={t('common.enterVehicleRegistration')} />
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Outsource && (
        <Form.Item label={t('common.trailer')} {...defaultFormItemLayout} name="trailerUuid">
          <TrailerSelector style={{ width: '100%' }} />
        </Form.Item>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.trailerCode')} {...defaultFormItemLayout} name="trailerCode">
            <Input placeholder={t('common.enterTrailerCode')} />
          </Form.Item>

          <Form.Item label={t('common.trailerReg')} {...defaultFormItemLayout} name="trailerName">
            <Input placeholder={t('common.enterTrailerRegistration')} />
          </Form.Item>
        </>
      )}

      <Form.Item label={t('common.remarks')} {...defaultFormItemLayout} name="remarks">
        <Input placeholder={t('common.enterRemarks')} />
      </Form.Item>

      {!leg?.planStart && (
        <Form.Item
          label={'Plan Start'}
          {...defaultFormItemLayout}
          name="planStart"
          rules={[{ required: !leg?.planStart, message: 'Plan Start timing is required' }]}
        >
          <DatePicker showTime placeholder={`${t('validation.selectDateTime')}`} />
        </Form.Item>
      )}
    </Form>
  )
}

export default memo(LegFormDetails)
