import { DocumentStatus } from '@/types/graphql'

import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Col } from 'antd'

import StatusIcon from '@/components/DocumentSelector/StatusIcon'
import { documentType } from '@/utils/labelMap'
import {
  Description,
  FileName,
  Header,
  Name,
  RemoveIcon,
  Selector,
  SelectorWrapper,
  StatusWrapper,
  Wrapper
} from './Styled'

type DocumentSelectorProps = {
  uploadedDoc
  removable?: boolean
  hrefUrl?: string
  onClick?: () => void
  onRemove?: () => void
}

const DocumentSelector = (props: DocumentSelectorProps) => {
  const { uploadedDoc, removable = false, hrefUrl } = props

  const isVerified = uploadedDoc.type === DocumentStatus.Verified
  const isDeleted = uploadedDoc.type === DocumentStatus.Deleted

  return (
    <Wrapper onClick={props.onClick}>
      {removable && <RemoveIcon type="close" onClick={props.onRemove} />}
      <SelectorWrapper>
        <Selector verified={isVerified} deleted={isDeleted} href={hrefUrl} target="_blank">
          <Header type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <Name>{documentType[uploadedDoc.type as string] || uploadedDoc.type}</Name>
            </Col>
          </Header>
          <Description>
            <Col span={20}>
              <FileName>
                {isDeleted ? <DeleteOutlined /> : <PaperClipOutlined />}
                {uploadedDoc.name}
              </FileName>
            </Col>
            <Col span={4}>
              <StatusWrapper>
                <StatusIcon uploadedDoc={uploadedDoc} />
              </StatusWrapper>
            </Col>
          </Description>
        </Selector>
      </SelectorWrapper>
    </Wrapper>
  )
}

export default DocumentSelector
