import { gql } from '@/types'

import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useLazyQuery } from '@apollo/client'
import { Flex, Spin } from 'antd-v5'
import { parse } from 'query-string'

import { FiltersView } from '@/components/Booking/Styled'
import LeftFilter from '@/components/Layouts/LeftFilter'
import NoAccess from '@/components/Shared/NoAccess'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const BOOKINGS_FILTERS_QUERY = gql(`
  query bookingsSideFilters($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      facets
    }
  }
`)

const SideFilters = memo(() => {
  const location = useLocation()
  const [input, setInput] = useState({})

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'booking',
    permission: 'index'
  })

  const [getBookingSearch, { loading, data, error }] = useLazyQuery(BOOKINGS_FILTERS_QUERY, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const fromURL = location.search.slice(1)
    const urlData: any = parse(fromURL)
    const query = {
      q: urlData?.q || '',
      sort: '',
      limit: 1,
      offset: 0,
      facets: ['status', 'isAssigned'],
      filter: urlData.filter ? JSON.parse(urlData.filter) : {}
    }
    setInput(query)

    getBookingSearch({ variables: { input: query } })
  }, [location])

  if (permLoading || loading) {
    return (
      <Flex justify="center" align="center" style={{ height: '50vh' }}>
        <Spin />
      </Flex>
    )
  }

  if (!hasPermission) return <NoAccess />

  if (error) {
    responseHandler(error, 'error')
    logger.error('SideFilter BOOKINGS_FILTERS_QUERY error', error)
  }

  return (
    <FiltersView>
      <LeftFilter customOverflow facets={data?.bookingsSearchJson?.facets} />
    </FiltersView>
  )
})

export default SideFilters
