import type { Booking, BookingDocument, Maybe, ProcessFlowDocumentType } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import type { UploadFile } from 'antd/lib/upload/interface'

import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import createSelectors from '@/store/createSelectors'

type BulkUploadDataSource = {
  key: string
  booking: Booking | undefined
  bookingNo: Maybe<string> | undefined
  file: UploadFile<any>
  document: any
  fileName: string
  status: 'UPLOADED' | 'APPROVED' | 'NOT STARTED' | 'FAILED' | 'READY'
  message: string
}

type BookingDocumentType = Partial<BookingDocument & { allowedApprovals: ProcessFlowDocumentType }>

type BookingState = {
  selectedBookingTypeCode: string | undefined
  setSelectedBookingTypeCode: (code: string) => void

  selectedBillToCompany: string | undefined
  setSelectedBillToCompany: (company: string) => void
  resetSelectedBillToCompany: () => void

  tripDynamicFields: any[]
  setTripDynamicFields: (fields: any) => void
  resetTripDynamicFields: () => void

  hideDeletedTrip: boolean
  setHideDeletedTrip: (check: boolean) => void

  hideDeletedJob: boolean
  setHideDeletedJob: (check: boolean) => void

  expandTrips: boolean
  setExpandTrips: (check: boolean) => void

  selectedFileBulkUpload: UploadFile<any>[]
  setSelectedFileBulkUpload: (files: UploadFile<any>[]) => void
  fileBulkUploadDataSource: BulkUploadDataSource[]
  setFileBulkUploadDataSource: (files: BulkUploadDataSource[]) => void

  bookingUuidForDocumentGenerator: string | undefined
  setBookingUuidForDocumentGenerator: (uuid: string) => void
  resetBookingUuidForDocumentGenerator: () => void

  documentGeneratorModalGeneratedDocument: any
  setDocumentGeneratorModalGeneratedDocument: (value: any) => void
  resetDocumentGeneratorModalGeneratedDocument: () => void

  documentGeneratorTableRefetch: () => any
  setDocumentGeneratorTableRefetch: (refetch: () => any) => void
  resetDocumentGeneratorTableRefetch: () => void

  documentGeneratorModalRefetch: () => any
  setDocumentGeneratorModalRefetch: (refetch: () => any) => void
  resetDocumentGeneratorModalRefetch: () => void

  documentGeneratorAssociatedBookingsForm: WrappedFormUtils | undefined
  setDocumentGeneratorAssociatedBookingsForm: (form: any) => void
  resetDocumentGeneratorAssociatedBookingsForm: () => void

  documentGeneratorDataForm: WrappedFormUtils | undefined
  setDocumentGeneratorDataForm: (form: any) => void
  resetDocumentGeneratorDataForm: () => void

  isBookingCollapse: boolean
  setIsBookingCollapse: (check: boolean) => void

  selectedBookingsTable: string[]
  setSelectedBookingsTable: (selected: string[]) => void
  clearSelectedBookingsTable: () => void

  openDocumentViewer: boolean
  setOpenDocumentViewer: (open?: boolean) => void

  selectedBookingDocument: BookingDocumentType | undefined
  setSelectedBookingDocument: (document: BookingDocumentType) => void
}

const nonPersistkey = [
  'selectedBillToCompany',
  'tripDynamicFields',
  'selectedFileBulkUpload',
  'fileBulkUploadDataSource',
  'documentGenerator',
  'isBookingCollapse'
]

export const useBookingStore = createSelectors(
  create<BookingState>()(
    persist(
      set => ({
        selectedBookingTypeCode: undefined,
        setSelectedBookingTypeCode: (code: string) => set({ selectedBookingTypeCode: code }),

        selectedBillToCompany: undefined,
        setSelectedBillToCompany: (company: string) => set({ selectedBillToCompany: company }),
        resetSelectedBillToCompany: () => set({ selectedBillToCompany: undefined }),

        tripDynamicFields: [],
        setTripDynamicFields: (fields: any) => set({ tripDynamicFields: fields }),
        resetTripDynamicFields: () => set({ tripDynamicFields: [] }),

        hideDeletedTrip: false,
        setHideDeletedTrip: () => set(state => ({ hideDeletedTrip: !state.hideDeletedTrip })),
        hideDeletedJob: false,
        setHideDeletedJob: () => set(state => ({ hideDeletedJob: !state.hideDeletedJob })),
        expandTrips: false,
        setExpandTrips: () => set(state => ({ expandTrips: !state.expandTrips })),

        selectedFileBulkUpload: [],
        setSelectedFileBulkUpload: files => {
          const dataSource: BulkUploadDataSource[] = files.map(file => ({
            file,
            key: file.uid,
            bookingNo: '-',
            booking: undefined,
            document: undefined,
            fileName: file.name,
            message: '-',
            status: 'NOT STARTED'
          }))

          set({ fileBulkUploadDataSource: dataSource })
          set({ selectedFileBulkUpload: files })
        },
        fileBulkUploadDataSource: [],
        setFileBulkUploadDataSource: files => set({ fileBulkUploadDataSource: files }),

        bookingUuidForDocumentGenerator: undefined,
        setBookingUuidForDocumentGenerator: uuid => set({ bookingUuidForDocumentGenerator: uuid }),
        resetBookingUuidForDocumentGenerator: () =>
          set({ bookingUuidForDocumentGenerator: undefined }),

        documentGeneratorTableRefetch: () => {},
        setDocumentGeneratorTableRefetch: refetch =>
          set({ documentGeneratorTableRefetch: refetch }),
        resetDocumentGeneratorTableRefetch: () => set({ documentGeneratorTableRefetch: () => {} }),

        documentGeneratorModalRefetch: () => {},
        setDocumentGeneratorModalRefetch: refetch =>
          set({ documentGeneratorModalRefetch: refetch }),
        resetDocumentGeneratorModalRefetch: () => set({ documentGeneratorModalRefetch: () => {} }),

        documentGeneratorModalGeneratedDocument: {},
        setDocumentGeneratorModalGeneratedDocument: value =>
          set({ documentGeneratorModalGeneratedDocument: value }),
        resetDocumentGeneratorModalGeneratedDocument: () =>
          set({ documentGeneratorModalGeneratedDocument: {} }),

        documentGeneratorAssociatedBookingsForm: undefined,
        setDocumentGeneratorAssociatedBookingsForm: form =>
          set({ documentGeneratorAssociatedBookingsForm: form }),
        resetDocumentGeneratorAssociatedBookingsForm: () =>
          set({ documentGeneratorAssociatedBookingsForm: undefined }),

        documentGeneratorDataForm: undefined,
        setDocumentGeneratorDataForm: form => set({ documentGeneratorDataForm: form }),
        resetDocumentGeneratorDataForm: () => set({ documentGeneratorDataForm: undefined }),

        isBookingCollapse: false,
        setIsBookingCollapse: () => set(state => ({ isBookingCollapse: !state.isBookingCollapse })),

        selectedBookingsTable: [],
        setSelectedBookingsTable: selectedBookingsTable => set({ selectedBookingsTable }),
        clearSelectedBookingsTable: () => set({ selectedBookingsTable: [] }),

        openDocumentViewer: false,
        setOpenDocumentViewer: open =>
          set(state => ({
            openDocumentViewer: open !== undefined ? open : !state.openDocumentViewer
          })),

        selectedBookingDocument: undefined,
        setSelectedBookingDocument: (document: Partial<BookingDocument>) =>
          set({ selectedBookingDocument: document })
      }),
      {
        name: 'bookings-storage',
        partialize: state =>
          Object.fromEntries(Object.entries(state).filter(([key]) => !nonPersistkey.includes(key)))
      }
    )
  )
)
