import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DownOutlined, PlusCircleOutlined, UpOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Form, Input } from 'antd'
import { Alert, Button, Modal } from 'antd-v5'
import startCase from 'lodash/startCase'
import styled from 'styled-components'

import LegFormDetails from '@/components/Transport/Components/Modals/JobActivityModal/LegFormDetails'
import AddPlanVehicleJobsTable from '@/components/Transport/Planning/Vehicle/AddPlanVehicleJobsTable'
import { PLAN_VEHICLE_JOBS_QUERY } from '@/components/Transport/Planning/Vehicle/schema'
import SelectedAddPlanVehicleJobsTable from '@/components/Transport/Planning/Vehicle/SelectedAddPlanVehicleJobsTable'
import useDebounce from '@/hooks/useDebounce'
import { useUnplannedStore } from '@/store/unplanned'

const LegContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

const Leg = styled.div`
  width: 48%;
  padding: 10px;
`

const JobsContainer = styled.div`
  border-width: 1px;
  border-color: black;
  padding: 0 10px 10px 10px;
  background-color: #eeeeee;
`

const JobsInput = styled.div`
  gap: 10;
  width: 100%;
  display: flex;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: flex-end;
`

const keys = [
  'bookingNo',
  'no',
  'jobNo',
  'references',
  'shipperRef',
  'consigneeRef',
  'bl',
  'houseBl'
]

interface AddPlanVehicleProps {
  isValidForUpdate?: boolean
  legUuids?: string[]
  defaultJobSearch?: string
  isMultiSelectMode?: boolean
  isSelectedTable?: string
  form: WrappedFormUtils
}

const AddPlanVehicle = (props: AddPlanVehicleProps) => {
  const {
    form,
    defaultJobSearch = '',
    isMultiSelectMode = false,
    legUuids = [],
    isValidForUpdate = true,
    isSelectedTable = ''
  } = props
  const { t } = useTranslation()

  const { selectedUnplannedLegs } = useUnplannedStore()

  const [formError, setFormError] = useState('')
  const [selectedRow, setSelectedRow] = useState<any>()
  const [activityObj, setActivityObj] = useState<any>()
  const [modalVisible, setModalVisible] = useState(false)
  const [formSuccessMsg, setFormSuccessMsg] = useState('')
  const [selectedJobs, setSelectedJobs] = useState<any>([])
  const [jobSearch, setJobSearch] = useState(defaultJobSearch)
  const [showJobSelectTable, setShowJobSelectTable] = useState(true)

  const debouncedSearch = useDebounce(jobSearch, 300) as string

  const [pageObj, setPageObj] = useState<any>({
    current: 1,
    pageSize: 30,
    onChange: (current: number) => setPageObj({ ...pageObj, current })
  })

  const [getJobs, { data: jobsData, loading: legsLoading }] = useLazyQuery(
    PLAN_VEHICLE_JOBS_QUERY,
    {
      variables: {
        input: {
          q: debouncedSearch,
          legUuids: legUuids,
          isSearchAllFields: true,
          limit: pageObj.pageSize,
          offset: pageObj.current - 1
        }
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (isMultiSelectMode) {
          return setPageObj({
            ...pageObj,
            total: selectedUnplannedLegs.length || 0
          })
        }

        if (legUuids.length) {
          setSelectedJobs(data?.transportJobs?.rows)
        }

        setPageObj({
          ...pageObj,
          pageSize: data?.transportJobs?.pageInfo?.limit,
          showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
          total: data?.transportJobs?.pageInfo?.count || 0
        })

        if (selectedJobs?.length) {
          const newSelected = selectedJobs.map(old => {
            const leg = data?.transportJobs?.rows?.find(leg => leg.legUuid === old.legUuid)
            const newLeg = leg || old
            return {
              ...newLeg,
              updateStatus:
                old.isUpdated && old.updateStatus !== 'Failed'
                  ? 'Updated'
                  : old.updateStatus
                    ? old.updateStatus
                    : 'Pending update'
            }
          })
          setSelectedJobs(newSelected || [])
        }
      }
    }
  )

  useEffect(() => {
    if (!selectedRow) return

    setActivityObj({
      planStart: selectedRow?.planStart || null,
      start: selectedRow?.start || null,
      startOut: selectedRow?.startOut || null,
      planEnd: selectedRow?.planEnd || null,
      end: selectedRow?.end || null,
      endOut: selectedRow?.endOut || null
    })
  }, [selectedRow])

  useEffect(() => {
    if (isMultiSelectMode) {
      setSelectedJobs(selectedUnplannedLegs)
    }
  }, [isMultiSelectMode, selectedUnplannedLegs])

  useEffect(() => {
    if (legUuids.length) {
      getJobs()
    }
  }, [getJobs, legUuids.length])

  const selectJobData = isMultiSelectMode ? selectedUnplannedLegs : jobsData

  const disableButton =
    !isValidForUpdate || (isMultiSelectMode && !selectedUnplannedLegs.length) || !isSelectedTable

  return (
    <>
      {isMultiSelectMode ? (
        <Button onClick={() => setModalVisible(true)} disabled={disableButton}>
          {t('transport.bulkUpdateLegsModal.editBulk')}{' '}
        </Button>
      ) : (
        <Button
          id="bulk-edit-legs-button"
          style={{ cursor: 'pointer' }}
          onClick={() => setModalVisible(true)}
          disabled={disableButton}
        >
          {disableButton
            ? t('transport.bulkUpdateLegsModal.invalidForBulkUpdate')
            : t('transport.planning.byVehicle.bulkUpdateLegs')}{' '}
          <PlusCircleOutlined />
        </Button>
      )}

      <Modal
        width={'90vw'}
        open={modalVisible}
        cancelText={t('common.close')}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        title={startCase(t('transport.planning.byVehicle.bulkUpdateLegs'))}
      >
        {formError && <Alert message={formError} type="error" style={{ fontSize: 12 }} />}

        {formSuccessMsg && (
          <Alert message={formSuccessMsg} type="success" style={{ fontSize: 12 }} />
        )}

        <LegContainer>
          <Leg>
            <LegFormDetails
              form={form}
              leg={selectedRow}
              setFormError={setFormError}
              setFormSuccessMsg={setFormSuccessMsg}
            />
          </Leg>
        </LegContainer>

        {!selectedUnplannedLegs.length && !legUuids.length && (
          <JobsContainer>
            <JobsInput>
              <Input
                defaultValue={defaultJobSearch}
                style={{ maxWidth: 500, marginRight: 5 }}
                onChange={e => setJobSearch(e.target.value)}
                placeholder={t('transport.planning.byVehicle.searchJob')}
                onPressEnter={() => {
                  if (!jobsData?.transportJobs?.rows?.length) return

                  if (jobsData.transportJobs.rows.length === 1) {
                    setSelectedJobs([...selectedJobs, jobsData?.transportJobs?.rows?.[0]])
                  } else {
                    const notSelected = jobsData.transportJobs.rows.filter((leg: any) => {
                      return selectedJobs.every((selected: any) => selected.legUuid !== leg.legUuid)
                    })

                    const match = notSelected?.find((leg: any) => {
                      return keys.some(
                        key => leg[key]?.toLowerCase() === debouncedSearch.toLowerCase()
                      )
                    })

                    if (match) setSelectedJobs([...selectedJobs, match])
                  }
                }}
              />
              {showJobSelectTable ? (
                <UpOutlined onClick={() => setShowJobSelectTable(!showJobSelectTable)} />
              ) : (
                <DownOutlined onClick={() => setShowJobSelectTable(!showJobSelectTable)} />
              )}
            </JobsInput>

            {showJobSelectTable && (
              <AddPlanVehicleJobsTable
                getJobs={getJobs}
                pageObj={pageObj}
                data={selectJobData}
                loading={legsLoading}
                selectedJobs={selectedJobs}
                jobSearch={debouncedSearch}
                setSelectedJobs={setSelectedJobs}
              />
            )}
          </JobsContainer>
        )}

        <SelectedAddPlanVehicleJobsTable
          form={form}
          getJobs={getJobs}
          activityObj={activityObj}
          selectedRow={selectedRow}
          selectedJobs={selectedJobs}
          setFormError={setFormError}
          setSelectedRow={setSelectedRow}
          setSelectedJobs={setSelectedJobs}
          setFormSuccessMsg={setFormSuccessMsg}
        />
      </Modal>
    </>
  )
}

export default Form.create<AddPlanVehicleProps>()(AddPlanVehicle)
