import { Layout } from 'antd-v5'
import styled from 'styled-components'

const { Content } = Layout

export const SingleLineTableContainer = styled(Content)`
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-top: 4px;

  .ant-table-small {
    box-shadow: none;
    border: none;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-wrapper {
    background: white;
    margin: 20px;
    margin-top: 0;
    padding: 10px 15px 0;
    box-shadow:
      0 2px 4px 0 rgba(35, 37, 51, 0.08),
      0 0 2px 0 rgba(35, 37, 51, 0.18);
    border-radius: 2px;

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 13.222px;
      font-weight: 400;
      color: #151b26;
      padding: 10px 8px;
      padding-left: 0;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 11.5px;
      font-weight: 500;
      color: #848f99;
      white-space: nowrap;
      padding: 8px 8px;
      padding-left: 0;
    }
  }
`

export const FiltersView = styled.div`
  width: 100%;
  padding-top: 0px;
  border-right: 1px solid rgb(235, 235, 235);
`
