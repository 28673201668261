import type { SelectProps } from 'antd/lib/select'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd'

import { BOOKING_COST_ITEMS } from '@/components/Select/BookingSelect/schema'

const { Option } = Select

type CostItemSelectProps = SelectProps & {
  bookingUuid: string
  autoSelect?: boolean
}

const CostItemSelect = (props: CostItemSelectProps) => {
  const {
    value,
    onChange,
    autoSelect = true,
    bookingUuid,
    placeholder = 'Select a cost item'
  } = props

  const { data, loading } = useQuery(BOOKING_COST_ITEMS, {
    variables: { uuid: bookingUuid }
  })

  useEffect(() => {
    const costItems = data?.booking?.costItems ?? []

    if (autoSelect && costItems.length === 1) onChange?.(costItems[0]?.uuid, costItems[0]?.uuid)
    if (costItems.length === 0 && value) onChange?.(null as any, null as any)
  }, [data, autoSelect])

  return (
    <Select {...props} loading={loading} placeholder={placeholder}>
      {data?.booking?.costItems?.map(costItem => (
        <Option key={costItem?.uuid} value={costItem?.uuid}>
          {[costItem?.chargeItem?.code, costItem?.chargeItem?.description, costItem?.sellRate]
            .filter(Boolean)
            .join(' - ')}
        </Option>
      ))}
    </Select>
  )
}

export default CostItemSelect
