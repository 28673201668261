import type { PropsWithChildren } from 'react'
import { ActiveStatus } from '@/types/graphql'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from 'antd-v5'

import DynamicComponentLoader from '@/components/dynamic-component-loader'
import Spinner from '@/components/Spinner'
import { GET_PLUGIN_LIST_TEST } from '@/pages/manage/plugins/schema'
import { PluginComponentLocation } from '@/plugins/options'

type PluginLoaderProps = {
  moduleLocations: PluginComponentLocation[]
  componentProps: Record<string, any>
  setEnabledPlugins?: (plugins: string[]) => void
  returnType?: string
  onPluginsNotFound?: () => void
}

const PluginLoader = (props: PropsWithChildren<PluginLoaderProps>) => {
  const { moduleLocations, componentProps, setEnabledPlugins, returnType, onPluginsNotFound } =
    props

  const { data, loading, error } = useQuery(GET_PLUGIN_LIST_TEST, {
    variables: {
      locations: moduleLocations,
      statuses: [ActiveStatus.Active]
    }
  })

  useEffect(() => {
    if (!data?.plugins?.rows?.length) return
    if (setEnabledPlugins) setEnabledPlugins(data.plugins.rows.map(plugin => plugin?.name || ''))
  }, [data, setEnabledPlugins])

  if (data?.plugins?.rows?.length === 0)
    return (
      <Typography.Text strong type="warning">
        {`No plugins found`}
      </Typography.Text>
    )

  if (error) {
    return (
      <Typography.Text strong type="warning">
        {`Error while loading plugins. ${error?.message}`}
      </Typography.Text>
    )
  }

  const renderPlugins = () => {
    if (loading) return returnType === 'array' ? [] : <Spinner />

    const plugins = data?.plugins?.rows || []

    if (!plugins.length) {
      onPluginsNotFound?.()
      return <></>
    }

    return plugins
      .map(plugin => {
        if (!plugin?.name) return null

        return (
          <DynamicComponentLoader
            key={plugin.id}
            plugin={plugin}
            componentName={plugin.data || ''}
            {...componentProps}
          />
        )
      })
      .filter(Boolean)
  }

  if (returnType === 'array') {
    return renderPlugins()
  }
  return <>{renderPlugins()}</>
}

export default PluginLoader
