import { DocumentStatus } from '@/types/graphql'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Alert, Button, Flex } from 'antd-v5'

import { StatusSummary } from '@/components/DocumentViewer/Content/Activities/Styled'
import { VERIFY_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import { useBookingStore } from '@/store/booking'
import { logger } from '@/utils/logger'
import notify from '@/utils/notify'

type DocumentVerifyViewProps = {
  bookingDocument: any
  refreshBooking: any
}

const DocumentVerifyView = (props: DocumentVerifyViewProps) => {
  const { bookingDocument, refreshBooking } = props

  const setOpenDocumentViewer = useBookingStore.use.setOpenDocumentViewer()
  const [verifyBookingDocumentMutation, { error, loading }] = useMutation(VERIFY_BOOKING_DOCUMENT, {
    variables: { uuid: bookingDocument.uuid }
  })

  const handleVerified = async () => {
    try {
      await verifyBookingDocumentMutation()
      await refreshBooking?.()
      setOpenDocumentViewer(false)
    } catch (error) {
      if (error instanceof Error) {
        logger.error('Verify document err:', error.message)
        notify(error.message, 'error')
      }
    }
  }

  if (bookingDocument.status === DocumentStatus.Verified) {
    return (
      <StatusSummary>
        <CheckCircleOutlined style={{ color: '#52c41a' }} /> Document has been verified.
      </StatusSummary>
    )
  } else if (bookingDocument.status === DocumentStatus.Deleted) {
    return (
      <StatusSummary>
        <CloseCircleOutlined style={{ color: '#f5222d' }} /> Document has been deleted.
      </StatusSummary>
    )
  }

  return (
    <Flex vertical gap={10}>
      {error && <Alert message={error.toString()} type="error" />}

      <Button
        className="w-full"
        size="large"
        type="primary"
        loading={loading}
        onClick={handleVerified}
      >
        {loading ? 'Hang on...' : 'Verify this document'}
      </Button>
    </Flex>
  )
}

export default DocumentVerifyView
