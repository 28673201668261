import type { BookingDocument } from '@/types/graphql'

import { useState } from 'react'
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd-v5'

type SelectableActionProps = {
  document: BookingDocument
  onSelectSingleDocument: (document: BookingDocument) => void
  isSelected: boolean
  selectable?: boolean
}

const SelectableAction = (props: SelectableActionProps) => {
  const { document, onSelectSingleDocument, isSelected, selectable } = props

  const [loading, setLoading] = useState(false)

  const handleSelect = async () => {
    setLoading(true)
    await onSelectSingleDocument(document)
    setLoading(false)
  }

  if (loading) return <LoadingOutlined />
  else if (isSelected) return <CheckCircleOutlined />
  else if (selectable)
    return (
      <Button type="primary" onClick={handleSelect}>
        Select
      </Button>
    )
  else return null
}

export default SelectableAction
