import type { TransportJob } from '@/types/graphql'

import { formatVehicle } from '@/components/Transport/Components/VehicleSelector'
import {
  getDriverName,
  getDriverNameFromObj
} from '@/components/Transport/Planning/Jobs/helpers/getTableAccessors'
import {
  formatLegTimings,
  getBkDetailsForPlanning,
  getBookingInfo
} from '@/components/Transport/Utils/jobHelper'

export const formatLegsData = (legs: Array<TransportJob | null>, vehicleCodeOrReg: string) => {
  const formattedLegs = legs
    ?.map((leg: TransportJob | null) => {
      const bookingInfo = leg && getBookingInfo(leg)
      const bookingPlanning = getBkDetailsForPlanning(leg)
      const legTimings = leg && formatLegTimings(leg)

      return {
        ...leg,
        PM: formatVehicle(leg, vehicleCodeOrReg),
        DR: getDriverName(leg),
        assistants: leg?.assistants?.map(a => getDriverNameFromObj(a)).join(', '),
        ...bookingInfo,
        ...bookingPlanning,
        ...legTimings
      }
    })
    ?.sort((a: any, b: any) => a.sequence - b.sequence)
    ?.sort((a: any, b: any) => a.tripSequence - b.tripSequence)
    ?.sort((a: any, b: any) => a.jobNo?.localeCompare(b.jobNo))

  return formattedLegs
}
