import styled from 'styled-components'

export const DocUsage = styled.span`
  float: right;

  i.anticon {
    margin-left: 2px;
  }
`

export const ApproveType = styled.p`
  font-size: 13.222px;
  font-weight: 600;
`
