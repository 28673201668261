import { Component } from 'react'
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row } from 'antd'
import { Button } from 'antd-v5'
import debounce from 'lodash/debounce'

import ImportExport from 'App/components/Shared/ImportExport'
import { ActionWrapper } from './Styled'

class TableViewActions extends Component {
  constructor(props) {
    super(props)

    this.handleSearch = debounce(this.handleSearch, 300)
    this.state = {
      keyword: ''
    }
  }

  handleInput = value => {
    this.setState({
      keyword: value
    })

    this.handleSearch(value)
  }

  handleSearch(value) {
    this.props.updateSearchKeyword(value)
    this.props.updatePageSize(0)
  }

  render() {
    const { keyword } = this.state
    const suffix = keyword ? <CloseCircleOutlined onClick={() => this.handleInput('')} /> : null

    return (
      <ActionWrapper>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12}>
            <Input
              value={this.state.keyword}
              prefix={<SearchOutlined />}
              suffix={suffix}
              placeholder="Search something here..."
              onChange={e => this.handleInput(e.target.value)}
            />
          </Col>
          <Col span={12}>
            <Row type="flex" justify="end">
              <Col span={6}>
                <ImportExport selectedEntity="employees" filter={this.props.filter} />
              </Col>
              <Col span={6}>
                <Button href="/manage/employees/create">
                  <PlusOutlined />
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </ActionWrapper>
    )
  }
}

export default TableViewActions
