import { EditOutlined } from '@ant-design/icons'
import { compose, withStateHandlers } from 'recompose'

import EditCostItemForm from '@/components/Voucher/CostItems/EditCostItemForm'
import { CostItemModal } from '@/components/Voucher/CostItems/Styled'
import { StyledAnchor } from '@/components/Voucher/Styled'

const stateHandlers = withStateHandlers(
  {
    visible: false
  },
  {
    handleClickEdit: () => () => ({
      visible: true
    }),
    handleOnVisibleChange: () => visible => ({
      visible
    }),
    handleOnCostItemEdited: (state, props) => newCostItemValues => {
      const { onCostItemEdited = () => {} } = props

      onCostItemEdited(newCostItemValues)

      return { visible: false }
    },
    handleOnCostItemDeleted: (state, props) => deletedCostItemValues => {
      const { onCostItemDeleted = () => {} } = props

      onCostItemDeleted(deletedCostItemValues)

      return { visible: false }
    }
  }
)

const enhance = compose(stateHandlers)

const EditCostItemAction = props => {
  const {
    index,
    visible,
    costItem,
    handleClickEdit,
    handleOnVisibleChange,
    handleOnCostItemEdited,
    handleOnCostItemDeleted
  } = props

  return (
    <>
      <StyledAnchor id={`edit-charge-item-${index}-anchor`} onClick={handleClickEdit}>
        <EditOutlined />
      </StyledAnchor>

      <CostItemModal
        width={600}
        title="Edit cost item"
        footer={null}
        open={visible}
        onCancel={() => handleOnVisibleChange(false)}
      >
        <EditCostItemForm
          costItem={costItem}
          onCostItemEdited={handleOnCostItemEdited}
          onCostItemDeleted={handleOnCostItemDeleted}
        />
      </CostItemModal>
    </>
  )
}

export default enhance(EditCostItemAction)
