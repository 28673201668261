import { gql } from '@apollo/client'

export const gqlRoles = gql`
  query employeeRoles($input: RolesQuery) {
    roles(input: $input) {
      rows {
        uuid
        name
        code
        status
        updatedAt
        createdAt
        editBy {
          name
          email
        }
        editByUuid
      }
    }
  }
`
