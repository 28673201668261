import type { DocumentCreatorTemplatesSelectorQuery } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Select, Typography } from 'antd-v5'
import debounce from 'lodash/debounce'

import { logger } from '@/utils/logger'

const DOCUMENT_CREATOR_TEMPLATES = gql(`
  query documentCreatorTemplatesSelector(
    $q: String
    $limit: Int
    $offset: Int
    $statuses: [DocumentCreatorTemplateStatus]
    $byCompany: UUID
  ) {
    documentCreatorTemplates(
      q: $q
      limit: $limit
      offset: $offset
      statuses: $statuses
      byCompany: $byCompany
    ) {
      rows {
        uuid
        type
        status
        template
        companies {
          uuid
          name
        }
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)

type DocumentCreatorTemplateSelectProps = SelectProps & {
  queryOnMount?: boolean
  byCompany?: string
  statuses?: string[]
  filter?: string
}

const DocumentCreatorTemplateSelect = (props: DocumentCreatorTemplateSelectProps) => {
  const {
    statuses = ['activated', 'deleted'],
    filter,
    queryOnMount = false,
    byCompany,
    ...selectProps
  } = props
  const { value, disabled = false } = selectProps

  const [templates, setTemplates] = useState<
    NonNullable<
      NonNullable<DocumentCreatorTemplatesSelectorQuery>['documentCreatorTemplates']
    >['rows']
  >([])
  const [searchInput, setSearchInput] = useState('')

  const [byCompanyFilter, setByCompanyFilter] = useState(byCompany)

  const params: any = useMemo(() => {
    let queryParams: any = {
      q: searchInput ? filter + ':' + searchInput : filter,
      statuses
    }

    if (byCompanyFilter) queryParams = { ...queryParams, byCompany: byCompanyFilter }

    return queryParams
  }, [value, searchInput, byCompanyFilter])

  const [getDocumentCreatorTemplates, { data, error, loading }] = useLazyQuery(
    DOCUMENT_CREATOR_TEMPLATES,
    {
      variables: { ...params }
    }
  )

  useEffect(() => {
    if (byCompanyFilter) {
      const variables = { q: undefined, statuses: undefined }

      getDocumentCreatorTemplates({ variables })
    }

    if (queryOnMount || value) getDocumentCreatorTemplates()
  }, [])

  useEffect(() => {
    const dataRows = data?.documentCreatorTemplates?.rows

    setTemplates(dataRows)

    if (!searchInput && byCompanyFilter && dataRows?.length === 0) setByCompanyFilter(undefined)
  }, [data])

  const handleSearch = useCallback(
    debounce((userInput: string) => {
      setSearchInput(userInput)
      getDocumentCreatorTemplates()
    }, 500),
    []
  )

  if (error) {
    logger.error('DocumentCreatorTemplateSelect DOCUMENT_CREATOR_TEMPLATES error', error)
    return <Typography.Text>Error while loading DocumentCreatorTemplateSelect.</Typography.Text>
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDocumentCreatorTemplates()
  }

  const templateOptions = templates?.map(t => ({ label: t?.type, value: t?.uuid }))

  return (
    <Select
      id="invoice-document-type-selector"
      {...selectProps}
      allowClear
      showSearch
      value={value}
      loading={loading}
      onFocus={fetchOnFocus}
      disabled={disabled}
      filterOption={false}
      placeholder="Select a template..."
      onSearch={userInput => handleSearch(userInput)}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      options={templateOptions}
    />
  )
}

export default memo(DocumentCreatorTemplateSelect)
