import type { SelectProps } from 'antd-v5'
import { AddressType } from '@/types/graphql'

import { Select } from 'antd-v5'
import { startCase } from 'lodash'

const AddressTypesSelect = (props: SelectProps) => {
  return (
    <Select
      showSearch
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={props.placeholder || 'Select address type'}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      options={Object.values(AddressType).map(type => ({
        label: startCase(type.toLowerCase()),
        value: type
      }))}
      {...props}
    />
  )
}

export default AddressTypesSelect
