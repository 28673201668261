import { gql } from '@/types'

const BOOKING_FOR_WORKFLOWS_SLIM_QUERY = gql(`
  query bookingForSchedulesSlim1($uuid: UUID, $no: String) {
    booking(uuid: $uuid, no: $no) {
      uuid
      no
      chronologies {
        uuid
        jobUuid
        tripUuid
        date
        type
        remarks
        reference
        updatedAt
        user {
          email
        }
      }
      requests {
        uuid
        type
        to
        status
        remarks
        createdAt
        from {
          uuid
          name
          email
        }
        meta {
          jobs
          attachments
        }
      }
      type
      bookingDocuments {
        uuid
        type
        reference
        remarks
        tags
        status
        url
        jobUuid
        tripUuid
        document {
          uuid
          name
          type
          size
        }
        approvals {
          uuid
          type
          status
          createdAt
          approvedBy {
            email
          }
        }
        createdBy {
          uuid
          email
        }
        verifiedBy {
          uuid
          email
        }
        createdAt
        updatedAt
      }
      vouchers {
        uuid
        type
        status
        voucherNumber
        total
        isCreditNote
        isJournalVoucher
        transactionType
      }
      jobs {
        bookingUuid
        type
        uuid
        no
        jobNo
        status
        type
        remarks
        details
        trips {
          details
          uuid
          type
          sequence
          status
          references
          remarks
          num
          from {
            name
          }
          to {
            name
          }
        }
      }
      processFlow {
        type
        sections {
          name
          type
          display
          bookingDocuments {
            type
            approvals
            required
            isJobRequired
            isTripRequired
          }
          vouchers {
            bookingDocuments
            approvals
            type
            transactionCategory
            transactionType
          }
          requests {
            type
          }
          steps {
            key
            helpText
            isComplete
            isCurrentStep
            permission {
              canApprove
              canReject
            }
          }
          chronologies {
            type
            isJobRequired
            isTripRequired
          }
        }
      }
      quotation {
        uuid
        quotationNo
      }
    }
  }
`)

export default BOOKING_FOR_WORKFLOWS_SLIM_QUERY
