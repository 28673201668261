import type { UploadFile, UploadProps } from 'antd-v5'

import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd-v5'

import styles from '@/components/Booking/DynamicField/UploadDocument/UploadDocument.module.css'
import WithUpload from '@/hocs/upload'

type UploadDocumentProps = {
  data?: File[]
  label?: string
  fieldKey: string
  isUploadable?: boolean
  setFieldsValue?: (values: { [key: string]: UploadFile[] }) => void
}

function UploadDocument({ fieldKey, setFieldsValue, isUploadable = true }: UploadDocumentProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
      setFieldsValue?.({ [fieldKey]: [...fileList, file] })
    },
    beforeUpload: file => {
      setFileList([...fileList, file])
      setFieldsValue?.({ [fieldKey]: [...fileList, file] })

      return false
    },
    fileList
  }

  return (
    <div className={styles.container}>
      {isUploadable && (
        <Upload {...props}>
          <Button>
            <UploadOutlined />
            Select File
          </Button>
        </Upload>
      )}
    </div>
  )
}

export default WithUpload(UploadDocument)
