import { gql } from '@apollo/client'

export const JOB_TYPES_QUERY = gql`
  query jobTypesMain($limit: Int, $offset: Int, $q: String, $statuses: [JobTypeStatus]) {
    jobTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        status
        sorting
        description
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const CREATE_JOB_TYPE_GQL = gql`
  mutation createJobType($input: UpdateJobTypeInput!) {
    createJobType(input: $input) {
      uuid
    }
  }
`

export const VIEW_JOB_TYPE_GQL = gql`
  query jobType1($uuid: UUID!) {
    jobType(uuid: $uuid) {
      code
      name
      status
      sorting
      defaultTripFormat
      companies {
        uuid
        name
      }
      tripDetails {
        type
        fromCompanyTypes
        toCompanyTypes
      }
      requiredFields {
        name
        control
      }
      computeType
      unitOptions {
        units
        values
      }
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
      tripDynamicFields {
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
    }
  }
`

export const UPDATE_JOB_TYPE_GQL = gql`
  mutation updateJobType($input: UpdateJobTypeInput!) {
    updateJobType(input: $input) {
      uuid
      name
      description
      sorting
      defaultTripFormat
      tripDetails {
        type
        fromCompanyTypes
        toCompanyTypes
      }
      requiredFields {
        name
        control
      }
      computeType
      unitOptions {
        units
        values
      }
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
      tripDynamicFields {
        key
        type
        query
        companyTypes
        control
        enumName
        customValues
        tags
      }
    }
  }
`

export const DELETE_JOB_TYPE_GQL = gql`
  mutation deleteJobType1($uuid: UUID!) {
    deleteJobType(uuid: $uuid) {
      success
    }
  }
`

export const UNDELETE_JOB_TYPE_GQL = gql`
  mutation undeleteJobType1($uuid: UUID!) {
    undeleteJobType(uuid: $uuid) {
      success
    }
  }
`
