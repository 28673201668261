import { useState } from 'react'

const WithSearchAndPagination = ({ children }) => {
  const [currentSize, updateSize] = useState(0)
  const [searchKeyword, updateSearchKeyword] = useState('')

  const returnProps = {
    updateSize,
    currentSize,
    searchKeyword,
    updateSearchKeyword
  }

  if (typeof children === 'function') return children(returnProps)
  return returnProps
}

export default WithSearchAndPagination
