import type { InputProps } from 'antd/lib/input'

import { forwardRef, useEffect, useState } from 'react'
import { Input, Tag } from 'antd'
import { identity } from 'lodash'
import uuidv4 from 'uuid/v4'

import './TagsInput.module.css'

type TagsInputProps = {
  value?: string[]
  onChange?: (value: string[]) => void
} & InputProps

type TagObject = {
  key: string
  value: string
}

/**
 * Use this component if you need to allow duplicate values in tag mode for the Ant Design Select component.
 *
 * Note: The default Ant Design Select component does not support duplicate values in tag mode.
 */
const TagsInput = forwardRef((props: TagsInputProps, ref: any) => {
  const { value, onChange, ...otherProps } = props
  const [text, setText] = useState('')
  const [tags, setTags] = useState<TagObject[]>([])

  const updateTags = (
    newTags: TagObject[],
    filterPrevStateTags: (prev: TagObject[]) => TagObject[] = identity
  ) => {
    setTags(prev => {
      const updateTags = [...filterPrevStateTags(prev), ...newTags]
      onChange?.(updateTags.map(t => t.value))
      return updateTags
    })
  }

  const handleTagsChange = (newTags: TagObject[], text: string = '') => {
    updateTags(newTags)
    setText(text)
  }

  const getTagObject = (value: string) => ({ key: uuidv4(), value })

  const convertToTags = (value: string, delimiter: string = ',') => {
    return value.split(delimiter).map(getTagObject)
  }

  const handlePressEnter = (e: any) => {
    const value: string = e.target.value //@ts-ignore
    const newTags = convertToTags(value)
    handleTagsChange(newTags)
  }

  const handleText = (e: any) => {
    const value: string = e.target.value
    setText(value)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace' && text === '') {
      updateTags([], prev => prev.slice(0, -1))
    }
  }

  const handleTagClose = (key: string) => {
    updateTags([], prev => prev.filter(t => t.key !== key))
  }

  useEffect(() => {
    if (!value?.length || tags.length) return

    const v = typeof value === 'string' ? [value] : value
    const initialTags = v.map(getTagObject) || []

    handleTagsChange(initialTags)
  }, [])

  return (
    <span className="tags-input-container">
      {tags.length ? (
        <span className="tags-container">
          {tags.map(t => (
            <Tag key={t.key} closable onClose={() => handleTagClose(t.key)}>
              {t.value}
            </Tag>
          ))}
        </span>
      ) : null}
      <Input
        ref={ref}
        allowClear
        {...otherProps}
        value={text}
        onChange={handleText}
        onPressEnter={handlePressEnter}
        onKeyDown={handleKeyDown}
      />
    </span>
  )
})

export default TagsInput
