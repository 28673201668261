import { CompanyType, PortalCompanyQueryType } from '@/types/graphql'

import { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Form } from 'antd'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { COMPANIES_QUERY_SLIM } from '@/graphql/company'

const FormItem = Form.Item

const VendorFilterFormItem = props => {
  const { form, initialCompanies, value } = props

  const [searchInput, setSearchInput] = useState(value)

  const { data, refetch } = useQuery(COMPANIES_QUERY_SLIM, {
    variables: {
      limit: 5,
      query: searchInput,
      portalCompanyQueryType: PortalCompanyQueryType.All,
      types: [
        CompanyType.Forwarder,
        CompanyType.FreightForwarder,
        CompanyType.Haulier,
        CompanyType.Transporter,
        CompanyType.Customs,
        CompanyType.ShippingAgent,
        CompanyType.Liner,
        CompanyType.Port,
        CompanyType.Depot,
        CompanyType.OneTimeVendor
      ]
    },
    fetchPolicy: 'network-only'
  })

  const handleSearch = useCallback(
    debounce(value => {
      setSearchInput(value)
      refetch()
    }, 500),
    []
  )

  const options = data?.companies?.rows?.map(r => {
    return {
      label: `${r?.code} - ${r?.name}`,
      value: r?.uuid
    }
  })

  return (
    <FormItem label="Vendor">
      {form.getFieldDecorator('vendor', {
        initialValue: initialCompanies
      })(
        <Select
          labelInValue
          mode="multiple"
          options={options}
          filterOption={false}
          notFoundContent="Not found"
          onSearch={debounce(handleSearch, 800)}
          placeholder="Search a company..."
        />
      )}
    </FormItem>
  )
}

export default VendorFilterFormItem
