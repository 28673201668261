import type { SelectProps } from 'antd-v5'

import { Select } from 'antd-v5'

const tags = [
  {
    label: 'Default',
    value: 'isDefault'
  }
]

const AddressTagsSelect = (props: SelectProps) => {
  return (
    <Select
      style={{ width: '100%' }}
      mode="tags"
      showSearch
      getPopupContainer={triggerNode => triggerNode.parentNode}
      options={tags}
      {...props}
    />
  )
}

export default AddressTagsSelect
