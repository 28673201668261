import { useState } from 'react'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import { Popconfirm, Popover } from 'antd-v5'

import RenderEditables from '@/components/Voucher/VoucherItems/RenderEditables'
import RenderPopover from '@/components/Voucher/VoucherItems/RenderPopover'
import { ActionWrapper, StyledAnchor, StyledButton } from '../Styled'

const VoucherItemActions = props => {
  const {
    form,
    booking,
    voucherItem,
    loadingShift,
    handleShiftUp: shiftUp,
    handleShiftDown: shiftDown,
    onRemoveVoucherItem,
    handleUpdateVoucherItem
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [showVoucherItem, setShowVoucherItem] = useState(false)

  const handleView = () => setShowVoucherItem(!showVoucherItem)

  const handleCancel = () => setShowVoucherItem(false)

  const handleShiftUp = async () => await shiftUp(voucherItem)

  const handleShiftDown = async () => await shiftDown(voucherItem)

  const handleRemove = async () => {
    setIsLoading(true)
    const success = await onRemoveVoucherItem(voucherItem)
    if (!success) setIsLoading(false)
  }

  return (
    <ActionWrapper>
      <Popover
        arrow={false}
        trigger="click"
        placement="right"
        destroyTooltipOnHide
        open={showVoucherItem}
        styles={{ root: { width: 400 } }}
        onOpenChange={visible => setShowVoucherItem(visible)}
        title={<RenderPopover key={voucherItem?.uuid} selectedVoucherItem={voucherItem} />}
        content={
          <RenderEditables
            key={voucherItem?.uuid}
            showVoucherItem={showVoucherItem}
            handleCancel={handleCancel}
            form={form}
            booking={booking}
            selectedVoucherItem={voucherItem}
            handleUpdateVoucherItem={handleUpdateVoucherItem}
          />
        }
      >
        <StyledAnchor onClick={handleView}>
          <EditOutlined />
        </StyledAnchor>
      </Popover>

      <Popconfirm
        arrow={false}
        okText="Confirm"
        placement="topRight"
        destroyTooltipOnHide
        onConfirm={handleRemove}
        title="All changes to this item will be lost, confirm remove ?"
      >
        <StyledAnchor>{isLoading ? <LoadingOutlined /> : <DeleteOutlined />}</StyledAnchor>
      </Popconfirm>

      <StyledButton icon={<ArrowUpOutlined />} onClick={handleShiftUp} disabled={loadingShift} />

      <StyledButton
        icon={<ArrowDownOutlined />}
        onClick={handleShiftDown}
        disabled={loadingShift}
      />
    </ActionWrapper>
  )
}

export default VoucherItemActions
