import type { GeneratedDocumentQuery } from '@/types/graphql'

import ResourceViewer from '@/components/ResourceViewer'
import config from '@/config'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

interface DocumentViewerProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
  renderRaw: boolean
}

const DocumentViewer = ({ generatedDocument, renderRaw }: DocumentViewerProps) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const url = `${config.documentGenerator.baseUrl}/preview?document=${generatedDocument?.uuid}&rawHtml=${renderRaw}`

  return (
    <div style={{ height: 550, border: 'solid 1px lightgray' }}>
      <ResourceViewer
        url={url}
        mimeType="text/html"
        fetchOptions={{
          method: 'GET',
          headers: {
            authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
            'base-company-uuid': selectedGlobalCompany.uuid
          }
        }}
      />
    </div>
  )
}

export default DocumentViewer
