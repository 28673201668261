import { useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { Button, Flex, InputNumber, Popover } from 'antd-v5'

type DuplicateButtonProps = {
  handleDuplicate: (index, duplicateNumber) => void
  index: number
}

const DuplicateButton = ({ handleDuplicate = () => {}, index }: DuplicateButtonProps) => {
  const [duplicateNumber, setDuplicateNumber] = useState(1)

  const updateDuplicateNumber = (value: number | null) => {
    if (value) {
      setDuplicateNumber(value)
    }
  }

  const content = (
    <Flex vertical gap={'small'}>
      <InputNumber defaultValue={duplicateNumber} onChange={updateDuplicateNumber} />
      <Button onClick={() => handleDuplicate(index, duplicateNumber)}>Duplicate</Button>
    </Flex>
  )

  return (
    <Popover arrow={false} trigger="click" content={content}>
      <Button icon={<CopyOutlined />} />
    </Popover>
  )
}

export default DuplicateButton
