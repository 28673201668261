import { gql } from '@/types'

export const BOOKING_TRANSPORT_JOBS_QUERY = gql(`
  query transportJobTrips($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        jobUuid
        tripUuid
        legUuid
        jobType
        tripType
        tripSequence
        tripStatus
        legStatus
        sequence
        jobNo
        shipperRequiredDate
        planStart
        start
        startOut
        planEnd
        end
        endOut
        driverCode
        driverName
        driverUuid
        vehicleCode
        vehicleName
        vehicleUuid
        assistants{
          code
          name
        }
        transportSource
        remarks
      }
    }
  }
`)
