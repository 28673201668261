import type { FormItemConfig } from '@/components/Manage/FormItemMapper'
import type { DynamicField, Job, JobType, TransportJob } from '@/types/graphql'
import { DynamicFieldControl, DynamicFieldTag, DynamicFieldType } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import startCase from 'lodash/startCase'
import moment from 'moment'

import FormItemMapper, { defaultFormItemLayout } from '@/components/Manage/FormItemMapper'
import DynamicFieldComponent, { DATE_FORMAT } from '@/components/Shared/DynamicFieldComponent'
import useJobTypes from '@/hooks/useJobTypes'

interface JobFormDetailsProps {
  job: Job
  form: any
  value: any
  leg: TransportJob
  editMode: boolean
}

const JobFormDetails = (props: JobFormDetailsProps) => {
  const { form, job, leg, editMode = false, value = {} } = props
  const { getFieldDecorator } = form

  const [jobType, setJobType] = useState<JobType>()
  const { jobTypes, requestJobTypes } = useJobTypes()

  const generateFormItemProps = useCallback(
    (field: DynamicField, details: Record<string, any>, type: 'job' | 'trip') => {
      const key = field.key || ''
      const val = details?.[key]

      return {
        label: startCase(key),
        value: `${type}.details.${key}`,
        input: <DynamicFieldComponent id={key} field={field} disabled={!editMode} />,
        attributes: {
          initialValue:
            field.type === DynamicFieldType.Date ? (val ? moment(val, DATE_FORMAT) : null) : val,
          rules: [
            {
              required: DynamicFieldControl.Required === field?.control,
              message: `${field.key} is required`
            }
          ]
        },
        ...defaultFormItemLayout
      }
    },
    [editMode, value]
  )

  const cols = useMemo(() => {
    if (job && !job.details) job.details = {}
    const jobFields = (jobType?.dynamicFields ?? [])
      .filter(df => df?.tags?.includes(DynamicFieldTag.IsOperationsUpdateable))
      .map((field: DynamicField | null) => {
        if (field) return generateFormItemProps(field, job?.details, 'job')
        return null
      })

    const trip = job.trips?.find(trip => trip?.uuid === leg.tripUuid)

    if (trip && !trip.details) trip.details = {}

    const tripFields = (jobType?.tripDynamicFields ?? [])
      .filter(df => df?.tags?.includes(DynamicFieldTag.IsOperationsUpdateable))
      .map((field: DynamicField | null) => {
        if (field) return generateFormItemProps(field, trip?.details, 'trip')
        return null
      })

    return {
      jobFields,
      tripFields
    }
  }, [jobType, job, editMode, leg?.tripUuid, value])

  useEffect(() => {
    setJobType(jobTypes?.[0])
  }, [jobTypes])

  useEffect(() => {
    if (!job.type || jobType?.code === job.type) return
    requestJobTypes({ codes: [job.type] })
  }, [job.type, jobType])

  return (
    <>
      <FormItemMapper
        fields={[{ cols: cols.jobFields as FormItemConfig[] }]}
        headerTitle={'Job Details'}
        getFieldDecorator={getFieldDecorator}
      />

      <FormItemMapper
        fields={[{ cols: cols.tripFields as FormItemConfig[] }]}
        headerTitle={'Trip Details'}
        getFieldDecorator={getFieldDecorator}
      />
    </>
  )
}

export default memo(JobFormDetails)
