import { useEffect, useMemo, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import { logger } from 'App/utils/logger'

type VariablesType = {
  key: string
}

type OptionsType = {
  variables: VariablesType
  fetchPolicy: any
}

export const DEPARTMENTS_SETTING = `
  query settingDepartmentsPlanningFilter ($key: String) {
    setting (key: $key) {
      key
      setting { value type }
    }
  }
`

export function useBaseCompanySetting(queryString: string, options: OptionsType) {
  const BASE_COMPANY_SETTING = useMemo(() => {
    if (queryString) {
      return gql`
        ${queryString}
      `
    }
  }, [queryString])

  const [setting, setSetting] = useState<any>({})

  // @ts-ignore
  const [getSetting, { loading, data, error }] = useLazyQuery(BASE_COMPANY_SETTING, options)

  useEffect(() => {
    if (queryString && options?.variables?.key) {
      getSetting({ variables: options.variables })
    }
  }, [queryString])

  useEffect(() => {
    if (setSetting) {
      setSetting(data?.setting?.setting)
    }
  }, [data])

  if (error) {
    logger.error(
      `useBaseCompanySetting ${options?.variables?.key} BASE_COMPANY_SETTING error`,
      error
    )
  }

  return { setting, loading, data, error, getSetting }
}
