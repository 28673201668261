import styled from 'styled-components'

export const PermissionName = styled.div`
  font-size: 11.5px;
  font-weight: 600;
  text-transform: uppercase;
  color: #151b26;
`

export const PermissionItem = styled.div`
  width: 100px;

  .fa,
  .fas {
    font-size: 16px;
    color: rgb(145, 147, 149);
    cursor: pointer;

    &:hover {
      color: #151b26;
    }
  }

  .anticon-spin {
    color: rgb(160, 160, 160);
    animation: loadingCircle 0.7s infinite linear;
  }
`

export const NotAvailable = styled.span`
  font-size: 11.222px;
  color: rgb(160, 160, 160);
`
