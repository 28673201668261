import { Button, Modal } from 'antd-v5'

import JobModalContent from '@/components/Workflow/JobModal/JobModalContent'

const JobModal = ({ visible, job, onClose, onChange }) => {
  return (
    <Modal
      title="Job"
      width={'97%'}
      destroyOnClose
      open={visible}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Close
        </Button>
      ]}
      onCancel={onClose}
      onOk={onClose}
    >
      <JobModalContent job={job} onChange={onChange} />
    </Modal>
  )
}

export default JobModal
