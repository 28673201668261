import type { Job } from '@/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'

const Option = Select.Option

type TransportJobSelectorProps = {
  value?: any
  onChange?: any
  mode?: string | undefined | null
  bookingUuids?: Array<string>
  jobUuids?: Array<string | undefined>
  queryOnMount?: boolean
  style?: any
}

export const JOBS_SELECTOR_QUERY = gql`
  query jobsSelectEs($input: JobsSearchInput) {
    jobsSearchJson(input: $input) {
      rows
    }
  }
`

const TransportJobSelector = forwardRef((props: TransportJobSelectorProps, ref: any) => {
  const { value, onChange, mode, bookingUuids, jobUuids, queryOnMount = false, style } = props

  const [jobs, setJobs] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(
    () => ({
      q: (!mode && !isUuid(value) && value) || searchInput || '',
      filter: {
        jobUuids:
          !searchInput && jobUuids?.filter(Boolean)?.length ? jobUuids?.filter(Boolean) : null,
        bookingUuid: bookingUuids?.filter(Boolean)?.length ? bookingUuids?.filter(Boolean) : null
      },
      limit: 20
    }),
    [mode, jobUuids, value, searchInput, bookingUuids]
  )

  const [getJobs, { data, error, loading }] = useLazyQuery(JOBS_SELECTOR_QUERY, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInput(value)
      getJobs()
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount || value) {
      getJobs()
    }
  }, [queryOnMount, value, getJobs])

  useEffect(() => {
    if (setJobs) {
      setJobs(data?.jobsSearchJson?.rows)
    }
  }, [data])

  if (error) {
    logger.error('TransportJobSelector TRANSPORT_JOBS_SELECTOR_QUERY error', error)
    responseHandler(error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getJobs()
  }

  const jobOptions = jobs?.map((job: Job) => {
    return {
      value: job.uuid,
      label: [job.jobNo, job.no].filter(Boolean).join(' - ')
    }
  })
  return (
    <Select
      id={'jobUuid-selector'}
      allowClear
      filterOption={false}
      loading={loading}
      // @ts-ignore
      mode={mode}
      onFocus={fetchOnFocus}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      onChange={onChange}
      onSearch={handleSearch}
      placeholder="Search a job here..."
      ref={ref}
      showSearch
      value={value}
      style={style}
      options={jobOptions}
    />
  )
})

export default memo(TransportJobSelector)
