import { browserTracingIntegration, replayIntegration } from '@sentry/react'

const docUrl = process.env.REACT_APP_DOC_URL || 'http://localhost:8089'
const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:8080'
const gatewayUrl = process.env.REACT_APP_GATEWAY_URL || 'http://localhost:8000'
const pdfBaseUrl = process.env.REACT_APP_PDF_BASE_URL || 'http://localhost:8555'
const entityAppUrl = process.env.REACT_APP_ENTITY_APP_URL || 'http://localhost:3100'
const transportAppUrl = process.env.REACT_APP_TRANSPORT_APP_URL || 'http://localhost:3799'
const reportAppUrl = process.env.REACT_APP_REPORT_APP_URL || 'http://localhost:8088'
const documentGeneratorAppUrl =
  process.env.REACT_APP_DOCUMENT_GENERATOR_URL || 'http://localhost:8092'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY || ''

const env = {
  name: process.env.NODE_ENV || 'development',

  dev: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || false,
  test: (process.env.NODE_ENV && process.env.NODE_ENV === 'test') || false,
  stag: (process.env.NODE_ENV && process.env.NODE_ENV === 'staging') || false,
  prod: (process.env.NODE_ENV && process.env.NODE_ENV === 'production') || false,

  development: !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || false,
  staging: (process.env.NODE_ENV && process.env.NODE_ENV === 'staging') || false,
  production: (process.env.NODE_ENV && process.env.NODE_ENV === 'production') || false
}

const config = {
  env,
  gateway: {
    baseUrl: gatewayUrl,
    graphqlUrl: `${gatewayUrl}/graphql`
  },
  api: {
    baseUrl
  },
  pdf: {
    baseUrl: pdfBaseUrl
  },
  entity: {
    appUrl: entityAppUrl
  },
  transport: {
    appUrl: transportAppUrl
  },
  report: {
    baseUrl: reportAppUrl
  },
  doc: {
    docUrl
  },
  documentGenerator: {
    baseUrl: documentGeneratorAppUrl
  },
  version: process.env.REACT_APP_NPM_PACKAGE_VERSION,
  mapbox: {
    token: process.env.REACT_APP_MAPBOX_API_TOKEN
  },
  sentry: {
    enable: (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') || false,
    dsn:
      process.env.REACT_APP_SENTRY_DSN ||
      'https://be4717bfbc624fc78fc0a886d70de157@sentry.io/1205667',
    release: `${process.env.REACT_APP_NPM_PACKAGE_NAME}@${process.env.REACT_APP_NPM_PACKAGE_VERSION}`,
    environment: `universal-${env.name}-${process.env.NODE_ENV}`,
    integrations: [
      browserTracingIntegration({
        tracingOrigins: ['localhost', /^\//]
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false
      })
    ],
    tracesSampleRate: env.production ? 0.2 : 1.0,
    tracePropagationTargets: [/^https:\/\/([^.]+(\.shipx\.cc)|localhost)/],
    replaysSessionSampleRate: env.production ? 0.1 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    attachStacktrace: true,
    normalizeDepth: 10,
    ignoreErrors: [
      // Network errors
      /epipe/i,
      /econnreset/i,
      'Failed to fetch',
      'NetworkError',
      'Network request failed',
      /^request to http:\/\/shipx/,
      // Auth errors
      /s\.subscribe is not a function/i,
      /^GraphQL error: Not Authorised!/,
      /^GraphQL error: You don't have access to:/,
      // Server errors that don't need tracking
      /^GraphQL error: 500: Internal Server Error/,
      /^GraphQL error: request to.*failed$/,
      // Navigation/Browser errors
      /non-error promise rejection captured/i,
      /resizeobserver loop limit exceeded/i,
      /reason:\s*read\s+econnreset/i,
      /reason:\s*read\s+econnrefused/i,
      /error:\s*write\s*econnreset/i,
      /you'll need to be logged in to execute this query\./i,
      /apolloerror: request to http:\/\/shipx-api-stag\.default\.svc\.cluster\.local\/graphql failed, reason: read econnreset/i,
      /apolloerror: duplicated incentive and type found\. set the "overrideduplicateleg" flag\. duplicate incentive found in shiftuuid:/i
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i
    ],
    beforeSend(event) {
      // Don't send events for ignored paths
      if (event.request?.url?.match(/(sockjs-node|hot-update)/)) {
        return null
      }
      return event
    }
  },
  keen: {
    projectId: process.env.REACT_APP_KEEN_PROJECT_ID || '57692d33bcb79c1185927734',
    writeKey:
      process.env.REACT_APP_KEEN_WRITE_KEY ||
      'f80e7721c20fb535ffaa1a1fa4cc59f9472b4cf7793f6ac5641e99591ad90bec98a3b0396cabb47e6f216025693c4c18c3e45795556e53c38ac0ef4b41c82e5ff4b20fe8c79a1bc375cd3d6a242bb5c67a11606ef1a0c6bfc08f8665c38c497d',
    readKey:
      process.env.REACT_APP_KEEN_READ_KEY ||
      '8a57ffbf5e28a03287c1d78dd019baec1933b13f37fc8924a99c26285b54322597dc1f7744995344d4468edb0cbcbc9bebf0ded0df8352288717d529cadb326cfe638a396606383ae29e7f2f6a457f70331b9eecb522deda29fb02af6b2fe3ba'
  },
  auth0: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'q2pPmETALii9d4IwCPTAKL8VrrDNFGkJ',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'yoozrr.auth0.com',
    logOutAfterDays: +(process.env.REACT_APP_LOG_OUT_AFTER_DAYS || 0)
  },
  google: {
    mapsAPIKey: googleMapsApiKey,
    mapsPlaceUrl: 'https://www.google.com/maps/place/?q=place_id:'
  },
  whatsapp: {
    apiLinkFront: 'https://api.whatsapp.com/send?text='
  },
  s3: {
    url: `https://s3.${process.env.REACT_APP_AWS_REGION || 'ap-southeast-1'}.amazonaws.com/${
      process.env.REACT_APP_AWS_S3_BUCKET || 'development.shipx.cc'
    }`
  },
  youDoNotHavePermission: 'You do not have permission.',
  youDoNotHavePermissionToViewThis: 'You do not have permission to view this.',
  youDoNotHavePermissionToPerformThisAction: 'You do not have permission to perform this action.',
  anErrorOccurredPleaseTryAgainLater: 'An error occurred, please try again later.',
  playWright: {
    baseUrl: process.env.REACT_APP_FRONTEND_URL || '',
    botEmail: process.env.REACT_APP_PLAYWRIGHT_BOT_EMAIL || '',
    botPassword: process.env.REACT_APP_PLAYWRIGHT_BOT_PASSWORD || '',
    baseCompany:
      process.env.REACT_APP_FRONTEND_URL === 'https://staging.shipx.cc'
        ? 'Another Base Company Testing'
        : 'Another Base Company'
  }
}

export default config
