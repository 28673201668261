import type { Booking } from '@/types/graphql'

import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex, List, Popover, Typography } from 'antd-v5'

import BookingSelect from 'App/components/Select/BookingSelect'
import { getJobNo } from 'App/components/Transport/Utils/jobHelper'
import { SearchControl } from './Styled'

type VoucherBookingSearchProps = {
  handleSelect: (value: any) => Promise<void>
  voucherBookings: Booking[]
}

const VoucherBookingSearch = (props: VoucherBookingSearchProps) => {
  const { handleSelect, voucherBookings } = props

  const [visible, setVisible] = useState(false)

  const handleSelectBooking = async value => {
    await handleSelect(value)
  }

  const renderSearchSelect = () => {
    return (
      <SearchControl>
        <BookingSelect onChange={handleSelectBooking} />
      </SearchControl>
    )
  }

  const renderContent = (voucherBookings: Booking[] = []) => {
    return (
      <Flex vertical className="overflow-y-auto" style={{ maxHeight: '420px' }}>
        <Typography.Text strong type="secondary">
          Associated bookings :
        </Typography.Text>
        <List size="small">
          {voucherBookings.map(booking => (
            <List.Item key={booking.uuid}>{getJobNo(booking)}</List.Item>
          ))}
        </List>
      </Flex>
    )
  }

  return (
    <Popover
      arrow={false}
      title={renderSearchSelect()}
      trigger="click"
      open={visible}
      content={renderContent(voucherBookings)}
      placement="right"
      onOpenChange={visible => setVisible(visible)}
    >
      <Button shape="circle" size="small" icon={<PlusOutlined />} />
    </Popover>
  )
}

export default VoucherBookingSearch
