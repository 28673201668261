import { Flex } from 'antd-v5'
import filter from 'lodash/filter'
import find from 'lodash/find'
import indexOf from 'lodash/indexOf'
import sortBy from 'lodash/sortBy'

import { NoDocument } from '@/components/Workflow/Styled'
import UploadedItem from '@/components/Workflow/Uploaded/UploadedItems/UploadedItem'

const validVoucherStatus = ['DRAFT', 'SUBMITTED', 'APPROVED', 'PARTIALLY_PAID', 'PAID']

type UploadedItemsProps = {
  section: any
  booking: any
  showDeleted: boolean
  uploadedDocs?: any[]
  highlightedDocuments?: any[]
  refetchBooking?: () => void
}

const UploadedItems = (props: UploadedItemsProps) => {
  const {
    section,
    booking,
    showDeleted,
    uploadedDocs = [],
    highlightedDocuments,
    refetchBooking
  } = props

  const sortedDocs = sortBy(uploadedDocs, uploadedDoc =>
    indexOf(section.bookingDocuments, doc => uploadedDoc.type === doc.type)
  )

  const nonDeletedDocs =
    filter(sortedDocs, doc => {
      return doc.status !== 'DELETED' || null
    }) || []

  const displayDocs = showDeleted ? sortedDocs : nonDeletedDocs

  if (displayDocs.length === 0) {
    return (
      <div className="align-top">
        <NoDocument>No documents uploaded</NoDocument>
      </div>
    )
  }

  const sequence = booking?.jobs?.map(job =>
    job.trips?.map(trip => {
      return {
        jobUuid: job.uuid,
        sequence: trip.sequence,
        tripUuid: trip.uuid
      }
    })
  )

  const flatSequence = sequence?.reduce((acc, val) => acc.concat(val), [])

  const tripSequenceMap = flatSequence?.reduce((map, trip) => {
    map[trip?.tripUuid] = trip?.sequence
    return map
  }, {})

  const sortedDisplayDocs = displayDocs?.sort((a, b) => {
    if (a.jobUuid !== b.jobUuid) {
      return a?.jobUuid?.localeCompare(b.jobUuid)
    }
    const sequenceA = tripSequenceMap[a.tripUuid]
    const sequenceB = tripSequenceMap[b.tripUuid]
    return sequenceA - sequenceB
  })

  const orderedDocs = sortBy(sortedDisplayDocs, doc => {
    return doc.status === 'VERIFIED'
  })

  return (
    <Flex gap={10} wrap="wrap">
      {orderedDocs.map(doc => {
        const documentApproval = find(section.bookingDocuments, bDoc => bDoc.type === doc.type)
        const isHighlighted = find(highlightedDocuments, hd => hd.type === doc.type)

        const documentVouchers = booking.vouchers
          ? booking.vouchers.filter(
              voucher =>
                validVoucherStatus.includes(voucher.status) &&
                voucher.bookingDocuments?.find(bd => bd.uuid === doc.uuid)
            )
          : []

        const documentRequests = booking.requests
          ? booking.requests.filter(request => request.meta?.attachments?.find(a => a === doc.uuid))
          : []

        return (
          <UploadedItem
            key={doc.uuid}
            refetchBooking={refetchBooking}
            uploadedDoc={doc}
            vouchers={documentVouchers}
            requests={documentRequests}
            isHighlighted={isHighlighted}
            documentApproval={documentApproval}
          />
        )
      })}
    </Flex>
  )
}

export default UploadedItems
