import { gql } from '@/types'

import { useQuery } from '@apollo/client'

import notify from '@/utils/notify'

export type useFeatureFlagProps = {
  name: string
  baseCompanyUuid?: string
}

const IS_FEATURE_FLAGGED = gql(`
  query isFeatureFlagEnabledHook($name: String, $baseCompanyUuid: UUID) {
    isFeatureFlagEnabled(name: $name, baseCompanyUuid: $baseCompanyUuid)
  }
`)

const useFeatureFlag = ({ name, baseCompanyUuid }: useFeatureFlagProps) => {
  const { loading, data, error, refetch } = useQuery(IS_FEATURE_FLAGGED, {
    variables: { name, baseCompanyUuid },
    fetchPolicy: 'cache-first'
  })

  if (
    error &&
    // @ts-expect-error: statusCode is not in the type
    error?.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 403 &&
    error?.message !== 'Failed to fetch'
  ) {
    notify(
      `useFeatureFlag Error name='${name}'; Please contact IT to check if the feature flag is turned on and works.`,
      'warning'
    )
  }

  return {
    error,
    loading,
    refetch,
    isEnabled: !!data?.isFeatureFlagEnabled
  }
}

export default useFeatureFlag
