import { memo } from 'react'
import { Typography } from 'antd-v5'

import { getContainerStats } from '@/utils/booking'

const getShipperConsignee = booking => {
  return [
    booking?.type === 'IMPORT' ? booking?.details?.consignee?.name : null,
    booking?.type === 'EXPORT' ? booking?.details?.shipper?.name : null
  ]
    .filter(Boolean)
    ?.join(', ')
}

const Title = ({ booking }) => {
  const bookingNo = booking?.no

  const activeJobs = booking?.jobs?.filter(j => j.status !== 'CANCELLED')

  const containerStats = getContainerStats(activeJobs)
    ?.map(s => `${s.count}x${s.size} ${s.type}`)
    ?.join(', ')

  const shipperConsigneeString = getShipperConsignee(booking) || ''

  const title = `${bookingNo} - (${booking?.type || booking?.details?.shipmentType || 'NA'}) ${shipperConsigneeString ? `[${shipperConsigneeString}]` : ''}${containerStats ? ` - ${containerStats}` : ''}`

  return (
    <div className="flex flex-col">
      <Typography.Text style={{ color: 'black' }}>{title}</Typography.Text>
      <Typography.Text style={{ color: 'gray', fontSize: 12 }}>{booking.status}</Typography.Text>
    </div>
  )
}

export default memo(Title)
