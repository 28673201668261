import type { RadioChangeEvent } from 'antd-v5'
import type { ColumnsType } from 'antd-v5/es/table'

import { useDispatch, useSelector } from 'react-redux'
import { Col, Radio, Row, Table } from 'antd-v5'
import findIndex from 'lodash/findIndex'

import AddCostItemAction from '@/components/Voucher/CostItems/AddCostItemAction'
import { StyledRadio } from '@/components/Voucher/CostItems/Styled'
import SummaryItem from '@/components/Voucher/CostItems/SummaryItem'
import { NoItemsFound, SectionTitle, Summary, TableWrapper } from '@/components/Voucher/Styled'
import { Brief } from '@/components/Workflow/Styled'
import { updateCostItemsDeletedView, updateCostItemsView } from '@/states/reducers/voucher'

const { SummaryWrapper } = Summary

type CostItemsTableViewProps = {
  compact?: boolean
  columns: ColumnsType<any>
  dataSource: any[]
  voucherBooking: any
  voucherBookingCostItems: any[]
  onCostItemAdded?: () => void
}

const CostItemsTableView = (props: CostItemsTableViewProps) => {
  const {
    compact,
    columns,
    dataSource,
    voucherBooking,
    voucherBookingCostItems,
    onCostItemAdded = () => {}
  } = props

  const dispatch = useDispatch()

  const costItemsView = useSelector(state => state.voucher.costItemsView)
  const showDeletedCostItems = useSelector(state => state.voucher.showDeletedCostItems)

  const costSheet = voucherBooking.costSheet

  const hasDeletedCostItem =
    findIndex(voucherBookingCostItems, (vbci: { isDeleted: boolean }) => vbci.isDeleted) >= 0

  const toggleCostItemsView = (e: RadioChangeEvent) => {
    if (e.target?.value) dispatch(updateCostItemsView(e.target.value))
  }

  const toggleCostItemsDeletedView = () =>
    dispatch(updateCostItemsDeletedView(!showDeletedCostItems))

  return (
    <>
      <SectionTitle style={{ display: compact ? 'none' : 'inline' }} borderless>
        <Row>
          <Col span={10}>
            Cost Sheet
            <AddCostItemAction
              bookingUuid={voucherBooking.uuid}
              onCostItemAdded={onCostItemAdded}
            />
            {hasDeletedCostItem && (
              <Brief onClick={toggleCostItemsDeletedView}>
                {showDeletedCostItems ? 'Hide' : 'Show'} deleted
              </Brief>
            )}
          </Col>

          <Col span={14}>
            <StyledRadio.Wrapper>
              <Radio.Group
                size="small"
                buttonStyle="solid"
                value={costItemsView}
                onChange={toggleCostItemsView}
              >
                <Radio.Button value="operation">Operation</Radio.Button>
                <Radio.Button value="finance">Finance</Radio.Button>
              </Radio.Group>
            </StyledRadio.Wrapper>
          </Col>
        </Row>
      </SectionTitle>

      <TableWrapper>
        {!voucherBookingCostItems.length ? (
          <NoItemsFound align="left">No cost items available.</NoItemsFound>
        ) : (
          <Table
            columns={columns}
            pagination={false}
            dataSource={dataSource}
            locale={{ emptyText: 'No cost items available.' }}
          />
        )}
      </TableWrapper>

      <SummaryWrapper style={{ display: compact ? 'none' : 'inline' }}>
        <Col span={12} />
        <Col span={6}>
          <SummaryItem label="Sell Total" value={costSheet?.sellTotal} />
          <SummaryItem label="Cost Total" value={costSheet?.costTotal} />
          <SummaryItem label="AR Total" value={costSheet?.accountReceivable} />
          <SummaryItem label="AP Total" value={costSheet?.accountPayable} />
        </Col>
        <Col span={6}>
          <SummaryItem label="Estimated Profit" value={costSheet?.estimatedProfit} />
          <SummaryItem label="Short Bill" value={costSheet?.shortBill} />
          <SummaryItem label="Accrual Total" value={costSheet?.accrual} />
          <SummaryItem label="Gross Profit" value={costSheet?.grossProfit} />
        </Col>
      </SummaryWrapper>
    </>
  )
}

export default CostItemsTableView
