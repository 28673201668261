import { TransactionType, VoucherStatus } from '@/types/graphql'

import { includes } from 'lodash'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import moment from 'moment'

import { exchangeRatesQuery } from '@/components/Voucher/CostItems/schemas'
import handleResponse from '@/utils/responseHandler'

export const getLocalExchangeRate = async (client, voucher, costItem, localCurrency) => {
  const costItemCurrency =
    voucher.transactionType === TransactionType.Accpay
      ? costItem.costCurrencyUuid || costItem.costCurrency?.uuid
      : costItem.sellCurrencyUuid || costItem.sellCurrency?.uuid

  const vCurrencyUuid = voucher.currencyUuid || voucher.currency?.uuid

  if (costItemCurrency === vCurrencyUuid && vCurrencyUuid !== localCurrency?.uuid) {
    return voucher.transactionType === TransactionType.Accpay
      ? costItem.costExchangeRate
      : costItem.sellExchangeRate
  }

  if (vCurrencyUuid !== localCurrency?.uuid) {
    const exchangeRateResult = await client.query({
      query: exchangeRatesQuery,
      variables: {
        fromUuid: vCurrencyUuid,
        toUuid: localCurrency?.uuid,
        date: moment().format('YYYY-MM-DD')
      }
    })

    return (
      exchangeRateResult?.data?.exchangeRates?.rows?.length &&
      exchangeRateResult?.data?.exchangeRates?.rows[0]?.rate
    )
  }

  return 1
}

export const getExchangeRate = async props => {
  const { isAp, costItem, voucher, selectedGlobalCompany, client } = props

  const currency = isAp ? costItem.costCurrency : costItem.sellCurrency

  let exchangeRate = {
    rate: 1
  }

  if (selectedGlobalCompany?.currency.uuid === voucher.currency.uuid) {
    exchangeRate = {
      rate: isAp ? costItem.costExchangeRate : costItem.sellExchangeRate
    }
  } else if (currency.uuid !== (voucher.currency && voucher.currency.uuid)) {
    const exchangeRateResult = await client.query({
      fetchPolicy: 'network-only',
      query: exchangeRatesQuery,
      variables: {
        fromUuid: currency && currency.uuid,
        toUuid: voucher.currency && voucher.currency.uuid,
        date: (voucher.issueDate && moment(voucher.issueDate)) || moment().toDate()
      }
    })

    if (exchangeRateResult.data.exchangeRates.rows.length === 0) {
      handleResponse(
        'No exchange rate found. Rate of 1 was set, please change accordingly.',
        'warning'
      )
    }

    exchangeRate = exchangeRateResult.data?.exchangeRates?.rows?.[0]
  }

  return exchangeRate
}

export const getExchangeRates = async props => {
  const { voucher, groupedVoucherItems, client } = props

  const groupedCurrencies =
    groupedVoucherItems || groupBy(voucher.voucherItems, vi => vi.currency.uuid)

  const exchangeRateQueries = await Promise.all(
    Object.keys(groupedCurrencies).map(key => {
      if (key !== (voucher.currency && voucher.currency.uuid)) {
        return client.query({
          fetchPolicy: 'network-only',
          query: exchangeRatesQuery,
          variables: {
            fromUuid: key,
            toUuid: voucher.currency && voucher.currency.uuid,
            date: (voucher.issueDate && moment(voucher.issueDate)) || moment().toDate()
          }
        })
      }
      return null
    })
  )

  return exchangeRateQueries.map(er => {
    if (!isEmpty(er) && er.data && er.data.exchangeRates && er.data.exchangeRates.rows) {
      return {
        ...er.data.exchangeRates.rows[0]
      }
    }
    return null
  })
}

export const calculateExchangeRate = props => {
  const {
    isAp,
    voucher,
    costItem,
    voucherItem,
    exchangeRates,
    selectedGlobalCompany,
    costItemOnly = false
  } = props

  let overrideExchangeRate = {
    rate: 1
  }

  const ciCurrency = isAp ? costItem.costCurrency : costItem.sellCurrency

  const ciComparison =
    costItemOnly && ciCurrency.uuid === selectedGlobalCompany.company.currency.uuid
  const viComparison =
    ciCurrency.uuid === selectedGlobalCompany.company.currency.uuid &&
    voucherItem.currency.uuid === voucher.currency.uuid

  if (
    ciComparison ||
    viComparison ||
    voucher.currency.uuid === selectedGlobalCompany.company.currency.uuid
  ) {
    overrideExchangeRate.rate = isAp ? costItem.costExchangeRate : costItem.sellExchangeRate
  } else {
    if (exchangeRates && exchangeRates.length) {
      const hasDifferentExchangeRate = find(exchangeRates, er => {
        return (
          !isEmpty(er) &&
          er.from.uuid === voucherItem.currency.uuid &&
          er.to.uuid === (voucher.currency && voucher.currency.uuid)
        )
      })

      if (hasDifferentExchangeRate) {
        overrideExchangeRate = hasDifferentExchangeRate
      }
    }
  }

  return overrideExchangeRate
}

export const getUpdateVoucherItemRequest = vi => {
  if (!vi) {
    return null
  }

  return {
    ...pick(vi, ['uuid', 'quantity', 'baseRate', 'exchangeRate', 'unit', 'size', 'description']),
    taxUuid: vi.tax && vi.tax.uuid,
    currencyUuid: vi.currency && vi.currency.uuid,
    jobUuid: vi.job && vi.job.uuid
  }
}

export const isApproved = voucher => {
  return includes(
    [VoucherStatus.Approved, VoucherStatus.PartiallyPaid, VoucherStatus.Paid],
    voucher.status
  )
}

export const readDateFromUrl = (urlQuery, fullName = false) => {
  if (urlQuery.shipperRequiredDateStart) {
    return {
      [fullName ? 'shipperRequiredDateStart' : 'start']: urlQuery.shipperRequiredDateStart,
      [fullName ? 'shipperRequiredDateEnd' : 'end']: urlQuery.shipperRequiredDateEnd
    }
  } else {
    return {
      [fullName ? 'activityStartDate' : 'start']: urlQuery.activityStartDate,
      [fullName ? 'activityEndDate' : 'end']: urlQuery.activityEndDate
    }
  }
}
