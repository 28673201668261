import styled from 'styled-components'

export const WorkflowWrapper = styled.div`
  margin: 0px 0px;
`

export const EmptyBooking = styled.div`
  text-align: center;
  margin-top: 40px;

  .msg {
    font-size: 14px;
    font-weight: 500;
    color: rgb(120, 120, 120);
    letter-spacing: 0.1px;
    padding: 10px 0;
  }

  .anticon {
    font-size: 1.5rem;
    color: rgb(120, 120, 120);
  }
`
