import styled from 'styled-components'

export const ListWrapper = styled.div`
  min-width: 800px;
  max-width: 1400px;
  margin: auto;
  padding: 16px;
`

export const ViewAnchor = styled.a`
  font-size: 12px;
  font-weight: 600;
`
