import moment from 'moment'

export const getEntitySelectorColor = (entity: any) => {
  const dates = entity.miscFieldTypes
    .filter(f => f.tags.includes('isExp'))
    .map(f => entity.miscFields?.[f.key])
    .filter(Boolean)
  const hasExpired = dates.some(date => moment(date).isBefore(moment(), 'day'))
  const hasExpiringSoon = dates.some(date =>
    moment(date).isBetween(moment(), moment().add(14, 'days'), 'day', '[]')
  )
  const color = hasExpired ? 'red' : hasExpiringSoon ? '#FFC000' : 'inherit'
  return { color }
}
