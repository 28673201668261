import styled from 'styled-components'

export default styled.div`
  padding: 8px 5px;
  line-height: normal;

  .ant-checkbox-group-item {
    display: block;
    padding: 4px;
    font-size: 13px;
    letter-spacing: 0.3px;
  }

  .ant-table-pagination.ant-pagination {
    margin: 10px 0;
  }
`
