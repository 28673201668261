import { LoadingOutlined } from '@ant-design/icons'
import { Col } from 'antd-v5'

import { StyledRow } from '@/components/Auth/Styled'

const SignOff = ({ message }: { message: string }) => {
  return (
    <StyledRow type="flex" justify="space-around" align="middle">
      <Col span={24} style={{ textAlign: 'center' }}>
        <div className="load-text">{message || 'Signing off...'}</div>
        <LoadingOutlined />
      </Col>
    </StyledRow>
  )
}

export default SignOff
