import type { Approval } from '@/types/graphql'

import { forwardRef, useState } from 'react'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd-v5'
import startCase from 'lodash/startCase'
import moment from 'moment'

import { Actions, ApprovalTitle, ApprovedMessage, Selector, SelectorText } from './Styled'

interface ApprovalSelectorProps {
  title: string
  approval: Approval
  mappedType: { text: string }
  approvedApproval: { createdAt: string; approvedBy: { email: string } } | null
  onApprove: (approval: any) => void
  submitting?: boolean
}

const ApprovalSelector = forwardRef<Popover, ApprovalSelectorProps>((props, ref) => {
  const { title, approval, mappedType, approvedApproval, onApprove, submitting = false } = props

  const [showApproval, toggleShowApproval] = useState(false)

  const renderApprovalDetails = (approvedApproval, approval: Approval) => {
    if (approvedApproval) {
      const formattedDate = moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')
      const approvedBy = (approvedApproval.approvedBy && approvedApproval.approvedBy.email) || '-'

      return (
        <ApprovedMessage>
          Approved by
          <span className="highlight">{approvedBy}</span>on
          <span className="highlight">{formattedDate}</span>
        </ApprovedMessage>
      )
    }

    const onClickApprove = () => onApprove(approval)

    return (
      <Actions>
        <Button onClick={() => toggleShowApproval(false)}>Cancel</Button>
        <Button
          id="finance-approve-voucher-button"
          type="primary"
          loading={submitting}
          onClick={onClickApprove}
        >
          Approve
        </Button>
      </Actions>
    )
  }

  return (
    <Popover
      ref={ref}
      arrow={false}
      trigger="click"
      open={showApproval}
      onOpenChange={visible => toggleShowApproval(visible)}
      title={<ApprovalTitle>{startCase(title)}</ApprovalTitle>}
      content={renderApprovalDetails(approvedApproval, approval)}
    >
      <Selector id="approval-selector" style={{ width: '200px' }}>
        <SelectorText>
          {approvedApproval ? (
            <CheckCircleOutlined style={{ color: 'green' }} />
          ) : (
            <ExclamationCircleOutlined style={{ color: 'red' }} />
          )}
          {startCase(mappedType.text)}
        </SelectorText>
      </Selector>
    </Popover>
  )
})

export default ApprovalSelector
