import { memo } from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'

const PendingVerificationIcon = () => {
  return (
    <Tooltip title="Pending verification.">
      <WarningOutlined className="text-warning" />
    </Tooltip>
  )
}

export default memo(PendingVerificationIcon)
