import type { BookingDocument } from '@/types/graphql'

import React from 'react'
import { Col, Row, Typography } from 'antd-v5'

import DocumentSelector from 'App/components/DocumentSelector'
import Selectables from 'App/components/Voucher/Attachments/Selectables'
import { SectionTitle } from 'App/components/Workflow/Requisition/Template/Container'

type AttachmentProps = {
  value?: string[]
  onChange?: (value: string[]) => void
  selectableDocuments: BookingDocument[]
}

const Attachments = (props: AttachmentProps) => {
  const { selectableDocuments, value, onChange } = props

  const handleSelectDocument = (document: BookingDocument) => {
    onChange?.([...(value || []), document.uuid])
  }

  const handleRemove = (document: BookingDocument) => () => {
    const newValue = (value || []).filter(v => v !== document.uuid)
    onChange?.(newValue)
  }

  const selectedAttachments = selectableDocuments.filter(a => (value || []).includes(a.uuid))

  return (
    <React.Fragment>
      <SectionTitle>
        Attachments
        <Typography.Text className="ml-2">
          <Selectables
            selectable
            selectableDocuments={selectableDocuments}
            selectedDocuments={selectedAttachments}
            onSelectSingleDocument={handleSelectDocument}
          />
        </Typography.Text>
      </SectionTitle>
      <div className="leading-none">
        <Row>
          {selectedAttachments.length === 0 && (
            <Col span={24}>
              <span>No attachments.</span>
            </Col>
          )}
          {selectedAttachments.map(doc => {
            return (
              <Col key={doc.uuid} span={8}>
                <DocumentSelector
                  hrefUrl={`/booking/document/${doc.uuid}`}
                  uploadedDoc={doc}
                  removable
                  onRemove={handleRemove(doc)}
                />
              </Col>
            )
          })}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Attachments
