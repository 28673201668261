import type { BaseCompany } from '@/types/graphql'
import { gql } from '@/types'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { setUser } from '@sentry/react'
import isEmpty from 'lodash/isEmpty'

import useGlobalCompanyStore from '@/store/globalCompany'
import useUserStore from '@/store/user'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'

const VIEWER_QUERY = gql(`
  query viewerSlim3($auth0UserId: String) {
    viewer(auth0UserId: $auth0UserId) {
      email
      baseCompanies {
        uuid
        name
        types
        status
        country {
          alpha2
          alpha3
        }
        company {
          uuid
          name
          currency {
            uuid
            code
            name
          }
        }
      }
    }
  }
`)

const useBootstrap = (auth0UserId: string) => {
  const cachedViewer = useUserStore.use.viewerData()
  const setViewerData = useUserStore.use.setViewerData()

  const setGlobalCompanies = useGlobalCompanyStore.use.setGlobalCompanies()
  const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

  const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { data, error, loading } = useQuery(VIEWER_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { auth0UserId }
  })

  useEffect(() => {
    if (data?.viewer && !loading) {
      setViewerData(data.viewer)
      setUser({ email: data.viewer.email })

      if (isEmpty(selectedGlobalCompany)) {
        const autoSelectFirstBc = data.viewer.baseCompanies?.[0]
        if (autoSelectFirstBc) setSelectedGlobalCompany(autoSelectFirstBc)
        setUser({ baseCompany: autoSelectFirstBc })
      } else {
        const company = data.viewer.baseCompanies?.find(
          bc => bc?.uuid === selectedGlobalCompany.uuid
        )
        if (company) {
          setSelectedGlobalCompany(company)
          setUser({ baseCompany: company })
        }
      }
      const companies = (data.viewer.baseCompanies ?? []).filter(
        (company): company is BaseCompany => company !== null
      )
      setGlobalCompanies(companies)
    }
  }, [
    data,
    loading,
    setViewerData,
    setSelectedGlobalCompany,
    selectedGlobalCompany,
    setGlobalCompanies
  ])

  if (error) {
    logger.error('useBootstrap VIEWER_QUERY Error.', error)
    handleResponse(error, 'error')
  }

  return {
    viewer: data?.viewer || cachedViewer || {},
    globalCompanies,
    loading,
    error
  }
}

export default useBootstrap
