import { gql } from '@/types'

import { useCallback } from 'react'
import { DeploymentUnitOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Tooltip } from 'antd-v5'
import { Base64 } from 'js-base64'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

export const PROCESS_FLOW_MAP_QUERY = gql(`
  query getProcessFlowMap($type: String!, $process: String!) {
    processFlowMap(type: $type, process: $process)
  }
`)
const MermaidLink = ({ type, process }) => {
  const [getProcessFlowMap, { error }] = useLazyQuery(PROCESS_FLOW_MAP_QUERY, {
    variables: { type, process },
    fetchPolicy: 'cache-first'
  })

  const loadProcessFlowMap = useCallback(async () => {
    const res = await getProcessFlowMap({ variables: { type, process } })

    if (res?.data?.processFlowMap) {
      const defaultState = {
        code: res.data.processFlowMap,
        mermaid: { theme: 'default' }
      }
      const base64Str = Base64.encodeURI(JSON.stringify(defaultState))
      const url = `https://mermaidjs.github.io/mermaid-live-editor/#/view/${base64Str}`
      window.open(url, '_blank')
    }
  }, [])

  if (error) {
    responseHandler(error, 'error')
    logger.error(error, 'error')
  }

  return (
    <Tooltip title="View process flow map">
      <DeploymentUnitOutlined
        onClick={loadProcessFlowMap}
        style={{ color: 'gray', cursor: 'pointer' }}
      />
    </Tooltip>
  )
}

export default MermaidLink
