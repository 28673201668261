import { VoucherStatus } from '@/types/graphql'

import { memo, useCallback, useMemo } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd-v5'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'

import { Brief, CategoryTitle } from '@/components/Workflow/Styled'
import withVouchers from '@/components/Workflow/Voucher/Container'
import TypeSelections from '@/components/Workflow/Voucher/TypeSelections'
import ViewSelections from '@/components/Workflow/Voucher/ViewSelections'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'

function Vouchers(props) {
  const {
    showPopover,
    voucherTypes,
    handleViewClick,
    viewableVouchers,
    togglePopoverView,
    handleCreateClick,
    showDeletedVouchers,
    toggleShowDeletedVouchers,
    section = { vouchers: [] }
  } = props

  const { isPortalUser } = useProcessPortalUser()

  const onToggleDeleted = useCallback(() => {
    toggleShowDeletedVouchers(!showDeletedVouchers)
  }, [showDeletedVouchers, toggleShowDeletedVouchers])

  const renderTypeSelections = (
    <TypeSelections
      section={section}
      voucherTypes={voucherTypes}
      handleCreateClick={handleCreateClick}
    />
  )

  const deletedVoucher = useMemo(
    () =>
      find(viewableVouchers, voucher =>
        includes([VoucherStatus.Voided, VoucherStatus.Deleted], voucher.status)
      ),
    [viewableVouchers]
  )

  const isAllowed = useMemo(() => section.vouchers?.length > 0, [section])

  const displayingVouchers = useMemo(
    () =>
      deletedVoucher && !showDeletedVouchers
        ? filter(
            viewableVouchers,
            sv => !includes([VoucherStatus.Voided, VoucherStatus.Deleted], sv.status)
          )
        : viewableVouchers,
    [deletedVoucher, showDeletedVouchers, viewableVouchers]
  )

  return (
    <>
      <CategoryTitle>
        Customer Invoice/Vendor Invoice Entry
        {deletedVoucher && (
          <Brief onClick={onToggleDeleted}>{showDeletedVouchers ? 'Hide' : 'Show'} Deleted</Brief>
        )}
        {isAllowed && !isPortalUser && (
          <Popover
            arrow={false}
            className="ml-2"
            open={showPopover}
            trigger="click"
            placement="right"
            title="Voucher Types"
            content={renderTypeSelections}
            onOpenChange={visible => togglePopoverView(visible)}
          >
            <Button id="voucher-types-button" shape="circle" size="small" icon={<PlusOutlined />} />
          </Popover>
        )}
      </CategoryTitle>

      <ViewSelections
        section={section}
        voucherTypes={voucherTypes}
        handleViewClick={handleViewClick}
        viewableVouchers={displayingVouchers}
      />
    </>
  )
}

export default withVouchers(memo(Vouchers))
