import { Fragment } from 'react'
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd-v5'
import moment from 'moment'

import { ApproveType } from '@/components/Workflow/Document/Container'
import { documentType } from '@/utils/labelMap'

const ApprovalIcon = props => {
  const { workflowApproval, approvedApproval } = props

  if (!approvedApproval) {
    return (
      <Tooltip
        title={
          <Fragment>
            <ApproveType>{documentType[workflowApproval] || workflowApproval}</ApproveType>
            <p>Pending approval.</p>
          </Fragment>
        }
      >
        <ClockCircleOutlined />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      title={
        <Fragment>
          <ApproveType>{documentType[workflowApproval] || workflowApproval}</ApproveType>
          <p>
            Approved by : <br />
            {approvedApproval.approvedBy?.email}
          </p>
          Approved on : <br />
          {moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')}
        </Fragment>
      }
    >
      <CheckCircleOutlined />
    </Tooltip>
  )
}

export default ApprovalIcon
