import { useParams } from 'react-router-dom'
import { Flex, Typography } from 'antd-v5'
import moment from 'moment'

import { getBkQueryVariables } from '@/components/Booking/bookingHelper'
import EditJobAndTrip from '@/components/DocumentViewer/Content/Activities/EditJobTrip'
import DocumentVerifyView from '@/components/DocumentViewer/Content/Activities/Verify'
import ApprovalsView from '@/components/DocumentViewer/Content/Approvals'
import EditableInput from '@/components/DocumentViewer/Content/EditableInput'
import FieldItem from '@/components/DocumentViewer/Content/FieldItem'
import { UPDATE_BOOKING_DOCUMENT } from '@/components/DocumentViewer/Content/schema'
import BookingDocumentQuery from '@/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from '@/containers/booking/schema'
import { useBookingStore } from '@/store/booking'

const ActivitiesView = props => {
  const { refreshBooking, bookingDocument } = props

  const params = useParams()
  const setSelectedBookingDocument = useBookingStore.use.setSelectedBookingDocument()

  const onHandleUpdates = (update, key, value) => {
    update({
      variables: {
        uuid: bookingDocument.uuid,
        type: bookingDocument.type,
        [key]: value
      }
    })
  }

  const showJobTripUpdate = bookingDocument?.allowedApprovals?.isJobRequired

  const mutationProps = {
    mutation: UPDATE_BOOKING_DOCUMENT,
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params)
      },
      {
        query: BookingDocumentQuery,
        variables: {
          uuid: bookingDocument.uuid
        }
      }
    ],
    onCompleted: res => {
      refreshBooking()
      setSelectedBookingDocument({
        ...res,
        allowedApprovals: bookingDocument.allowedApprovals
      })
    }
  }

  return (
    <div className="relative h-full">
      <Flex vertical className="px-4" gap="small">
        <Typography.Title level={3}>Approvals</Typography.Title>

        <ApprovalsView
          bookingDocument={bookingDocument}
          approvedApprovals={bookingDocument.approvals}
          approvals={bookingDocument.allowedApprovals?.approvals}
        />

        <Typography.Title level={4}>Activities</Typography.Title>

        {showJobTripUpdate && (
          <FieldItem name="Job & Trip :" value={<EditJobAndTrip {...props} />} />
        )}

        <FieldItem
          name="Reference :"
          value={
            <EditableInput
              fieldName="reference"
              mutationProps={mutationProps}
              handleUpdates={onHandleUpdates}
              value={bookingDocument.reference}
            />
          }
        />
        <FieldItem
          name="Remarks :"
          value={
            <EditableInput
              fieldName="remarks"
              mutationProps={mutationProps}
              value={bookingDocument.remarks}
              handleUpdates={onHandleUpdates}
            />
          }
        />
        <FieldItem
          name="Tags :"
          value={
            <EditableInput
              type="tags"
              fieldName="tags"
              value={bookingDocument.tags}
              mutationProps={mutationProps}
              handleUpdates={onHandleUpdates}
            />
          }
        />
        <FieldItem name="Status :" value={bookingDocument.status} type="bold" />
        <FieldItem
          name="Last updated on :"
          value={
            bookingDocument.updatedAt &&
            moment(bookingDocument.updatedAt).format('Do MMM, YYYY - h:mm a')
          }
        />
        <FieldItem
          name="Created on :"
          value={
            bookingDocument.createdAt &&
            moment(bookingDocument.createdAt).format('Do MMM, YYYY - h:mm a')
          }
        />
        <FieldItem name="Created by :" value={bookingDocument.createdBy?.email} />
      </Flex>

      <DocumentVerifyView bookingDocument={bookingDocument} refreshBooking={refreshBooking} />
    </div>
  )
}

export default ActivitiesView
