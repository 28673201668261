import type { DynamicField, Maybe, Trip } from 'App/types/graphql'
import { DocumentCreatorTemplateStatus, TripStatus } from 'App/types/graphql'

import { useEffect, useState } from 'react'
import { Col, Row, Typography } from 'antd-v5'

import { DisplayAddress, RenderFields } from '@/components/Booking/General/FieldDisplayHelper'
import WayBillDisplay from '@/components/Booking/JobsAndTrips/WayBillDisplay'
import DocumentCreatorPrinter from '@/components/Shared/DocumentCreatorPrinter'
import { containerStyle, contentStyle, verticalStyle } from './styles'

interface JobFormProps {
  trips?: Maybe<Maybe<Trip>[]> | undefined
  tripDynamicFields: DynamicField[]
  hideDeleted?: boolean
  hideFunctions?: boolean
}

const TripsDetailsDisplay = (props: JobFormProps) => {
  const { trips, tripDynamicFields, hideDeleted = true, hideFunctions = false } = props
  const [filteredTrips, setFilteredTrips] = useState<Maybe<Maybe<Trip>[]>>([])

  useEffect(() => {
    if (!trips) return
    if (hideDeleted)
      setFilteredTrips(
        trips?.filter(
          job =>
            job && ![TripStatus.Cancelled, TripStatus.Deleted].includes(job.status as TripStatus)
        )
      )
    else setFilteredTrips(trips)
  }, [hideDeleted, trips])

  return (
    <>
      {filteredTrips?.map((trip, index) => {
        const isCancelledTrip = [TripStatus.Cancelled, TripStatus.Deleted].includes(
          trip?.status as TripStatus
        )
        const style = isCancelledTrip
          ? { ...containerStyle, backgroundColor: 'rgba(242, 177, 177,0.4)' }
          : containerStyle

        const isTripDeleted = trip?.status === TripStatus.Deleted
        return (
          <div key={`${trip?.uuid}-${index}`} style={style}>
            <div style={verticalStyle}>{trip?.type || `Trip ${index + 1}`}</div>
            <div style={contentStyle}>
              {!hideFunctions && (
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    {trip?.waybillId ? <WayBillDisplay id={trip.waybillId} /> : <></>}
                    <Typography.Text
                      strong
                      style={{
                        fontSize: 13,
                        paddingRight: 5,
                        color: isTripDeleted ? '#ff6b6b' : undefined
                      }}
                    >
                      {trip?.status}
                    </Typography.Text>
                    <DocumentCreatorPrinter
                      job={trip}
                      variables={{
                        qs: ['TRIP_DOCUMENT'],
                        statuses: [DocumentCreatorTemplateStatus.Activated]
                      }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={12}>{DisplayAddress(trip?.fromUuid)}</Col>
                <Col span={12}>{DisplayAddress(trip?.toUuid)}</Col>
              </Row>
              <Row gutter={[16, { xs: 20, lg: 48 }]} style={{ marginTop: 2 }}>
                {[0, 1, 2].map(colIndex => (
                  <Col key={colIndex} xs={24} sm={12} lg={8}>
                    {tripDynamicFields
                      .slice(
                        Math.floor((colIndex * tripDynamicFields.length) / 3),
                        Math.floor(((colIndex + 1) * tripDynamicFields.length) / 3)
                      )
                      .map((field: DynamicField) => RenderFields(trip, field))}
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TripsDetailsDisplay
