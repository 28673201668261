import { Card } from 'antd-v5'

interface DocumentCreatorTemplateCardProps {
  documentGeneratorTemplate: any
  onClick: (documentCreatorTemplate: any) => void
}

const DocumentGeneratorTemplateCard = (props: DocumentCreatorTemplateCardProps) => {
  const { documentGeneratorTemplate, onClick } = props

  const handleClick = () => {
    onClick(documentGeneratorTemplate)
  }

  return (
    <Card className="cursor-pointer flex items-center" onClick={handleClick}>
      {documentGeneratorTemplate.code}
    </Card>
  )
}

export default DocumentGeneratorTemplateCard
