import type { AnchorHTMLAttributes, PropsWithChildren } from 'react'

import styled from 'styled-components'

export const StyledMenuItem = styled.a<PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>>>`
  font-size: 13.222px;
  color: #151b26;
  display: block;
  padding: 8px 0;

  &:hover {
    background: none;
  }
`
