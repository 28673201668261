import { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { compose } from 'recompose'

import TransportSubmitModal from 'App/components/TransportSubmit/TransportSubmitModal'
import changeDocumentCreatorStatusMutation from 'App/containers/documentCreator/mutation/changeDocumentCreatorStatus'
import updateDocumentCreatorMutation from 'App/containers/documentCreator/mutation/updateDocumentCreator'
import { StyledButton } from '../../ButtonWrapper'

const enhance = compose(updateDocumentCreatorMutation, changeDocumentCreatorStatusMutation)

class ROTEditAction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      loading: false
    }
  }

  handleClick = () => {
    this.setState({
      ...this.state,
      visible: true
    })
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      visible: false
    })
  }

  handleChange = () => {
    const { onChange = () => {} } = this.props
    onChange()
  }

  render() {
    const { documentCreator } = this.props

    return (
      <>
        <StyledButton onClick={this.handleClick}>
          <EditOutlined />
        </StyledButton>
        <TransportSubmitModal
          documentCreator={documentCreator}
          visible={this.state.visible}
          onChange={this.handleChange}
          onClose={this.handleClose}
        />
      </>
    )
  }
}

export default enhance(ROTEditAction)
