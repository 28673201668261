import type { DriversForSelectQuery } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { DriverStatus } from '@/types/graphql'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Select, Typography } from 'antd-v5'
import { debounce, isObject } from 'lodash'

import { getEntitySelectorColor } from '@/components/Transport/Components/IsExpFilterSelector'
import { DRIVERS_QUERY } from '@/graphql/driver'
import notify from '@/utils/notify'

type DriverSelectorProps = SelectProps & {
  isUpdate?: boolean
  queryOnMount?: boolean
  statuses?: Array<DriverStatus>
  setVehiclePreference?: (value: (string | null)[]) => void
  returnObject?: boolean
  selectFirstValue?: boolean
}

export function DriverSelector(props: DriverSelectorProps) {
  const {
    queryOnMount,
    statuses = [DriverStatus.Active],
    returnObject,
    setVehiclePreference,
    selectFirstValue = false,
    isUpdate,
    ...selectProps
  } = props
  const { value, mode, onChange } = selectProps

  const { t } = useTranslation()

  const [searchInput, setSearchInput] = useState('')
  const [drivers, setDrivers] = useState<
    NonNullable<NonNullable<DriversForSelectQuery>['drivers']>['rows']
  >([])

  const getInputValue = useMemo(() => {
    return isObject(value) ? (value as { uuid: string })?.uuid : value || searchInput || undefined
  }, [value, searchInput])

  const input = useMemo(() => {
    return {
      q: (!mode && getInputValue) || searchInput || '',
      statuses,
      uuids: (mode && !searchInput && value) || [],
      limit: 20
    }
  }, [mode, getInputValue, searchInput, statuses])

  const [getDrivers, { error, loading }] = useLazyQuery(DRIVERS_QUERY, {
    variables: { ...input },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const rows = data?.drivers?.rows
      if (rows?.length) {
        setDrivers(rows)
        if (rows.length === 1 && selectFirstValue)
          handleChange(returnObject ? rows[0] : rows[0]?.uuid)
      }
    }
  })

  useEffect(() => {
    if (queryOnMount || value) getDrivers()
  }, [queryOnMount, value])

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDrivers()
  }

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getDrivers()
      } catch (error) {
        if (error instanceof Error) notify(error.message, 'error')
      }
    }, 500),
    []
  )

  const onSelect = useCallback(
    (onSelectVal: string) => {
      const selectedDriver = drivers?.find(d => d?.uuid === onSelectVal)
      if (selectedDriver?.vehiclePreference?.length && setVehiclePreference) {
        setVehiclePreference(selectedDriver.vehiclePreference)
      }
    },
    [drivers, setVehiclePreference]
  )

  const handleChange = useCallback(
    selectedValue => {
      if (!returnObject) {
        onChange?.(selectedValue)
        return
      }

      if (!selectedValue) {
        onChange?.(null)
        return
      }

      const selectedDriver = drivers?.find(d => d?.uuid === selectedValue)
      onChange?.(selectedDriver || selectedValue)
    },
    [onChange, returnObject, drivers]
  )

  const handleClear = useCallback(() => {
    setSearchInput('')
    onChange?.(null)
  }, [onChange])

  if (error) {
    console.error('DriverSelector useQuery error', error)
  }

  const driverOptions = drivers?.map(d => ({
    label: (
      <Typography.Text style={getEntitySelectorColor(d)}>
        {d?.code} - {d?.name}
      </Typography.Text>
    ),
    value: d?.uuid
  }))

  return (
    <Select
      showSearch
      allowClear
      id="driver-leg-form-selector"
      loading={loading}
      onChange={handleChange}
      onSelect={onSelect}
      onClear={handleClear}
      filterOption={false}
      disabled={Boolean(isUpdate)}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      placeholder={t('common.searchADriver')}
      value={getInputValue || undefined}
      notFoundContent={loading ? t('common.loading') : t('common.notFound')}
      options={driverOptions}
      {...selectProps}
    />
  )
}
