import type { ModalProps } from 'antd-v5'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Modal, Typography } from 'antd-v5'

import ViewerContent from '@/components/DocumentViewer/Content'
import HeaderOptions from '@/components/DocumentViewer/Header/Options'
import { useBookingStore } from '@/store/booking'
import { documentType } from '@/utils/labelMap'

type DocumentViewerProps = ModalProps & {
  refreshBooking: () => void
  bookingQuery: any
}

export default function DocumentViewer(props: DocumentViewerProps) {
  const { bookingQuery, refreshBooking, ...modalProps } = props

  const selectedBookingDocument = useBookingStore.use.selectedBookingDocument()
  const openDocumentViewer = useBookingStore.use.openDocumentViewer()
  const setOpenDocumentViewer = useBookingStore.use.setOpenDocumentViewer()

  const docPOD = bookingQuery?.booking?.jobs.flatMap(job =>
    job.trips
      ?.filter(trip => trip.uuid === selectedBookingDocument?.tripUuid)
      .map(trip => {
        const jobNoPart = job.jobNo.slice(0, job.jobNo.length)
        return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
      })
  )

  const closeDocumentViewer = () => {
    setOpenDocumentViewer(false)
    refreshBooking()
  }

  if (!openDocumentViewer) return null

  return (
    <Modal
      centered
      {...modalProps}
      title={
        <Flex justify="space-between" align="center">
          <Flex vertical>
            <Typography.Text>
              {selectedBookingDocument?.document?.name} - ${docPOD}
            </Typography.Text>
            <Typography.Text className="text-xs text-muted">
              {(
                documentType[selectedBookingDocument?.type ?? ''] ||
                (selectedBookingDocument?.type ?? '')
              ).toUpperCase()}
            </Typography.Text>
          </Flex>
          <Flex gap={10}>
            <HeaderOptions
              bookingDocument={selectedBookingDocument}
              refreshBooking={refreshBooking}
            />
            <Button
              onClick={closeDocumentViewer}
              className="border-none"
              size="small"
              type="text"
              icon={<CloseOutlined />}
            />
          </Flex>
        </Flex>
      }
      footer={null}
      closeIcon={null}
      open={openDocumentViewer}
      width={'100%'}
    >
      <ViewerContent
        bookingQuery={bookingQuery}
        bookingDocument={selectedBookingDocument}
        refreshBooking={refreshBooking}
      />
    </Modal>
  )
}
