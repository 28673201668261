import '@/libs/i18n'

import { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { Auth0ProviderWithHistory } from '@/Auth0ProviderWithHistory'
import CacheBuster from '@/components/CacheBuster'
import Fallback from '@/components/Spinner/Fallback'
import initApollo from '@/libs/initApollo'
import initRedux from '@/libs/initRedux'
import { Router } from '@/router'

const store = initRedux()
const apolloClient = initApollo()

export default function App() {
  return (
    <CacheBuster>
      <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
        <Auth0ProviderWithHistory>
          <Provider store={store}>
            <ApolloProvider client={apolloClient}>
              <Suspense fallback={<Fallback />}>
                <Router />
              </Suspense>
            </ApolloProvider>
          </Provider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </CacheBuster>
  )
}
