import { TransactionType } from '@/types/graphql'

import { CloseOutlined } from '@ant-design/icons'
import { Divider, Flex, Skeleton } from 'antd-v5'
import startCase from 'lodash/startCase'

import { voucherTypes } from 'App/utils/labelMap'
import { HeaderDescription, HeaderTitle, TextWrapper } from '../Styled'
import withModalHeader from './Container'
import Selections from './Selections'
import { CapsText, MutedText, NormalText } from './Styled'

const ModalHeader = props => {
  const {
    onCancel,
    bookingQuery,
    selectedVoucher,
    fullBookingQuery,
    loadRequiredData,
    handleCreateCreditNote
  } = props

  if (!selectedVoucher) return <Skeleton />

  const type = selectedVoucher.type
  const description = selectedVoucher.status

  const mappedType = voucherTypes[type] || { text: type }
  const titleCategory =
    selectedVoucher.transactionType === TransactionType.Accpay
      ? 'Vendor Invoice'
      : 'Customer Invoice'

  return (
    <>
      <Flex justify="space-between" align="center">
        <TextWrapper>
          <HeaderTitle>
            <CapsText>{titleCategory}</CapsText>
            {' - '}
            <NormalText>{startCase(mappedType.text)}</NormalText>{' '}
            <MutedText>({selectedVoucher.transactionType})</MutedText>
            {selectedVoucher.isCreditNote && <MutedText> [CREDIT NOTE]</MutedText>}
            {selectedVoucher.isJournalVoucher && <MutedText> [JOURNAL VOUCHER]</MutedText>}
          </HeaderTitle>
          <HeaderDescription>{description}</HeaderDescription>
        </TextWrapper>

        <Flex align="flex-start" gap={5}>
          {selectedVoucher.status !== 'NEW' && (
            <Selections
              bookingQuery={bookingQuery}
              fullBookingQuery={fullBookingQuery}
              selectedVoucher={selectedVoucher}
              loadRequiredData={loadRequiredData}
              handleCreateCreditNote={handleCreateCreditNote}
            />
          )}
          <CloseOutlined style={{ paddingLeft: 15 }} onClick={onCancel} />
        </Flex>
      </Flex>
      <Divider style={{ margin: 0, marginTop: 10 }} />
    </>
  )
}

export default withModalHeader(ModalHeader)
