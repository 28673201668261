import type {
  CreateTripInput,
  JobTripUpdateInput,
  JobType,
  JobTypeDetails,
  Trip as TripClone
} from '@/types/graphql'
import type { ChangeEvent } from 'react'
import {
  AddressType,
  DynamicFieldTag,
  JobTypeRequiredFieldControl,
  TripStatus
} from '@/types/graphql'

import { memo, useCallback, useState } from 'react'
import {
  CopyOutlined,
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  UpOutlined
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Form, Input, Select } from 'antd'
import { Button, Col, Flex, Popconfirm, Row, Tooltip, Typography } from 'antd-v5'
import { t } from 'i18next'
import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import startCase from 'lodash/startCase'
import { v4 as uuidv4 } from 'uuid'

import { hasDynamicFieldTag } from '@/components/Booking/DynamicField/helper'
import { UPDATE_TRIPS_MUTATION } from '@/components/Booking/General/schema'
import {
  ADD_TRIP,
  DELETE_TRIP
} from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm/schema'
import styles from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm/Trip.module.css'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import AddressSelect from '@/components/Select/AddressSelect'
import CompanySelect from '@/components/Select/TypeToFetch/CompanySelect'
import DynamicField from '@/components/Shared/DynamicField'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import responseHandler from '@/utils/responseHandler'
import { generateSixCharacterString } from '@/utils/u'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

interface TripProps {
  jobUuid: string
  index: number
  onChange: any
  format: string
  jobType: JobType
  requiredFields: any
  numberOfTrips: number
  trips: JobTripUpdateInput[]
  tripDetail: JobTypeDetails | undefined
  mode: FormMode
}

const Trip = (props: TripProps) => {
  const {
    mode,
    index,
    format,
    jobUuid,
    onChange,
    tripDetail,
    trips = [{}],
    numberOfTrips,
    requiredFields = []
  } = props

  const [createTripMutation] = useMutation(ADD_TRIP)
  const [deleteTripMutation] = useMutation(DELETE_TRIP)
  const [updateTripsMutation] = useMutation(UPDATE_TRIPS_MUTATION)

  // Currently I think the issue comes from toFromCompany is not set because it's stored in a state... need to change this.
  const tripUuid = trips[index]?.uuid
  const [fromCompanyUuid, setFromCompanyUuid] = useState<string>(
    trips[index]?.fromCompanyUuid || trips[index]?.from?.companyUuid
  )
  const [toCompanyUuid, setToCompanyUuid] = useState<string>(
    trips[index]?.toCompanyUuid || trips[index]?.to?.companyUuid
  )
  const [references, setReferences] = useState(trips[index]?.references || [])
  const [remarks, setRemarks] = useState<string>(trips[index]?.remarks || '')
  const [fromUuid, setFromUuid] = useState<string>(
    trips[index]?.fromUuid || trips[index]?.from?.uuid
  )
  const [toUuid, setToUuid] = useState<string>(trips[index]?.toUuid || trips[index]?.to?.uuid)
  const [sealNo, setSealNo] = useState(trips[index]?.seal || '')

  const tripDynamicFields = useBookingStore.use.tripDynamicFields()
  const setTripDynamicFields = useBookingStore.use.setTripDynamicFields()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const isSameStartFormat = format === 'sameStart'
  const isSameEndFormat = format === 'sameEnd'
  const isLinearFormat = format === 'linear'
  const isNoneFormat = format === 'none'

  const onDuplicateTrip = async (index: number) => {
    const newArray = cloneDeep(trips)
    const targetDuplicate = newArray[index]

    const sanitisedTrip: TripClone = {
      jobUuid,
      ...targetDuplicate,
      // @ts-expect-error
      sequence: newArray[index].sequence + 1,
      ownerUuid: selectedGlobalCompany?.uuid,
      waybillId: generateSixCharacterString()
    }

    if (Array.isArray(sanitisedTrip.details)) delete sanitisedTrip.details

    delete sanitisedTrip.status
    delete sanitisedTrip.uuid
    delete sanitisedTrip.to
    delete sanitisedTrip.from
    delete sanitisedTrip.__typename

    if (mode === FormMode.Edit) {
      try {
        const result = await createTripMutation({ variables: { input: { ...sanitisedTrip } } })
        newArray.splice(index + 1, 0, {
          ...targetDuplicate,
          uuid: result.data?.createTrip?.[0]?.uuid
        })
        onChange(newArray)
      } catch (error: any) {
        responseHandler(error.message, 'error')
      }
    } else {
      newArray.splice(index + 1, 0, { ...targetDuplicate, key: uuidv4(), uuid: uuidv4() })
      onChange(newArray)
    }
  }

  const onAddTrip = async (index: number) => {
    const newArray = cloneDeep(trips)

    const isAddLastTrip = index === trips.length - 1
    const isAddMiddleTrip = index !== trips.length - 1

    let sequence: number = 0

    const input: CreateTripInput = {
      jobUuid
    }

    if (isSameStartFormat) {
      newArray.splice(index + 1, 0, {
        uuid: uuidv4(),
        type: newArray[index].type,
        fromUuid: newArray[index].fromUuid,
        fromCompanyUuid: newArray[index].fromCompanyUuid
      })

      sequence = index++
      input.type = newArray[index].type
      input.fromUuid = newArray[index].fromUuid
      input.fromCompanyUuid = newArray[index].fromCompanyUuid
    }

    if (isSameEndFormat) {
      newArray.splice(index + 1, 0, {
        uuid: uuidv4(),
        type: newArray[index].type,
        toUuid: newArray[index].toUuid,
        toCompanyUuid: newArray[index].toCompanyUuid
      })

      sequence = index++
      input.type = newArray[index].type
      input.toUuid = newArray[index].toUuid
      input.toCompanyUuid = newArray[index].toCompanyUuid
    }

    if (isLinearFormat) {
      if (isAddLastTrip) {
        newArray.splice(index + 1, 0, {
          uuid: uuidv4(),
          type: newArray[index].type,
          fromUuid: newArray[index].toUuid,
          fromCompanyUuid: newArray[index].toCompanyUuid
        })

        sequence = index++
        input.type = newArray[index].type
        input.fromUuid = newArray[index].toUuid
        input.fromCompanyUuid = newArray[index].toCompanyUuid
      }

      if (isAddMiddleTrip) {
        newArray.splice(index + 1, 0, {
          uuid: uuidv4(),
          type: newArray[index].type,
          fromUuid: newArray[index].toUuid,
          fromCompanyUuid: newArray[index].toCompanyUuid
        })

        sequence = index++
        input.type = newArray[index].type
        input.fromUuid = newArray[index].toUuid
        input.fromCompanyUuid = newArray[index].toCompanyUuid

        newArray.map((t, i) => {
          if (i === index + 2) {
            t.fromUuid = undefined
            t.fromCompanyUuid = undefined
          }
          return t
        })
      }
    }

    if (isNoneFormat) {
      newArray.splice(index + 1, 0, {
        uuid: uuidv4(),
        type: newArray[index].type
      })

      sequence = index++
      input.type = newArray[index].type
    }

    if (mode === FormMode.Edit) {
      input.sequence = sequence
      input.status = TripStatus.Pending
      newArray[index].uuid = uuidv4()
    }

    onChange(newArray)
  }

  const onRemove = useCallback(
    async (index: number) => {
      let newTrips = [...trips]

      newTrips = newTrips.map((t, i) => {
        if (i === index + 1) {
          return {
            ...t,
            fromUuid: newTrips[index].fromUuid,
            fromCompanyUuid: newTrips[index].fromCompanyUuid
          }
        }
        return t
      })

      const removeTrip = newTrips.filter((t, tripIndex) => tripIndex !== index)
      onChange(removeTrip)

      if (mode === FormMode.Edit) {
        const res = await deleteTripMutation({ variables: { uuid: tripUuid } })
        if (!res.data?.deleteTrip?.success) {
          return responseHandler(res.data?.deleteTrip?.message, 'error')
        }
      }
    },

    [onChange, trips]
  )

  const onUp = (index: number) => {
    const newArray = cloneDeep(trips)

    const newnewArray = newArray.map((t, i) => {
      if (i === index - 1) {
        t.fromUuid = newArray[index].fromUuid
        t.fromCompanyUuid = newArray[index].fromCompanyUuid
        t.toUuid = trips[index - 1].fromUuid
        t.toCompanyUuid = trips[index - 1].fromCompanyUuid
      }
      if (i === index) {
        t.fromUuid = newArray[index - 1].toUuid
        t.fromCompanyUuid = newArray[index - 1].toCompanyUuid
      }
      if (i === index - 2) {
        t.toUuid = trips[index].fromUuid
        t.toCompanyUuid = trips[index].fromCompanyUuid
      }
      return t
    })

    onChange(newnewArray)
  }

  const onDown = (index: number) => {
    const newArray = cloneDeep(trips)

    const newnewArray = newArray.map((t, i) => {
      if (i === index - 1) {
        t.toUuid = trips[index + 1].fromUuid
        t.toCompanyUuid = trips[index + 1].fromCompanyUuid
      }
      if (i === index) {
        t.toUuid = trips[index].fromUuid
        t.toCompanyUuid = trips[index].fromCompanyUuid
        t.fromUuid = newArray[index + 1].fromUuid
        t.fromCompanyUuid = newArray[index + 1].fromCompanyUuid
      }
      if (i === index + 1) {
        t.fromUuid = trips[index].fromUuid
        t.fromCompanyUuid = trips[index].fromCompanyUuid
      }
      return t
    })

    onChange(newnewArray)
  }

  const onUpRight = (index: number) => {
    const newArray = cloneDeep(trips)
    const newnewArray = newArray.map((t, i) => {
      if (i === index - 1) {
        t.toUuid = newArray[index].toUuid
        t.toCompanyUuid = newArray[index].toCompanyUuid
      }
      if (i === index) {
        t.fromUuid = newArray[index].toUuid
        t.fromCompanyUuid = newArray[index].toCompanyUuid
        t.toUuid = trips[index - 1].toUuid
        t.toCompanyUuid = trips[index - 1].toCompanyUuid
      }
      return t
    })

    onChange(newnewArray)
  }

  const onDownRight = (index: number) => {
    const newArray = cloneDeep(trips)
    const newnewArray = newArray.map((t, i) => {
      if (i === index) {
        t.toUuid = newArray[index + 1].toUuid
        t.toCompanyUuid = newArray[index + 1].toCompanyUuid
      }
      if (i === index + 1) {
        t.fromUuid = newArray[index].toUuid
        t.fromCompanyUuid = newArray[index].toCompanyUuid
        t.toUuid = trips[index].toUuid
        t.toCompanyUuid = trips[index].toCompanyUuid
      }
      if (i === index + 2) {
        t.fromUuid = newArray[index + 1].toUuid
        t.fromCompanyUuid = newArray[index + 1].toCompanyUuid
      }
      return t
    })

    onChange(newnewArray)
  }

  const onSetCompanyUuid = (index: number, type: string, uuid: string) => {
    const updatedTrips = cloneDeep(trips)
    if (format === 'sameStart' && type === 'from') {
      updatedTrips.forEach((trip: JobTripUpdateInput) => {
        trip.fromCompanyUuid = uuid
        if (trip.from) trip.from = {}
      })
      setFromCompanyUuid(uuid)
      updatedTrips[index].fromCompanyUuid = uuid
    } else if (format === 'sameEnd' && type === 'to') {
      updatedTrips.forEach((trip: JobTripUpdateInput) => {
        trip.toCompanyUuid = uuid
        if (trip.to) trip.to = {}
      })
      setToCompanyUuid(uuid)
      updatedTrips[index].toCompanyUuid = uuid
    }
    if (type === 'to') {
      setToCompanyUuid(uuid)
      updatedTrips[index].toCompanyUuid = uuid
      if (updatedTrips[index].to) updatedTrips[index].to = {}
      if (format === 'linear' && index !== updatedTrips.length - 1) {
        updatedTrips[index + 1].fromCompanyUuid = uuid
      }
    } else {
      setFromCompanyUuid(uuid)
      updatedTrips[index].fromCompanyUuid = uuid
      if (updatedTrips[index].from) updatedTrips[index].from = {}
      if (format === 'linear' && index !== 0) {
        updatedTrips[index - 1].toCompanyUuid = uuid
      }
    }
    onChange(updatedTrips)
  }

  const onSetAddressUuid = (index: number, type: string, uuid: string) => {
    const updatedTrips = cloneDeep(trips)
    if (format === 'sameStart' && type === 'from') {
      updatedTrips.forEach((trip: JobTripUpdateInput) => (trip.fromUuid = uuid))
      setFromUuid(uuid)
      updatedTrips[index].fromUuid = uuid
    } else if (format === 'sameEnd' && type === 'to') {
      updatedTrips.forEach((trip: JobTripUpdateInput) => (trip.toUuid = uuid))
      setToUuid(uuid)
      updatedTrips[index].toUuid = uuid
    } else if (type === 'to') {
      setToUuid(uuid)
      updatedTrips[index].toUuid = uuid
      // sync To address with next trip From address
      if (format === 'linear' && updatedTrips[index + 1]) {
        updatedTrips[index + 1].fromUuid = uuid
      }
    } else {
      setFromUuid(uuid)
      updatedTrips[index].fromUuid = uuid
      // sync From address with the next updatedTrips To address
      if (format === 'linear' && updatedTrips[index - 1]) {
        updatedTrips[index - 1].toUuid = uuid
      }
    }
    onChange(updatedTrips)
  }

  const onSetSealNo = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedTrips = cloneDeep(trips)
    setSealNo(e.target.value)
    updatedTrips[index] = { ...updatedTrips[index], seal: e.target.value }
    onChange(updatedTrips)
  }

  const onSetRemarks = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedTrips = cloneDeep(trips)
    setRemarks(e.target.value)
    updatedTrips[index] = { ...updatedTrips[index], remarks: e.target.value }
    onChange(updatedTrips)
  }

  const onSetReferences = (value: any[]) => {
    setReferences(value)
    trips[index].references = value
    onChange(trips)
  }

  const onSetDynamicFieldValue = (value: any, dynamicFieldKey: string) => {
    const newTrips = cloneDeep(trips)

    const details = {
      ...newTrips[index].details,
      [dynamicFieldKey]: value
    }

    newTrips[index].details = details
    // Trigger a targetted re-render only for the dynamic fields by
    // mimicking what the other onChange functions are doing.
    setTripDynamicFields([...tripDynamicFields])
    onChange(newTrips)
  }

  const undeleteTrip = () => {
    const newTrip = cloneDeep(trips)
    newTrip[index].status = TripStatus.Pending
    onChange(newTrip)
  }

  const createTrip = async () => {
    const newTrips = cloneDeep(trips)

    const nextTripSequence = index + 1

    const input = {
      ...newTrips[index],
      jobUuid,
      sequence: index + 1,
      ownerUuid: selectedGlobalCompany?.uuid,
      waybillId: generateSixCharacterString()
    }

    delete input.uuid

    try {
      const result = await createTripMutation({ variables: { input } })

      newTrips[index] = { ...input, ...result.data?.createTrip?.[0] }

      if (result.data?.createTrip) {
        responseHandler('Trip created successfully', 'success')

        if (isNoneFormat) return onChange(newTrips)

        const findResultTrip = result.data.createTrip.filter(
          t => t?.sequence === nextTripSequence
        )?.[0]

        if (isLinearFormat) {
          newTrips[index] = {
            ...newTrips[index],
            uuid: findResultTrip?.uuid
          }
          newTrips.map((t, i) => {
            if (i > index + 1) {
              return {
                ...t,
                // @ts-expect-error
                sequence: t + 1
              }
            }
          })

          const tripsInput = newTrips?.map(trip => {
            return {
              ...pick(trip, [
                'uuid',
                'fromUuid',
                'toUuid',
                'remarks',
                'references',
                'seal',
                'status',
                'type',
                'details'
              ])
            }
          })

          await updateTripsMutation({
            variables: {
              input: {
                jobUuid,
                trips: tripsInput
              }
            }
          })
        }

        onChange(newTrips)
      }
    } catch (error: any) {
      responseHandler(error.message, 'error')
    }
  }

  // show or hide button logic
  const isDownButtonVisible = trips.length > 1 && !(trips.length === index + 1) && !tripDetail?.type

  const isUpButtonVisible = index !== 0 && !tripDetail?.type

  const canAddTrip = numberOfTrips === 0 ? true : trips.length < numberOfTrips

  const isDeleted = trips[index].status === TripStatus.Deleted

  const isNewTrip = !trips[index].status

  return (
    <Flex
      vertical
      id={`trips #${index} container`}
      style={{
        marginTop: 10,
        padding: isDeleted ? '10px 5px' : undefined,
        backgroundColor: isDeleted ? '#F0F0F0' : undefined
      }}
    >
      <Typography.Text strong style={{ marginLeft: -60 }}>
        {tripDetail ? tripDetail.type : `Trip #${index + 1}`}
      </Typography.Text>

      <Row>
        <Col span={12}>
          <Form.Item label="From Company" {...formItemLayout} required={true}>
            <CompanySelect
              formId={`trips-${index}-from-company-selector`}
              quickCreate
              disabled={isDeleted}
              value={fromCompanyUuid}
              style={{ width: '100%' }}
              types={(tripDetail?.fromCompanyTypes as string[]) || ['shipperConsignee']}
              onChange={(uuid: string) => onSetCompanyUuid(index, 'from', uuid)}
            />
          </Form.Item>

          <Form.Item label="From Address" {...formItemLayout} required={true}>
            <AddressSelect
              value={fromUuid}
              disabled={isDeleted}
              style={{ width: '100%' }}
              quickAdd={!!fromCompanyUuid}
              companyUuid={fromCompanyUuid}
              type={[AddressType.Delivery, AddressType.Warehouse]}
              onChange={(uuid: string) => onSetAddressUuid(index, 'from', uuid)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="To Company" {...formItemLayout} required={true}>
            <CompanySelect
              formId={`trips-${index}-to-company-selector`}
              quickCreate
              disabled={isDeleted}
              value={toCompanyUuid}
              style={{ width: '100%' }}
              types={(tripDetail?.toCompanyTypes as string[]) || ['shipperConsignee']}
              onChange={(uuid: string) => onSetCompanyUuid(index, 'to', uuid)}
            />
          </Form.Item>
          <Form.Item label="To Address" {...formItemLayout} required={true}>
            <AddressSelect
              value={toUuid}
              disabled={isDeleted}
              style={{ width: '100%' }}
              quickAdd={!!toCompanyUuid}
              companyUuid={toCompanyUuid}
              type={[AddressType.Delivery, AddressType.Warehouse]}
              onChange={(uuid: string) => onSetAddressUuid(index, 'to', uuid)}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {requiredFields?.tripSeal && (
            <Form.Item
              label="Seal No"
              {...formItemLayout}
              required={requiredFields?.tripSeal?.control === JobTypeRequiredFieldControl.Required}
            >
              <Input
                value={sealNo}
                disabled={isDeleted}
                onChange={onSetSealNo}
                style={{ width: '100%' }}
                placeholder={'Enter seal number...'}
              />
            </Form.Item>
          )}

          {requiredFields?.tripReferences && (
            <Form.Item
              label="References"
              {...formItemLayout}
              required={
                requiredFields?.tripReferences?.control === JobTypeRequiredFieldControl.Required
              }
            >
              <Select
                mode="tags"
                value={references}
                disabled={isDeleted}
                style={{ width: '100%' }}
                onChange={onSetReferences}
                placeholder="Enter references..."
              />
            </Form.Item>
          )}
        </Col>
      </Row>

      {tripDynamicFields.length > 0 ? (
        <Flex vertical>
          {tripDynamicFields.map(field => {
            if (hasDynamicFieldTag(field, DynamicFieldTag.HideFromNewBooking)) {
              return null
            }
            return (
              <Form.Item
                key={field.key}
                label={startCase(field.key)}
                {...{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
                required={field.control === JobTypeRequiredFieldControl.Required}
              >
                <DynamicField
                  field={field}
                  value={trips[index]?.details?.[field.key]}
                  onChange={(value: any) => onSetDynamicFieldValue(value, field.key)}
                />
              </Form.Item>
            )
          })}
        </Flex>
      ) : null}

      <Form.Item
        label={t('common.remarks')}
        {...{ labelCol: { span: 3 }, wrapperCol: { span: 21 } }}
      >
        <Input
          value={remarks}
          autoComplete="off"
          disabled={isDeleted}
          onChange={onSetRemarks}
          placeholder={`Enter trip #${index + 1} remarks...`}
        />
      </Form.Item>

      <div id="trips-button-container" className={styles.tripButtonsContainer}>
        {isLinearFormat && (
          <div className={styles.rearrangeContainer}>
            <div className={styles.gap}>
              {isUpButtonVisible && (
                <Button disabled={isDeleted} icon={<UpOutlined />} onClick={() => onUp(index)} />
              )}
              {isDownButtonVisible && (
                <Button
                  disabled={isDeleted}
                  icon={<DownOutlined />}
                  onClick={() => onDown(index)}
                />
              )}
            </div>

            <div className={styles.gap}>
              {isUpButtonVisible && (
                <Button
                  disabled={isDeleted}
                  icon={<UpOutlined />}
                  onClick={() => onUpRight(index)}
                />
              )}
              {isDownButtonVisible && (
                <Button
                  disabled={isDeleted}
                  icon={<DownOutlined />}
                  onClick={() => onDownRight(index)}
                />
              )}
            </div>
          </div>
        )}

        {!tripDetail?.type && (
          <div className={styles.addMinusTrip}>
            {canAddTrip && (
              <div className={styles.tripButtonsContainer}>
                <span style={{ fontSize: '0.8em', opacity: 0.6 }}>{t('common.trip')}:</span>
                <Tooltip
                  placement="bottom"
                  mouseEnterDelay={0.7}
                  title={t('booking.duplicateTrip')}
                >
                  <Popconfirm
                    arrow={false}
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    title={t('common.duplicateTrip')}
                    onConfirm={() => onDuplicateTrip(index)}
                  >
                    <Button
                      id="trip-duplicate-button"
                      icon={<CopyOutlined />}
                      disabled={isDeleted}
                    />
                  </Popconfirm>
                </Tooltip>

                <Tooltip title={t('common.addTrip')} mouseEnterDelay={0.7}>
                  <Button
                    id="trip-add-button"
                    disabled={isDeleted}
                    icon={<PlusOutlined />}
                    onClick={() => onAddTrip(index)}
                  />
                </Tooltip>
              </div>
            )}

            {index >= 1 &&
              (isNewTrip ? (
                <Tooltip placement="bottom" title={t('common.deleteTrip')} mouseEnterDelay={0.7}>
                  <Button
                    type="dashed"
                    disabled={isDeleted}
                    icon={<MinusOutlined />}
                    onClick={() => onRemove(index)}
                  />
                </Tooltip>
              ) : (
                <Popconfirm
                  arrow={false}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  title={t('common.deleteTrip')}
                  onConfirm={() => onRemove(index)}
                >
                  <Tooltip placement="bottom" title={t('common.deleteTrip')} mouseEnterDelay={0.7}>
                    <Button icon={<MinusOutlined />} disabled={isDeleted} type="dashed" />
                  </Tooltip>
                </Popconfirm>
              ))}
          </div>
        )}

        {isDeleted && (
          <Popconfirm
            arrow={false}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => undeleteTrip()}
            title={t('instructions.areYouSure')}
          >
            <Button danger>{t('booking.undeleteTrip')}</Button>
          </Popconfirm>
        )}

        {isNewTrip && mode === FormMode.Edit && (
          <Popconfirm
            arrow={false}
            title={'Add trip?'}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => createTrip()}
          >
            <Button type="primary">{t('common.create')}</Button>
          </Popconfirm>
        )}
      </div>
    </Flex>
  )
}

export default memo(Trip)
