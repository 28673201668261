import type { LoggedInUserType } from '@/hocs/types/auth0'
import type { MenuProps } from 'antd-v5'

import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import {
  AndroidOutlined,
  AppleOutlined,
  DownOutlined,
  MenuOutlined,
  PlusOutlined,
  UserOutlined
} from '@ant-design/icons'
import { Avatar, Button, Dropdown, Popover } from 'antd-v5'
import includes from 'lodash/includes'

import { handleCheckUpdate } from '@/components/CacheBuster'
import {
  AppVersion,
  NavItem,
  RightNav,
  Separator,
  SettingItem,
  SettingItemLanguage,
  SettingsView,
  UserEmail,
  UserProfile
} from '@/components/Layouts/Options//Styled'
import EntitySystemLink from '@/components/Layouts/Options/EntitySystemLink'
import Logout from '@/components/Logout'
import FeatureGuard from '@/components/Shared/FeatureGuard'
import config from '@/config'
import { useIsMobile } from '@/hooks/use-mobile'
import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import useFeatureFlag from '@/hooks/useFeatureFlag'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import i18n, { languageOptions } from '@/libs/i18n'
import useGlobalCompanyStore from '@/store/globalCompany'
import useUserStore from '@/store/user'
import auth from '@/utils/auth'

const HeaderOptions = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const isMobile = useIsMobile()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const user: LoggedInUserType = auth.getProfile() || useUserStore.use.loggedInUser()

  const { data = [] } = useBaseCompanyType()

  const { isPortalUser, baseCompanyUuids } = useProcessPortalUser(user)

  const [visible, setVisible] = useState(false)
  const [versionDisplay, setVersionDisplay] = useState(config.version)

  const { pathname = '' } = location

  const handleClickChange = useCallback(visible => setVisible(visible), [])

  const noUpdates = useCallback(() => setVersionDisplay('App up-to-date'), [])
  const revertState = useCallback(() => setVersionDisplay(config.version), [])
  const initState = useCallback(() => setVersionDisplay('Checking for updates'), [])

  const changeLanguage = (language: string) => i18n.changeLanguage(language)

  const isTransport = useMemo(
    () => !isPortalUser && !!data.find(d => d.meta?.type === 'TRANSPORT'),
    [isPortalUser, data]
  )

  const { isEnabled } = useFeatureFlag({
    name: 'LC PLANNING',
    baseCompanyUuid: selectedGlobalCompany?.uuid
  })
  const { isEnabled: isFeatureDocumentGeneratorEnabled } = useFeatureFlag({
    name: 'DOCUMENT GENERATOR',
    baseCompanyUuid: selectedGlobalCompany?.uuid
  })

  const Settings = () => (
    <SettingsView>
      <UserProfile>
        <Avatar src={user?.picture} />
        <UserEmail>{user?.email}</UserEmail>
        <AppVersion onClick={() => handleCheckUpdate(initState, noUpdates, revertState)}>
          {versionDisplay}
        </AppVersion>
      </UserProfile>

      <Separator />

      {user?.isSA && (
        <>
          <SettingItem>
            <Link to="/superadmin/base-companies">SuperAdmin</Link>
          </SettingItem>
          <Separator />
        </>
      )}

      {baseCompanyUuids?.length > 0 && !isPortalUser && (
        <>
          <SettingItem>
            <EntitySystemLink />
          </SettingItem>
          <Separator />
        </>
      )}

      {baseCompanyUuids?.length > 0 && (
        <>
          <SettingItem>
            <Link to="/manage/portal-company">{t('header.manage')}</Link>
          </SettingItem>

          <Separator />

          <SettingItem>
            <a href="https://shipxhelp.freshdesk.com/support/home" target="_blank" rel="noreferrer">
              {t('header.help')}
            </a>
          </SettingItem>

          <Separator />

          <SettingItem>
            <span
              className="inline-icon"
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              {t('header.driverApp')}
              <div className="flex flex-row gap-10 cursor-pointer">
                <AndroidOutlined
                  style={{ padding: 0 }}
                  href="https://play.google.com/store/apps/details?id=my.com.swiftlogistics.transport"
                  target="_blank"
                  rel="noreferrer"
                />
                <AppleOutlined
                  style={{ padding: 0 }}
                  href="https://apps.apple.com/us/app/shipx-transport/id1616628481"
                  target="_blank"
                  rel="noreferrer"
                />
              </div>
            </span>
          </SettingItem>
          <Separator />

          <SettingItemLanguage>
            <span>{t('header.language')}</span>
            <div
              style={{
                gap: 20,
                width: '100%',
                maxHeight: 20,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {languageOptions.map(({ label, value }) => {
                const isSelected = i18n.language === value
                return (
                  <p
                    key={value}
                    style={{ cursor: 'pointer', color: isSelected ? '#1890FF' : '#151b26' }}
                    onClick={() => changeLanguage(value)}
                  >
                    {label}
                  </p>
                )
              })}
            </div>
          </SettingItemLanguage>
          <Separator />
        </>
      )}

      <SettingItem>
        <Logout />
      </SettingItem>
    </SettingsView>
  )

  const transportMenu: MenuProps['items'] = [
    {
      key: 'planning',
      label: t('header.planning'),
      children: [
        isEnabled
          ? {
              key: 'header.prePlanning',
              label: (
                <FeatureGuard name="LC PLANNING">
                  <Link to="/pre-planning">{t('header.prePlanning')}</Link>
                </FeatureGuard>
              )
            }
          : null,
        {
          key: 'header.unplanned',
          label: <Link to="/transport/planning/unplanned">{t('header.unplanned')}</Link>
        },
        { key: 'header.byLegs', label: <Link to="/transport/planning">{t('header.byLegs')}</Link> },
        {
          key: 'header.byVehicle',
          label: <Link to="/transport/planning/vehicle">{t('header.byVehicle')}</Link>
        }
      ]
    },
    {
      key: 'operations',
      label: t('header.operations'),
      children: [
        {
          key: 'header.tracking',
          label: <Link to="/transport/operation">{t('header.tracking')}</Link>
        },
        {
          key: 'header.manifests',
          label: <Link to="/transport/manifests">{t('header.manifests')}</Link>
        }
      ]
    },
    {
      key: 'incentives',
      label: t('header.incentives'),
      children: [
        {
          key: 'header.shifts',
          label: <Link to="/transport/operation/incentiveShift">{t('header.shifts')}</Link>
        },
        {
          key: 'header.incentiveVoucher',
          label: (
            <Link to="/transport/operation/incentiveVoucher">{t('header.incentiveVoucher')}</Link>
          )
        }
      ]
    }
  ]

  const dashboardMenu: MenuProps['items'] = [
    {
      label: <Link to="/transport/dashboard">{t('header.overview')}</Link>,
      key: 'header.overview'
    },
    { key: 'header.reports', label: <Link to="/reports">{t('header.reports')}</Link> },
    {
      key: 'header.bookingStatus',
      label: <Link to="/dashboard">{t('header.bookingStatus')}</Link>
    },
    {
      key: 'header.jobStatus',
      label: <Link to="/transport/monitoring/job-status">{t('header.jobStatus')}</Link>
    },
    {
      key: 'header.tripStatus',
      label: <Link to="/transport/monitoring/trip-status">{t('header.tripStatus')}</Link>
    },
    {
      key: 'header.emptyTrips',
      label: <Link to="/transport/monitoring/empty-trips">{t('header.emptyTrips')}</Link>
    },
    {
      key: 'header.legActivities',
      label: (
        <Link to="/transport/monitoring/leg-activities-report">{t('header.legActivities')}</Link>
      )
    },
    {
      key: 'header.bulkFunctions',
      label: <Link to="/bulk-functions">{t('header.bulkFunctions')}</Link>
    },
    isFeatureDocumentGeneratorEnabled
      ? {
          key: 'header.documents',
          label: (
            <FeatureGuard name="DOCUMENT GENERATOR">
              <Link to="/documents">{t('header.documents')}</Link>{' '}
            </FeatureGuard>
          )
        }
      : null
  ]

  const mobileMenu: MenuProps['items'] = [
    {
      key: 'header.newBooking',
      label: (
        <Link to="/bookings/new" className="flex flex-row items-center gap-10">
          <PlusOutlined /> <span>{t('header.newBooking')}</span>
        </Link>
      )
    },
    isTransport
      ? {
          key: 'transport',
          label: 'Transport',
          children: transportMenu
        }
      : null,
    {
      key: 'header.invoices',
      label: <Link to="/vouchers">{t('header.invoices')}</Link>
    },
    !isPortalUser
      ? {
          key: 'dashboard',
          label: 'Dashboard',
          children: dashboardMenu
        }
      : null,
    {
      key: 'more',
      label: 'More',
      children: [
        user?.isSA
          ? {
              key: 'header.superAdmin',
              label: <Link to="/superadmin/base-companies">{t('header.superAdmin')}</Link>
            }
          : null,
        !isPortalUser
          ? {
              key: 'header.entitySystem',
              label: <EntitySystemLink />
            }
          : null,
        {
          key: 'header.manage',
          label: <Link to="/manage/portal-company">{t('header.manage')}</Link>
        },
        {
          key: 'help',
          label: (
            <a href="https://shipxhelp.freshdesk.com/support/home" target="_blank" rel="noreferrer">
              Help
            </a>
          )
        },
        {
          key: 'logout',
          label: <Logout />
        }
      ]
    }
  ]

  return (
    <RightNav type="flex" justify="end" align="middle" style={{ maxHeight: 58 }} gutter={15}>
      {isMobile ? (
        <Dropdown menu={{ items: mobileMenu }} trigger={['click']}>
          <Button style={{ marginRight: 10 }} onClick={e => e.preventDefault()}>
            <MenuOutlined />
          </Button>
        </Dropdown>
      ) : (
        <>
          {baseCompanyUuids?.length > 0 && (
            <NavItem selected={includes(pathname, '/bookings/new')}>
              <Link to="/bookings/new">
                <Button id="create-booking-button">
                  <PlusOutlined />
                  {t('header.newBooking')}
                </Button>
              </Link>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && isTransport && (
            <NavItem selected={includes(pathname, '/transport/')}>
              <Dropdown menu={{ items: transportMenu }}>
                <div className="ant-dropdown-link">
                  {t('header.transport')} <DownOutlined />
                </div>
              </Dropdown>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && (
            <NavItem selected={includes(pathname, '/vouchers')}>
              <Link to="/vouchers">{t('header.invoices')}</Link>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && !isPortalUser && (
            <NavItem selected={includes(pathname, '/transport/dashboard')}>
              <Dropdown menu={{ items: dashboardMenu }}>
                <div className="ant-dropdown-link">
                  {t('header.dashboard')} <DownOutlined />
                </div>
              </Dropdown>
            </NavItem>
          )}

          <NavItem>
            <Popover
              arrow={false}
              open={visible}
              content={Settings}
              placement="bottomRight"
              onOpenChange={handleClickChange}
              styles={{ body: { padding: 0 } }}
            >
              <UserOutlined
                style={{
                  fontSize: 20,
                  paddingRight: 5,
                  cursor: 'pointer',
                  paddingBottom: '0.5rem',
                  color: 'rgb(140,140,140)'
                }}
              />
            </Popover>
          </NavItem>
        </>
      )}
    </RightNav>
  )
}

export default memo(HeaderOptions)
