import type { Booking, DynamicField } from '@/types/graphql'
import {
  DynamicFieldQuery,
  DynamicFieldTag,
  DynamicFieldType,
  QuotationStatus
} from '@/types/graphql'

import _, { get, isObject } from 'lodash'
import moment from 'moment'

import {
  DEPARTMENT_QUERY,
  ENUMS_QUERY,
  GET_COMPANIES_QUERY,
  QUOTATION_QUERY
} from '@/components/Booking/DynamicField/schema'
import { getChronologyDate } from '@/components/Booking/NewBookingForm/SubmitAction/convertValuesToInput'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'

export const rootKeys = ['remarks', 'serviceTypes', 'quotationUuid']

export const getSelectQueryPropsForDynamicField = (
  queryType: DynamicField['query'],
  companyTypes: DynamicField['companyTypes']
) => {
  const departmentQuery = {
    query: DEPARTMENT_QUERY,
    variables: { key: 'bookingDepartments' },
    getData: (data: any) => {
      const val = data?.setting?.setting?.[0]?.value
      return val ? JSON.parse(val).departments : []
    }
  }

  const quotationQuery = {
    query: QUOTATION_QUERY,
    variables: {
      statuses: QuotationStatus.Activated
    },
    getData: (data: any) => {
      return data?.quotations?.rows
    },
    labelFormatter: item => item.quotationNo,
    valueFormatter: item => item.quotationNo
  }

  const companyQuery = {
    query: GET_COMPANIES_QUERY,
    variables: {
      types: companyTypes
    },
    getData: (data: any) => {
      return data?.companies?.rows
    },
    labelFormatter: item => item.name,
    valueFormatter: item => item.uuid
  }

  const enumQuery = {
    query: ENUMS_QUERY,
    variables: {},
    getData: (data: any) => {
      return _(data?.__schema?.types).filter({ kind: 'ENUM' }).map('name').value()
    }
  }

  switch (queryType) {
    case DynamicFieldQuery.Department:
      return departmentQuery
    case DynamicFieldQuery.Quotation:
      return quotationQuery
    case DynamicFieldQuery.Company:
      return companyQuery
    case DynamicFieldQuery.Address:
      return companyQuery
    case DynamicFieldQuery.Enums:
      return enumQuery
  }
}

const getDateFieldPath = (key: string, mode: FormMode = FormMode.Create, booking: Booking = {}) => {
  let path = `details.${key}`

  if (mode === FormMode.Edit) {
    const dateIndex = booking?.chronologies?.findIndex(
      chronology => chronology?.type === key
    ) as number

    path = dateIndex >= 0 ? `chronologies[${dateIndex}].date` : path
  }

  return path
}

export const getDynamicFieldLookupKey = (
  key: string,
  mode: FormMode = FormMode.Create,
  type?: DynamicFieldType | null,
  booking?: Booking | null
) => {
  let fieldKey: any = rootKeys.includes(key || '') ? key || '' : `details.${key}`

  if (type === DynamicFieldType.Date) {
    fieldKey = getDateFieldPath(key, mode, booking || {})
  }

  return fieldKey
}

export const getDynamicFieldInitialValue = (
  key: string,
  dynamicField: DynamicField | null,
  booking: Booking | any = {},
  mode: FormMode = FormMode.Create,
  emptyDate: boolean = false
) => {
  let initialValue = get(booking, key)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [prefixKey, suffixKey] = key.split('.')
  const isDateField = dynamicField?.type === DynamicFieldType.Date

  if (isDateField) {
    if (!emptyDate) {
      initialValue = getChronologyDate(booking?.type, suffixKey)
    }
  }

  if (mode === FormMode.Edit) {
    if (isDateField) {
      const chronDate = booking.chronologies.find(chronology => chronology.type === suffixKey)
      initialValue = moment(chronDate?.date || initialValue || '')
    }

    if (isDateField && key === 'details.ETA') {
      const eta = get(booking, `details.${key}`) || get(booking, key) || {}
      initialValue = moment(eta || '')
    }

    if (dynamicField?.type === DynamicFieldType.Selector) {
      if (dynamicField.query === DynamicFieldQuery.Company) {
        const company = get(booking, `details.${key}`) || get(booking, key) || {}
        initialValue = company.uuid
      }
    }
  }

  if (!initialValue) {
    initialValue = dynamicField?.tags?.includes(DynamicFieldTag.Multiple) ? [] : ''
  }

  if (dynamicField?.query === DynamicFieldQuery.CustomValues && !dynamicField.customValues) {
    initialValue = initialValue || undefined
  }

  if (dynamicField?.type === DynamicFieldType.Text) {
    return initialValue || undefined
  }

  if (dynamicField?.type === DynamicFieldType.Boolean) {
    return initialValue || false
  }

  initialValue =
    isObject(initialValue) && !isDateField ? (initialValue as { uuid: string }).uuid : initialValue

  return initialValue || undefined
}

export const DYNAMIC_FIELD_DATE_FORMAT = 'DD/MM/YYYY HH:mm'

export const hasDynamicFieldTag = (dynamicField: DynamicField | null, targetTag: DynamicFieldTag) =>
  Boolean(dynamicField?.tags?.includes(targetTag))
