import type { BookingDocument } from '@/types/graphql'
import { DocumentStatus } from '@/types/graphql'

import { useState } from 'react'
import { PaperClipOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Popover, Typography } from 'antd-v5'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import Action from '@/components/Voucher/Attachments/Selectables/Action'
import { NoItemsFound } from '@/components/Voucher/Styled'
import { documentType } from '@/utils/labelMap'

type SelectablesProps = {
  selectedDocuments: BookingDocument[]
  selectableDocuments: BookingDocument[]
  onSelectSingleDocument: (document: BookingDocument) => void
  selectable?: boolean
  handleView?: () => void
}

const Selectables = (props: SelectablesProps) => {
  const { selectedDocuments, selectableDocuments, onSelectSingleDocument, selectable, handleView } =
    props

  const [visible, setVisible] = useState(false)

  const renderSelectables = () => {
    if (!selectableDocuments.length) {
      return <NoItemsFound>No documents available.</NoItemsFound>
    }

    const displayableDocuments = filter(
      selectableDocuments,
      doc => doc.status !== DocumentStatus.Uploaded && doc.status !== DocumentStatus.Deleted
    )

    return (
      <>
        <Typography.Text>Select a document</Typography.Text>
        <div className="overflow-auto" style={{ maxHeight: 400 }}>
          {displayableDocuments.map(doc => {
            const selectedDoc = find(
              selectedDocuments,
              selectedDoc => selectedDoc.uuid === doc.uuid
            )
            const isSelected = !isEmpty(selectedDoc)

            return (
              <Flex key={doc.uuid} align="middle" justify="space-around">
                <Col span={18}>
                  {(doc.type && documentType[doc.type as keyof typeof documentType]) ||
                    doc.type ||
                    ''}
                  <Typography.Text className="text-sm">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/booking/document/${doc.uuid}`}
                    >
                      <PaperClipOutlined />
                      {doc.document?.name}
                    </a>
                  </Typography.Text>
                </Col>
                <Col span={6}>
                  <div className="text-center">
                    <Action
                      document={doc}
                      selectable={selectable}
                      isSelected={isSelected}
                      onSelectSingleDocument={onSelectSingleDocument}
                    />
                  </div>
                </Col>
              </Flex>
            )
          })}
        </div>
      </>
    )
  }

  return (
    <Popover
      arrow={false}
      title={null}
      trigger="click"
      open={visible}
      content={renderSelectables()}
      placement="right"
      styles={{ root: { width: 400 } }}
      onOpenChange={newVisible => setVisible(newVisible)}
    >
      <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={handleView} />
    </Popover>
  )
}

export default Selectables
