import type { ProcessFlowDocumentType } from '@/types/graphql'

import { useTranslation } from 'react-i18next'
import { Button, Flex, Modal, Typography } from 'antd-v5'
import filter from 'lodash/filter'

import { documentType } from '@/utils/labelMap'
import { UploadSection } from './Styled'
import UploadButton from './UploadButton'

type UploadZoneProps = {
  visible: boolean
  toggleUploadZone: (visible: boolean) => void
  requiredDocs: ProcessFlowDocumentType[] | any
  refetchBooking?: () => void
}

function UploadZone(props: UploadZoneProps) {
  const { visible, toggleUploadZone, requiredDocs, refetchBooking } = props
  const { t } = useTranslation()

  const mandatoryDocs = filter(requiredDocs, doc => doc.required) || []
  const nonMandatoryDocs = filter(requiredDocs, doc => !doc.required) || []

  const onDoneClick = () => {
    toggleUploadZone(false)
    refetchBooking?.()
  }

  return (
    <Modal
      width="60%"
      okText="Done"
      title={t('bulkUpdate.uploadDocuments')}
      open={visible}
      footer={
        <Flex justify="space-between">
          <Button href="/bookings/new/upload-document">Bulk Upload</Button>
          <Button type="primary" onClick={onDoneClick}>
            Done
          </Button>
        </Flex>
      }
      onCancel={() => toggleUploadZone(false)}
    >
      <Flex vertical gap={10}>
        <Typography.Text strong>Required Documents</Typography.Text>

        <UploadSection>
          {mandatoryDocs.length === 0 && (
            <Typography.Text type="secondary">No documents available.</Typography.Text>
          )}

          {mandatoryDocs.map((doc: ProcessFlowDocumentType) => {
            const uploadLabel = doc.type && documentType[doc.type]
            return (
              <UploadButton
                key={doc.type}
                type={doc.type as string}
                label={uploadLabel || doc.type}
              />
            )
          })}
        </UploadSection>
      </Flex>

      <Flex vertical gap={10}>
        <Typography.Text strong>Optional Documents</Typography.Text>
        <UploadSection>
          {nonMandatoryDocs.length === 0 && (
            <Typography.Text type="secondary">No documents available.</Typography.Text>
          )}
          {nonMandatoryDocs.map((doc: ProcessFlowDocumentType) => {
            const uploadLabel = doc.type ? documentType[doc.type] : undefined

            return (
              <UploadButton
                key={doc.type}
                type={doc.type as string}
                label={uploadLabel || doc.type}
              />
            )
          })}
        </UploadSection>
      </Flex>
    </Modal>
  )
}

export default UploadZone
