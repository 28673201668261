import type { SelectProps } from 'antd/lib/select'

import { forwardRef, memo } from 'react'
import { Select } from 'antd'
import { v4 as uuidv4 } from 'uuid'

interface SharedSelectorProps extends SelectProps {
  dataKey: string
  dataArray: any[]
}

const SharedSelector = forwardRef((props: SharedSelectorProps, ref: any) => {
  const {
    dataKey,
    dataArray,
    filterOption = false,
    placeholder = 'Please select...',
    ...rest
  } = props
  return (
    <Select ref={ref} {...rest} placeholder={placeholder} filterOption={filterOption}>
      {dataArray?.map((data: any) => {
        const optionValue = dataKey ? data[dataKey] : data
        const optionLabel = data.label ? data.label : dataKey ? data[dataKey] : data
        return (
          <Select.Option key={optionValue} value={optionValue}>
            {optionLabel}
          </Select.Option>
        )
      })}
    </Select>
  )
})

export default memo(SharedSelector)
