import type { MenuProps } from 'antd-v5'

import { memo, useCallback, useState } from 'react'
import { MoreOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd-v5'

import Export from '@/components/Shared/ImportExport/Export'
import ImportFormModal from '@/components/Shared/ImportExport/ImportFormModal'
import useGlobalCompanyStore from '@/store/globalCompany'
import impExpUtil from '@/utils/importExport'

const ImportExport = memo(({ style, selectedEntity, filter = {} }: any) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false)
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)

  const toggleDropdownVisibility = useCallback(() => {
    setIsDropdownMenuOpen(!isDropdownMenuOpen)
  }, [isDropdownMenuOpen])

  const toggleModalVisibility = useCallback(() => {
    setIsImportModalOpen(!isImportModalOpen)
  }, [isImportModalOpen])

  const handleClickImport = useCallback(() => {
    toggleDropdownVisibility()
    toggleModalVisibility()
  }, [toggleDropdownVisibility])

  const items: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Export
          filter={filter}
          impExpUtils={impExpUtil[selectedEntity]}
          selectedGlobalCompany={selectedGlobalCompany}
          toggleDropdownVisibility={toggleDropdownVisibility}
        />
      )
    },
    {
      key: 'import',
      label: (
        <>
          <div onClick={handleClickImport}>
            <UploadOutlined /> Import
          </div>
          <ImportFormModal
            selectedGlobalCompany={selectedGlobalCompany}
            setIsDropdownMenuOpen={setIsDropdownMenuOpen}
            setIsImportModalOpen={setIsImportModalOpen}
            isImportModalOpen={isImportModalOpen}
            impExpUtils={impExpUtil[selectedEntity]}
          />
        </>
      )
    }
  ]

  return (
    <Dropdown menu={{ items }} trigger={['hover']}>
      <Button loading={!selectedGlobalCompany?.uuid} style={style}>
        <MoreOutlined /> Import / Export
      </Button>
    </Dropdown>
  )
})

export default ImportExport
