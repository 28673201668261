import type { BaseCompany } from '@/types/graphql'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { Popover } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import pick from 'lodash/pick'

import {
  GuideText,
  IconWrapper,
  NameWrapper,
  SelectedCompany,
  SelectedName,
  SelectedTypes,
  SelectOption
} from '@/components/Layouts/Companies/Styled'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

export default function CompaniesSelection() {
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()

  const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

  const clearSelectedBookingsTable = useBookingStore.use.clearSelectedBookingsTable()

  if (isEmpty(selectedGlobalCompany)) {
    return (
      <SelectedCompany>
        <GuideText>{t('header.noBaseCompanyFound')}</GuideText>
      </SelectedCompany>
    )
  }

  const types = selectedGlobalCompany.types || []

  const handleClickChange = () => setVisible(!visible)

  const handleSelectBc = (selected?: BaseCompany) => {
    if (selected && selectedGlobalCompany.uuid !== selected.uuid) {
      clearSelectedBookingsTable()
      setSelectedGlobalCompany(selected)

      const keys = [
        LOCAL_STORAGE_KEYS.VIEWER_DATA,
        LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES,
        LOCAL_STORAGE_KEYS.TIMELINE_VEHICLE_DEPARTMENTS,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_TABLE,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_VEHICLES_TABLE
      ]
      keys.forEach(key => webStorage.removeItem(key))
      window.location.reload()
    }
  }

  const Content = () => (
    <>
      <GuideText>
        {t('header.allowedCompanies')} ({globalCompanies?.length ?? 0})
      </GuideText>
      {globalCompanies?.map(gc => (
        <SelectOption
          key={gc.uuid}
          onClick={() => handleSelectBc(gc)}
          selected={gc.uuid === selectedGlobalCompany.uuid}
        >
          {gc.name}
        </SelectOption>
      ))}
    </>
  )

  return (
    <SelectedCompany>
      <Popover
        arrow={false}
        open={visible}
        trigger="click"
        placement="bottomLeft"
        styles={{ body: { padding: 0 } }}
        onOpenChange={handleClickChange}
        content={Content}
      >
        <SelectedTypes>{join(types, ', ')}</SelectedTypes>
        <SelectedName type="flex">
          <NameWrapper>{selectedGlobalCompany.name}</NameWrapper>
          <IconWrapper>
            <EditOutlined />
          </IconWrapper>
        </SelectedName>
      </Popover>
    </SelectedCompany>
  )
}
