import { CompanyStatus } from 'App/types/graphql'

import { toUpper } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { isUuid } from 'App/utils/u'
import { getGqlResponse } from '../helpers'
import {
  createEntityGql,
  getCompaniesGql,
  getDataGql,
  getTransportAreaCodesGql,
  updateEntityGql
} from './schema'

const sampleData = [
  {
    no: 1,
    customer: {
      code: 'BBBA00001',
      name: 'Sample Customer Sdn. Bhd.',
      uuid: 'f2a0c965-86fc-419b-ae3d-fce0f38a17e7'
    },
    distance: null,
    from: 'KUL-TEST',
    name: 'Sample Transport Rate',
    to: 'SGR-SEPAN',
    transportType: 'TRANSPORT',
    type: 'areaCode',
    uom: 'TRIP',
    uuid: 'c99ea3a6-fef5-402e-9803-001b244477bf',
    vendor: {
      code: 'BBBA00006',
      name: 'Sample Vendor Sdn. Bhd.',
      uuid: '97efb7a1-5c92-4bee-8913-ad0061c960ec'
    },
    rates_1: {
      cargoType: 'DG1',
      rateType: 'FIXED',
      rate: '1200',
      size: '40-foot',
      transactionType: 'SELL',
      transportType: 'CURTAINSIDER',
      type: 'TRIP'
    },
    // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
    zzz: ''
  }
]

const remarks =
  '*Note: Please first export to update. The required fields are: from (i.e. input an area code), name, to (i.e. input an area code), transportType (e.g. TRANSPORT). ' +
  'The required rates fields are: rates_1.rateType (e.g. FIXED), rates_1.rate (e.g. 1200), rates_1.transactionType (e.g. SELL), and rates_1.type (e.g. TRIP).'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Customer',
    dataIndex: 'customer.code',
    key: 'customer.code',
    ellipsis: true
  },
  {
    title: 'From*',
    dataIndex: 'from',
    key: 'from'
  },
  {
    title: 'Name*',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true
  },
  {
    title: 'To*',
    dataIndex: 'to',
    key: 'to'
  },
  {
    title: 'Transport Type*',
    dataIndex: 'transportType',
    key: 'transportType'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid',
    ellipsis: true
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor.code',
    key: 'vendor.code',
    ellipsis: true
  },
  {
    title: 'rates_1*',
    dataIndex: 'rates[0].rate',
    key: 'rates_1.rate'
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  company: {
    uuid: {},
    code: {}
  },
  areaCode: {}
}

const findNullInputs = async (selectedGlobalCompany: any, transportRate: any) => {
  if (!transportRate?.from)
    return { nullError: 'From is required, input an area code, e.g. SGR-KLANG.' }
  if (!transportRate?.name) return { nullError: 'Name is required.' }
  if (!transportRate?.to)
    return { nullError: 'To is required, input an area code, e.g. JHR-PASIR GDG.' }

  if (!transportRate?.rates) {
    // This is sufficient. Don't check for every transportRate.rates[i], because some may have 1 rate while some may have more rates
    const errMsg =
      'Rates are required. ' +
      'The required rates fields are: rates_1.rateType (e.g. FIXED), rates_1.rate (e.g. 1200), rates_1.transactionType (e.g. SELL), and rates_1.type (e.g. TRIP).'
    return { nullError: errMsg }
  }

  if (Object.keys(cache.areaCode)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getTransportAreaCodesGql, {
        limit: 10000
      })
      const areaCodes = results?.data?.transportAreaCodes?.rows
      for (let i = 0; i < areaCodes?.length; i++) {
        cache.areaCode[areaCodes[i]?.code] = areaCodes[i]?.uuid
      }
    } catch (error) {
      logger.error('transportRatesHelper cache getTransportAreaCodesGql error', error)
      return error
    }
  }

  if (transportRate?.from) {
    if (!cache.areaCode[transportRate?.from]) {
      return {
        nullError: `The provided area code from="${transportRate?.from}" is not found, please create it in the Transport Area Codes page first.`
      }
    }
  }

  if (transportRate?.to) {
    if (!cache.areaCode[transportRate?.to]) {
      return {
        nullError: `The provided area code to="${transportRate?.to}" is not found, please create it in the Transport Area Codes page first.`
      }
    }
  }

  if (Object.keys(cache.company.uuid)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getCompaniesGql, {
        limit: 10000,
        statuses: CompanyStatus.Activated
      })
      const companies = results?.data?.companies?.rows

      for (let i = 0; i < companies?.length; i++) {
        cache.company.uuid[companies[i]?.uuid] = companies[i]
        cache.company.code[companies[i]?.code] = companies[i]?.uuid
      }
    } catch (error) {
      logger.error('transportRatesHelper cache getCompaniesGql error', error)
      return error
    }
  }

  if (transportRate?.customer?.uuid && isUuid(transportRate?.customer?.uuid)) {
    if (!cache.company.uuid[transportRate?.customer?.uuid]) {
      return {
        nullError: `The provided customer.uuid="${transportRate?.customer?.uuid}" is not found, please create this company in the Companies page, or leave the customer fields blank.`
      }
    }
  } else if (transportRate?.customer?.name || transportRate?.customer?.code) {
    if (!transportRate?.customer?.code)
      return { nullError: 'Customer company code is required (e.g. BBBA00001).' }

    if (cache.company.code[transportRate?.customer?.code]) {
      transportRate.customer.uuid = cache.company.code[transportRate?.customer?.code]
    } else {
      return {
        nullError: `The provided customer.code="${transportRate?.customer?.code}" is not found, please create this company in the Companies page, or leave the customer fields blank.`
      }
    }
  }

  if (transportRate?.vendor?.uuid && isUuid(transportRate?.vendor?.uuid)) {
    if (!cache.company.uuid[transportRate?.vendor?.uuid]) {
      return {
        nullError: `The provided vendor.uuid="${transportRate?.vendor?.uuid}" is not found, please create this company in the Companies page, or leave the vendor fields blank.`
      }
    }
  } else if (transportRate?.vendor?.name || transportRate?.vendor?.code) {
    if (!transportRate?.vendor?.code)
      return { nullError: 'Vendor company code is required (e.g. BBBA00001).' }

    if (cache.company.code[transportRate?.vendor?.code]) {
      transportRate.vendor.uuid = cache.company.code[transportRate?.vendor?.code]
    } else {
      return {
        nullError: `The provided vendor.code="${transportRate?.vendor?.code}" is not found, please create this company in the Companies page, or leave the vendor fields blank.`
      }
    }
  }
}

const populateTransportRateObj = async (obj: any) => {
  const currentRates = obj.rates
    ?.map((rate: any) => {
      if (!rate) return {}

      return {
        cargoType: rate.cargoType,
        rate: rate.rate ? Number(rate.rate) : null,
        rateType: rate.rateType,
        size: rate.size?.toString(),
        transactionType: rate.transactionType,
        transportType: rate.transportType,
        type: rate.type,
        rateUom: rate.rateUom,
        rateRule: rate.rateRule,
        rateValue: rate.rateValue
      }
    })
    ?.filter((rate: any) => rate?.transactionType && rate?.type && rate?.rate && rate?.rateType)

  const transportRateObj: any = {
    uuid: obj.uuid || null,
    customerUuid: obj.customer?.uuid?.trim() || null,
    distance: obj.distance ? Number(obj.distance) : null,
    from: toUpper(obj.from.trim()),
    name: obj.name,
    rates: currentRates,
    to: toUpper(obj.to.trim()),
    transportType: obj.transportType,
    type: obj.type,
    uom: obj.uom,
    vendorUuid: obj.vendor?.uuid?.trim() || null
  }

  return transportRateObj
}

const transportRatesHelper = {
  queryName: 'transportRates',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      const transportRates = results?.data?.transportRates?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row
      }))

      transportRates?.forEach((item: any) => {
        if (item.rates?.length === 0) {
          // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
          item.zzz = ''
        }

        if (item.rates?.length > 0) {
          let ratesCount = 0

          item.rates?.forEach((rate: any) => {
            rate.zzz = ''
            ratesCount += 1
            item[`rates_${ratesCount}`] = rate
          })
        }
        delete item.rates
      })

      return transportRates
    } catch (error) {
      logger.error('transAreaCodesHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (transportRateData: any, selectedGlobalCompany: any) => {
    const { ...transportRate } = transportRateData

    if (!transportRate) return

    const nullInputError = await findNullInputs(selectedGlobalCompany, transportRate)
    if (nullInputError) return nullInputError
    const transportRateObj = await populateTransportRateObj(transportRate)

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 100000 })
        const transportRates = results?.data?.transportRates?.rows

        for (let i = 0; i < transportRates.length; i++) {
          cache.uuid[transportRates[i].uuid] = transportRates[i]
        }
      } catch (error) {
        logger.error('transportRatesHelper cache getDataGql error', error)
        return error
      }
    }

    let mutationResults
    // Update transport rate
    if (cache.uuid[transportRate.uuid]) {
      try {
        transportRateObj.uuid = transportRate.uuid.trim()
        mutationResults = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: transportRateObj
        })
      } catch (error) {
        logger.error('transportRatesHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new transport rate
      try {
        transportRateObj.uuid = uuidv4()
        mutationResults = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: transportRateObj
        })
      } catch (error) {
        logger.error('transportRatesHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    return mutationResults
  },

  mapFromCsv: (csvData: any) => {
    if (!csvData || csvData.length <= 0) {
      return []
    }

    for (let i = 0; i < csvData.length; i++) {
      csvData[i].from = csvData[i].from?.toString()?.trim()
      csvData[i].name = csvData[i].name?.toString()?.trim()
      csvData[i].to = csvData[i].to?.toString()?.trim()
      csvData[i].transportType = csvData[i].transportType?.toString()?.trim()
      csvData[i].type = csvData[i].type?.toString()?.trim()
      csvData[i].uom = csvData[i].uom?.toString()?.trim()

      if (csvData[i].rates_1) {
        csvData[i].rates = []

        let ratesCount = 0

        Object.keys(csvData[i]).forEach((key: any) => {
          if (key.indexOf('rates_') > -1) {
            ratesCount += 1
          }
        })

        for (let j = 0; j < ratesCount; j++) {
          if (!csvData[i][`rates_${j + 1}`]) {
            break
          } else {
            csvData[i][`rates_${j + 1}`].cargoType =
              csvData[i][`rates_${j + 1}`].cargoType?.toString()?.trim() || null
            csvData[i][`rates_${j + 1}`].rate =
              csvData[i][`rates_${j + 1}`].rate &&
              Number.parseFloat(csvData[i][`rates_${j + 1}`].rate)
            csvData[i][`rates_${j + 1}`].rateType = csvData[i][`rates_${j + 1}`].rateType
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].size = csvData[i][`rates_${j + 1}`].size
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].transactionType = csvData[i][
              `rates_${j + 1}`
            ].transactionType
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].transportType =
              csvData[i][`rates_${j + 1}`].transportType?.toString()?.trim() || null
            csvData[i][`rates_${j + 1}`].type = csvData[i][`rates_${j + 1}`].type
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].rateUom = csvData[i][`rates_${j + 1}`].rateUom
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].rateRule = csvData[i][`rates_${j + 1}`].rateRule
              ?.toString()
              ?.trim()
            csvData[i][`rates_${j + 1}`].rateValue =
              csvData[i][`rates_${j + 1}`].rateValue &&
              Number.parseFloat(csvData[i][`rates_${j + 1}`].rateValue)

            csvData[i].rates.push(csvData[i][`rates_${j + 1}`])

            delete csvData[i][`rates_${j + 1}`]
          }
        }
      }

      csvData[i].importStatus = 'pending'
      csvData[i].key = i + 1
    }

    return csvData
  },

  sampleData,

  remarks,

  tableColumns
}

export default transportRatesHelper
