import { gql } from '@apollo/client'

export const BASE_COMPANY_CURRENCY = gql(`
  query baseCompanyCurrencies($uuid: UUID!) {
    baseCompany(uuid: $uuid) {
      uuid
      currencies {
        uuid
        code
        name
      }
    }
  }
`)

export const GET_CURRENCIES = gql(`
  query Currencies($query: String, $limit: Int, $offset: Int) {
    currencies(query: $query, limit: $limit, offset: $offset) {
      rows {
        uuid
        name
        code
      }
    }
  }
`)
