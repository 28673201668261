export const getDataGql = `
  query holidaysExport($limit: Int, $offset: Int) {
    holidays(input: { limit: $limit, offset: $offset }) {
      rows {
        uuid
        name
        date
        description
        status
        updatedAt
        createdAt
      }
    }
  }
`

export const createEntityGql = `
  mutation createHolidayImport($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      uuid
    }
  }
`

export const updateEntityGql = `
  mutation updateHolidayImport($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      uuid
    }
  }
`
