import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { List, Spin } from 'antd-v5'

import {
  DOCUMENT_GENERATOR_TEMPLATES_QUERY,
  GENERATE_DOCUMENT
} from '@/components/Booking/General/DocumentGeneratorSection/schema'
import DocumentGeneratorTemplateCard from '@/components/Booking/General/DocumentGeneratorSection/TemplateSelectionList/DocumentCreatorTemplateCard'
import { useBookingStore } from '@/store/booking'
import respHandler from '@/utils/responseHandler'

interface TemplateSelectionListProps {
  handleInitialDocumentGenerated: (generatedDocument: any) => void
}

const TemplateSelectionList = ({ handleInitialDocumentGenerated }: TemplateSelectionListProps) => {
  const { t } = useTranslation()

  const bookingUuidForDocumentGenerator = useBookingStore.use.bookingUuidForDocumentGenerator()

  const [generateDocument] = useMutation(GENERATE_DOCUMENT)

  const handleClick = async (documentGeneratorTemplate: any) => {
    try {
      const { data } = await generateDocument({
        variables: {
          input: {
            groups: [bookingUuidForDocumentGenerator ?? ''],
            code: documentGeneratorTemplate.code
          }
        }
      })

      handleInitialDocumentGenerated(data?.generateDocument)
    } catch (error) {
      respHandler(error, 'error')
    }
  }

  const {
    data,
    loading,
    error,
    refetch: documentCreatorTemplatesRefetch
  } = useQuery(DOCUMENT_GENERATOR_TEMPLATES_QUERY)

  if (error) {
    const errorCodes = error.graphQLErrors.map((e: any) => e.extensions.exception.statusCode)

    if (errorCodes.includes(403)) {
      return <span>{t('error.permission.general')}</span>
    }

    return <span>{t('common.errorOccurred')}</span>
  }

  const handlePageChange = (page, pageSize) => {
    documentCreatorTemplatesRefetch({
      limit: pageSize,
      offset: (page - 1) * pageSize
    })
  }

  const { rows = [], pageInfo } = data?.documentGeneratorTemplates || {}

  const pagination = pageInfo && {
    total: pageInfo.count ?? 0,
    pageSize: pageInfo.limit ?? 0,
    current: pageInfo.offset ?? 0 / (pageInfo.limit ?? 0) + 1,
    onChange: handlePageChange
  }

  return (
    <Spin spinning={loading}>
      <List
        dataSource={rows || []}
        pagination={pagination}
        grid={{ gutter: 16, column: 4 }}
        renderItem={item => (
          <List.Item>
            <DocumentGeneratorTemplateCard documentGeneratorTemplate={item} onClick={handleClick} />
          </List.Item>
        )}
      />
    </Spin>
  )
}

export default TemplateSelectionList
