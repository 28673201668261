import type { MenuProps } from 'antd-v5'

import { useState } from 'react'
import { EllipsisOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd-v5'
import includes from 'lodash/includes'

import { StyledButton } from '@/components/ButtonWrapper'
import PermissionCheckBox from '@/components/Manage/Permissions/TableView/PermissionCheckBox'

const MiscPermissions = ({ selectedRole, allowedPermissions, miscResource, miscPermissions }) => {
  const [showMisc, setShowMisc] = useState(false)

  const items: MenuProps['items'] = miscPermissions.map((mp, index) => {
    const isAllowed = includes(allowedPermissions, mp)
    return {
      key: index,
      label: (
        <>
          <PermissionCheckBox
            key={miscResource}
            displayName={mp}
            resourceType={mp}
            isAllowed={isAllowed}
            resourceName={miscResource}
            selectedRole={selectedRole}
          />
        </>
      )
    }
  })

  return (
    <Dropdown menu={{ items }}>
      <StyledButton onClick={() => setShowMisc(!showMisc)}>
        <EllipsisOutlined style={{ color: 'black' }} />
      </StyledButton>
    </Dropdown>
  )
}

export default MiscPermissions
