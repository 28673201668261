import type { PortalCompany } from '@/types/graphql'

import { useCallback, useEffect } from 'react'
import { DownloadOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Button, Flex, Input } from 'antd-v5'

import config from '@/config'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import { PORTAL_COMPANIES_QUERY } from '@/utils/importExport/portalCompanies/schema'
import { logger } from '@/utils/logger'
import handleResponse from '@/utils/responseHandler'
import { getXlsx, hasPermissionError, removeNestedObjProps } from '@/utils/u'

type TableActionsProps = {
  isLoading: boolean
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TableActions = ({ handleSearch, isLoading }: TableActionsProps) => {
  const { isPortalUser } = useProcessPortalUser()

  const [getPortalCompanies, { loading, data, error }] = useLazyQuery(PORTAL_COMPANIES_QUERY, {
    fetchPolicy: 'cache-and-network'
  })

  const queryData = useCallback(() => {
    getPortalCompanies({ variables: { limit: 10000 } })
  }, [getPortalCompanies])

  const getExcelData = useCallback(
    (data: PortalCompany[]) => {
      if (!data?.length) return

      const formattedData: any[] = data?.map((d: PortalCompany, i: number) =>
        removeNestedObjProps({ no: i + 1, ...d }, ['__typename'])
      )
      getXlsx(
        formattedData,
        `Portal_Companies_${new Date().toLocaleDateString()}`,
        'Portal_Companies'
      )
    },
    [data]
  )

  if (error) {
    logger.error('PortalCompany Export Error.', error)
    if (hasPermissionError(error)) {
      handleResponse(config.youDoNotHavePermissionToPerformThisAction, 'warning')
    } else {
      handleResponse(config.anErrorOccurredPleaseTryAgainLater, 'error')
    }
  }

  useEffect(() => {
    getExcelData(data?.portalCompanies?.rows)
  }, [data, getExcelData])

  return (
    <Flex justify="space-between" align="center" gap="middle">
      <Input.Search
        loading={isLoading}
        prefix={<SearchOutlined />}
        allowClear
        onChange={handleSearch}
        placeholder="Search a portal company here..."
      />

      <Button loading={loading} onClick={queryData}>
        <DownloadOutlined />
        Export
      </Button>

      {!isPortalUser && (
        <Button href="/manage/portal-company/create">
          <PlusOutlined />
          Create
        </Button>
      )}
    </Flex>
  )
}

export default TableActions
