import type { TransportJob } from '@/types/graphql'
import type { ReactNode } from 'react'

import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useExpanded, useTable } from 'react-table'
import { SyncOutlined } from '@ant-design/icons'
import { Button, Divider, Flex, Tooltip, Typography } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'

import AddLegForm from '@/components/Transport/Components/Modals/JobActivityModal/AddLegForm'
import { getColumns } from '@/components/Transport/Components/Modals/JobActivityModal/columns'
import { formatLegTableData } from '@/components/Transport/Components/Modals/JobActivityModal/formatLegTableData'
import LegActivities from '@/components/Transport/Components/Modals/JobActivityModal/LegActivities'
import { LegTableStyles } from '@/components/Transport/Components/Modals/JobActivityModal/LegTableStyles'
import UpdateLegForm from '@/components/Transport/Components/Modals/JobActivityModal/UpdateLegForm'
import { isActiveLeg } from '@/components/Transport/Utils/jobHelper'

interface LegTableProps {
  refetchLegs?: () => void
  legs: TransportJob[]
  legsLoading?: boolean
  vehicleCodeOrReg?: string
}

const LegTable = (props: LegTableProps) => {
  const { legs, legsLoading, refetchLegs, vehicleCodeOrReg } = props

  const [showDeleted, setShowDeleted] = useState<boolean>(true)
  const [legFormVisible, setLegFormVisible] = useState<boolean>(false)
  const [legActivitiesVisible, setLegActivitiesVisible] = useState<boolean>(false)
  const [selectedLeg, setSelectedLeg] = useState<TransportJob | undefined>(undefined)

  const clonedLegs: TransportJob[] = useMemo(() => cloneDeep(legs), [legs])

  const deletedLeg: TransportJob | undefined = useMemo(
    () => clonedLegs?.find((leg: TransportJob) => !isActiveLeg(leg)),
    [clonedLegs]
  )
  const memoData: TransportJob[] = useMemo(
    () => (showDeleted ? clonedLegs : clonedLegs?.filter((leg: TransportJob) => isActiveLeg(leg))),
    [clonedLegs, showDeleted]
  )

  const setFormVisibility = useCallback(
    (legUuid: string) => {
      if (selectedLeg?.legUuid !== legUuid) {
        const currentLeg = memoData?.find((leg: TransportJob) => leg.legUuid === legUuid)
        setSelectedLeg(currentLeg)
      }
      setLegFormVisible(true)
    },
    [memoData, selectedLeg]
  )

  const onCloseForm = useCallback(() => {
    setSelectedLeg(undefined)
    setLegFormVisible(false)
  }, [])

  const onSetShowDeleted = useCallback(() => {
    setShowDeleted((state: boolean) => !state)
  }, [])

  const setActivitiesVisibility = useCallback(
    (legUuid: string) => {
      if (selectedLeg?.legUuid !== legUuid) {
        const currentLeg = memoData?.find((leg: TransportJob) => leg.legUuid === legUuid)
        setSelectedLeg(currentLeg)
      }
      setLegActivitiesVisible(true)
    },
    [memoData, selectedLeg?.legUuid]
  )

  useEffect(() => {
    const noEndOut: TransportJob | undefined = memoData?.find((data: TransportJob) => !data?.endOut)
    if (!noEndOut) return

    const isDeleted: boolean = !isActiveLeg(noEndOut)
    if (!selectedLeg?.legUuid && noEndOut && !isDeleted) {
      setFormVisibility(noEndOut.legUuid)
    }
  }, [memoData, selectedLeg, setFormVisibility])

  const columns = useMemo(
    () => getColumns(setFormVisibility, refetchLegs, setActivitiesVisibility),
    [refetchLegs, setFormVisibility, setActivitiesVisibility]
  )

  const tableData = useMemo(
    () => formatLegTableData(memoData, vehicleCodeOrReg) || [],
    [memoData, vehicleCodeOrReg]
  )

  const newSelectedLeg = useMemo(
    () => memoData?.find((l: TransportJob) => l?.legUuid === selectedLeg?.legUuid),
    [memoData, selectedLeg]
  )

  const activeLegs = useMemo(
    () => clonedLegs?.filter((l: TransportJob) => isActiveLeg(l)),
    [clonedLegs]
  )

  const { rows, prepareRow, headerGroups, getTableProps, visibleColumns, getTableBodyProps } =
    useTable(
      {
        /* @ts-ignore */
        columns,
        data: tableData
      },
      useExpanded
    )

  return (
    <>
      <Divider>
        <Flex gap="middle">
          <Typography.Text>{'Jobs > Trips > Legs'}</Typography.Text>
          <Tooltip title="Refresh legs">
            <Button
              icon={<SyncOutlined />}
              size="small"
              loading={legsLoading}
              onClick={refetchLegs}
            />
          </Tooltip>
        </Flex>
      </Divider>

      {deletedLeg && (
        <Flex justify="end">
          <Button onClick={onSetShowDeleted} style={{ color: 'rgba(0, 0, 0, 0.45)' }} type="link">
            {showDeleted ? 'Hide' : 'Show'} Deleted
          </Button>
        </Flex>
      )}

      <LegTableStyles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups?.map(headerGroup => {
              const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()
              return (
                <tr key={key} {...headerGroupProps}>
                  {headerGroup.headers?.map(column => {
                    const { key, ...columnProps } = column.getHeaderProps()
                    return (
                      <th key={key} {...columnProps}>
                        {column.render('Header') as ReactNode}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()

              return (
                <Fragment key={key}>
                  <tr {...rowProps}>
                    {row.cells.map((cell: any) => {
                      const isDeleted: boolean = !isActiveLeg(row.original as TransportJob)
                      const renderByCell = cell.column.id === 'legCrudButtons'
                      const isSelected = row.original.legUuid === selectedLeg?.legUuid
                      const bgColor = isSelected
                        ? '#e6f7ff'
                        : isDeleted
                          ? 'rgba(0,0,0,0.06)'
                          : undefined

                      if (renderByCell) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            key={cell.getCellProps().key}
                            style={{ backgroundColor: bgColor, ...cell.column.style }}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      }

                      return (
                        <td
                          {...cell.getCellProps()}
                          key={cell.getCellProps().key}
                          style={{ backgroundColor: bgColor, ...cell.column.style }}
                        >
                          <Button
                            disabled={!!isDeleted}
                            type="link"
                            style={{
                              padding: 0,
                              width: '100%',
                              display: 'block',
                              fontSize: '10px',
                              textAlign: 'left',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              color: 'rgba(0, 0, 0, 0.65)'
                            }}
                            onClick={() => setFormVisibility(row.original.legUuid)}
                          >
                            {cell.render('Cell')}
                          </Button>
                        </td>
                      )
                    })}
                  </tr>
                  {/* @ts-expect-error - object is there but weaked type issue */}
                  {row.isExpanded && (
                    <AddLegForm
                      row={row}
                      rowProps={rowProps}
                      refetchLegs={refetchLegs}
                      visibleColumns={visibleColumns}
                    />
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </LegTableStyles>

      {legFormVisible && (
        <UpdateLegForm
          legs={activeLegs}
          leg={newSelectedLeg}
          onCloseForm={onCloseForm}
          legsLoading={legsLoading}
          refetchLegs={refetchLegs}
        />
      )}

      {legActivitiesVisible && (
        <LegActivities leg={newSelectedLeg} setLegActivitiesVisible={setLegActivitiesVisible} />
      )}
    </>
  )
}

export default memo(LegTable)
