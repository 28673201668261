import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { Typography } from 'antd-v5'
import delay from 'lodash/delay'
import queryString from 'query-string'
import { compose } from 'recompose'

import Auth0 from '@/hocs/auth0'
import withLogin from '@/hocs/login'
import useAuthStore from '@/store/auth'
import { logger } from '@/utils/logger'

type CheckInPageProps = {
  auth: any
}

const CheckInPage = ({ auth }: CheckInPageProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { error, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  const [legacyLoginError, setLegacyLoginError] = useState<string>()

  const setUser = useAuthStore.use.setUser()
  const isLegacyMode = useAuthStore.use.isLegacyMode()

  useEffect(() => {
    // if user logged in using universal login
    if (!isLegacyMode) {
      // need delay because of the Auth0 issue
      delay(() => {
        getAccessTokenSilently({ detailedResponse: true })
          .then(data => {
            const result = setUser(data)
            if (result) navigate('/')
          })
          .catch(error => {
            if (error.error === 'login_required') return loginWithRedirect()
            logger.error('CheckIn getAccessTokenSilently', error)
          })
      }, 2000)
    } else {
      // if user logged in using legacy login
      const hashedProps = queryString.parse(location.hash)

      if (auth.hasLoginError(hashedProps)) {
        setLegacyLoginError(t('error.couldNotLogInPleaseTryAgainLater'))
        delay(() => navigate('/auth/sign-in'), 2000)
      }

      if (hashedProps?.error_description === 'Unable to configure verification page.') {
        setLegacyLoginError(t('error.couldNotLogginCookiesIssue'))
        delay(() => navigate('/auth/sign-in'), 2000)
      }

      if (hashedProps?.error_description === 'Unknown or invalid login ticket.') {
        setLegacyLoginError(t('error.corsIssue'))
        delay(() => navigate('/auth/sign-in'), 2000)
      }

      if (!auth.checkSecret(hashedProps.state)) {
        delay(() => navigate('/auth/sign-in'), 2000)
      } else {
        delay(() => auth.digestHashes(hashedProps, navigate), 2000)
      }
    }
  }, [])

  return (
    <div
      className="flex flex-col h-full align-center justify-center items-center"
      style={{ height: '100vh' }}
    >
      <>
        {legacyLoginError ? (
          <>
            <Typography.Paragraph type="secondary">{legacyLoginError}</Typography.Paragraph>
            <Typography.Paragraph type="secondary">
              {t('login.redirectingToNewPage')}
            </Typography.Paragraph>
          </>
        ) : error ? (
          <Typography.Paragraph type="secondary">{error.message}</Typography.Paragraph>
        ) : (
          <Typography.Paragraph type="secondary">{t('index.preparing')}</Typography.Paragraph>
        )}
        {!legacyLoginError && <LoadingOutlined spin />}
      </>
    </div>
  )
}

// @ts-expect-error compose types
export default compose(Auth0, withLogin)(CheckInPage)
