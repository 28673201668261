import type { Booking } from '@/types/graphql'
import type { UploadProps } from 'antd-v5'

import { UploadOutlined } from '@ant-design/icons'
import { Button, Flex, message, Upload } from 'antd-v5'

import config from '@/config'
import { withBooking } from '@/contexts/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

type UploadButtonProps = {
  type: string
  label: string
  taxUuid?: string
  booking: Booking
}

function UploadButton({ label, type, booking }: UploadButtonProps) {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const uploadActions: UploadProps = {
    name: 'files',
    action: `${config.api.baseUrl}/booking/upload-document`,
    headers: {
      'base-company-uuid': selectedGlobalCompany.uuid,
      authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`
    },
    data: {
      uuid: booking?.uuid,
      type: type
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }

  return (
    <Upload {...uploadActions}>
      <Button type="dashed" style={{ height: '100%', padding: 10 }}>
        <Flex vertical gap={10}>
          <UploadOutlined className="text-primary text-2xl" />
          {label}
        </Flex>
      </Button>
    </Upload>
  )
}

export default withBooking(UploadButton)
